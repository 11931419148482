import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {User} from '../../model/User';
import {SuperClient} from '../../model/SuperClient';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userSubject: ReplaySubject<User>;
    superClientContextSubject: ReplaySubject<SuperClient>;
    EDIT_ACCESS = 'edit';
    ADMIN_ACCESS = 'admin';
    private user: User;
    private superClients: SuperClient[] = [];
    private superClientContext: SuperClient = {
        id: 0
    };

    constructor() {
        this.userSubject = new ReplaySubject<User>();
        this.superClientContextSubject = new ReplaySubject<SuperClient>();
    }

    setUser(user: User) {
        this.user = user;
        this.superClients = user.clients;
        this.superClientContext = user.clients[0];
        this.superClientContextSubject.next(this.superClientContext);
        this.userSubject.next(user);
    }

    getUserSuperClients() {
        return this.superClients;
    }

    getUser(): User {
        return this.user;
    }

    setSuperClientContext(superClient: SuperClient) {
        this.superClientContext = superClient;
        this.superClientContextSubject.next(this.superClientContext);
    }

    getSuperClientContext(): SuperClient {
        return this.superClientContext;
    }

    getSuperClientContextSubject(): ReplaySubject<SuperClient> {
        return this.superClientContextSubject;
    }

    hasEditPermission(): boolean {
        let hasEdit = false;
        if (this.user !== undefined) {
            const userRole = this.user.appRoles
                .filter(role => (role.toLowerCase().includes(this.EDIT_ACCESS)
                    || (role.toLowerCase().includes(this.ADMIN_ACCESS))));
            hasEdit = (userRole.length > 0) ? true : false;
            return hasEdit;
        }
    }

    hasAdminPermission(): boolean {
        let hasAdmin = false;
        if (this.user !== undefined) {
            const userRole = this.user.appRoles
                .filter(role => (role.toLowerCase().includes(this.ADMIN_ACCESS)));
            hasAdmin = (userRole.length > 0) ? true : false;
            return hasAdmin;
        }
    }

    unSubscribeAuthUser() {
        this.userSubject.unsubscribe();
    }
}

