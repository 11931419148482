<div>
    <div id="recommendationsBanner"></div>

    <div id="recommendationsButtons">
        <span>
            <button id="backToPreviousPage" class="cvs-btn-text-only" (click)="navigateToRecommendationsTable()">
                <mat-icon svgIcon="angle-left--xs"></mat-icon>
                Back to {{blob}} Requirements Table
            </button>
        </span>
        <span>
            <button class="cvs-btn-ghost" (click)="navigateToRecommendationsTable()">Exit Requirement</button>
        </span>
    </div>

    <div class="form-title">
        <h1 *ngIf="!isUpdateTypeGpiAdHoc">Product Requirements Form</h1>
        <h1 *ngIf="isUpdateTypeGpiAdHoc">GPI Requirements Form</h1>
        <app-status-chip [statusId]="statusId" [displayStatusChip]="true"
                         style="margin: 0 0 10px 10px;"></app-status-chip>
    </div>
    <div *ngIf="!isUpdateTypeGpiAdHoc">
        <app-ndc-header
                [fromSourceInput]="fromSource"
                [medispanTrackerResponse]="medispanTrackerResponse">
        </app-ndc-header>
    </div>
    <div *ngIf="isUpdateTypeGpiAdHoc">
        <app-gpi-header
                [fromSourceInput]="fromSource"
                [medispanTrackerResponse]="medispanTrackerResponse">
        </app-gpi-header>
    </div>

    <app-recommendations-notes
            [isDisabled]="false"
            [medispanTrackerResponse]="medispanTrackerResponse"
            [mainTabName]="tabs[0]"
            [subTabIndex]="tabs[1]">
    </app-recommendations-notes>

    <span id="sideNavAndForm">
        <app-recommendation-left-navigation class="side-nav"
                                            [programIndex]="programIndexSelected"
                                            [selectedFormularyNode]="selectedFormulary"
                                            [selectedUmProgramNode]="selectedUmProgram"
                                            [selectedProductDrugCoverageNode]="selectedProductDrugCoverage"
                                            [lob]="blob"
                                            (selectedFormularyEmitter)="onPanelFormularySelectionChange($event)"
                                            (selectedUmProgramEmitter)="onPanelUmProgramSelectionChange($event)"
                                            (selectedProductDrugCoverageEmitter)="onPanelProductDrugCoverageSelectionChange($event)"
                                            (selectedParentEmitter)="onPanelTreeChange($event)"
        >
        </app-recommendation-left-navigation>

        <app-formulary-form-entry *ngIf="programIndexSelected === TREE_LEVELS.FORMULARY.code"
                                  [formularyNumber]="selectedFormulary?.formularyNumber"
                                  [medispanTrackerResponse]="medispanTrackerResponse"
                                  (isFormularyFormSaved)="isFormularyFormSaved($event)">
        </app-formulary-form-entry>

        <app-um-program-form-entry *ngIf="programIndexSelected === TREE_LEVELS.PRIOR_AUTH.code
        || programIndexSelected === TREE_LEVELS.STEP_THERAPY.code
        || programIndexSelected === TREE_LEVELS.QUANTITY_LIMIT.code"
                                   [umProgram]="selectedUmProgram"
                                   [medispanTrackerResponse]="medispanTrackerResponse"
                                   [programIndexSelected]="programIndexSelected"
                                   (isUmProgramFormSaved)="isUmProgramFormSaved($event)"
        >
        </app-um-program-form-entry>
        <app-product-drug-coverage-form-entry *ngIf="programIndexSelected === TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code"
                                              [blob]="blob"
                                              [productDrugCoverage]="selectedProductDrugCoverage"
                                              [medispanTrackerResponse]="medispanTrackerResponse"
                                              (isPdcFormSaved)="isProductDrugCoverageSaved($event)"
        >
        </app-product-drug-coverage-form-entry>

    </span>
</div>
