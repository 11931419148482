export class StepTherapyRequirements {
    encryptedRecordId: string;
    businessLobCd: string;
    clientUmProgramId: string;
    statusCd: string;
    attachmentType?: string;
    attachmentLevel?: string;
    attachmentMony?: string;
    attachmentRxOtcCd?: string;
    attachmentMedDCd?: string;
    applyDefaultAttachment?: string;
    notes?: string;
    customRejectMessage?: string;
    stepTypeCd: string;
}
