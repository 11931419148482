<div id="successBanner"></div>
<div>
    <div class="buttons-container">
        <button class="cvs-btn-text-only" id="backToPreviousPage" (click)="backToPreviousPage()">
            <mat-icon svgIcon="angle-left--xs"></mat-icon>
            <span *ngIf="workFlowSource.includes(fromSource)">Back to Workflow</span>
            <span *ngIf="fromSource === PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH">Back to Drug Search</span>
            <span *ngIf="fromSource === PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH">Back to Formulary Search</span>
        </button>
        <div *ngIf="hasEditPermission && showWorkFlowButton"
            class="right-justified margin-bottom-10">
            <app-custom-button [class]="'cvs-btn-primary'"
                                id="moveInWorkflowButton"
                                buttonText="Move in Workflow"
                                [placement]="'left'"
                                [disabled]="moveInWorkflowButton"
                                [showArrow]="true"
                                [matMenuTriggerFor]="moveInWorkflowMenu"
                                [svgIcon]="'angle-down--s'">
            </app-custom-button>
            <mat-menu #moveInWorkflowMenu="matMenu">
                <ng-container *ngFor="let dropDownItem of dropDownMenuItems">
                    <button mat-menu-item (click)="onMenuItemClick(dropDownItem)">
                        {{ dropDownItem.description }}
                    </button>
                </ng-container>
            </mat-menu>
        </div>
    </div>
    <div class="header-and-status">
        <h1 *ngIf="!isUpdateTypeGpiAdHoc" class="title">Product Overview</h1>
        <h1 *ngIf="isUpdateTypeGpiAdHoc" class="title">GPI Overview</h1>
        <app-status-chip
            [statusId]="statusId"
            [displayStatusChip]="displayStatusChip()">
        </app-status-chip>
    </div>
    <div *ngIf="!isUpdateTypeGpiAdHoc">
        <app-ndc-header
            [fromSourceInput]="fromSource"
            [medispanTrackerResponse]="medispanTrackerResponse">
        </app-ndc-header>
    </div>

    <div *ngIf="isUpdateTypeGpiAdHoc">
        <app-gpi-header
            [fromSourceInput]="fromSource"
            [medispanTrackerResponse]="medispanTrackerResponse">
        </app-gpi-header>
    </div>
    <ng-container *ngIf="fromSource !== PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH && fromSource !== PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH && fromSource !== PRODUCT_OVERVIEW_SOURCE.WORKFLOW_MEDISPAN">
       <app-recommendations-notes
        [isDisabled]="isDisabled"
        [medispanTrackerResponse]="medispanTrackerResponse"
        [mainTabName]="mainTabName"
        [subTabIndex]="subTabIndex">
       </app-recommendations-notes>
    </ng-container>

    <mat-tab-group class="pds-primary-tab" (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngIf="!isUpdateTypeGpiAdHoc" label="Product Information"></mat-tab>
        <mat-tab *ngIf="!isUpdateTypeGpiAdHoc && showFormularyInformationTab" label="Formulary Information"></mat-tab>
        <mat-tab *ngIf="showRecommendationsTab" label="Requirements"></mat-tab>
        <mat-tab *ngIf="showTrackingDatesTab" label="Tracking Dates"></mat-tab>
    </mat-tab-group>

    <cvs-loader-overlay-container *ngIf="!isUpdateTypeGpiAdHoc && isProductInformationTabSelected"
                                  id="spinner"
                                  [isLoading]="showProductInformationSpinner"
                                  loadingMessage="Loading">
    <app-product-information [productOverviewData]="productOverviewData"></app-product-information>
    </cvs-loader-overlay-container>

    <div *ngIf="isFormularyInfoTabSelected ">
        <app-formulary-information [ndc]="NDC.value" [ndcBusinessLob]="ndcBusinessLob" [selectedLOBS]="selectedLOBS"></app-formulary-information>
    </div>
    <div *ngIf="isRecommendationsTabSelected">
        <app-recommendations
            [ndcBusinessLob]="ndcBusinessLob"
            [medispanTrackerResponse]="medispanTrackerResponse"
            [mainTabName]="mainTabName"
            [subTabIndex]="subTabIndex"
            [fromSource]="fromSource">
        </app-recommendations>
    </div>
    <div *ngIf="isTrackingTabSelected">
        <app-tracking-dates
          [medispanTrackerResponse]="medispanTrackerResponse">
        </app-tracking-dates>
    </div>
</div>
<ng-template #formRef>
    <app-move-in-workflow-modal></app-move-in-workflow-modal>
</ng-template>

