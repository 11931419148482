import {Component, Input, OnInit} from '@angular/core';
import {ICellEditorParams, ICellRenderer, ICellRendererParams} from '@ag-grid-community/core';
import {ICellEditorAngularComp} from '@ag-grid-community/angular';

@Component({
    selector: 'app-custom-state',
    templateUrl: './custom-state.component.html',
    styleUrls: ['./custom-state.component.scss']
})
export class CustomStateComponent implements OnInit, ICellEditorAngularComp, ICellRenderer {
    @Input() status = '0';
    state = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
        '0': {description: 'No Edits Made', icon: 'not-allowed--xs', iconColor: 'black'},
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '1': {description: 'Incomplete', icon: 'warning-circle-f--xs', iconColor: 'red'},
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '2': {description: 'Saved', icon: 'check-circle-f--xs', iconColor: 'green'}
    };

    constructor() { }

    ngOnInit(): void {
    }

    agInit(params: ICellEditorParams): void {
        if (params.value){
            this.status = params.value;
        }
    }

    getValue(): any {
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
