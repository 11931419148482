<div class="gpi-info-grid">
    <span *ngFor="let value of gpiDetailHeader; let j=index">
        <mat-card-title class="gpi-info-header"
                        [class.no-margin-bottom]="value.value === undefined ">{{value.name}}</mat-card-title>
        <mat-card-content *ngIf="value.value !== undefined"
                            class="gpi-info-value">{{value.value}}</mat-card-content>
        <ng-container *ngIf="value.field !== undefined">
            <mat-radio-group id="{{value.name.replaceAll(' ','')}}"
                                [(ngModel)]="groupGPISelection[value.field]"
                                ngDefaultControl
                                [disabled]="!hasEditPermission"
                                (change)="saveSelections()"
                                class="no-left-padding font-bold">
            <span class="checkbox-area">
                <mat-radio-button value="Y" id="{{value.name.replaceAll(' ','')}}-YES">Yes</mat-radio-button>
                <mat-radio-button value="N" id="{{value.name.replaceAll(' ','')}}-NO">No</mat-radio-button>
            </span>
            </mat-radio-group>
        </ng-container>
    </span>
</div>
