<ng-container>
  <mat-card-header>
    <mat-card-title>Requirement Dates</mat-card-title>
    <mat-card-subtitle>{{ recommendationDate?.adhocUpdate ? 'Ad Hoc Date' : 'Medispan Date'}}</mat-card-subtitle>
    <mat-card-subtitle class="date">{{ medAdHocDate }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Discussion Approval Date</mat-label>
      <input matInput [matDatepicker]="discussionDate" [formControl]="approvalDate"/>
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="discussionDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #discussionDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
      <mat-label class="notes-label">Discussion Approval Notes</mat-label>
      <textarea>{{recommendationDate?.approvalNotes}}</textarea>
  </mat-card-content>
</ng-container>
