import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {RECORD_STATUSES} from '../../enum/RecordStatus';

@Component({
    selector: 'app-status-chip',
    templateUrl: './status-chip.component.html',
    styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent implements OnInit, OnChanges {
    @Input() statusId: number = undefined;
    @Input() displayStatusChip = false;
    statusDescription: string = undefined;
    statusChipClass: string = undefined;
    statusIconClass: string = undefined;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes) {
        this.setStatusChipStyle();
    }

    setStatusChipStyle() {
        if (+this.statusId) {
            this.statusDescription = RECORD_STATUSES.find(f => f.status.valueOf() === this.statusId).description;
            this.statusChipClass = this.statusDescription.toLowerCase().replace(/ |&/g, '-');
            this.statusIconClass = `${this.statusChipClass}-icon`;
        }
    }
}
