<app-business-lob-tabs
    (selectedTab)="onBusinessLobTabSelectionChange($event)"
    [ndcBusinessLob]="ndcBusinessLob"
    [selectedLOBS]="selectedLOBS"
    [isPrimaryTab]="false">
</app-business-lob-tabs>
<cvs-loader-overlay-container id="formularySpinner" [isLoading]="showFormularySpinner" loadingMessage="Loading">
    <h2 class="page-heading-productOverview-formulary">Current Formularies</h2>
    <span>Below are the current formularies.</span>
    <app-cvs-ag-grid id="formularyInfoGrid"
                     class="ag-theme-balham margin-top"
                     [gridOptions]="formularyInfoGridOptions"
                     [modules]="modules"
                     [rowData]="formularyDrugInfo"
                     [popupParent]="popupParent"
                     [disableCvsExport]="true"
                     [domLayout]="'autoHeight'">
    </app-cvs-ag-grid>
</cvs-loader-overlay-container>
