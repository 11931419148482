<div class="header-parent">
    <ng-container *ngIf="params.hasEditPermission && !params.tooltip && !params.popoverTemplateRef">
        <mat-checkbox #checkbox (change)="headerCheckChange($event)" id="{{params.id}}"></mat-checkbox>
    </ng-container>
    <div ref="eLabel"
         class="ag-header-cell-label"
         role="presentation"
         unselectable="on"
         class="header-elabel"
         (click)="onSortRequested($event)">
            <span ref="eText" class="header-text ag-header-cell-text" unselectable="on">&nbsp;{{params.displayName}}&nbsp;
                    <span *ngIf="params.column.isFilterActive()"
                          class="ag-icon ag-icon-filter"
                          unselectable="on"
                          role="presentation"></span>
                    <span *ngIf="ascSort" class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
                    <span *ngIf="descSort" class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
            </span>
    </div>
    <span *ngIf="params.tooltip">
        <mat-icon class="tooltipIcon"
                  svgIcon="info-circle--s"
                  [cvsTooltip]="params.tooltip"
                  showArrow
                  placement="right"></mat-icon>
    </span>
    <mat-icon #popoverIcon id="popoverIcon" *ngIf="params.popoverTemplateRef"
              class="tooltipIcon"
              svgIcon="info-circle--s"></mat-icon>
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button ag-header-menu-always-show" aria-hidden="true">
            <span #menuButton
                  (click)="onMenuClicked()"
                  class="ag-icon ag-icon-menu"
                  unselectable="on"
                  role="presentation"></span>
    </span>
</div>
