import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MasterDrugResponse} from '../../model/MasterDrugResponse';
import {HeaderInfoBlock} from '../product-overview.component';

@Component({
    selector: 'app-product-information',
    templateUrl: './product-information.component.html',
    styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit, OnChanges {

    @Input() productOverviewData: MasterDrugResponse[];
    ndcDetails: HeaderInfoBlock[];

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ndcDetails = changes.productOverviewData?.currentValue ?
            this.buildNDCDetails(changes.productOverviewData.currentValue[0]) : undefined;
    }

    buildNDCDetails(masterDrugResponse: MasterDrugResponse): HeaderInfoBlock[] {
        return (
            [
                {
                    name: 'Additional Info', value:
                [
                    {name: 'Therapeutic Class', value: masterDrugResponse?.therapeuticClass},
                    {name: 'Therapeutic Category', value: masterDrugResponse?.therapeuticCategory},
                    {name: 'Active/Inactive', value: ''},
                    {name: 'Effective Date', value: masterDrugResponse?.effectiveDate},
                    {name: 'Obsolete Date', value: masterDrugResponse?.obsoleteDate},
                    {name: 'Manufacturer', value: masterDrugResponse?.manufacturer}
                ]
                },
                {
                    name: 'Product Designation', value: [
                        {name: 'B/G/T', value: masterDrugResponse?.brandNameCode},
                        {name: 'NDA', value: masterDrugResponse?.fdaAppType === 'NDA' ? 'Y' : 'N'},
                        {name: 'BLA', value: masterDrugResponse?.fdaAppType === 'BLA' ? 'Y' : 'N'},
                        {name: 'ANDA', value: masterDrugResponse?.fdaAppType === 'ANDA' ? 'Y' : 'N'},
                        {name: 'RxOTC', value: masterDrugResponse?.rxOtc},
                        {name: 'DEA', value: masterDrugResponse?.deaCode}
                    ]
                },
                {
                    name: 'Packaging', value: [
                        {name: 'Pkg Qty', value: masterDrugResponse?.packagedQuantity},
                        {name: 'Total Pkg Qty', value: masterDrugResponse?.totalPackagedQuantity},
                        {name: 'Pkg Size', value: ''},
                        {name: 'Pkg UOM', value: ''},
                        {name: 'Type', value: masterDrugResponse?.productType},
                        {name: 'Repackaging', value: masterDrugResponse?.repackaged}
                    ]
                },
                {
                    name: 'Formulation', value: [
                        {name: 'Strength', value: masterDrugResponse?.strength},
                        {name: 'Dosage', value: ''},
                        {name: 'Form', value: masterDrugResponse?.dosageForm},
                        {name: 'ROA', value: masterDrugResponse?.routeOfAdmin},
                        {name: 'Third Party Exception', value: masterDrugResponse?.thirdPartyException}
                    ]
                },
                {
                    name: 'Med D', value: [
                        {name: 'Med D', value: masterDrugResponse?.medD},
                        {name: 'RxCUI', value: masterDrugResponse?.rxcui},
                        {name: 'DDID', value: masterDrugResponse?.ddid},
                        {name: 'NSDE', value: masterDrugResponse?.ndse},
                        {name: 'Part Labeler', value: ''}
                    ]
                },
                {
                    name: 'Pricing', value: [
                        {name: 'AWP Pricing', value: masterDrugResponse?.awpPricing}
                    ]
                },
                {
                    name: 'Monograph Info', value: [
                        {name: 'FDA Approved Indication', value: masterDrugResponse?.fdaApprovedIndication},
                        {name: 'Dosage Administration', value: masterDrugResponse?.dosageAdministration},
                        {name: 'How Supplied', value: masterDrugResponse?.howSupplied}
                    ]
                }
            ]);
    }

}
