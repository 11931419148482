import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {
    AgGridEvent,
    CellClassParams,
    ColDef,
    ExcelExportParams,
    FirstDataRenderedEvent,
    GridOptions,
    IRowNode,
    RowDataUpdatedEvent
} from '@ag-grid-community/core';
import {formatDate} from '@angular/common';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {take} from 'rxjs/operators';
import {PRODUCT_OVERVIEW_SOURCE} from 'src/app/enum/ProductOverviewSource';
import {RECORD_STATUS} from 'src/app/enum/RecordStatus';
import {getUpdateTypeDisplayNameForWorkflow} from 'src/app/mapper/hierarchyUpdateType';
import {WorkflowResponse} from 'src/app/model/WorkflowResponse';
import {AppService} from 'src/app/service/app/app.service';
import {TrackerDataService} from 'src/app/service/tracker-data/tracker-data.service';
import {WorkflowService} from 'src/app/service/workflow/workflow.service';
import {
    dateTruncation, dateFormatter, gridHyperLinkSelectorByUpdateType, gridHyperLinkSelector, isValidDate
} from 'src/app/utility/utility';
import moment from 'moment';

@Component({
    selector: 'app-workflow-no-action-list',
    templateUrl: './workflow-no-action-list.component.html',
    styleUrls: ['./workflow-no-action-list.component.scss']
})
export class WorkflowNoActionListComponent implements OnInit, OnDestroy {
    @Input() mainTabName = '';
    @Input() navigatedFromLink = '';
    @Output() selectedRowCount = new EventEmitter();

    statuses = [RECORD_STATUS.NO_ACTION];
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString',
        cellClassRules: {
            lightGreenRow: (params: CellClassParams) => (params.node.rowIndex || 0) % 2 !== 0,
        }
    };
    isLoading = true;
    superClientIndex;
    filteredWorkflowNoActionData: WorkflowResponse[] = [];

    columnDefs: ColDef[] = [
        {
            headerName: 'Date', field: 'weekDate', valueGetter: params => dateTruncation(params.data.weekDate),
            valueFormatter: params => dateFormatter(params.data.weekDate), filter: 'agSetColumnFilter', width: 130,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        },
        {
            headerName: 'Update Type', field: 'updateType', filter: 'agSetColumnFilter', width: 195,
            valueGetter: (params) => getUpdateTypeDisplayNameForWorkflow(params.data.updateType),
            filterParams: {
                comparator: (value1, value2) => {
                    if (value1.hierarchy === value2.hierarchy) {
                        return 0;
                    }
                    return value1.hierarchy > value2.hierarchy ? 1 : -1;
                }
            }
        },
        {
            headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelectorByUpdateType(params.data.updateType),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_NO_ACTION,
                mainTabName: this.mainTabName,
                subTabIndex: undefined,
                navigatedFromLink: this.navigatedFromLink
            }
        },
        {headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', width: 200},
        {
            headerName: 'NDC', field: 'ndc', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelector(params.data.ndc, 'N/A'),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_NO_ACTION,
                mainTabName: this.mainTabName,
                subTabIndex: undefined,
                navigatedFromLink: this.navigatedFromLink
            }
        },
        {headerName: 'Label Name', field: 'labelName', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'Line of Business', field: 'businessLob', filter: 'agSetColumnFilter', width: 140},
        {headerName: 'Notes', field: 'note', filter: 'agSetColumnFilter', width: 200}];

    workflowNoActionGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        excelStyles: [{
            id: 'header',
            font: {
                bold: true
            },
            alignment: {
                horizontal: 'Center'
            },
            borders: {
                borderBottom: {
                    lineStyle: 'Double'
                }
            },
            interior: {
                color: '#8b8585',
                patternColor: '#8b8585',
                pattern: 'Solid'
            }
        }, {
            id: 'excelString',
            dataType: 'String',
            alignment: {
                horizontal: 'Left'
            }
        }, {
            id: 'lightGreenRow',
            interior: {
                color: '#b9dca6',
                patternColor: '#b9dca6',
                pattern: 'Solid',
            }
        }
        ]
    };
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    isDisabled = true;
    picker: any;
    endDate = new Date();
    startDate = moment().subtract(29, 'days').toDate();
    maxDate = new Date();
    dateRangeError = 'Error: Provide a valid date range within 01/01/1995 to today\'s date.';

    subscriptions = [];
    popupParent: HTMLElement | null = document.querySelector('body');
    showProductNotesChecked = false;
    gridHeight: string;
    private currentRowNodeSelection: IRowNode[];

    constructor(private trackerDataService: TrackerDataService,
        private appService: AppService,
        private workflowService: WorkflowService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.subscribeToWorkflowMessage();
        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.getData();
    }

    getData() {
        this.isLoading = true;
        this.workflowNoActionGridOptions.suppressNoRowsOverlay = true;
        const formattedStartDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en_US');
        const formattedEndDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en_US');
        this.trackerDataService.getWorkflowData(RECORD_STATUS.NO_ACTION, this.superClientIndex, formattedStartDate, formattedEndDate)
            .pipe(take(1))
            .subscribe(response => {
                if (response) {
                    this.filteredWorkflowNoActionData = response;
                    this.isDisabled = false;
                } else {
                    this.workflowNoActionGridOptions.api.setRowData([]);
                    this.isDisabled = true;
                }
                this.workflowNoActionGridOptions.suppressNoRowsOverlay = false;
                this.workflowNoActionGridOptions.api.showNoRowsOverlay();
                this.isLoading = false;
            });
    }

    setGridRowHeight(rows: number) {
        if (rows <= 20) {
            this.workflowNoActionGridOptions.domLayout = 'autoHeight';
            this.gridHeight = '';
        } else {
            this.gridHeight = '62.6vh';
        }
    }

    onSelectionChanged(event: AgGridEvent) {
        this.currentRowNodeSelection = event.api.getSelectedNodes();
        this.selectedRowCount.emit(event.api.getSelectedNodes());
    }

    subscribeToWorkflowMessage() {
        this.subscriptions.push(this.workflowService.getMessageProvider().subscribe(workflowMessage => {
            this.workflowService.processWorkflowMessage(this.mainTabName, undefined, this.statuses,
                this.workflowNoActionGridOptions, this.filteredWorkflowNoActionData,
                workflowMessage, () => this.getData());
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    getDates(event) {
        this.startDate = event[0];
        this.endDate = event[1];
        this.getData();
        this.isDisabled = this.filteredWorkflowNoActionData.length <= 0;
        this.filterNoActionDataByNote();
        const startDateString = this.startDate ? dateTruncation(this.startDate.toISOString()) : null;
        const endDateString = this.endDate ? dateTruncation(this.endDate.toISOString()) : null;
        if (this.validateDateWithMaxDate(startDateString)) {
            this.getDocument().getElementById('mat-date-range-input-0')?.focus();
        } else if (this.validateDateWithMaxDate(endDateString)) {
            this.getDocument().getElementById('endDate').focus();
        }
    }

    exportToExcel() {
        const formattedStartDate = formatDate(this.startDate, 'yyyyMMdd', 'en-US');
        const formattedEndDate = formatDate(this.endDate, 'yyyyMMdd', 'en-US');
        const sheetName = 'No Action';
        const fileName = `${sheetName}_${formattedStartDate}_${formattedEndDate}.xlsx`;
        this.workflowNoActionGridOptions.api?.exportDataAsExcel({fileName, sheetName} as ExcelExportParams);
    }

    onShowProductNotesClick() {
        this.showProductNotesChecked = !this.showProductNotesChecked;
        if (this.showProductNotesChecked) {
            this.filterNoActionDataByNote();
        } else {
            this.getData();
        }
    }

    filterNoActionDataByNote() {
        if (this.showProductNotesChecked) {
            this.filteredWorkflowNoActionData = this.filteredWorkflowNoActionData
                .filter(item => item.note);
        }
    }

    validateDateWithMaxDate(date: string) {
        return !isValidDate(date, this.maxDate.toISOString());
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    getDocument() {
        return document;
    }
}
