import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-customer-requirement-lifecycle',
    templateUrl: './customer-requirement-lifecycle.component.html',
    styleUrls: ['./customer-requirement-lifecycle.component.scss']
})
export class CustomerRequirementLifecycleComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
