
<div style="display: flex">
    <div style="width: 60%">
        <h2 class="page-heading-productOverview-recommendation">Formulary Requirements</h2>
        <span *ngIf="(hasEditPermission && (ndcBusinessLob===selectedBusinessLobName))">Provide tiering and attachment details for the following formularies below:</span>
        <span *ngIf="(hasEditPermission && (ndcBusinessLob !== selectedBusinessLobName))">Below formularies are view only. Select the corresponding business line of business in the tab above to make edits.</span>
    </div>
    <div *ngIf="(hasEditPermission && (ndcBusinessLob===selectedBusinessLobName))" class="right-justified">
        <button id="tableActionsButton" class="cvs-btn-ghost" [matMenuTriggerFor]="menu1">
            <mat-icon svgIcon="angle-down--s" class="pds-transition-base pds-icon-rotation"></mat-icon>
            Table Actions
        </button>
        <mat-menu #menu1="matMenu">
            <button class="cvs-btn-text-only"
                    mat-menu-item
                    [routerLink]="'/recommendationsForm'"
                    [state]="recommendationFormState">
                <mat-icon svgIcon="{{tableAction.icon}}"></mat-icon>
                {{tableAction.description}}
            </button>
            <button class="cvs-btn-text-only" mat-menu-item
                    [disabled]="disableCopyRecommendationsToLikeProducts"
                    [matTooltip] ="disableCopyRecommendationsToLikeProducts? 'Edit requirements to copy': ''"
                    [matTooltipPosition] ="'below'"
                    [matTooltipClass]="'below'"
            (click)="copyRecommendationsToLikeProducts()">
                <mat-icon svgIcon="duplicate--s"></mat-icon>
                Copy Requirements to Like Products (GPI 12)
            </button>
        </mat-menu>
    </div>
</div>
<span id="blobGrid">
<div class="margin-top requirementGrid">
    <cvs-loader-overlay-container id="formularySpinner" [isLoading]="showRequirementSpinner"
                                  loadingMessage="Loading">
        <app-cvs-ag-grid id="requirementsGrid"
                         class="ag-theme-balham margin-top"
                         [gridOptions]="activeGridOptions"
                         [modules]="modules"
                         [rowData]="requirementDrugInfo"
                         [getContextMenuItems]="getContextMenuItems"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [context]="context"
                         [ngStyle]="{'height': gridHeight}"
                       >
        </app-cvs-ag-grid>
    </cvs-loader-overlay-container>
</div>
</span>

<ng-template #copyPasteModalRef>
    <app-multi-select-modal></app-multi-select-modal>
</ng-template>
<ng-template #massCopyGridModalRef>
    <app-grid-display-modal noRecordsFoundMessage="No like products (GPI 12) found.">
    </app-grid-display-modal>
</ng-template>
