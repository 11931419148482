import {WORKFLOW_TABS} from '../WorkflowTab';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    AgGridEvent,
    ColDef,
    FirstDataRenderedEvent,
    GridOptions, IDetailCellRendererParams,
    IRowNode,
    RowDataUpdatedEvent,
    SuppressKeyboardEventParams
} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {PRODUCT_OVERVIEW_SOURCE} from 'src/app/enum/ProductOverviewSource';
import {RECORD_STATUS} from 'src/app/enum/RecordStatus';
import {getUpdateTypeDisplayNameForWorkflow} from 'src/app/mapper/hierarchyUpdateType';
import {AppService} from 'src/app/service/app/app.service';
import {TrackerDataService} from 'src/app/service/tracker-data/tracker-data.service';
import {UserService} from 'src/app/service/user/user.service';
import {WorkflowService} from 'src/app/service/workflow/workflow.service';
import {
    dateTruncation,
    dateFormatter,
    gridHyperLinkSelectorByUpdateType,
    gridHyperLinkSelector, caseNameHeader
} from 'src/app/utility/utility';
import {finalize, take} from 'rxjs/operators';
import {RecordService} from '../../service/record/record.service';
import {ApprovalTrackingCase} from '../../model/ApprovalTrackingCase';
import {MasterDetailModule} from '@ag-grid-enterprise/master-detail';
import {saveAs} from 'file-saver';
import {formatDate} from '@angular/common';
import {ReportService} from '../../service/report/report.service';
import {CVSAlertType, CVSBannerComponentData, CVSBannerService, CVSBannerType} from 'angular-component-library';

@Component({
    selector: 'app-workflow-operations-and-implementation-list',
    templateUrl: './workflow-operations-and-implementation-list.component.html',
    styleUrls: ['./workflow-operations-and-implementation-list.component.scss']
})
export class WorkflowOperationsAndImplementationListComponent implements OnInit, OnChanges {

    @Input() mainTabName = '';
    @Input() subTabIndex = 0;
    @Input() navigatedFromLink = '';
    @Output() selectedRows = new EventEmitter<any>();
    @Output() isLoadingSpinner = new EventEmitter<boolean>();
    public context = {this: this};
    // eslint-disable-next-line max-len
    statuses = [RECORD_STATUS.OPERATIONS_CHECKPOINT, RECORD_STATUS.PENDING_CODING, RECORD_STATUS.PENDING_TESTING, RECORD_STATUS.PENDING_APPROVAL];
    modules = [SetFilterModule, ColumnsToolPanelModule, MenuModule, ClientSideRowModelModule,
        MasterDetailModule, ClipboardModule, ExcelExportModule];
    crmReportDownloadSpinner = false;

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };

    approvalCasesData: ApprovalTrackingCase[] = [];
    popupParent: HTMLElement | null = document.querySelector('body');

    detailColumnDefs: ColDef[] = [
        {
            headerName: 'Date', field: 'weekDate',
            valueGetter: params => dateTruncation(params.data.weekDate),
            valueFormatter: params => dateFormatter(params.data.weekDate), filter: 'agSetColumnFilter', width: 130,
            headerCheckboxSelection: () => false,
            headerCheckboxSelectionFilteredOnly: false,
            checkboxSelection: ()=> false,
            showDisabledCheckboxes: true
        },
        {
            headerName: 'Update Type', field: 'updateType', filter: 'agSetColumnFilter', width: 195,
            valueGetter: (params) => getUpdateTypeDisplayNameForWorkflow(params.data.updateType),
            filterParams: {
                comparator: (value1, value2) => {
                    if (value1.hierarchy === value2.hierarchy) {
                        return 0;
                    }
                    return value1.hierarchy > value2.hierarchy ? 1 : -1;
                }
            }
        },
        {
            headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelectorByUpdateType(params.data.updateType),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION,
                mainTabName: this.mainTabName,
                subTabIndex: this.subTabIndex,
                navigatedFromLink: this.navigatedFromLink
            }
        },
        {headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', width: 200},
        {
            headerName: 'NDC', field: 'ndc', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelector(params.data.ndc, 'N/A'),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION,
                mainTabName: this.mainTabName,
                subTabIndex: this.subTabIndex,
                navigatedFromLink: this.navigatedFromLink
            },
        },
        {headerName: 'Label Name', field: 'labelName', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'Line of Business', field: 'businessLob', filter: 'agSetColumnFilter', width: 140},
        {
            headerName: 'Notes', field: 'note', filter: 'agSetColumnFilter', width: 200,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true, editable: (params) => this.userService.hasEditPermission(),
            cellEditorPopupPosition: 'under',
            cellEditorParams: {cols: 80, maxLength: 5000},
            onCellValueChanged: (params) => this.recordService
                .saveNote(params.data.encryptedRecordId, params.data.note, this.userService.getSuperClientContext().id).subscribe(),
            suppressKeyboardEvent: (params) => this.suppressTabKey(params),
            tooltipField: 'note',
            tooltipComponent: 'customTooltipComponent',
            tooltipComponentParams: {title: 'Notes'}
        }];

    columnDefs: ColDef[] = [
        {
            cellRenderer: 'agGroupCellRenderer',
            width: 50,
            filter: false,
            suppressMenu: true,
            sortable: false,
        },
        {
            headerName: 'Case Name',
            valueGetter: params => caseNameHeader(params.data),
            headerCheckboxSelection: () => this.isCheckBoxEnabledForSelection(),
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: () => this.isCheckBoxEnabledForSelection(),
            filter: 'agSetColumnFilter',
            flex: 1
        },
        {
            headerName: 'Effective Date',
            valueGetter: params => dateTruncation(params.data.effectiveDate),
            valueFormatter: params => dateFormatter(params.data.effectiveDate),
            filter: 'agSetColumnFilter',
            flex: 1
        },
        {
            headerName: 'Case Notes',
            field: 'caseNotes',
            filter: 'agSetColumnFilter',
            flex: 1
        },
    ];

    approvalCasesGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined
        },
    };
    detailCellRendererParams: IDetailCellRendererParams = {
        detailGridOptions: {
            popupParent: this.popupParent,
            domLayout: 'autoHeight',
            context: this.context,
            columnDefs: this.detailColumnDefs,
            defaultColDef: this.defaultColDef,
            rowSelection: 'multiple',
            excelStyles: [{
                id: 'excelString',
                dataType: 'String'
            }],
            defaultExcelExportParams: {
                sheetName: undefined
            },
            suppressRowClickSelection: true
        },
        getDetailRowData: (params) => {
            params.successCallback(params?.data?.workflowResponse);
        }
    } as IDetailCellRendererParams;

    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    superClientIndex;
    subscriptions = [];
    gridHeight: string;
    hasRowsSelect: boolean;
    currentRowNodeSelection: IRowNode[];

    constructor(private trackerDataService: TrackerDataService,
        private appService: AppService,
        private workflowService: WorkflowService,
        private userService: UserService,
        private recordService: RecordService,
        private reportService: ReportService,
        private bannerService: CVSBannerService) {
    }

    ngOnInit(): void {
        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.detailColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.mainTabName = this.mainTabName;
        this.detailColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.subTabIndex = this.subTabIndex;
        this.detailColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.detailColumnDefs.find(cd => cd.field === 'gpi').cellRendererParams.mainTabName = this.mainTabName;
        this.detailColumnDefs.find(cd => cd.field === 'gpi').cellRendererParams.subTabIndex = this.subTabIndex;
        this.detailColumnDefs.find(cd => cd.field === 'gpi').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.subscribeToWorkflowMessage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getWorkflowData(changes.subTabIndex?.currentValue);
    }

    setGridRowHeight(rows: number) {
        if (rows <= 20) {
            this.approvalCasesGridOptions.domLayout = 'autoHeight';
            this.gridHeight = '';
        } else {
            this.gridHeight = '62.6vh';
        }
    }

    getWorkflowData(subTabIndex: number) {
        this.approvalCasesGridOptions.suppressNoRowsOverlay = true;
        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.initializeData(this.statuses[subTabIndex]);
        this.approvalCasesGridOptions.defaultExcelExportParams.sheetName =
            WORKFLOW_TABS.TAB3OPERATIONS_AND_IMPLEMENTATION.subTabs[subTabIndex].name;
    }

    subscribeToWorkflowMessage() {
        this.subscriptions.push(this.workflowService.getMessageProvider().subscribe(workflowMessage => {
            const caseIds = [];
            this.approvalCasesData?.forEach( appCase =>{
                appCase.workflowResponse.forEach(wf => {
                    if (workflowMessage.encryptedRecordIds.includes(wf.encryptedRecordId)) {
                        if (caseIds.indexOf(appCase.caseId) === -1) {
                            caseIds.push(appCase.caseId);
                        }
                    }
                });
            });
            workflowMessage.caseId = caseIds;
            this.workflowService.processCaseWorkflowMessage(this.mainTabName, this.subTabIndex, this.statuses,
                this.approvalCasesGridOptions, this.approvalCasesData,
                workflowMessage, () => this.getWorkflowData(this.subTabIndex));
        }));
    }


    onSelectionChanged(event: AgGridEvent) {
        this.currentRowNodeSelection = event.api.getSelectedNodes();
        this.selectedRows.emit(event.api.getSelectedNodes());
        this.hasRowsSelect = event.api.getSelectedNodes().length > 0;
    }

    suppressTabKey(params: SuppressKeyboardEventParams) {
        if (params.event.key === 'Tab') {
            this.approvalCasesGridOptions.api.stopEditing();
        }
        return false;
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    initializeData(recordStatus: RECORD_STATUS) {
        this.approvalCasesData = [];
        this.approvalCasesGridOptions.suppressNoRowsOverlay = true;
        this.trackerDataService.getRecordsForStatusWithCase(recordStatus, this.superClientIndex).pipe(take(1)).subscribe(approvalCases => {
            this.approvalCasesData = approvalCases;
            this.approvalCasesGridOptions.suppressNoRowsOverlay = false;
            this.approvalCasesGridOptions.api.showNoRowsOverlay();
            this.isLoadingSpinner.emit(false);
        });
    }

    exportCRMReport() {
        const caseIds = this.currentRowNodeSelection.map( rows=>rows.data.caseId);
        this.crmReportDownloadSpinner = !this.crmReportDownloadSpinner;
        const clientName = this.userService.getSuperClientContext().clientName;
        const filename = `Clinical Requirements Matrix ${clientName} ${formatDate(new Date(), 'MMddYYYY', 'en-US')}.xlsx`;
        this.reportService.getClientRequirementMatrixReport(this.appService.getSuperClientContext().id,caseIds)
            .pipe(take(1),
                finalize(() => {
                    this.crmReportDownloadSpinner = !this.crmReportDownloadSpinner;
                }))
            .subscribe({
                next: (file) => this.saveAs(file,filename),
                error: (error) => {
                    const errorBanner = {
                        bannerType: CVSBannerType.Error,
                        hideX: false,
                        outletId: '#successBanner',
                        headline: 'Error',
                        body: 'Failed To Generate Report',
                        alertType: CVSAlertType.Error,
                    } as CVSBannerComponentData;
                    this.bannerService.sendAlert(errorBanner);
                }

            });
    }

    saveAs(file: Blob, fileName: string) {
        return saveAs(new Blob([file], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    isCheckBoxEnabledForSelection() {
        return this.userService.getUser().internalUser;
    }
}
