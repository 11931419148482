import {Injectable} from '@angular/core';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';
import {Observable, Subject} from 'rxjs';
import {ENVIRONMENT} from '../../environments/environment';
import {CVSHttpClient} from 'angular-component-library';
import {ClientLobWorkflow} from '../../model/ClientLobWorkflow';
import {ClientUmProgram} from '../../model/ClientUmProgram';
import {ClientProductDrugCoverage} from '../../model/ClientProductDrugCoverage';
import {MoveRankData} from '../../model/MoveRankData';
import {ClientFormularyRequirementRule} from '../../model/ClientFormularyRequirementRule';
import {CLIENT_CONFIGURATION_FOR_RANK_CHANGE} from '../../enum/ClientConfigurationForRankChange';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {
    rankMovementProvider = new Subject<CLIENT_CONFIGURATION_FOR_RANK_CHANGE>();
    private _isFormularyConfigModified = false;
    private _isLobConfigModified = false;
    private _currentStepSubject = new Subject<number>();

    constructor(private cvsHttpClient: CVSHttpClient) {
    }

    setIsFormularyConfigModified(val: boolean) {
        this._isFormularyConfigModified = val;
    }

    isFormularyConfigModified(): boolean {
        return this._isFormularyConfigModified;
    }

    setIsLobConfigModified(val: boolean) {
        this._isLobConfigModified = val;
    }

    isLobConfigModified(): boolean {
        return this._isLobConfigModified;
    }

    isClientConfigurationModified(): boolean {
        return this._isFormularyConfigModified || this._isLobConfigModified;
    }

    getCurrentStepSubject(): Subject<number> {
        return this._currentStepSubject;
    }

    saveClientFormularies(clientFormularyMapping: ClientFormularyMapping[], superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveClientFormularyMappings/${superClientIndex}`,
            clientFormularyMapping);
    }

    getClientFormularies(superClientIndex: number): Observable<ClientFormularyMapping[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getClientFormularyMappings/${superClientIndex}`);
    }

    saveClientLobWorkflows(clientLobWorkflows: ClientLobWorkflow[], superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveLobWorkflows/${superClientIndex}`,
            clientLobWorkflows);
    }

    getClientLobWorkflows(superClientIndex: number): Observable<ClientLobWorkflow[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getLobWorkflows/${superClientIndex}`);
    }

    submitClientSetUpRequest(superClientIndex: number, comment: { comment: string }): Observable<any[]> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/submitClientSetup/${superClientIndex}`, comment);
    }

    saveClientProductDrugCoverage(clientProductDrugCoverage: ClientProductDrugCoverage, superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveProductDrugCoverage/${superClientIndex}`,
            clientProductDrugCoverage);
    }

    getClientProductDrugCoverage(superClientIndex: number, lineOfBusiness: string): Observable<ClientProductDrugCoverage[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getProductDrugCoverage/${superClientIndex}/${lineOfBusiness}`);
    }

    saveClientUmProgram(clientUmProgram: ClientUmProgram, superClientIndex: number): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveUmProgram/${superClientIndex}`,
            clientUmProgram);
    }

    getClientUmProgram(superClientIndex: number, lineofBusiness: string): Observable<ClientUmProgram[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getUmProgram/${superClientIndex}/${lineofBusiness}`);
    }

    deleteUmProgram(superClientIndex: number, clientUmProgram: ClientUmProgram) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/deleteUmProgram/${superClientIndex}`, clientUmProgram);
    }

    updateUmProgram(superClientIndex: number, clientUmProgram: ClientUmProgram): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/updateUmProgram/${superClientIndex}`, clientUmProgram);
    }

    // eslint-disable-next-line max-len
    saveClientFormularyRequirementRule(superClientIndex: number, rule: ClientFormularyRequirementRule): Observable<ClientFormularyRequirementRule> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/saveClientFormularyRequirementRule/${superClientIndex}`, rule);
    }

    getClientFormularyRequirementRules(superClientIndex: number, lob: string): Observable<ClientFormularyRequirementRule[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getClientFormularyRequirementRules/${superClientIndex}/${lob}`);
    }

    deleteProductDrugCoverage(superClientIndex: number, id: number) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/deleteProductDrugCoverage/${superClientIndex}`, id);
    }

    updateProductDrugCoverage(superClientIndex: number, clientProductDrugCoverage: ClientProductDrugCoverage): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/updateProductDrugCoverage/${superClientIndex}`, clientProductDrugCoverage);
    }

    deleteClientFormularyRequirementRule(superClientIndex: number, requirementRule: ClientFormularyRequirementRule) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/deleteClientFormularyRequirementRule/${superClientIndex}`, requirementRule);
    }

    updateClientFormularyRequirementRule(superClientIndex: number, formularyRequirementsRule: ClientFormularyRequirementRule) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/updateClientFormularyRequirementRule/${superClientIndex}`, formularyRequirementsRule);
    }

    getActiveClientFormularyRequirementRules(superClientIndex: number, lob: string): Observable<ClientFormularyRequirementRule[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getActiveClientFormularyRequirementRules/${superClientIndex}/${lob}`);
    }

    moveRankClientConfiguration(superClientIndex: number, moveRankState: MoveRankData): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/moveRank/${superClientIndex}`, moveRankState);
    }
    getRankMovementProvider(): Observable<CLIENT_CONFIGURATION_FOR_RANK_CHANGE> {
        return this.rankMovementProvider;
    }
}
