import { Component, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CvsAgGridComponent } from '../../cvs-grid/cvs-ag-grid/cvs-ag-grid.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridEvent, ColDef, GridOptions, IRowNode, SelectionChangedEvent } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { Subject, take } from 'rxjs';
import { CommonCommon } from 'src/app/model/CommonCommon';
import { DrugPositionRequestBody, FormularyService } from 'src/app/service/formulary/formulary.service';
import { AppService } from 'src/app/service/app/app.service';
import { AttachmentData } from 'src/app/model/AttachmentData';
import { AdhocCreationDetail } from 'src/app/model/AdhocCreationDetail';
import moment from 'moment';

@Component({
    selector: 'app-convert-gpi-to-ndc-list',
    templateUrl: './convert-gpi-to-ndc-list.component.html',
    styleUrls: ['./convert-gpi-to-ndc-list.component.scss']
})
export class ConvertGpiToNdcListComponent implements OnInit, OnChanges {
    @ViewChild('convertToNdc', { static: true }) formularyListGrid: CvsAgGridComponent;
    @Input() gpiItem: CommonCommon;
    @Input() selectedGpi: string;
    @Input() selectedGPIAttachmentPayload: AttachmentData[];
    loadingTableData = true;
    ndcData = [];
    gridHeight = '100px';

    paginationEnabled = false;
    domLayout = 'normal';
    modules = [SetFilterModule, ClientSideRowModelModule, FiltersToolPanelModule, MenuModule, ColumnsToolPanelModule];
    defaultColDef: ColDef = {
	    sortable: true,
	    resizable: true,
	    headerClass: 'grid-formulary-Header',
	    filter: true,
	    menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab']
    };

    columnDefs: ColDef[] = [
	    {
	        headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
	        checkboxSelection: true,
	        headerName: 'NDC',
	        field: 'ndc',
	        minWidth: 200
	    },
	    {
	        headerName: 'Label Name',
	        field: 'labelName',
	        minWidth: 250,
	    },
	    {
	        headerName: 'MONY',
	        field: 'multiSourceCode',
	        minWidth: 100
	    },
	    {
	        headerName: 'Rx/OTC',
	        field: 'rxOtc',
	        minWidth: 100
	    },
	    {
	        headerName: 'RxCUI',
	        field: 'rxCui',
	        minWidth: 100
	    },
	    {
	        headerName: 'Med D',
	        field: 'medD',
	        minWidth: 100
	    }
    ];

    gridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
    };

    constructor(
        private formularyService: FormularyService,
        private appService: AppService,
        private convertNdcToGpiService: ConvertGpiToNdcService,
    ) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedGpi.currentValue) {
            this.formularyService.selectedDateRange.pipe(take(1)).subscribe(selectedDate => {
                const drugPositionRequestBody: DrugPositionRequestBody = {
                    searchDate: {
                        startDate: moment(selectedDate[0]).utc().format('YYYY-MM-DD'),
                        endDate: moment(selectedDate[1]).utc().format('YYYY-MM-DD'),
                        isSingleDateSelected: selectedDate[2]
                    },
                    attachmentData: this.selectedGPIAttachmentPayload
                };

                this.formularyService.getCurrentDrugPositionWithAttachmentForGPI(
                    this.appService.getSuperClientContext().id,
                    this.selectedGPIAttachmentPayload.map(item => item.formularyNumber).join(','),
                    'gpi',
                    changes.selectedGpi.currentValue,
                    drugPositionRequestBody
                ).pipe(take(1))
                    .subscribe(data => {
                        data.forEach(response => this.ndcData.push(response.commonCommon.drugMasterResponse));
                        this.loadingTableData = !this.loadingTableData;
                        const maxRowsToDisplay = (data.length > 10 ? 10 : data.length);
                        if (maxRowsToDisplay >= 10) {
                            this.gridHeight = '33vh';
                        } else if (maxRowsToDisplay !== 0) {
                            this.gridHeight = `${maxRowsToDisplay * (this.gridOptions.rowHeight ?? 32) + 41}px`;
                        }
                    });
            });
        }
    }

    onSelectionChange(event: AgGridEvent) {
        this.convertNdcToGpiService.setNdcSelectedItems(event.api.getSelectedNodes());
    }

}


@Injectable({
    providedIn: 'root'
})
export class ConvertGpiToNdcService {
    ndcItemsSelected = new Subject<AdhocCreationDetail[]>();

    setNdcSelectedItems(nodes: IRowNode<any>[]) {
        this.ndcItemsSelected.next(nodes?.map(rowNode => (
            {
                'gpi': rowNode.data.gpi,
                'gpiName': rowNode.data.gpiName,
                'ndc': rowNode.data.ndc,
                'labelName': rowNode.data.labelName,
                'adhocType': 'GPI_TO_NDC',
            }
        )));
    }
}
