export interface IHierarchyUpdateType {
    hierarchy: number;
    newUpdateTypeName: string;
}

export function getHierarchyUpdateTypeForMedispan(updateType: string): IHierarchyUpdateType {

    const updateTypeMedispanMap = new Map<string, IHierarchyUpdateType>();
    updateTypeMedispanMap.set('New GPI', {hierarchy: 1, newUpdateTypeName: 'New GPI'});
    updateTypeMedispanMap.set('GEN M', {hierarchy: 2, newUpdateTypeName: 'New NDC (M)'});
    updateTypeMedispanMap.set('GEN O', {hierarchy: 3, newUpdateTypeName: 'New NDC (O)'});
    updateTypeMedispanMap.set('GEN N', {hierarchy: 4, newUpdateTypeName: 'New NDC (N)'});
    updateTypeMedispanMap.set('GEN Y', {hierarchy: 5, newUpdateTypeName: 'New NDC (Y)'});
    updateTypeMedispanMap.set('MAINT', {hierarchy: 6, newUpdateTypeName: 'Maintenance Change'});
    updateTypeMedispanMap.set('GI Change', {hierarchy: 7, newUpdateTypeName: 'MONY (GI) Change'});
    updateTypeMedispanMap.set('RX-OTC CHG', {hierarchy: 8, newUpdateTypeName: 'Rx/OTC Change'});
    updateTypeMedispanMap.set('GPI CHG', {hierarchy: 9, newUpdateTypeName: 'GPI Change'});
    updateTypeMedispanMap.set('Med D', {hierarchy: 10, newUpdateTypeName: 'Med D Indicator Change'});
    updateTypeMedispanMap.set('FDA APP CHG', {hierarchy: 11, newUpdateTypeName: 'FDA Application Change'});
    updateTypeMedispanMap.set('PART LBLR CHG', {hierarchy: 12, newUpdateTypeName: 'Participating Labeler Change'});

    return updateTypeMedispanMap.get(updateType);
}


export function getHierarchyUpdateTypeForWorkflow(updateType: string): IHierarchyUpdateType {
    const updateTypeWorkflowMap = new Map<string, IHierarchyUpdateType>();
    updateTypeWorkflowMap.set('New GPI', {hierarchy: 1, newUpdateTypeName: 'New GPI'});
    updateTypeWorkflowMap.set('GEN M', {hierarchy: 2, newUpdateTypeName: 'New NDC (M)'});
    updateTypeWorkflowMap.set('GEN O', {hierarchy: 3, newUpdateTypeName: 'New NDC (O)'});
    updateTypeWorkflowMap.set('GEN N', {hierarchy: 4, newUpdateTypeName: 'New NDC (N)'});
    updateTypeWorkflowMap.set('GEN Y', {hierarchy: 5, newUpdateTypeName: 'New NDC (Y)'});
    updateTypeWorkflowMap.set('MAINT', {hierarchy: 6, newUpdateTypeName: 'Maintenance Change'});
    updateTypeWorkflowMap.set('Caremark GI Change', {hierarchy: 7, newUpdateTypeName: 'Caremark MONY (GI) Change'});
    updateTypeWorkflowMap.set('Medispan GI Change', { hierarchy: 8, newUpdateTypeName: 'Medispan only MONY (GI) Change'});
    updateTypeWorkflowMap.set('RX-OTC CHG', {hierarchy: 9, newUpdateTypeName: 'Rx/OTC Change'});
    updateTypeWorkflowMap.set('GPI CHG', {hierarchy: 10, newUpdateTypeName: 'GPI Change'});
    updateTypeWorkflowMap.set('Med D', {hierarchy: 11, newUpdateTypeName: 'Med D Indicator Change'});
    updateTypeWorkflowMap.set('FDA APP CHG', {hierarchy: 12, newUpdateTypeName: 'FDA Application Change'});
    updateTypeWorkflowMap.set('PART LBLR CHG', {hierarchy: 13, newUpdateTypeName: 'Participating Labeler Change'});
    updateTypeWorkflowMap.set('GPI Ad Hoc', {hierarchy: 14, newUpdateTypeName: 'GPI Ad Hoc'});
    updateTypeWorkflowMap.set('GPI to NDC Ad Hoc', {hierarchy: 15, newUpdateTypeName: 'GPI to NDC Ad Hoc'});
    updateTypeWorkflowMap.set('NDC Ad Hoc', {hierarchy: 16, newUpdateTypeName: 'NDC Ad Hoc'});
    updateTypeWorkflowMap.set('NDC to GPI Ad Hoc', {hierarchy: 17, newUpdateTypeName: 'NDC to GPI Ad Hoc'});
    return updateTypeWorkflowMap.get(updateType);

}

export function getUpdateTypeDisplayNameForWorkflow(updateType) {
    const hierarchyUpdateType = getHierarchyUpdateTypeForWorkflow(updateType);
    return hierarchyUpdateType ? hierarchyUpdateType.newUpdateTypeName : updateType;
}

export function getUpdateTypeDisplayNameForMedispan(updateType) {
    const hierarchyUpdateType = getHierarchyUpdateTypeForMedispan(updateType);
    return hierarchyUpdateType ? hierarchyUpdateType.newUpdateTypeName : updateType;
}
