<h2 class="workFlowHeading">Line of Business Workflow</h2>
<div class="workflow-container">
    <p class="subHeader padding-bottom-10">Select which Line of Business uses Multiple Years to manage the workflow.</p>
</div>
<ng-container *ngIf="displayClientWorkflow">
    <ng-container *ngFor="let lob of superClientLobs" [formGroup]="lobWorkFlowFormGroup">
        <h3 class="padding-bottom-10">{{lob.description}}</h3>
        <div class="display-flex padding-bottom-10">
        <span id="multiYearSpan">
            <p id="multiYearText">Uses Multiple Year Workflow</p>
            <mat-radio-group formControlName="workflowRadio_{{lob.description}}">
                <mat-radio-button [value]="'Y'">Yes</mat-radio-button>
                <mat-radio-button [value]="'N'">No</mat-radio-button>
            </mat-radio-group>
        </span>

            <mat-form-field id="workflowYearsMultiselect">
                <mat-label>Workflow Years</mat-label>
                <mat-select multiple formControlName="workflowYears_{{lob.description}}"
                            id="matSelect_{{lob.description}}"
                            (selectionChange)="onMatSelectionChange(multiSelectCheckbox,lob)" #multiSelectCheckbox>
                    <ng-container *ngIf="lob.isLobDisabled === true">
                        <mat-option value="{{ROLLING}}">{{ROLLING}}</mat-option>
                    </ng-container>
                    <ng-container *ngIf="lob.isLobDisabled === false">
                        <mat-checkbox id="selectAllCheckbox_{{lob.description}}" class="selectAllCheckbox"
                                      #selectAllCheckbox
                                      [checked]="lob.selectAllChecked"
                                      (change)="toggleAllSelectionCheckbox(lob,selectAllCheckbox)"
                                      [indeterminate]="lob.indeterminateState">
                            Select All
                        </mat-checkbox>
                        <mat-option *ngFor="let year of formularyYears[lob.description]"
                                    [value]="year">{{year}}</mat-option>
                    </ng-container>

                </mat-select>
                <mat-error>
                    <mat-icon svgIcon="error-f--xs"></mat-icon>
                    Select 1 or more year(s)
                </mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</ng-container>
<div id="clientMultiSourceSetup" class="margin-top-5">
    <h2>Client Multi-Source Setup</h2>
    <mat-form-field id="multiSourceCodeDropdown">
        <mat-label>Multi-Source Code Change Type(s)</mat-label>
        <mat-select pdsMatOptionKeyboardFocus [formControl]="multiSourceSetupControl">
            <ng-container>
                <mat-option *ngFor="let setup of CLIENT_MULTI_SOURCE_SETUP"
                            [value]="setup.code">{{setup.description}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-icon id="infoIcon" svgIcon="info-circle--s" role="img" [cvsTooltip]="clientMultiSourceSetupTooltip"
              [placement]="'bottom'"
              [showArrow]="true"
              [smallTooltip]="true">
    </mat-icon>
</div>
