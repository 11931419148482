import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {take} from 'rxjs/operators';
import {AppService} from '../../service/app/app.service';
import {Subscription} from 'rxjs';
import {FormularyService} from '../../service/formulary/formulary.service';
import {CurrentDrugPosition} from '../../model/CurrentDrugPosition';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {BUSINESS_LOB_CD, BUSINESS_LOB_DESC_CD} from '../../default-values/DefaultValues';
import {buildTabSelectionChangeEvent} from '../../utility/utility';

@Component({
    selector: 'app-formulary-information',
    templateUrl: './formulary-information.component.html',
    styleUrls: ['./formulary-information.component.scss']
})
export class FormularyInformationComponent implements OnInit, OnChanges, OnDestroy {
    @Input() ndc = '';
    @Input() ndcBusinessLob;
    @Input() selectedLOBS;
    subscriptions: Subscription[] = [];
    formularyDrugInfo;
    currentDrugPosition;
    showFormularySpinner;
    searchBy = {
        NDC: 'ndc'
    };
    popupParent: HTMLElement | null = document.querySelector('body');
    ndcData;

    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true
    };
    nonMedicareColumnDef: ColDef[] = [
        { headerName: 'Formulary Name', field: 'clientFormularyName', filter: 'agSetColumnFilter', width: 150, pinned: 'left'},
        {headerName: 'Formulary ID', field: 'formularyNumber', filter: 'agSetColumnFilter', width: 150, pinned: 'left'},
        {headerName: 'Tier', field: 'formularyDLTierNumber', filter: 'agSetColumnFilter', width: 80},
        {headerName: 'Form Status', field: 'formularyDLLTOnFormulary', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'Tier Code Description', field: 'formularyDLLTTierDescription', filter: 'agSetColumnFilter', width: 170},
        {headerName: 'PA Selection', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'PA Group Name', field: '', filter: 'agSetColumnFilter', width: 150},
        {headerName: 'Step Type', field: '', filter: 'agSetColumnFilter', width: 100},
        {headerName: 'Step Name', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'Step Behavior', field: '', filter: 'agSetColumnFilter', width: 140},
        {headerName: 'QL Type A', field: '', filter: 'agSetColumnFilter', width: 224},
        {headerName: 'QL Amount A', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Unit A', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Qty Per Period (Days) A', field: '', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'QL Type B', field: '', filter: 'agSetColumnFilter', width: 224},
        {headerName: 'QL Amount B', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Unit B', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Qty Per Period (Days) B', field: '', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'QL Type C', field: '', filter: 'agSetColumnFilter', width: 224},
        {headerName: 'QL Amount C', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Unit C', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'QL Qty Per Period (Days) C', field: '', filter: 'agSetColumnFilter', width: 200},
    ];

    medicareColumnDef: ColDef[] = [
        { headerName: 'Formulary Name', field: 'clientFormularyName', filter: 'agSetColumnFilter', width: 150, pinned: 'left'},
        {headerName: 'Formulary ID', field: 'formularyNumber', filter: 'agSetColumnFilter', width: 150, pinned: 'left'},
        {headerName: 'Tier', field: 'formularyDLTierNumber', filter: 'agSetColumnFilter', width: 80},
        {headerName: 'Form Status', field: 'formularyDLLTOnFormulary', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'Tier Code Description', field: 'formularyDLLTTierDescription', filter: 'agSetColumnFilter', width: 170},
        {headerName: 'PA Selection', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'PA Group Name', field: '', filter: 'agSetColumnFilter', width: 150},
        {headerName: 'Step Type', field: '', filter: 'agSetColumnFilter', width: 100},
        {headerName: 'Step Name', field: '', filter: 'agSetColumnFilter', width: 120},
        {headerName: 'Step Behavior', field: '', filter: 'agSetColumnFilter', width: 140},
        {headerName: 'QL Type', field: '', filter: 'agSetColumnFilter', width: 100},
        {headerName: 'QL Amount', field: '', filter: 'agSetColumnFilter', width: 110},
        {headerName: 'QL Unit', field: '', filter: 'agSetColumnFilter', width: 90},
        {headerName: 'QL Qty Per Period (Days)', field: '', filter: 'agSetColumnFilter', width: 190},
    ];

    formularyInfoGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
    };

    constructor(private _appService: AppService, private _formularyService: FormularyService) {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ndcData = changes.ndc.currentValue;
        if (changes.ndcBusinessLob.currentValue) {
            this.ndcBusinessLob = changes.ndcBusinessLob.currentValue;
            this.onBusinessLobTabSelectionChange(
                buildTabSelectionChangeEvent(BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),this.ndcBusinessLob));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getCurrentDrugPosition(businessLobCd: string) {
        this.showFormularySpinner = true;
        this.formularyDrugInfo = [];
        this._appService.getInitializationSubject().pipe(take(1)).subscribe(() => {
            const index = this._appService.getSuperClientContext().id;
            this.subscriptions.push(this._formularyService.getFormularyData(index).pipe(take(1)).subscribe(formularyResponse => {
                const formularies = this._formularyService.getDistinctFormulariesByBusinessLob(businessLobCd, formularyResponse);
                const formularyNumbers = formularies.map(formulary => formulary.formularyNumber);
                this._formularyService.getCurrentDrugPosition(index,formularyNumbers, this.searchBy.NDC, this.ndcData)
                    .pipe(take(1))
                    .subscribe(response => {
                        this.currentDrugPosition = response;
                        this.formularyDrugInfo = this.buildCurrentDrugPositionList(response, formularies);
                        this.showFormularySpinner = false;
                    }, () => {
                        this.showFormularySpinner = false;
                    });
            }));
        });
    }


    buildCurrentDrugPositionList(currentDrugPositions: CurrentDrugPosition[], formularies: any[]) {
        const formularyDrugInfo = [];
        formularies.forEach(formulary => {
            const foundFormulary = currentDrugPositions.find(cdp => cdp.formularyNumber === formulary.formularyNumber);
            if (foundFormulary) {
                formularyDrugInfo.push(
                    { ...foundFormulary, clientFormularyName: formulary.clientFormularyName }
                );
            } else {
                formularyDrugInfo.push(
                    { clientFormularyName: formulary.clientFormularyName, formularyNumber: formulary.formularyNumber }
                );
            }
        });
        return formularyDrugInfo;
    }

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        const businessLobCd = event.tab.ariaLabel;
        if (businessLobCd === BUSINESS_LOB_CD.MEDICARE) {
            this.formularyInfoGridOptions?.api?.setColumnDefs(this.medicareColumnDef);
            this.formularyInfoGridOptions.columnDefs = this.medicareColumnDef;
        } else {
            this.formularyInfoGridOptions?.api?.setColumnDefs(this.nonMedicareColumnDef);
            this.formularyInfoGridOptions.columnDefs = this.nonMedicareColumnDef;
        }
        this.getCurrentDrugPosition(businessLobCd);
    }
}
