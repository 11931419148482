<button [class]="class"
        id="{{id}}"
        [attr.aria-disabled]="disabled"
        [disabled]="disabled"
        [cvsTooltip]="tooltipText"
        [placement]="placement"
        [showArrow]="showArrow"
        (click)="onClick($event)"
        [style]="disabled? 'border: gray' : ''">
    <mat-icon
        (click)="onClick($event)"
        *ngIf="svgIcon" [svgIcon]="svgIcon" style="scale: .9"
        class="pds-transition-base pds-icon-rotation"
    >
    </mat-icon>
    {{buttonText}}
</button>
