<ng-container>
    <form [formGroup]="submissionForm" (ngSubmit)="saveSubmissionForm()">
        <div class="submission-form">
            <p>This case will include {{workflowService.dialogDataSubmission.selectedRows.length}}
                item(s):</p>
            <mat-form-field class="case-name" [ngClass]="{'form-warning' :submissionForm.controls.name.errors?.pastedValueTruncated}">
                <app-smart-input
                    placeholder="Case Name *"
                    maxlength="50"
                    formControlName="name"></app-smart-input>
                <mat-hint class="mat-hint">Enter a case name or number</mat-hint>
                <mat-error *ngIf="submissionForm.controls.name.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Case Name
                </mat-error>
                <mat-error *ngIf="submissionForm.controls.name.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{submissionForm.controls.name.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field class="effective-date" >
                <mat-label>Effective Date</mat-label>
                <input matInput [required]="true" [max]="maxDate" [min]="minDate" [matDatepicker]="effectiveDate" formControlName="effectiveDate"/>
                <mat-icon
                        class="icon"
                        svgIcon="calendar--s"
                        matSuffix
                        (click)="effectiveDate.open()"
                >
                </mat-icon>
                <mat-hint>Provide a date between 1/1/1995 - 12/31/2039</mat-hint>
                <mat-datepicker #effectiveDate></mat-datepicker>
                <mat-error *ngIf="submissionForm.controls.effectiveDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date between 01/1/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Case Notes (Optional)</mat-label>
                <textarea
                        matInput
                        (input)="onNotesChanges()"
                        class="notes-textarea-submission"
                        formControlName="notes"
                        (focusout)="stopWarning()"
                ></textarea>
                <mat-hint *ngIf="!hasTruncNotesError" class="mat-hint-notes">Provide a description for this case</mat-hint>
                <mat-hint *ngIf="hasTruncNotesError" class="warning-error-notes-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{submissionForm.controls['notes'].value?.length}} characters
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="form-buttons">
            <button class="pds-btn-ghost" (click)="onModalCancel()" type="reset">Cancel</button>
            <button class="pds-btn-primary" type="submit">Approve & Submit</button>
        </div>
    </form>
</ng-container>