<div class="tracking-grid">
  <div>
    <mat-card>
      <app-recommendation
        [medAdHocDate] = "medispanTrackerResponse?.weekDate"
        [recommendationDate] = "recommendationData">
      </app-recommendation>
    </mat-card>
  </div>
  <div>
    <mat-card>
      <app-trade></app-trade>
    </mat-card>
  </div>
  <div>
    <mat-card>
      <app-customer-requirement-lifecycle></app-customer-requirement-lifecycle>
    </mat-card>
  </div>
  <div class="regulatory-component">
    <mat-card>
      <app-regulatory-tracking
        [regulatoryTrackingValue] = "trackingData">
      </app-regulatory-tracking>
    </mat-card>
  </div>
  <div>
    <mat-card class="supplemental-component">
      <app-supplemental-lists></app-supplemental-lists>
    </mat-card>
  </div>
  <div class="cms-component" *ngIf="medispanTrackerResponse?.businessLob==='Medicare'">
    <mat-card>
      <app-cms-submission-tracking></app-cms-submission-tracking>
    </mat-card>
  </div>
</div>
