import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ClientUmProgram} from '../../../../../model/ClientUmProgram';
import {
    ATTACHMENT_LEVEL,
    ATTACHMENT_MONY,
    MED_D,
    RX_OTC,
    STEP_TYPE_MEDICARE,
    STEP_TYPE_OTHERS
} from '../../../../../default-values/DefaultValues';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BUSINESS_LOB} from '../../../../../enum/BusinessLob';
import {MedispanTrackerResponse} from '../../../../../model/MedispanTrackerResponse';
import {RecommendationsFormService} from '../../../../../service/recommendations-form/recommendations-form.service';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {RecommendationService} from '../../../../../service/recommendation/recommendation.service';
import {StepTherapyRequirements} from '../../../../../model/StepTherapyRequirements';
import {map, take, tap} from 'rxjs/operators';

@Component({
    selector: 'app-step-therapy-form-entry',
    templateUrl: './step-therapy-form-entry.component.html',
    styleUrls: ['./step-therapy-form-entry.component.scss']
})
export class StepTherapyFormEntryComponent implements OnInit {
    @ViewChildren('attachmentMony') attachmentMony: QueryList<MatSelect>;
    @ViewChildren('attachmentMonyCheckbox') attachmentMonyCheckbox:  QueryList<MatCheckbox>;
    @Input() stepTherapyUMPrograms: ClientUmProgram[] = [];
    @Input() umProgram: any;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Output() isStepTherapySavedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isSavedStepTherapyIncomplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    stepTherapyFormGroups: { [key: string]: FormGroup } = {};
    panelOpenState = true;
    stMedicareSelection = Object.values(STEP_TYPE_MEDICARE);
    stNonMedicareSelection = Object.values(STEP_TYPE_OTHERS);
    attachmentTypes = [];
    attachmentLevels: [key: string, value: string][] = [];
    attachmentMonyList = ATTACHMENT_MONY;
    monyIndeterminates = new Map<string, boolean>();
    rxOtcList = RX_OTC;
    medDList = MED_D;
    mappedStatus = new Map<string, string>();
    savedStepTherapyRequirements: StepTherapyRequirements[] = [];
    protected readonly BUSINESS_LOB = BUSINESS_LOB;
    constructor(private fb: FormBuilder, private recommendationsFormService: RecommendationsFormService,
        private recommendationService: RecommendationService) { }

    ngOnInit(): void {
        this.recommendationsFormService.setMedispanTrackerResponse(this.medispanTrackerResponse);
        this.recommendationsFormService.setAttachmentType();
        this.attachmentTypes = this.recommendationsFormService.getAttachmentType();
        this.createFormGroups();
        this.getStepTherapyRequirements();
    }

    ngAfterViewInit() {
        this.scrollToFormulary(`step-therapy-${this.umProgram?.umProgramName}-${this.umProgram?.rank}`);
    }
    scrollToFormulary(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }

    createFormGroups(): void {
        if (Object.keys(this.stepTherapyFormGroups).length === 0) {
            if (this.stepTherapyUMPrograms) {
                this.stepTherapyUMPrograms.forEach(st => {
                    this.stepTherapyFormGroups[st.id] = this.fb.group({
                        [`stepTherapyType`]: new FormControl({value: this.stMedicareSelection[0].code, disabled: false}),
                        [`customClaimRejectMessage`]: new FormControl({value: '', disabled: true}),
                        [`stNotes`]: new FormControl({value: '', disabled: true}),
                        [`attachmentType`]: new FormControl({value: this.attachmentTypes[0], disabled: true},
                            {validators: Validators.required}),
                        [`attachmentLevel`]: new FormControl({value: '', disabled: true}, {validators: Validators.required}),
                        [`attachmentMonyDD`]: new FormControl({value: '', disabled: true},{validators: Validators.required}),
                        [`monyCode`]: new FormControl({value: [''], disabled: true}),
                        [`coverageMiscRxOtc`]: new FormControl({value: '3', disabled: true}),
                        [`coverageMiscMedD`]: new FormControl({value: '3', disabled: true}),
                        [`applyDefaultCheckBox`]: new FormControl({value: true, disabled: true}),
                        [`attachmentMonyCheckbox`]: [false]
                    });
                    this.mappedStatus[st.id] = '0';
                    this.monyIndeterminates[st.id] = false;
                    this.buildAttachmentLevels(this.attachmentTypes[0], st.id, true);
                    this.stepTherapyFormGroups[st.id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
                    this.stepTherapyFormGroups[st.id].controls.attachmentMonyDD
                        .patchValue([...this.attachmentMonyList.map(m => m.code)], {emitEvent: false});
                    this.stepTherapyFormGroups[st.id].controls.attachmentMonyDD.disable();
                });
            }
        }
    }

    buildAttachmentLevels(value: any, stId: number, isReset: boolean) {
        const controls = this.stepTherapyFormGroups[stId].controls;
        this.recommendationsFormService.buildAttachmentLevels(value, controls, isReset);
        this.attachmentLevels[stId.toString()] = this.recommendationsFormService.getAttachmentLevels();
    }

    toggleAllSelection(umProgramId: number) {
        const controls = this.stepTherapyFormGroups[umProgramId].controls;
        this.recommendationsFormService.toggleAllSelection(controls);
    }

    onAttachmentMonyDDChange(umProgramId: number) {
        const controls = this.stepTherapyFormGroups[umProgramId].controls;
        const attachmentMonyDDFCLength = controls.attachmentMonyDD.value.length;
        this.monyIndeterminates[umProgramId] = (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        // eslint-disable-next-line max-len
        if ((!controls.attachmentMonyCheckbox && attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            // eslint-disable-next-line max-len
            (controls.attachmentMonyCheckbox && attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            controls.attachmentMonyCheckbox.setValue(false);
        }
        if (attachmentMonyDDFCLength === this.attachmentMonyList.length) {
            controls.attachmentMonyCheckbox.setValue(true);
        }
    }

    setDefaultSelection(id) {
        if (this.stepTherapyFormGroups[id].controls.applyDefaultCheckBox.value === true) {
            this.clearAllSelections(id);
        } else {
            this.enableAll(id);
        }
        this.stepTherapyFormGroups[id].updateValueAndValidity();
    }

    enableAll(id) {
        this.stepTherapyFormGroups[id].controls['attachmentMonyDD'].markAsUntouched();
        this.stepTherapyFormGroups[id].controls['attachmentType'].markAsUntouched();
        this.stepTherapyFormGroups[id].controls['attachmentLevel'].markAsUntouched();
        this.stepTherapyFormGroups[id].controls.attachmentType.enable();
        this.stepTherapyFormGroups[id].controls.attachmentLevel.enable();
        this.stepTherapyFormGroups[id].controls.attachmentMonyDD.enable();
        this.stepTherapyFormGroups[id].controls.coverageMiscRxOtc.enable();
        this.stepTherapyFormGroups[id].controls.coverageMiscMedD.enable();
    }

    clearAllSelections(id) {
        this.stepTherapyFormGroups[id].controls.attachmentType.patchValue(this.attachmentTypes[0], {emitEvent: false});
        this.buildAttachmentLevels(this.attachmentTypes[0], id, true);
        this.stepTherapyFormGroups[id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
        this.stepTherapyFormGroups[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
            {emitEvent: false});
        this.stepTherapyFormGroups[id].controls.coverageMiscRxOtc.patchValue('3', {emitEvent: false});
        this.stepTherapyFormGroups[id].controls.coverageMiscMedD.patchValue('3', {emitEvent: false});
        this.stepTherapyFormGroups[id].controls.attachmentType.disable();
        this.stepTherapyFormGroups[id].controls.attachmentLevel.disable();
        this.stepTherapyFormGroups[id].controls.attachmentMonyDD.disable();
        this.stepTherapyFormGroups[id].controls.monyCode.disable();
        this.stepTherapyFormGroups[id].controls.attachmentMonyCheckbox.disable();
        this.stepTherapyFormGroups[id].controls.coverageMiscRxOtc.disable();
        this.stepTherapyFormGroups[id].controls.coverageMiscMedD.disable();
    }

    enableGridNotes(umProgramId: number){
        const controls = this.stepTherapyFormGroups[umProgramId];
        const currentStepTherapyUMProgram =this.stepTherapyUMPrograms.filter(data => data.id === umProgramId)[0];
        if(this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE
            && controls.get('stepTherapyType').value === this.stNonMedicareSelection[2].code) {
            controls.get('stNotes').enable();
            controls.get('applyDefaultCheckBox').enable();
            controls.get('customClaimRejectMessage').disable();
            controls.get('customClaimRejectMessage').patchValue('', {emit: false});
        } else {
            controls.get('customClaimRejectMessage').patchValue(currentStepTherapyUMProgram?.rejectionMessage);
            controls.get('customClaimRejectMessage').enable();
            controls.get('stNotes').enable();
            controls.get('applyDefaultCheckBox').enable();
        }
        if(controls.get('stepTherapyType').value === this.stMedicareSelection[0].code
            || controls.get('stepTherapyType').value === this.stNonMedicareSelection[0].code) {
            controls.disable();
            controls.get('stepTherapyType').enable();
            controls.get('applyDefaultCheckBox').patchValue(true);
            controls.get('stNotes').patchValue('');
            controls.get('customClaimRejectMessage').patchValue('');
            this.clearAllSelections(umProgramId);
            controls.updateValueAndValidity();
        }
    }

    saveStepTherapy() {
        const stepTherapyRequirements = this.buildStepTherapy();
        if (stepTherapyRequirements.length > 0) {
            this.recommendationService.saveStepTherapyRequirements(stepTherapyRequirements)
                .pipe(take(1))
                .subscribe({
                    error: ()=> {
                        this.isStepTherapySavedEmitter.emit(false);
                    },
                    complete:() => {
                        const isIncomplete = !stepTherapyRequirements.every(data => data.statusCd !== '1');
                        this.isSavedStepTherapyIncomplete.emit(isIncomplete);
                        this.isStepTherapySavedEmitter.emit(true);
                    },
                });
        }
    }

    buildStepTherapy() {
        const stepTherapyRequirements: StepTherapyRequirements[] = [];
        this.stepTherapyUMPrograms.forEach(data => {
            const controls = this.stepTherapyFormGroups[data.id].controls;
            const stepTherapyRequirement =Object.keys(new StepTherapyRequirements()).reduce((acc, key) => {
                acc[key] = null;
                return acc;
            }, {} as StepTherapyRequirements);
            stepTherapyRequirement.encryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
            stepTherapyRequirement.clientUmProgramId = data.id.toString();
            stepTherapyRequirement.businessLobCd = this.medispanTrackerResponse.businessLobCd;
            stepTherapyRequirement.customRejectMessage = controls.customClaimRejectMessage.value;
            stepTherapyRequirement.notes = controls.stNotes.value;
            if (controls.stepTherapyType.value !== this.stMedicareSelection[0]?.code) {
                stepTherapyRequirement.applyDefaultAttachment =
                    controls.applyDefaultCheckBox.value === true ? 'Yes' : 'N/A';
                stepTherapyRequirement.attachmentType =
                    controls.attachmentType.value === '' ? null :
                        controls.attachmentType.value;
                stepTherapyRequirement.attachmentLevel = controls.attachmentLevel.value;
                stepTherapyRequirement.attachmentMony = (controls.attachmentMonyDD.value !== null
                    && controls.attachmentMonyDD.value !== '')
                    ? controls.attachmentMonyDD.value?.join('') === 'N/A' ? null
                        : controls.attachmentType.value === ATTACHMENT_LEVEL.GPI ?
                            controls.attachmentMonyDD.value?.join('') : null : null;
                stepTherapyRequirement.attachmentRxOtcCd = controls.coverageMiscRxOtc.value;
                stepTherapyRequirement.attachmentMedDCd = stepTherapyRequirement.businessLobCd !== 'BM' ? null :
                    controls.coverageMiscMedD.value;
            }
            stepTherapyRequirement.stepTypeCd = controls.stepTherapyType.value;
            if ((controls.attachmentType.value === ATTACHMENT_LEVEL.GPI &&
                    controls.attachmentMonyDD.errors?.required) || controls.attachmentType.errors?.required
                || controls.attachmentLevel.errors?.required) {
                controls.attachmentMonyDD.markAsTouched();
                controls.attachmentType.markAsTouched();
                controls.attachmentLevel.markAsTouched();
            }
            stepTherapyRequirement.statusCd = this.getStatusCd(stepTherapyRequirement);
            this.mappedStatus[data.id] = stepTherapyRequirement.statusCd;
            stepTherapyRequirements.push(stepTherapyRequirement);
        });
        return stepTherapyRequirements;
    }

    getStepTherapyRequirements() {
        this.recommendationService.getStepTherapyRequirements(this.medispanTrackerResponse.encryptedRecordId,
            this.medispanTrackerResponse.businessLobCd)
            ?.pipe(
                map(data => data?.map(d => ({
                    encryptedRecordId: d.encryptedRecordId,
                    businessLobCd: d.businessLobCd,
                    clientUmProgramId: d.clientUmProgramId,
                    statusCd: d.statusCd,
                    attachmentType: d.attachmentType,
                    attachmentLevel: d.attachmentLevel,
                    attachmentRxOtcCd: d.attachmentRxOtcCd,
                    attachmentMony: d.attachmentMony,
                    notes: d.notes,
                    customRejectMessage: d.customRejectMessage,
                    applyDefaultAttachment: d.applyDefaultAttachment,
                    attachmentMedDCd: d.attachmentMedDCd,
                    stepTypeCd: d.stepTypeCd
                }))),
                tap(stepTherapyRequirements => {
                    this.savedStepTherapyRequirements.push(...stepTherapyRequirements);
                })
            )
            .subscribe({
                next: () => {
                    this.loadSavedStepTherapyRequirements();
                },
                error: () => {},
            });
    }

    loadSavedStepTherapyRequirements() {
        this.savedStepTherapyRequirements.forEach(st => {
            if (this.stepTherapyUMPrograms.filter(item => item.id === Number(st.clientUmProgramId)).length === 1) {
                const control = this.stepTherapyFormGroups[st.clientUmProgramId].controls;
                control.stepTherapyType.patchValue(st.stepTypeCd, {emitEvent: false});
                if(st.stepTypeCd === '0') {
                    this.stepTherapyFormGroups[st.clientUmProgramId].disable();
                    control.stepTherapyType.enable();
                } else if (st.stepTypeCd !== '0') {
                    if(st.businessLobCd !== 'BM' && st.stepTypeCd === '2') {
                        control.customClaimRejectMessage.disable();
                    } else {
                        control.customClaimRejectMessage.enable();
                    }
                    control.stNotes.enable();
                    control.applyDefaultCheckBox.enable();
                }
                control.customClaimRejectMessage.patchValue(st.customRejectMessage, {emitEvent: false});
                control.stNotes.patchValue(st.notes, {emitEvent: false});
                control.applyDefaultCheckBox.patchValue(st.applyDefaultAttachment === 'Yes', {emitEvent: false});
                control.attachmentType.patchValue(st.attachmentType, {emitEvent: false});
                this.buildAttachmentLevels(st.attachmentType, parseInt(st.clientUmProgramId, 10), false);
                control.attachmentLevel.patchValue(st.attachmentLevel, {emitEvent: false});
                if (st.attachmentMony) {
                    const mony = st.attachmentMony.split('');
                    control.attachmentMonyDD.patchValue([...mony.map(m => m)], {emitEvent: false});
                }
                control.coverageMiscRxOtc.patchValue(st.attachmentRxOtcCd, {emitEvent: false});
                control.coverageMiscMedD.patchValue(st.attachmentMedDCd, {emitEvent: false});
                if (st.applyDefaultAttachment==='Yes' || !st.applyDefaultAttachment) {
                    this.stepTherapyFormGroups[st.clientUmProgramId].controls.applyDefaultCheckBox.patchValue(true, {emitEvent: false});
                    this.setDefaultSelection(st.clientUmProgramId);
                } else {
                    this.stepTherapyFormGroups[st.clientUmProgramId].controls.applyDefaultCheckBox.patchValue(false, {emitEvent: false});
                    this.setDefaultSelection(st.clientUmProgramId);
                }
                this.stepTherapyFormGroups[st.clientUmProgramId].markAsUntouched();
                this.stepTherapyFormGroups[st.clientUmProgramId].updateValueAndValidity();
                this.mappedStatus[st.clientUmProgramId] = st.statusCd;
            }
        });
    }

    getStatusCd(stepTherapyRequirement: StepTherapyRequirements) {
        if (stepTherapyRequirement.stepTypeCd === '0' || stepTherapyRequirement.stepTypeCd === null) {
            return '0';
        } else if (stepTherapyRequirement.applyDefaultAttachment !== 'Yes' &&
            (!stepTherapyRequirement.attachmentType ||
                (stepTherapyRequirement.attachmentType === ATTACHMENT_LEVEL.GPI &&
                    (stepTherapyRequirement.attachmentMony === null ||
                        stepTherapyRequirement.attachmentMony === '')))) {
            return '1';
        } else {
            return '2';
        }
    }
}

