import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CVSAlertType, CVSBannerService, CVSBannerType} from 'angular-component-library';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {SuperClientData} from '../../model/SuperClientData';
import {take} from 'rxjs/operators';
import {AppAdministrationService} from '../../service/app-administration/app-administration.service';

@Component({
    selector: 'app-super-client-modal',
    templateUrl: './super-client-modal.component.html',
    styleUrls: ['./super-client-modal.component.scss']
})
export class SuperClientModalComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    superClientForm: FormGroup;
    hasTruncDescriptionError = false;
    descriptionValue='';

    constructor(
        private fb: FormBuilder,
        private bannerService: CVSBannerService,
        private appAdministrationService: AppAdministrationService
    ) {
        this.createSuperClientModalForm();
    }
    ngOnInit(): void {
        this.resetSuperClientModalFields();
    }

    public createSuperClientModalForm() {
        this.superClientForm = this.fb.group({
            superClientId: [''],
            name: [''],
            description: ['']
        });
    }

    saveSuperClientForm() {
        this.addSuperClientModalValidators();
        this.superClientForm.markAllAsTouched();
        if (this.validateSuperClientForm()) {
            const superClientData: SuperClientData = {
                superclientId: null,
                name: null,
                description: null
            };
            superClientData.superclientId = this.superClientForm.controls['superClientId'].value;
            superClientData.name = this.superClientForm.controls['name'].value;
            superClientData.description = this.superClientForm.controls['description'].value;

            this.appAdministrationService.saveSuperClient(superClientData).pipe(take(1))
                .subscribe({
                    next: () => {
                        this.superClientForm.markAsPristine();
                        this.onSaveSuperClientFormSuccess();
                        this.closeEvent.emit(true);
                    }, error: () => {
                    }
                });
        }
    }

    onSaveSuperClientFormSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: this.superClientForm.controls['name'].value + ' added successfully.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this.bannerService.sendAlert(successBannerData);
    }

    stopWarning() {
        this.hasTruncDescriptionError = false;
    }

    onDescriptionChanges() {
        if (this.superClientForm.controls['description'].value?.length > 50) {
            if (this.descriptionValue.length === 0) {
                this.hasTruncDescriptionError = true;
            }
            this.superClientForm.controls['description']
                .setValue(this.superClientForm.controls['description'].value?.substring(0, 50));
        } else {
            this.hasTruncDescriptionError = false;
        }
        this.descriptionValue = this.superClientForm.controls['description'].value;
    }

    onModalCancel() {
        this.closeEvent.emit(false);
    }

    validateSuperClientForm() {
        if (!this.superClientForm.controls['superClientId'].errors?.required &&
            !this.superClientForm.controls['name'].errors?.required &&
            !this.superClientForm.controls['description'].errors?.required) {
            return true;
        } else {
            return false;
        }
    }

    private addSuperClientModalValidators() {
        this.superClientForm.controls['superClientId'].addValidators([Validators.required]);
        this.superClientForm.controls['superClientId'].updateValueAndValidity({onlySelf: true});
        this.superClientForm.controls['name'].addValidators([Validators.required]);
        this.superClientForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.superClientForm.controls['description'].addValidators([Validators.required]);
        this.superClientForm.controls['description'].updateValueAndValidity({onlySelf: true});
    }

    private resetSuperClientModalFields() {
        this.superClientForm.controls['superClientId'].removeValidators([Validators.required]);
        this.superClientForm.controls['superClientId'].updateValueAndValidity({onlySelf: true});
        this.superClientForm.controls['name'].removeValidators([Validators.required]);
        this.superClientForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.superClientForm.controls['description'].removeValidators([Validators.required]);
        this.superClientForm.controls['description'].updateValueAndValidity({onlySelf: true});
    }
}
