<h1 class="requirementsHeading">
    Formulary Requirements
</h1>
<p class="subHeader">Create or edit formulary requirement rules for each formulary below. If no requirement rules are
    created for a formulary, that formulary will not be displayed on the requirements page.</p>
<div class="requirements-container">
    <app-business-lob-tabs (selectedTab)="onBusinessLobTabSelectionChange($event)" [isPrimaryTab]="true"
                           [isUpdated]="isUpdated" [lobLastToggled]="lobLastToggled"></app-business-lob-tabs>
</div>
<ng-container *ngIf="showSpinner">
    <cvs-loader-overlay-container id="formularyRequirementsSpinner"
                                  [isLoading]=showSpinner
                                  [loadingMessage]="'Loading content...'">
    </cvs-loader-overlay-container>
</ng-container>
<ng-container *ngIf="!showSpinner">
    <div class="formularyContent" *ngFor="let formulary of resultsArray">
        <h2>{{formulary.formularyId}} - {{formulary.formularyName}}</h2>
        <button class="pds-btn-ghost"
                (click)="openDialog(formularyRequirementsFormRef, formulary)">
            <mat-icon svgIcon="plus--s"></mat-icon>
            Create Formulary Requirement Rule
        </button>
        <app-cvs-ag-grid id="formularyRuleGrid"
                         class="ag-theme-balham margin-top"
                         [gridOptions]="formulary.gridOption"
                         [modules]="modules"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [paginationEnabled]="true"
                         [paginationPageSize]="10"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [domLayout]="'autoHeight'"
                         [rowData]="formulary.data"
                         [defaultColDef]="defaultColDef"
                         [columnDefs]="columnDefs"
                         [suppressLoadingOverlay]="true"
                         [getContextMenuItems]="formularyRequirementsContextMenuItems"
                         [context]="context"
        >
        </app-cvs-ag-grid>
    </div>
</ng-container>
<ng-template #formularyRequirementsFormRef>
    <app-formulary-requirements-modal (closeEvent)="closeModal()" [formularyOpened]="formularyOpened"
                                      [formulariesDrugListMap]="formulariesDrugListMap" [formularyData]="formularyData"
                                      [spinnerValueFromFormularyRequirement]="spinnerValueFromFormularyRequirement" [isAnUpdate]="isAnUpdate" [formularyRequirementsState]="formularyRequirementsState"></app-formulary-requirements-modal>
</ng-template>

<ng-template #moveRankModalRef>
    <app-move-rank-modal (closeEvent)="onModalCancel()"  (submitEvent)="onMoveRankSubmit($event)" [selectedComponentName]="selectedFormularyName" [currentRankIndex]="currentRankIndex" [rankList]="moveRankOptions"  [moveRankData]="moveRankData" ></app-move-rank-modal>
</ng-template>