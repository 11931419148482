import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    CVSAlertType,
    CVSBannerService,
    CVSBannerType, CVSSnackBarData, CVSSnackBarService,
} from 'angular-component-library';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FORMULARY_DATE_BOUNDARY } from 'src/app/enum/FormularyDateBoundary';
import {take} from 'rxjs/operators';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {UM_PROGRAM_TYPE} from '../../../default-values/DefaultValues';
import {AppService} from '../../../service/app/app.service';
import {ConfigurationService} from '../../../service/configuration/configuration.service';
import {validDateRange} from '../dateRangeValidation';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {utcDate} from '../../../utility/utility';
@Component({
    selector: 'app-um-drug-program',
    templateUrl: './um-drug-program.component.html',
    styleUrls: ['./um-drug-program.component.scss']
})
export class UmDrugProgramComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() isAnUpdate;
    @Input() umProgramState;
    @Input() businessLobCode;
    hasTruncRejectMessageError = false;
    rejectionMessageValue='';
    umProgramForm: FormGroup;
    programTypes = UM_PROGRAM_TYPE;
    constructor(
        private fb: FormBuilder,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
        private _bannerService: CVSBannerService,
        private _snackBarService: CVSSnackBarService
    ) {
        this.createUmProgramForm();
    }
    ngOnInit(): void {
        this.resetUmProgramModalFields();
        if(this.isAnUpdate) {
            this.loadPrepopulatedUmProgram();
        }
    }

    public createUmProgramForm() {
        this.umProgramForm = this.fb.group({
            type: [''],
            name: [''],
            description: [''],
            viewStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            viewEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            rejectionMessage: ['']
        });
    }
    public loadPrepopulatedUmProgram() {
        this.umProgramForm = this.fb.group({
            type: [this.umProgramState.umProgramTypeCd],
            name: [this.umProgramState.umProgramName],
            description: [this.umProgramState.umProgramDescription],
            viewStartDate: [new Date(utcDate(this.umProgramState.umProgramViewStartDate)), [Validators.required, validDateRange]],
            viewEndDate: [new Date(utcDate(this.umProgramState.umProgramViewEndDate)), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(utcDate(this.umProgramState.umProgramEffectiveStartDate)), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(utcDate(this.umProgramState.umProgramEffectiveEndDate)), [Validators.required, validDateRange]],
            rejectionMessage: [this.umProgramState.rejectionMessage]
        });
    }

    saveUmProgram() {
        this.addUmProgramModalValidators();
        this.umProgramForm.markAllAsTouched();
        const isFormValid = this.validateUmProgramForm() && this.umProgramForm.valid;
        if (isFormValid) {
            const clientUmProgram: ClientUmProgram = {
                businessLobCode:null,
                umProgramTypeCd: null,
                umProgramName: null,
                umProgramDescription: null,
                rank: null,
                umProgramViewStartDate: null,
                umProgramViewEndDate: null,
                umProgramEffectiveStartDate: null,
                umProgramEffectiveEndDate: null,
                rejectionMessage: null
            };
            clientUmProgram.businessLobCode = this.businessLobCode;
            clientUmProgram.umProgramTypeCd = this.umProgramForm.controls['type'].value;
            clientUmProgram.umProgramName = this.umProgramForm.controls['name'].value.trim();
            clientUmProgram.umProgramDescription = this.umProgramForm.controls['description'].value.trim();
            clientUmProgram.umProgramViewStartDate = this.umProgramForm.controls['viewStartDate'].value;
            clientUmProgram.umProgramViewEndDate = this.umProgramForm.controls['viewEndDate'].value;
            clientUmProgram.umProgramEffectiveStartDate = this.umProgramForm.controls['effectiveStartDate'].value;
            clientUmProgram.umProgramEffectiveEndDate = this.umProgramForm.controls['effectiveEndDate'].value;
            clientUmProgram.rejectionMessage = this.umProgramForm.controls['rejectionMessage'].value;

            const index = this._appService.getSuperClientContext().id;
            if(this.isAnUpdate) {
                clientUmProgram.id = this.umProgramState.id;
                // eslint-disable-next-line max-len
                this._configurationService.updateUmProgram(index, clientUmProgram).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this._snackBarService.open({
                                iconName: 'check-circle--s',
                                message: 'UM Program updated successfully',
                                duration: 5,
                                verticalPosition: 'bottom',
                                horizontalPosition: 'center'
                            } as CVSSnackBarData);
                            this.umProgramForm.markAsPristine();
                            this.submitEvent.emit(true);
                        },error: () => {

                        }
                    });
            } else {
                this._configurationService.saveClientUmProgram(clientUmProgram, index).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.umProgramForm.markAsPristine();
                            this.onSaveUmProgramSuccess();
                            this.submitEvent.emit(true);
                        }, error: () => {
                            this.onSaveUmProgramError();
                        }
                    });
            }
        }
    }

    onSaveUmProgramSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: 'UM Program has been created.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(successBannerData);
    }

    onSaveUmProgramError() {
        const errorBannerData = {
            bannerType: CVSBannerType.Error,
            outletId: '#umProgramModalErrorBanner',
            hideX: true,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again.',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveUmProgram();
                    }
                }
            ]
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(errorBannerData);
    }

    stopWarning() {
        this.hasTruncRejectMessageError = false;
    }

    validateUmProgramForm() {
        if (!this.umProgramForm.controls['type'].errors?.required &&
            !this.umProgramForm.controls['name'].errors?.required &&
            !this.umProgramForm.controls['description'].errors?.required) {
            return true;
        } else {
            return false;
        }
    }

    onRejectionMessageUmChanges() {
        if (this.umProgramForm.controls['rejectionMessage'].value?.length > 210) {
            if (this.rejectionMessageValue.length === 0) {
                this.hasTruncRejectMessageError = true;
            }
            this.umProgramForm.controls['rejectionMessage']
                .setValue(this.umProgramForm.controls['rejectionMessage'].value?.substring(0, 210));
        } else {
            this.hasTruncRejectMessageError = false;
        }
        this.rejectionMessageValue = this.umProgramForm.controls['rejectionMessage'].value;
    }

    onModalCancel() {
        this.resetUmProgramModalFields();
        this.closeEvent.emit(true);
    }

    private addUmProgramModalValidators() {
        this.umProgramForm.controls['type'].addValidators([Validators.required]);
        this.umProgramForm.controls['type'].updateValueAndValidity({onlySelf: true});
        this.umProgramForm.controls['name'].addValidators([Validators.required]);
        this.umProgramForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.umProgramForm.controls['description'].addValidators([Validators.required]);
        this.umProgramForm.controls['description'].updateValueAndValidity({onlySelf: true});
    }

    private resetUmProgramModalFields() {
        this.umProgramForm.reset({
            viewStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            viewEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE),
            effectiveStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            effectiveEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE)
        });
        this.umProgramForm.controls['type'].reset();
        this.umProgramForm.controls['name'].reset();
        this.umProgramForm.controls['description'].reset();
        this.umProgramForm.controls['rejectionMessage'].reset();
    }

}
