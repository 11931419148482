import {Component, Input} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
    selector: 'app-kabob-cell',
    templateUrl: './kabob-cell.component.html',
    styleUrls: ['./kabob-cell.component.scss']
})
export class KabobCellComponent implements ICellRendererAngularComp {
    @Input() criteriaName = 'Open or Delete Criteria ';
    public params: any;

    agInit(params: any): void {
        this.params = params;
        this.criteriaName =  this.criteriaName.concat(params.data.criteriaTitle);
    }

    refresh(params: any): boolean {
        return false;
    }
}
