import {filter, pairwise, Subscription, timer} from 'rxjs';
import { Formulary } from '../model/Formulary';
import { ColDef, Column, GetDetailRowDataParams, IDetailCellRendererParams, RowNode } from '@ag-grid-community/core';
import { FormularyDrugAttachmentResponse } from '../model/FormularyDrugAttachmentResponse';
import { DrugUniverseApiService } from '../service/drug-universe/drug-universe-api.service';
import { MasterDrugResponse } from '../model/MasterDrugResponse';
import {Router, RoutesRecognized} from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AttachmentData } from '../model/AttachmentData';
import { BannerLink, CVSBannerService, CVSBannerType } from 'angular-component-library';
import { ICLRRouteReuseStrategy } from '../ICLRRouteReuseStrategy';
import { CVSBannerComponentData } from 'angular-component-library/completed/banner/banner.interfaces';
import { AppService } from '../service/app/app.service';
import { FormularyService } from '../service/formulary/formulary.service';
import { FormularyDrugSearch } from '../model/FormularyDrugSearch';
import { FormularyDrugChildResponse } from '../model/FormularyDrugChildResponse';
import { GRID_DATA } from '../enum/GridData';
import { MergedFormularyDrugAttachmentResponse } from '../model/MergedFormularyDrugAttachmentResponse';
import { FORMULARY_DATE_BOUNDARY } from '../enum/FormularyDateBoundary';
import { isValidDate } from '../utility/utility';
import moment from 'moment';

interface FormularyDrugCriteria {
    criteria: { drugIndicator; drug; fieldOrder; formularyDrugSearch };
}


export abstract class AbstractFormularyDrugPage {
    showLobSpinner = true;
    showFormularySpinner = true;
    showSpinner: boolean;
    childData = new Map<string, object>();
    drugAttachmentIndicator;
    formularyData;
    formularyChildData = undefined;
    showFormularyDrug = false;
    showUniverseList = false;
    errorState = false;
    errorHeaderText = 'We encountered an Error.';
    errorMessage = 'Something didn\'t work quite right. Try again or start over.';
    filteredFormularyData: Formulary[];
    linesOfBusiness;
    highlightColumn: string;
    subscriptions: Subscription[] = [];
    formularyDrugAttachmentResponse: FormularyDrugAttachmentResponse[];
    masterDrugResponses: MasterDrugResponse[];
    bannerData = {};
    minDate = FORMULARY_DATE_BOUNDARY.MINIMUM_DATE;
    maxDate = FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE;
    nodeCountData: { nodeId: string; count: string } = { nodeId: undefined, count: undefined };
    searchBy = {
        GPI: 'gpi',
    };
    detailDefaultColDef: ColDef = {
        headerClass: 'grid-Drug-Header',
        resizable: true,
        cellClass: 'excelString'
    };
    detailCellRendererParams: IDetailCellRendererParams = {
        detailGridOptions: {
            domLayout: 'autoHeight',
            columnDefs: [],
            enableCellTextSelection: true,
            detailRowHeight: 100,
            defaultColDef: this.detailDefaultColDef,
            pagination: true,
            paginationPageSize: 10,
            onGridReady: (event) => this.onDetailGridReady(),
            excelStyles: [{
                id: 'excelString',
                dataType: 'String'
            }],
            defaultExcelExportParams: {
                sheetName: undefined
            },
        },
        template: (params) => ('<div class="ag-details-row ag-details-row-auto-height" role="gridcell">' +
            `  <div style="padding-bottom: 10px;"><span id="count_${params.node.id}">Loading</span> Product Result(s)</div>` +
            '  <div ref="eDetailGrid" class="ag-details-grid ag-details-grid-auto-height ag-theme-balham" role="presentation"></div>' +
            '</div>'),
        getDetailRowData: (params) => {
            if (params.data?.drugMasterResponse?.labelName === GRID_DATA.DETAIL_LINK) {
                this.formularyChildData = this.buildMergedRowsForGrid(params.data?.mergedNDC11Detail);
                this.displayChildResults(params);
            } else {
                this.findAndSetAttachmentChildData(params);
            }
        }
    } as IDetailCellRendererParams;
    startDate: Date;
    endDate: Date;
    isSingleDateSelected: boolean;
    selectedLobs: string[];

    protected constructor(
        private appService: AppService,
        private formularyService: FormularyService,
        private drugUniverseService: DrugUniverseApiService,
        private _router: Router,
        private bannerService: CVSBannerService
    ) {
    }

    retrieveData() {
        this.appService.getInitializationSubject().subscribe(() => {
            this.retrieveAllFormularies();
            this.retrieveAllLinesOfBusiness();
        });
    }

    compareDate(obsoleteDate: string) {
        const startDate = moment(this.startDate).utc().format('YYYY-MM-DD');
        if (!!obsoleteDate) {
            return (moment(obsoleteDate, 'YYYY-MM-DD').isSameOrAfter(startDate));
        }
        return false;
    }

    findAndSetAttachmentChildData(params: GetDetailRowDataParams) {
        const formularyNumbers = params.data.formularies;
        const searchBy = this.searchBy.GPI;
        const searchByValue = params.data.drugAttachmentIndicator;
        const attachmentData: AttachmentData[] = [];

        this.showSpinner = true;
        formularyNumbers?.forEach((formularyNumber) => {
            const formularyNumberDetails = {
                formularyNumber: formularyNumber,
                tierNumber: params.data[`formularyDLTierNumber${formularyNumber}`],
                tierCode: params.data[`formularyDLLTTierCode${formularyNumber}`],
                pdlCode: params.data[`formularyDLLTPreferredDLCode${formularyNumber}`],
                fromDate: params.data[`tierFromDate${formularyNumber}`],
                thruDate: params.data[`tierThruDate${formularyNumber}`],
                coveredMultiSourceCode: params.data[`coveredMultisourceCodes${formularyNumber}`],
                drugAttachmentRxOTC: params.data[`drugAttachmentRxOTC${formularyNumber}`],
                drugAttachmentMEDD: params.data[`drugAttachmentMEDD${formularyNumber}`],
                formularyDLLTOnFormulary: params.data[`formularyDLLTOnFormulary${formularyNumber}`],
                formularyNDC: params.data[`formularyNDC${formularyNumber}`],
                formularyDLHierarchy: params.data[`formularyDLHierarchy${formularyNumber}`],
                drugAttachmentIndicatorType: params.data.drugAttachmentIndicatorType,
            };
            attachmentData.push(formularyNumberDetails);
        });

        if (!this.childData.has(formularyNumbers + ',' + params.data.drugAttachmentIndicator)) {
            const drugPositionRequestBody = {
                searchDate: {
                    startDate: moment(this.startDate).utc().format('YYYY-MM-DD'),
                    endDate: moment(this.endDate).utc().format('YYYY-MM-DD'),
                    isSingleDateSelected: this.isSingleDateSelected
                },
                attachmentData
            };

            if (params.data.drugAttachmentIndicatorType === 'GPI') {
                this.formularyService.getCurrentDrugPositionWithAttachmentForGPI(
                    this.appService.getSuperClientContext().id,
                    formularyNumbers,
                    searchBy,
                    searchByValue,
                    drugPositionRequestBody
                ).pipe(take(1)).subscribe(data => {
                    this.formularyChildData = this.buildMergedRowsForGrid(data);
                    this.showSpinner = false;
                    this.displayChildResults(params);
                });
            } else {
                this.formularyService.getCurrentDrugPositionWithAttachment(
                    this.appService.getSuperClientContext().id,
                    formularyNumbers,
                    searchBy,
                    searchByValue,
                    drugPositionRequestBody
                ).pipe(take(1))
                    .subscribe(data => {
                        this.formularyChildData = this.buildGPIRowForGrid(data);
                        this.showSpinner = false;
                        this.displayChildResults(params);
                    });
            }

        } else {
            this.formularyChildData = this.childData.get(params.data.formularyNumber + ',' + params.data.drugAttachmentIndicator);
            this.displayChildResults(params);
            this.showSpinner = false;
        }
    }

    displayChildResults(params: GetDetailRowDataParams) {
        const detailRow = params.node as RowNode;
        const generalInfoColumnGroupGPI: Column =
            detailRow.detailGridInfo?.columnApi?.getAllGridColumns().find(g => g.getColDef().headerName === 'GPI');
        this.setChildResultCount(params?.node?.id, this.formularyChildData?.length);
        if (this.formularyChildData.length === 0) {
            this.formularyChildData.push({ drugAttachmentIndicator: 'NO DATA FOUND' });
            detailRow.detailGridInfo.api.setHeaderHeight(0);
            detailRow.detailGridInfo.api.setPagination(false);
            generalInfoColumnGroupGPI.getColDef().checkboxSelection = () => false;
            generalInfoColumnGroupGPI.getColDef().showDisabledCheckboxes = false;
        } else if (params.data.drugAttachmentIndicatorType === 'GPI') {
            generalInfoColumnGroupGPI.getColDef().checkboxSelection = () => false;
            generalInfoColumnGroupGPI.getColDef().showDisabledCheckboxes = true;
        }
        params.successCallback(this.formularyChildData);
        this.childData.set(params.data.formularyNumber + ',' + params.data.drugAttachmentIndicator,
            this.formularyChildData);
        this.showSpinner = false;
    }

    buildGPIRowForGrid(data: FormularyDrugChildResponse[]) {
        data.forEach(i => {
            ['formularyNDC', 'formularyDLTierNumber', 'coveredMultisourceCodes', 'formularyDLLTOnFormulary',
                'drugAttachmentRxOTC', 'drugAttachmentMEDD', 'tierFromDate', 'tierThruDate',
                'drugAttachmentMessageType', 'drugAttachmentMessageCode', 'drugAttachmentMessageDescription',
                'formularyDLHierarchy', 'formularyDLLTTierCode', 'formularyDLLTTierDescription',
                'formularyDLLTPreferredDLCode',
                'formularyDLLTPreferredDLDescription'
            ].forEach(j => {
                if (i.hasOwnProperty(j)) {
                    i[`${j}${i.formularyNumber}`] = i[j];
                }
            });
        });
        return data.map(item => ({ ...item }));
    }


    retrieveAllFormularies() {
        const index = this.appService.getSuperClientContext().id;
        this.subscriptions.push(this.formularyService.getFormularyData(index).pipe(take(1)).subscribe({
            next: (formularyResponse) => {
                this.errorState = false;
                if (formularyResponse) {
                    formularyResponse.sort((a, b) =>
                        isNaN(Number(a.formularyNumber)) ? a.formularyNumber > b.formularyNumber ? 1 : -1
                            : Number(a.formularyNumber) > Number(b.formularyNumber) ? 1 : -1);
                }
                this.formularyData = formularyResponse;
                this.filteredFormularyData = formularyResponse;
            },
            error: () => {
                (this._router.routeReuseStrategy as ICLRRouteReuseStrategy).hasErrorDontReuseRoute(this._router.url);
                this.errorState = true;
                this.showFormularySpinner = false;
                const errorBannerData = {
                    bannerType: CVSBannerType.Error,
                    hideX: false,
                    outletId: '#errorBanner',
                    headline: this.errorHeaderText,
                    body: this.errorMessage,
                } as CVSBannerComponentData;
                this.bannerService.sendAlert(errorBannerData);
            }
        }));
    }

    findDrugs({ criteria }: FormularyDrugCriteria) {
        if (this._router.url.endsWith('formularySearch')) {
            this.findFormularyDrugs({ criteria });
        } else if (this._router.url.endsWith('drugSearch')) {
            this.findUniversalDrugs({ criteria });
        }
    }

    findUniversalDrugs({ criteria }: FormularyDrugCriteria) {
        const { drugIndicator, drug } = criteria;
        this.showSpinner = true;
        this.showUniverseList = true;
        this.masterDrugResponses = undefined;
        this.highlightColumn = drugIndicator;
        this.drugUniverseService.getDrug(drugIndicator, drug, 'true', 'true')
            .pipe(take(1))
            .subscribe({
                next: data => {
                    this.bannerService.close();
                    this.errorState = false;
                    this.showSpinner = false;
                    this.masterDrugResponses = data;
                },
                error: error => {
                    this.errorState = true;
                    this.showUniverseList = false;
                    this.showSpinner = false;
                    const errorBannerData = {
                        bannerType: CVSBannerType.Error,
                        hideX: false,
                        outletId: '#errorBanner',
                        headline: this.errorHeaderText,
                        body: this.errorMessage,
                        bannerLinks: this.onBannerLinkClick({ criteria }, 'findUniversalDrugs')
                    } as CVSBannerComponentData;
                    this.bannerService.sendAlert(errorBannerData);
                }
            });
    }

    isOnFormularyOrOffFormularySelected(formularyDrugSearch: FormularyDrugSearch) {
        return formularyDrugSearch.onFormulary || formularyDrugSearch.offFormulary;
    }

    isCodedOrDefaultSelected(formularyDrugSearch: FormularyDrugSearch) {
        return formularyDrugSearch.coded || formularyDrugSearch.defaulted;
    }

    isDateSelected(formularyDrugSearch: FormularyDrugSearch) {
        return (formularyDrugSearch.singleDateSelected && isValidDate(formularyDrugSearch.fromDate)) ||
            (!formularyDrugSearch.singleDateSelected && isValidDate(formularyDrugSearch.fromDate)
                && isValidDate(formularyDrugSearch.toDate));
    }

    findFormularyDrugs({ criteria }: FormularyDrugCriteria) {
        const { drugIndicator, drug, formularyDrugSearch } = criteria;
        this.showFormularyDrug = true;
        this.showSpinner = true;
        this.highlightColumn = drugIndicator;
        this.setUpFormularyDrugCriteria(formularyDrugSearch, drugIndicator, drug);
        this.startDate = formularyDrugSearch.fromDate;
        this.endDate = formularyDrugSearch.toDate;
        this.isSingleDateSelected = formularyDrugSearch.singleDateSelected;
        this.formularyService.selectedDateRange.next(
            [formularyDrugSearch.fromDate,formularyDrugSearch.toDate,formularyDrugSearch.singleDateSelected]);

        if (this.isOnFormularyOrOffFormularySelected(formularyDrugSearch) &&
            this.isCodedOrDefaultSelected(formularyDrugSearch) &&
            this.isDateSelected(formularyDrugSearch)) {
            this.formularyService.getFormularyWithDrugAttachment(formularyDrugSearch, this.appService.getSuperClientContext().id)
                .pipe(map((data) => this.buildMergedRowsForGrid(data)))
                .pipe(take(1))
                .subscribe({
                    next: data => {
                        this.bannerService.close();
                        this.formularyDrugAttachmentResponse = data;
                        this.showSpinner = false;
                    },
                    error: error => {
                        this.errorState = true;
                        this.showFormularyDrug = false;
                        this.showSpinner = false;
                        const errorBannerData = {
                            bannerType: CVSBannerType.Error,
                            hideX: false,
                            outletId: '#errorBanner',
                            headline: this.errorHeaderText,
                            body: this.errorMessage,
                            bannerLinks: this.onBannerLinkClick({ criteria }, 'findFormularyDrugs')
                        } as CVSBannerComponentData;
                        this.bannerService.sendAlert(errorBannerData);
                    }});
        } else {
            timer(50).pipe(take(1)).subscribe(() => {
                this.bannerService.close();
                this.formularyDrugAttachmentResponse = [];
                this.showSpinner = false;
            });
        }
    }

    buildMergedRowsForGrid(data: MergedFormularyDrugAttachmentResponse[]) {
        const rowData: FormularyDrugAttachmentResponse[] = [];
        if (data) {
            data.forEach(d => {
                const thisRow = {} as FormularyDrugAttachmentResponse;
                thisRow.formularies = [];
                d.formularies.forEach(i => {
                    thisRow.formularies.push(i.formularyNumber);
                    ['formularyNDC', 'formularyDLTierNumber', 'coveredMultisourceCodes', 'formularyDLLTOnFormulary',
                        'drugAttachmentRxOTC', 'drugAttachmentMEDD', 'tierFromDate', 'tierThruDate', 'drugAttachmentMessageType',
                        'drugAttachmentMessageCode', 'drugAttachmentMessageDescription', 'formularyDLHierarchy',
                        'formularyDLLTTierCode', 'formularyDLLTTierDescription', 'formularyDLLTPreferredDLCode',
                        'formularyDLLTPreferredDLDescription', 'defaultTier'
                    ].forEach(j => {
                        if (i.hasOwnProperty(j)) {
                            thisRow[`${j}${i.formularyNumber}`] = i[j];
                        }
                    });
                });
                rowData.push({
                    ...thisRow,
                    mergedNDC11Detail: d.commonCommon?.mergedNDC11Detail,
                    drugMasterResponse: d.commonCommon.drugMasterResponse,
                    drugAttachmentIndicator: d.commonCommon.drugAttachmentIndicator,
                    drugAttachmentIndicatorType: d.commonCommon.drugAttachmentIndicatorType,
                    rowType: d.commonCommon.rowType
                });
            });
        }
        return rowData;
    }

    setUpFormularyDrugCriteria(formularyDrugSearch, drugIndicator, drug) {
        formularyDrugSearch.searchBy = drugIndicator;
        formularyDrugSearch.searchByValue = drug;
        formularyDrugSearch.includeDrafts = false;
    }

    filterFormularyGrid(event) {
        this.selectedLobs = event.lobValues;
        let filteredFormularyData = [];
        let formularyLob = [];
        this.selectedLobs.forEach(lob =>  {
            formularyLob = [...formularyLob, ...this.formularyService.businessLobToFormularyLobMap[lob]];
        });
        formularyLob.forEach(lobFilter => {
            const formularyListFilteredBySelectedLOB = event.formularyValues
                .filter(data => data.linesOfBusiness.find(lob => lob.code === lobFilter));
            filteredFormularyData = filteredFormularyData.concat(formularyListFilteredBySelectedLOB);
        });
        const filterLobFormularyData = filteredFormularyData.filter((n, i) => filteredFormularyData.indexOf(n) === i);
        this.filteredFormularyData = filterLobFormularyData;
    }

    retrieveAllLinesOfBusiness() {
        this.subscriptions.push(this.formularyService
            .getSuperClientBusinessLobs(this.appService.getSuperClientContext().id).pipe(take(1)).subscribe({
                next: lob => {
                    this.errorState = false;
                    this.linesOfBusiness = lob;
                },
                error: error => {
                    (this._router.routeReuseStrategy as ICLRRouteReuseStrategy).hasErrorDontReuseRoute(this._router.url);
                    this.errorState = true;
                    this.showFormularyDrug = false;
                    this.showLobSpinner = false;
                    const errorBannerData = {
                        bannerType: CVSBannerType.Error,
                        hideX: false,
                        outletId: '#errorBanner',
                        headline: this.errorHeaderText,
                        body: this.errorMessage,
                    } as CVSBannerComponentData;
                    this.bannerService.sendAlert(errorBannerData);
                }}));
    }

    onBannerLinkClick({ criteria }, request) {
        const linkFuncs: BannerLink[] = [];
        if (request === 'findUniversalDrugs') {
            linkFuncs.push({ linkText: 'Retry', linkFunc: () => this.findUniversalDrugs({ criteria }) });
        }
        if (request === 'findFormularyDrugs') {
            linkFuncs.push({ linkText: 'Retry', linkFunc: () => this.findFormularyDrugs({ criteria }) });
        }
        return linkFuncs;
    }

    suppressRow(params: any) {
        return false;
    }

    getDocument() {
        return document;
    }

    onDetailGridReady() {
        const countElement = this.getDocument().getElementById(`count_${this.nodeCountData.nodeId}`);
        if (countElement) {
            countElement.innerText = this.nodeCountData.count;
        }
    }

    setChildResultCount(nodeId: string, count: string) {
        this.nodeCountData = { nodeId, count };
        this.onDetailGridReady();
    }
}
