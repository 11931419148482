<div id="successBanner"></div>
<div id="errorBanner"></div>
<div id="medispanSettingsContainer">
    <h1>Medispan Settings</h1>
    <span class="right-justified">
        <button class="pds-btn-primary"
                (click)="medispanSettingsDialog()"
                [disabled]="disableSave">
            Save</button>
    </span>
</div>
<app-business-lob-tabs
        [isPrimaryTab]="true"
        (selectedTab)="onBusinessLobTabSelectionChange($event)"></app-business-lob-tabs>
<h2 id="medispanUpdateTypeHeader"> {{selectedBusinessLob?.description}} Medispan Update Type</h2>
<cvs-inline-callout class="padding-bottom-10" [type]="inlineCalloutType.info"
                    message="Changes made will affect other {{superClientContext.clientName}} user's view of
                    the Medispan Report Update Types in the CLR tool"
                    isIconFilled
>
</cvs-inline-callout>
<p class="medispan-setting-p"> Select which Medispan Report Update Types to Manage </p>
<div *ngFor="let medispanUpdate of medispanUpdates">
    <mat-checkbox [checked]="medispanUpdate?.checked" (change)="toggleCheckbox(medispanUpdate, VALUE)">
        {{medispanUpdate.type}}
    </mat-checkbox>
    <ng-container *ngIf="medispanUpdate?.hasOwnProperty(UPDATE_REPACKAGER) && medispanUpdate?.checked">
        <div class="margin-left">
            <p>Review MONY updates for repackager products?</p>
            <mat-radio-button class="radio1" [checked]="medispanUpdate?.updateRepackager?.checked"
                              (change)="toggleCheckbox(medispanUpdate, UPDATE_REPACKAGER)">Yes
            </mat-radio-button>
            <mat-radio-button class="radio2" [checked]="!medispanUpdate?.updateRepackager?.checked"
                              (change)="toggleCheckbox(medispanUpdate, UPDATE_REPACKAGER)">No
            </mat-radio-button>
        </div>
    </ng-container>
</div>



