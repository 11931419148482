import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '../../environments/environment';
import {UserPreference} from '../../model/UserPreference';
import {map, tap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UserPreferenceService {
    userPreference: UserPreference = undefined;

    constructor(private cvshttp: CVSHttpClient) {
    }

    loadUserPreference(): Observable<UserPreference> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}userPreference/load`)
            .pipe(tap(result => this.userPreference = result));
    }

    saveUserPreference(userPreference: UserPreference): Observable<any>{
        this.userPreference = userPreference;
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}userPreference/save`, userPreference);
    }
}
