import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TrackingDate} from '../../../model/TrackingDate';
import {FormControl} from '@angular/forms';
import {utcDate} from '../../../utility/utility';

@Component({
    selector: 'app-recommendation',
    templateUrl: './recommendation.component.html',
    styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent implements OnInit, OnChanges {
    @Input() recommendationDate!: TrackingDate;
    @Input() medAdHocDate!: string;
    approvalDate = new FormControl();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.approvalDate.setValue(new Date(utcDate(changes.recommendationDate.currentValue?.approvalDt)));
        this.medAdHocDate = utcDate(new Date(this.medAdHocDate));
    }

}
