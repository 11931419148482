import {
    AfterViewInit,
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    ATTACHMENT,
    ATTACHMENT_LEVEL,
    ATTACHMENT_MONY, BUSINESS_LOB_CD,
    MED_D, NA,
    QL_TYPE_FOR_ALL, QL_UNIT_OF_MEASURE,
    RX_OTC
} from '../../../../../default-values/DefaultValues';
import {MedispanTrackerResponse} from '../../../../../model/MedispanTrackerResponse';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {ClientUmProgram} from '../../../../../model/ClientUmProgram';
import {BUSINESS_LOB} from '../../../../../enum/BusinessLob';
import {of, Subscription} from 'rxjs';
import {RecommendationsFormService} from '../../../../../service/recommendations-form/recommendations-form.service';
import {map, take, tap} from 'rxjs/operators';
import {RecommendationService} from '../../../../../service/recommendation/recommendation.service';
import {QuantityLimitRequirements} from '../../../../../model/QuantityLimitRequirements';
import {CVSBannerService} from 'angular-component-library';

@Component({
    selector: 'app-quantity-limit-form-entry',
    templateUrl: './quantity-limit-form-entry.component.html',
    styleUrls: ['./quantity-limit-form-entry.component.scss']
})
export class QuantityLimitFormEntryComponent implements OnInit, OnDestroy  {
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() umProgram: any;
    @Input() programIndexSelected: number;
    @Input() qlUmPrograms;
    @Output() isQuantityLimitSavedEmitter = new EventEmitter<boolean>();
    @Output() isQuantityLimitIncompleteEmitter = new EventEmitter<boolean>();
    @ViewChildren('attachmentMony') attachmentMony: QueryList<MatSelect>;
    @ViewChildren('attachmentMonyCheckbox') attachmentMonyCheckbox: QueryList<MatCheckbox>;
    quantityLimits: ClientUmProgram[] = [];
    quantityLimitFormGroups: { [key: string]: FormGroup } = {};
    panelOpenState = true;
    attachmentLevels = ATTACHMENT_LEVEL;
    attachmentLevelsKey: {[key: string]: [key: string, value: string][]} = {};
    attachmentMonyList = ATTACHMENT_MONY;
    rxOtcList = RX_OTC;
    medDList = MED_D;
    qlTypeSelection = QL_TYPE_FOR_ALL;
    attachmentTypes = [];
    savedQuantityLimits: QuantityLimitRequirements[] = [];
    mappedStatus = new Map<string, string>();
    monyIndeterminates = new Map<string, boolean>();
    qlDescriptions= new Map<string, string>();
    enableReadOnlys = new Map<string, string>();
    NA = NA;
    disableQlUnitOfMeasure = new Map<string, boolean>();
    enableQlUnitOfMeasure= new Map<string, boolean>();
    qlUnitOfMeasureOptions = QL_UNIT_OF_MEASURE;
    observableData = of(this.qlUnitOfMeasureOptions);
    index = 1;
    protected readonly BUSINESS_LOB = BUSINESS_LOB;
    private subscriptions: Subscription[] = [];
    constructor(private _recommendationService: RecommendationService, private recommendationsFormService: RecommendationsFormService,
        private fb: FormBuilder, private bannerService: CVSBannerService) {
    }

    ngOnInit(): void {
        this.recommendationsFormService.setMedispanTrackerResponse(this.medispanTrackerResponse);
        this.recommendationsFormService.setAttachmentType();
        this.attachmentTypes = this.recommendationsFormService.getAttachmentType();
        this.createFormGroups();
        this.getSavedQuantityLimits();
    }

    ngAfterViewInit() {
        this.scrollToFormulary(`quantity-limit-${this.umProgram?.umProgramName}-${this.umProgram?.rank}`);
    }

    scrollToFormulary(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }

    getSavedQuantityLimits() {
        this.subscriptions.push(this._recommendationService
            .getQuantityLimitRequirements(this.medispanTrackerResponse.encryptedRecordId,
                this.medispanTrackerResponse.businessLobCd)
            .pipe(
                map(data => data?.map(d => ({
                    encryptedRecordId: d.encryptedRecordId,
                    umProgramName: d.umProgramName,
                    businessLobCd: d.businessLobCd,
                    statusCd: d.statusCd,
                    qlType: !d.qlType ? this.qlTypeSelection[0].code : d.qlType,
                    qlAmount: !d.qlAmount ? '' : d.qlAmount?.toString(),
                    qlUnitOfMeasure: !d.qlUnitOfMeasure ? '' : d.qlUnitOfMeasure,
                    qlPeriod: !d.qlPeriod ? '' : d.qlPeriod?.toString(),
                    qlDescription: !d.qlDescription ? '' : d.qlDescription,
                    attachmentType: d.attachmentType,
                    attachmentLevel: d.attachmentLevel,
                    attachmentMony: d.attachmentMony,
                    attachmentRxOtcCd: d.attachmentRxOtcCd,
                    attachmentMedDCd: d.attachmentMedDCd,
                    clientUmProgramId: d.clientUmProgramId,
                    applyDefaultAttachment: d.applyDefaultAttachment,
                    notes: d.notes,
                    rejectionMessage: d.rejectionMessage
                }))),
                tap(qls => {
                    this.savedQuantityLimits.push(...qls);
                })
            )
            .subscribe({
                next: () => {
                    this.setFormGroup();
                }, error: () => {},
            }));
    }

    setFormGroup() {
        this.savedQuantityLimits.forEach(ql => {
            if (this.quantityLimits.filter(item => item.id === Number(ql.clientUmProgramId)).length === 1) {
                this.loadSavedQuantityLimits(ql);
            }
        });
    }

    loadSavedQuantityLimits(ql: QuantityLimitRequirements) {
        let mony: string[] = [];
        const formArrayFormGroup = this.quantityLimitFormGroups[ql.clientUmProgramId];
        if (formArrayFormGroup) {
            formArrayFormGroup.controls.qlType.patchValue(ql.qlType, {emitEvent: false});
            this.enableGridNotes(ql.clientUmProgramId);
            if (formArrayFormGroup.controls.qlAmount?.enabled) {
                formArrayFormGroup.controls.qlAmount.patchValue(ql.qlAmount, {emitEvent: false});
            }
            if (formArrayFormGroup.controls.qlPeriod?.enabled) {
                formArrayFormGroup.controls.qlPeriod.patchValue(ql.qlPeriod, {emitEvent: false});
            }
            if (formArrayFormGroup.controls.qlDescription?.enabled) {
                formArrayFormGroup.controls.qlDescription.patchValue(ql.qlDescription, {emitEvent: false});
            }
            if (formArrayFormGroup.controls.qlUnitOfMeasure?.enabled) {
                formArrayFormGroup.controls.qlUnitOfMeasure.patchValue(this.qlUnitOfMeasureOptions
                    [Number(ql.qlUnitOfMeasure) - 1], {emitEvent: false});
            }
            formArrayFormGroup.controls.attachmentType.patchValue(ql.attachmentType, {emitEvent: false});
            this.buildAttachmentLevels(ql.attachmentType, ql.clientUmProgramId, false);
            if (ql.attachmentMony) {
                mony = ql.attachmentMony.split('');
                if (mony.length === this.attachmentMonyList.length) {
                    formArrayFormGroup.controls.attachmentMonyCheckbox.setValue(true);
                }
            }
            formArrayFormGroup.controls.attachmentMonyDD.patchValue([...mony.map(m => m)],
                {emitEvent: false});
            formArrayFormGroup.controls.monyCode.patchValue('N/A', {emitEvent: false});
            formArrayFormGroup.controls.qlNotes.patchValue(ql.notes, {emitEvent: false});
            formArrayFormGroup.controls.attachmentLevel.patchValue(ql.attachmentLevel, {emitEvent: false});
            formArrayFormGroup.controls.customClaimRejectMessage.patchValue(ql.rejectionMessage, {emitEvent: false});
            formArrayFormGroup.controls.coverageMiscRxOtc.patchValue(ql.attachmentRxOtcCd, {emitEvent: false});
            formArrayFormGroup.controls.coverageMiscMedD.patchValue(ql.attachmentMedDCd, {emitEvent: false});
            if (ql.applyDefaultAttachment==='Yes' || !ql.applyDefaultAttachment) {
                formArrayFormGroup.controls.applyDefaultCheckBox.patchValue(true, {emitEvent: false});
                this.setDefaultSelection(ql.clientUmProgramId);
            } else {
                formArrayFormGroup.controls.applyDefaultCheckBox.patchValue(false, {emitEvent: false});
                this.setDefaultSelection(ql.clientUmProgramId);
            }
            this.onAttachmentMonyDDChange(ql.clientUmProgramId);
            this.handleQlDescriptions(ql.clientUmProgramId);
            this.mappedStatus[ql.clientUmProgramId.toString()] = ql.statusCd;
        }
        formArrayFormGroup.updateValueAndValidity();
    }


    createFormGroups(): void {
        if (Object.keys(this.quantityLimitFormGroups).length === 0) {
            this.quantityLimits = this.qlUmPrograms;
            this.quantityLimits.forEach(ql => {
                this.quantityLimitFormGroups[ql.id] = this.fb.group({
                    [`qlType`]: new FormControl({value: this.qlTypeSelection[0].code, disabled: false}),
                    // eslint-disable-next-line max-len
                    [`qlAmount`]: new FormControl({
                        value: 'N/A',
                        disabled: true
                    }, [Validators.pattern(/^\d*?(\.\d{1,4})?$/), Validators.required, Validators.min(0.0001)]),
                    [`qlUnitOfMeasure`]: new FormControl({value: '', disabled: true}, Validators.required),
                    [`disabledQlUnitOfMeasure`]: new FormControl({value: 'N/A', disabled: true}),
                    [`qlPeriod`]: new FormControl({
                        value: 'N/A',
                        disabled: true
                    }, [Validators.pattern(/^[0-9]+$/), Validators.required, Validators.min(1)]),
                    [`qlDescription`]: new FormControl({value: 'N/A', disabled: true}),
                    [`customClaimRejectMessage`]: new FormControl({value: '', disabled: true}),
                    [`qlNotes`]: new FormControl({value: '', disabled: true}),
                    [`attachmentType`]: new FormControl({value: this.attachmentTypes[0], disabled: true}, Validators.required),
                    [`attachmentLevel`]: new FormControl({value: '', disabled: true}, Validators.required),
                    [`attachmentMonyDD`]: new FormControl({value: [''], disabled: true}, Validators.required),
                    [`monyCode`]: new FormControl({value: [''], disabled: true}),
                    [`coverageMiscRxOtc`]: new FormControl({value: '3', disabled: true}),
                    [`coverageMiscMedD`]: new FormControl({value: '3', disabled: true}),
                    [`applyDefaultCheckBox`]: new FormControl({value: true, disabled: true}),
                    [`attachmentMonyCheckbox`]: new FormControl(false)
                });
                this.mappedStatus[ql.id] = '0';
                this.disableQlUnitOfMeasure[ql.id] = true;
                this.enableQlUnitOfMeasure[ql.id] = false;
                this.monyIndeterminates[ql.id] = false;
                this.buildAttachmentLevels(this.attachmentTypes[0], ql.id, true);
                this.quantityLimitFormGroups[ql.id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
                this.quantityLimitFormGroups[ql.id].controls.attachmentMonyDD
                    .patchValue([...this.attachmentMonyList.map(m => m.code)], {emitEvent: false});
                this.quantityLimitFormGroups[ql.id].controls.attachmentMonyDD.disable();
            });
        }
    }

    buildAttachmentLevels(value: any, qlId, isReset: boolean) {
        const controls = this.quantityLimitFormGroups[qlId]?.controls;
        this.recommendationsFormService.buildAttachmentLevels(value, controls, isReset);
        this.attachmentLevelsKey[qlId.toString()] = this.recommendationsFormService.getAttachmentLevels();
    }


    toggleAllSelection(umProgramId: number) {
        const controls = this.quantityLimitFormGroups[umProgramId].controls;
        this.recommendationsFormService.toggleAllSelection(controls);
    }

    onAttachmentMonyDDChange(umProgramId) {
        const controls = this.quantityLimitFormGroups[umProgramId].controls;
        const attachmentMonyDDFCLength = controls.attachmentMonyDD.value.length;
        this.monyIndeterminates[umProgramId] = (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        if ((!controls.attachmentMonyCheckbox && attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            (controls.attachmentMonyCheckbox && attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            controls.attachmentMonyCheckbox.setValue(false);
        }
        if (attachmentMonyDDFCLength === this.attachmentMonyList.length) {
            controls.attachmentMonyCheckbox.setValue(true);
        }
    }

    setDefaultSelection(id) {
        if (this.quantityLimitFormGroups[id].controls.applyDefaultCheckBox.value === true) {
            this.clearAllSelections(id);
        } else {
            this.enableAll(id);
        }
        this.quantityLimitFormGroups[id].updateValueAndValidity();
    }

    enableAll(id) {
        this.quantityLimitFormGroups[id].controls['attachmentMonyDD'].markAsUntouched();
        this.quantityLimitFormGroups[id].controls['attachmentType'].markAsUntouched();
        this.quantityLimitFormGroups[id].controls['attachmentLevel'].markAsUntouched();
        this.quantityLimitFormGroups[id].controls.attachmentType.enable();
        this.quantityLimitFormGroups[id].controls.attachmentLevel.enable();
        this.quantityLimitFormGroups[id].controls.attachmentMonyDD.enable();
        this.quantityLimitFormGroups[id].controls.coverageMiscRxOtc.enable();
        this.quantityLimitFormGroups[id].controls.coverageMiscMedD.enable();
    }

    clearAllSelections(id) {
        this.quantityLimitFormGroups[id].controls.attachmentType.patchValue(this.attachmentTypes[0], {emitEvent: false});
        this.buildAttachmentLevels(this.attachmentTypes[0], id, true);
        this.quantityLimitFormGroups[id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
        this.quantityLimitFormGroups[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
            {emitEvent: false});
        this.quantityLimitFormGroups[id].controls.coverageMiscRxOtc.patchValue('3', {emitEvent: false});
        this.quantityLimitFormGroups[id].controls.coverageMiscMedD.patchValue('3', {emitEvent: false});
        this.quantityLimitFormGroups[id].controls.attachmentType.disable();
        this.quantityLimitFormGroups[id].controls.attachmentLevel.disable();
        this.quantityLimitFormGroups[id].controls.attachmentMonyDD.disable();
        this.quantityLimitFormGroups[id].controls.monyCode.disable();
        this.quantityLimitFormGroups[id].controls.attachmentMonyCheckbox.disable();
        this.quantityLimitFormGroups[id].controls.coverageMiscRxOtc.disable();
        this.quantityLimitFormGroups[id].controls.coverageMiscMedD.disable();
    }


    enableGridNotes(qlProgramId) {
        const controls = this.quantityLimitFormGroups[qlProgramId].controls;
        const currentQLUmProgram =this.quantityLimits.filter(data => data.id === qlProgramId)[0];

        this.handleQuantityMax(controls,currentQLUmProgram);
        this.handleDaySupplyMaxAndMaxFill(controls,currentQLUmProgram);
        this.handleDaySupplyOverTime(controls,currentQLUmProgram);
        if (controls.qlType.value === this.qlTypeSelection['1'].code
            || controls.qlType.value === this.qlTypeSelection['4'].code) {
            controls.qlNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.customClaimRejectMessage.patchValue((currentQLUmProgram?.rejectionMessage));
            controls.customClaimRejectMessage.enable();
            controls.qlAmount.enable();
            controls.qlAmount.patchValue('');
            controls.qlUnitOfMeasure.enable();
            controls.qlUnitOfMeasure.patchValue('');
            controls.qlPeriod.enable();
            controls.qlPeriod.patchValue('');
            // controls.qlDescription.enable();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
            controls.qlDescription.patchValue('');
            this.disableQlUnitOfMeasure[qlProgramId] = false;
            this.enableQlUnitOfMeasure[qlProgramId] = true;
        }
        if (controls.qlType.value === this.qlTypeSelection['7'].code
            || controls.qlType.value === this.qlTypeSelection['8'].code
            || controls.qlType.value === this.qlTypeSelection['9'].code
            || controls.qlType.value === this.qlTypeSelection['10'].code
            || controls.qlType.value === this.qlTypeSelection['11'].code
            || controls.qlType.value === this.qlTypeSelection['12'].code) {
            controls.qlNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.qlAmount.disable();
            controls.qlAmount.patchValue('N/A');
            controls.qlUnitOfMeasure.disable();
            controls.disabledQlUnitOfMeasure.disable();
            controls.disabledQlUnitOfMeasure.patchValue('N/A');
            controls.qlPeriod.disable();
            controls.qlPeriod.patchValue('N/A');
            controls.qlDescription.disable();
            controls.qlDescription.patchValue('N/A');
            controls.customClaimRejectMessage.disable();
            controls.customClaimRejectMessage.patchValue('');
            this.disableQlUnitOfMeasure[qlProgramId]  = true;
            this.enableQlUnitOfMeasure[qlProgramId]  = false;
        }
        if (controls.qlType.value === this.qlTypeSelection[0]?.code) {
            this.clearAllSelections(qlProgramId);
            controls.customClaimRejectMessage.patchValue('', {emitEvent: false});
            controls.customClaimRejectMessage.disable();
            controls.qlNotes.patchValue('', {emitEvent: false});
            controls.qlNotes.disable();
            controls.applyDefaultCheckBox.patchValue(true);
            controls.applyDefaultCheckBox.disable();
            controls.qlAmount.disable();
            controls.qlAmount.patchValue('N/A');
            controls.qlUnitOfMeasure.disable();
            controls.qlUnitOfMeasure.patchValue('');
            controls.disabledQlUnitOfMeasure.disable();
            controls.disabledQlUnitOfMeasure.patchValue('N/A');
            controls.qlPeriod.disable();
            controls.qlPeriod.patchValue('N/A');
            controls.qlDescription.disable();
            controls.qlDescription.patchValue('N/A');
            this.enableReadOnlys[qlProgramId] = '';
            this.disableQlUnitOfMeasure[qlProgramId]  = true;
            this.enableQlUnitOfMeasure[qlProgramId]  = false;
        }

    }
    handleQuantityMax(controls , currentQLUmProgram: ClientUmProgram){
        if(controls.qlType.value === this.qlTypeSelection['2'].code) {
            controls.qlNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.customClaimRejectMessage.patchValue((currentQLUmProgram?.rejectionMessage));
            controls.customClaimRejectMessage.enable();
            controls.qlAmount.enable();
            controls.qlAmount.patchValue('');
            controls.qlUnitOfMeasure.enable();
            controls.qlUnitOfMeasure.patchValue('');
            controls.qlPeriod.disable();
            controls.qlPeriod.patchValue('N/A');
            controls.qlDescription.patchValue('');
            this.disableQlUnitOfMeasure[currentQLUmProgram.id]  = false;
            this.enableQlUnitOfMeasure[currentQLUmProgram.id]  = true;
            this.enableReadOnlys[currentQLUmProgram.id] = 'pds-read-only-field';
        }
    }
    handleDaySupplyMaxAndMaxFill(controls, currentQLUmProgram: ClientUmProgram){
        if(controls.qlType.value === this.qlTypeSelection['3'].code || controls.qlType.value === this.qlTypeSelection['6'].code) {
            controls.qlNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.customClaimRejectMessage.patchValue((currentQLUmProgram?.rejectionMessage));
            controls.customClaimRejectMessage.enable();
            controls.qlAmount.enable();
            controls.qlAmount.patchValue('');
            controls.qlUnitOfMeasure.disable();
            controls.qlUnitOfMeasure.patchValue('');
            controls.disabledQlUnitOfMeasure.patchValue('N/A');
            controls.qlPeriod.disable();
            controls.qlPeriod.patchValue('N/A');
            controls.qlDescription.patchValue('');
            this.disableQlUnitOfMeasure[currentQLUmProgram.id] = true;
            this.enableQlUnitOfMeasure[currentQLUmProgram.id] = false;
            this.enableReadOnlys[currentQLUmProgram.id] = 'pds-read-only-field';
        }
    }
    handleDaySupplyOverTime(controls, currentQLUmProgram: ClientUmProgram){
        if(controls.qlType.value === this.qlTypeSelection['5'].code) {
            controls.qlNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.customClaimRejectMessage.patchValue((currentQLUmProgram?.rejectionMessage));
            controls.customClaimRejectMessage.enable();
            controls.qlAmount.enable();
            controls.qlAmount.patchValue('');
            controls.qlUnitOfMeasure.disable();
            controls.qlUnitOfMeasure.patchValue('');
            controls.disabledQlUnitOfMeasure.patchValue('N/A');
            controls.qlPeriod.enable();
            controls.qlPeriod.patchValue('');
            controls.qlDescription.patchValue('');
            this.disableQlUnitOfMeasure[currentQLUmProgram.id] = true;
            this.enableQlUnitOfMeasure[currentQLUmProgram.id] = false;
            this.enableReadOnlys[currentQLUmProgram.id] = 'pds-read-only-field';
        }
    }
    listenToInputChanges(qlProgramId){
        const controls = this.quantityLimitFormGroups[qlProgramId].controls;
        this.subscriptions.push(controls.qlAmount?.valueChanges.subscribe(() => this.handleQlDescriptions(qlProgramId)),
            controls.qlUnitOfMeasure?.valueChanges.subscribe(()=> this.handleQlDescriptions(qlProgramId)),
            controls.qlPeriod?.valueChanges.subscribe(()=> this.handleQlDescriptions(qlProgramId)));
    }
    handleQlDescriptions(qlProgramId){
        const controls = this.quantityLimitFormGroups[qlProgramId].controls;
        const qlAmount = controls.qlAmount?.value ? Number(controls.qlAmount?.value) : '';
        // eslint-disable-next-line max-len
        const qlUnitOfMeasure = controls?.qlUnitOfMeasure?.value?.formattedSearchResult === undefined ? '' : controls?.qlUnitOfMeasure?.value?.formattedSearchResult;
        const qlPeriod = controls.qlPeriod?.value ? Number(controls.qlPeriod?.value) : '';
        if(controls.qlType.value === this.qlTypeSelection['1'].code || controls.qlType.value === this.qlTypeSelection['4'].code) {
            this.qlDescriptions[qlProgramId] =`${qlAmount} ${qlUnitOfMeasure} per ${qlPeriod} day(s)`;
            controls.qlDescription.patchValue(`${this.qlDescriptions[qlProgramId]}`);
            controls.qlDescription.updateValueAndValidity();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
        }
        if(controls.qlType.value === this.qlTypeSelection['2'].code) {
            this.qlDescriptions[qlProgramId] =`${qlAmount} ${qlUnitOfMeasure} per fill`;
            controls.qlDescription.patchValue(`${this.qlDescriptions[qlProgramId]}`);
            controls.qlDescription.updateValueAndValidity();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
        }
        if(controls.qlType.value === this.qlTypeSelection['3'].code) {
            // eslint-disable-next-line max-len
            this.qlDescriptions[qlProgramId] =`Max ${qlAmount} day(s)`;
            controls.qlDescription.patchValue(`${this.qlDescriptions[qlProgramId]}`);
            controls.qlDescription.updateValueAndValidity();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
        }
        if(controls.qlType.value === this.qlTypeSelection['5'].code) {
            this.qlDescriptions[qlProgramId] =`Max ${qlAmount} day(s) per ${qlPeriod} day(s)`;
            controls.qlDescription.patchValue(`${this.qlDescriptions[qlProgramId]}`);
            controls.qlDescription.updateValueAndValidity();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
        }
        if(controls.qlType.value === this.qlTypeSelection['6'].code) {
            this.qlDescriptions[qlProgramId] =`Max ${qlAmount} fill(s)`;
            controls.qlDescription.patchValue(`${this.qlDescriptions[qlProgramId]}`);
            controls.qlDescription.updateValueAndValidity();
            this.enableReadOnlys[qlProgramId] = 'pds-read-only-field';
        }
    }

    saveQuantityLimits() {
        const quantityLimitsForSave = this.buildQuantityLimits();
        if (quantityLimitsForSave.length > 0) {
            this.subscriptions.push(this._recommendationService.saveQuantityLimitRequirements(quantityLimitsForSave)
                .pipe(take(1))
                .subscribe({
                    complete: () => {
                        const isIncomplete = !quantityLimitsForSave.every(data => data.statusCd !== '1');
                        this.isQuantityLimitIncompleteEmitter.emit(isIncomplete);
                        this.isQuantityLimitSavedEmitter.emit(true);
                        quantityLimitsForSave.forEach(ql =>{
                            this.mappedStatus[ql.clientUmProgramId] = ql.statusCd;
                        });
                    },
                    error: () => {
                        this.isQuantityLimitSavedEmitter.emit(false);
                    },
                }));
        }
    }

    buildQuantityLimits(): QuantityLimitRequirements[]{
        const builtQls: QuantityLimitRequirements[] = [];
        Object.keys(this.quantityLimitFormGroups).forEach((key) => {
            const qlGroup = this.quantityLimitFormGroups[key];
            const ql: QuantityLimitRequirements = {
                encryptedRecordId: null,
                umProgramName: null,
                businessLobCd: null,
                statusCd: '0',
                qlType: null,
                qlAmount: null,
                qlUnitOfMeasure: null,
                qlPeriod: null,
                qlDescription: null,
                attachmentType: null,
                attachmentLevel: null,
                attachmentMony: null,
                attachmentRxOtcCd: null,
                attachmentMedDCd: null,
                clientUmProgramId: null,
                applyDefaultAttachment: null,
                notes: null,
                rejectionMessage: null
            };
            ql.encryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
            ql.businessLobCd = this.medispanTrackerResponse.businessLobCd;
            ql.clientUmProgramId = key;
            ql.qlType = qlGroup.controls.qlType.value === 'N/A' ? null : qlGroup.controls.qlType.value;
            ql.qlAmount = qlGroup.controls.qlAmount.value === 'N/A' ? null :
                Number(qlGroup.controls.qlAmount.value) <= 0 ? null : qlGroup.controls.qlAmount.value;
            ql.qlUnitOfMeasure = qlGroup.controls.qlUnitOfMeasure.value === 'N/A' ? null :
                !qlGroup.controls.qlUnitOfMeasure.value ? null : qlGroup.controls.qlUnitOfMeasure.value?.code;
            ql.qlPeriod = qlGroup.controls.qlPeriod.value === 'N/A' ? null :
                Number(qlGroup.controls.qlPeriod.value) <= 0 ? null : qlGroup.controls.qlPeriod.value;
            ql.qlDescription = qlGroup.controls.qlDescription.value === 'N/A' ? null : qlGroup.controls.qlDescription.value;
            ql.notes = qlGroup.controls.qlNotes.value;
            ql.rejectionMessage = qlGroup.controls.customClaimRejectMessage.value;
            if (qlGroup.controls.qlType.value !== this.qlTypeSelection[0]?.code) {
                ql.attachmentType = qlGroup.controls.attachmentType.value;
                ql.attachmentLevel = qlGroup.controls.attachmentLevel.value;
                ql.attachmentMony = qlGroup.controls.attachmentMonyDD.value === 'N/A' ? null
                    : qlGroup.controls.attachmentType.value === ATTACHMENT_LEVEL.GPI ?
                        // eslint-disable-next-line max-len
                        Array.isArray(qlGroup.controls.attachmentMonyDD.value) ? qlGroup.controls.attachmentMonyDD.value?.join('') : qlGroup.controls.attachmentMonyDD.value : null;
                ql.attachmentRxOtcCd = qlGroup.controls.coverageMiscRxOtc.value;
                ql.attachmentMedDCd = this.medispanTrackerResponse.businessLobCd === BUSINESS_LOB_CD.MEDICARE ?
                    qlGroup.controls.coverageMiscMedD.value: null;
                ql.applyDefaultAttachment = qlGroup.controls.applyDefaultCheckBox.value ? 'Yes' : 'N/A';
            }
            let hasErrors = false;
            if ((qlGroup.controls.attachmentType.value === this.attachmentLevels.GPI &&
                    qlGroup.controls['attachmentMonyDD'].errors?.required) || qlGroup.controls['attachmentType'].errors?.required
                || qlGroup.controls['attachmentLevel'].errors?.required || qlGroup.controls['qlUnitOfMeasure'].errors?.required
                || qlGroup.controls['qlAmount'].errors?.required || qlGroup.controls['qlPeriod'].errors?.required
                || qlGroup.controls['qlAmount'].errors?.min || qlGroup.controls['qlPeriod'].errors?.min) {
                qlGroup.controls['attachmentMonyDD'].markAsTouched();
                qlGroup.controls['attachmentType'].markAsTouched();
                qlGroup.controls['attachmentLevel'].markAsTouched();
                qlGroup.controls['qlUnitOfMeasure'].markAsTouched();
                hasErrors = true;
            }
            if (!ql.qlType) {
                ql.statusCd = '0';
            } else if (hasErrors) {
                ql.statusCd = '1';
            } else {
                ql.statusCd = '2';
            }
            builtQls.push(ql);
        });
        return builtQls;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub=> sub.unsubscribe());
    }
}
