import {ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {ICLRRouteReuseStrategy} from './ICLRRouteReuseStrategy';

export class RouteStrategy implements ICLRRouteReuseStrategy {
    prevUrl = 'workflow';
    routePathCache = new Map<string, DetachedRouteHandle>();
    routePathIgnore = new Set<string>();
    routePathsToEnableCache = {
        'formularyformularySearch': true,
        'formularydrugSearch': true,
        'workflowmedispancurrentReport': true,
        'workflowmedispanpreviousReport': true,
        'workflowresearchinResearch': true,
        'workflowresearchreadyForClinicalR': true,
        'workflowresearchreadyForDiscussion': true,
        'workflowapprovalTracking': true,
        'workflowapprovalTrackingpendingUM': true,
        'workflowapprovalTrackingpendingFormulary': true,
        'workflowapprovalTrackingpendingPT': true,
        'workflowapprovalTrackingpendingSubmission': true,
        'workflowoperationsoperationsCheckpoint': true,
        'workflowoperationspendingCoding': true,
        'workflowoperationspendingTesting': true,
        'workflowoperationspendingApproval': true,
        'workflownoAction': true,
        'workflowarchive': true,
        'productOverview': true,
    };

    shouldReuseRoute(before: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        const beforePath = this.getPath(before);
        if (before.routeConfig && this.routePathsToEnableCache.hasOwnProperty(beforePath)) {
            if (before.queryParamMap.has('reset') || (beforePath==='productOverview' && !before.queryParamMap.has('back'))) {
                this.routePathsToEnableCache[beforePath] = false;
                this.routePathCache.delete(beforePath);
                return false;
            } else {
                this.routePathsToEnableCache[beforePath] = true;
            }
        }
        return before.routeConfig === curr.routeConfig;
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        return this.routePathCache.get(this.getPath(route)) as DetachedRouteHandle;
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.routePathsToEnableCache[path]) {
            return this.routePathCache.has(this.getPath(route));
        }
        return false;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        const path = this.getPath(route);
        if (this.routePathsToEnableCache.hasOwnProperty(path)) {
            return true;
        }
        return false;
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        const path = this.getPath(route);
        if (this.routePathIgnore.has(path)) {
            this.routePathIgnore.delete(path);
            this.routePathCache.delete(path);
        } else {
            this.routePathCache.set(path, detachedTree);
        }
    }

    getPath(route: ActivatedRouteSnapshot): string {
        if (route.routeConfig !== null && route.routeConfig.path !== null) {
            return route.pathFromRoot.filter( i => i.routeConfig).map(i => i.routeConfig?.path).join('');
        }
        return '';
    }

    flush() {
        this.routePathCache.clear();
    }

    hasErrorDontReuseRoute(path: string) {
        // @ts-ignore
        this.routePathIgnore.add(path.replaceAll('/',''));
    }

    delete(path: string) {
        // @ts-ignore
        this.routePathCache.delete(path.replaceAll('/',''));
    }

}
