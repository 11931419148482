import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {LinesOfBusiness} from '../../model/LinesOfBusiness';
import {Formulary} from '../../model/Formulary';
import {HttpDataCache} from '../HttpDataCache';
import {ENVIRONMENT} from '../../environments/environment';
import {FormularyDrugChildResponse} from '../../model/FormularyDrugChildResponse';
import {MergedFormularyDrugAttachmentResponse} from '../../model/MergedFormularyDrugAttachmentResponse';
import {AttachmentData} from 'src/app/model/AttachmentData';
import {BusinessLob} from '../../model/BusinessLob';
import {CurrentDrugPosition} from '../../model/CurrentDrugPosition';
import { FormularyDrugSearch } from '../../model/FormularyDrugSearch';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';

export interface SearchDate {
    startDate: string;
    endDate: string;
    isSingleDateSelected: boolean;
}
export interface DrugPositionRequestBody {
    searchDate: SearchDate;
    attachmentData: AttachmentData[];
}

@Injectable({
    providedIn: 'root'
})
export class FormularyService extends HttpDataCache {
    businessLobToFormularyLobMap = {
        BM: ['E', 'B', 'D', 'Y'],
        BC: ['C'],
        BX: ['X'],
        BE: ['M']
    };

    selectedDateRange = new BehaviorSubject<[Date, Date, boolean]>([new Date(), null, true]);

    private URL_PATH = {
        ALL_FORMULARIES: 'formulary/all',
        ALL_LINES_OF_BUSINESS: 'formulary/allLinesOfBusiness',
        ALL_BUSINESS_LOB_BY_SUPER_CLIENT: 'businessLob'
    };

    constructor(private cvsHttpClient: CVSHttpClient) {
        super(cvsHttpClient);
    }

    /* istanbul ignore next */
    getFormularyData(index: number, fetchAllFormularies?: boolean): Observable<Formulary[]> {
        const flag = fetchAllFormularies || false;
        return this.getCachedHttpData(`${this.URL_PATH.ALL_FORMULARIES}/${index}?allFormularies=${flag}`);
    }

    getClientFormularies(superClientIndex: number): Observable<ClientFormularyMapping[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}clientConfiguration/getClientFormularyMappings/${superClientIndex}`);
    }

    /* istanbul ignore next */
    getAllLinesOfBusiness(): Observable<LinesOfBusiness[]> {
        return this.getCachedHttpData(this.URL_PATH.ALL_LINES_OF_BUSINESS);
    }

    getSuperClientBusinessLobs(index: number): Observable<BusinessLob[]> {
        return this.getCachedHttpData(`${this.URL_PATH.ALL_BUSINESS_LOB_BY_SUPER_CLIENT}/${index}`);
    }

    /* istanbul ignore next */
    getFormularyWithDrugAttachment(formularyDrugSearch: FormularyDrugSearch, superClientIndex: number):
    Observable<MergedFormularyDrugAttachmentResponse[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${this.getEnvironmentBackendBaseUrl()}drug/formularyWithDrugAttachment/search/${superClientIndex}`, formularyDrugSearch);
    }

    getCurrentDrugPositionWithAttachment(
        superClientIndex: number,
        formularyNumbers: string,
        searchBy: string,
        searchByValue: string,
        attachmentData: DrugPositionRequestBody,
    ): Observable<FormularyDrugChildResponse[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}drug/currentDrugPosition/${superClientIndex}/${formularyNumbers}/${searchBy}/${searchByValue}`, attachmentData);
    }

    getCurrentDrugPositionWithAttachmentForGPI(
        superClientIndex: number,
        formularyNumbers: string,
        searchBy: string,
        searchByValue: string,
        attachmentData: DrugPositionRequestBody,
    ): Observable<MergedFormularyDrugAttachmentResponse[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}drug/currentDrugPositionForGPI/${superClientIndex}/${formularyNumbers}/${searchBy}/${searchByValue}`, attachmentData);
    }

    getCurrentDrugPosition(superClientIndex: number, formularyNumbers: string[],
        searchBy: string, searchByValue: string): Observable<CurrentDrugPosition[]> {
        return formularyNumbers.length > 0 ?
            this.getCachedHttpData(`drug/currentDrugPosition/${superClientIndex}/${formularyNumbers}/${searchBy}/${searchByValue}`)
            : throwError(() => new Error('No formularies provided'));
    }

    getDistinctFormulariesByBusinessLob(businessLobCode: string, allFormularyList: Formulary[]){
        return this.getDistinctFormulariesByFormularyLobs(this.businessLobToFormularyLobMap[businessLobCode], allFormularyList);
    }

    getDistinctFormulariesByFormularyLobs(lobList: string[], allFormularyList: Formulary[]) {
        const formularyList = [];
        allFormularyList?.forEach(formulary => {
            formulary.linesOfBusiness?.forEach(formularyLob => {
                if(lobList.includes(formularyLob.code) && formularyList.indexOf(formulary) < 0){
                    formularyList.push(formulary);
                }
            });
        });

        return formularyList;
    }

    getActiveFutureDrugListInfoForClientFormulary(index: number): Observable<Formulary[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${this.getEnvironmentBackendBaseUrl()}formulary/activeFutureDrugListInfoForClientFormulary/${index}`);
    }
}
