import {Observable, ReplaySubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {CVSHttpClient} from 'angular-component-library';
import {ENVIRONMENT} from '../environments/environment';

export class HttpDataCache {
    private cache = new Map<string, ReplaySubject<any>>();

    constructor(private _cvsHttpClient: CVSHttpClient) {
        CacheService.instance().getCacheSubject().subscribe( () => {
            this.clear();
        });
    }

    getCachedHttpData(urlPath: string): Observable<any> {
        if (!this.cache.has(urlPath)) {
            this.cache.set(urlPath, this.createResponseObservable(urlPath));
        }
        return this.cache.get(urlPath);
    }

    createResponseObservable(urlPath: string): ReplaySubject<any> {
        const subject = new ReplaySubject<any>();
        this.getCVSHttp(urlPath, subject);
        return subject;
    }

    getCVSHttp(urlPath: string, subject: ReplaySubject<any>) {
        this._cvsHttpClient.get(`${this.getEnvironmentBackendBaseUrl()}${urlPath}`).pipe(take(1)).subscribe(data => {
            subject.next(data);
        },error => {
            subject.error(error);
            this.cache.delete(urlPath);
        });
    }


    /* istanbul ignore next */
    getEnvironmentBackendBaseUrl(): string {
        return ENVIRONMENT.backendBaseUrl;
    }

    clear(){
        this.cache.clear();
    }
}

export class CacheService {
    private static cacheService;
    private cacheSubject = new Subject<void>();
    public static instance(): CacheService{
        if(!this.cacheService){
            this.cacheService = new CacheService();
        }
        return this.cacheService;
    }

    clearCache(){
        this.cacheSubject.next();
    }

    getCacheSubject(): Subject<any>{
        return this.cacheSubject;
    }
}
