<h2 class="formularyHeading">
    Formulary Name Setup
</h2>
<div class="formulary-container">
    <div>
        <p class="subHeader">Edit and save the formulary information to view and edit in Clinical Requirements (CLR).
            All fields marked with an asterisk (*) are required.</p>
    </div>
    <div class="collapse-buttons">
        <button class="pds-btn-white" (click)="accordion.closeAll()">
            <mat-icon svgIcon="collapse--xs"></mat-icon>
            Collapse All
        </button>
        <button class="pds-btn-white" (click)="accordion.openAll()">
            <mat-icon svgIcon="expand--xs"></mat-icon>
            Expand All
        </button>
    </div>
</div>
<div *ngIf="loadingPanels" class="loadingSpinner">
    <cvs-loader-overlay-container [isLoading]="loadingPanels" [loadingMessage]="'Loading Content...'"
                                  [opacity]="0"></cvs-loader-overlay-container>
</div>
<ng-container *ngIf="!loadingPanels">
    <mat-accordion multi>
        <mat-expansion-panel [@.disabled]="true" #panel *ngFor="let formulary of formularies"
                             [expanded]="panelOpenState"
                             [formGroup]="formGroups[formulary.formularyNumber]">
            <mat-expansion-panel-header class="formularyHeader">
                <mat-panel-title id="formularyTitle">
                    {{ formulary.name }}
                </mat-panel-title>
                <pds-toggle-switch #toggleButton smallToggleSwitch
                                   [toggleValue]="formGroups[formulary.formularyNumber].controls.showFormulary.value ? viewFormularyToggleText : hideFormularyToggleText"
                                   id="toogle-{{formulary.formularyNumber}}"
                                   (click)="$event.stopPropagation();"
                                   formControlName="showFormulary"
                                   aria-label="viewHideFormularyToggle"
                                   [leftLabelPlacement]="true"
                                   (onToggle)="updateForm(formulary)">
                </pds-toggle-switch>
            </mat-expansion-panel-header>
            <ng-container *ngIf="panel.expanded">
                <div class="containerGrid">
                    <div class="formFieldsGrid">
                        <mat-form-field>
                            <app-smart-input
                                    (input)="updateForm(formulary)"
                                    placeholder="Client Formulary Name*"
                                    formControlName="formularyName"
                                    maxlength="50"></app-smart-input>
                            <mat-error aria-label="error"
                                       *ngIf="!formGroups[formulary.formularyNumber].controls.formularyName.errors?.pastedValueTruncated">
                                <mat-icon svgIcon="error-f--s" matPrefix>
                                </mat-icon>
                                Provide Client Formulary Name
                            </mat-error>
                            <mat-error
                                    *ngIf="formGroups[formulary.formularyNumber].controls.formularyName.errors?.pastedValueTruncated">
                                <mat-icon svgIcon="warning-circle--xs" matPrefix></mat-icon>
                                Pasted data truncated to 50 characters
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                        <mat-label>Formulary Year Type</mat-label>
                        <mat-select pdsMatOptionKeyboardFocus formControlName="formularyYearType" (selectionChange)="onFormularyYearTypeChange($event, formGroups[formulary.formularyNumber])">
                            <ng-container>
                                <mat-option *ngFor="let yearType of formularyYearTypeArr"
                                            [value]="yearType.code">{{yearType.description}}</mat-option>
                            </ng-container>
                        </mat-select>

                        <mat-error aria-label="error">
                            <mat-icon svgIcon="error-f--s" matPrefix>
                            </mat-icon>
                            Provide a year between 1995 - 2039
                        </mat-error>
                        </mat-form-field>
                                                        <mat-form-field>
                                                            <app-smart-input

                                                                    placeholder="Formulary Year *"
                                                                    formControlName="formularyYear"
                                                                    minlength="4"
                                                                    maxlength="4"
                                                                    allowDigits="true"
                                                            ></app-smart-input>
                                                            <mat-error aria-label="error">
                                                                <mat-icon svgIcon="error-f--s" matPrefix>
                                                                </mat-icon>
                                                                Provide a year between 1995 - 2039
                                                            </mat-error>
                                                        </mat-form-field>
                                                        <mat-form-field>
                                                            <app-smart-input

                                                                    placeholder="Formulary Group"
                                                                    formControlName="formularyGroup"
                                                                    maxlength="50"></app-smart-input>
                                                            <mat-error>
                                                                <mat-icon svgIcon="warning-circle--xs" matPrefix></mat-icon>
                                                                Pasted data truncated to 50 characters
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="divBlocksGrid">
                                                        <div>
                                                            <label>Formulary Name</label>
                                                            <p>{{ formulary.name }}</p>
                                                        </div>
                                                        <div>
                                                            <label>Formulary From Date</label>
                                                            <p>{{ dateFormatter(formulary.fromDate.toString()) }}</p>
                                                        </div>
                                                        <div>
                                                            <label>Formulary Thru Date</label>
                                                            <p>{{ dateFormatter(formulary.thruDate.toString()) }}</p>
                                                        </div>
                                                        <div>
                                                            <label>Formulary Number</label>
                                                            <p>{{ formulary.formularyNumber }}</p>
                                                        </div>
                                                        <div>
                                                            <label>Line of Business</label>
                                                            <p>{{ concatLobDescriptions(formulary) }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>