import {
    ChangeDetectorRef,
    Component, EventEmitter, Input,
    OnInit, Output,
} from '@angular/core';
import {WorkflowService} from '../../service/workflow/workflow.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FORMULARY_DATE_BOUNDARY} from '../../enum/FormularyDateBoundary';
import {RecordService} from '../../service/record/record.service';
import {CVSAlertType, CVSBannerService, CVSBannerType} from 'angular-component-library';
import {ApprovalTrackingCase} from '../../model/ApprovalTrackingCase';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {take} from 'rxjs';
import {AppService} from '../../service/app/app.service';
import {TrackerDataService} from '../../service/tracker-data/tracker-data.service';
import {RECORD_STATUS} from '../../enum/RecordStatus';

@Component({
    selector: 'app-approve-and-submit-modal',
    templateUrl: './approve-and-submit-modal.component.html',
    styleUrls: ['./approve-and-submit-modal.component.scss']
})
export class ApproveAndSubmitModalComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() currentStatus;
    submissionForm: FormGroup;
    hasTruncNotesError = false;
    startDate = new Date(new Date().setDate(new Date().getDate() + 14));
    maxDate = new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE);
    minDate= new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE);
    notesValue='';

    constructor(public workflowService: WorkflowService, private fb: FormBuilder,
        private recordService: RecordService,
        private trackerDataService: TrackerDataService,
        private bannerService: CVSBannerService,
        private appService: AppService) {
    }

    ngOnInit() {
        this.createSubmissionForm();
    }

    public createSubmissionForm() {
        this.submissionForm = this.fb.group({
            name: [''],
            effectiveDate: [this.startDate],
            notes: [''],
        });
        this.resetModalFields();
    }

    saveSubmissionForm() {
        this.addSubmissionModalValidators();
        this.submissionForm.markAllAsTouched();
        if (!this.submissionForm.controls['name'].errors?.required && !this.submissionForm.controls['effectiveDate'].errors?.required) {
            this.onModalApproveAndSubmit();
        }
    }

    resetModalFields() {
        this.submissionForm.reset({effectiveDate: this.startDate});
        this.submissionForm.controls['name'].removeValidators([Validators.required]);
        this.submissionForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.submissionForm.controls['effectiveDate'].removeValidators([Validators.required]);
        this.submissionForm.controls['effectiveDate'].updateValueAndValidity({onlySelf: true});
    }

    onModalCancel() {
        this.closeEvent.emit(false);
    }

    onModalApproveAndSubmit() {
        const approvalTrackingCase: ApprovalTrackingCase = {
            encryptedRecordIds: null,
            caseName: null,
            effectiveDate: null,
            caseNotes: null,
            currentStatus: null,
            nextStatus: null,
        };

        const encryptedRecordIds= this.workflowService.dialogDataSubmission.selectedRows.map(i => i.data.encryptedRecordId);
        approvalTrackingCase.encryptedRecordIds = encryptedRecordIds;
        approvalTrackingCase.caseName = this.submissionForm.controls['name'].value;
        approvalTrackingCase.effectiveDate = this.submissionForm.controls['effectiveDate'].value;
        approvalTrackingCase.caseNotes = this.submissionForm.controls['notes'].value;
        approvalTrackingCase.currentStatus = this.currentStatus;
        approvalTrackingCase.nextStatus = RECORD_STATUS.OPERATIONS_CHECKPOINT;

        this.trackerDataService.createCaseAndUpdateStatus(approvalTrackingCase,this.appService.getSuperClientContext().id).pipe(take(1))
            .subscribe({
                next: () => {
                    this.onSaveCaseSuccess();
                    this.closeEvent.emit(true);
                }
            });
    }

    onNotesChanges() {
        if (this.submissionForm.controls['notes'].value?.length > 250) {
            if (this.notesValue.length === 0) {
                this.hasTruncNotesError = true;
            }
            this.submissionForm.controls['notes'].setValue(this.submissionForm.controls['notes'].value?.substring(0, 250));
        } else {
            this.hasTruncNotesError = false;
        }
        this.notesValue = this.submissionForm.controls['notes'].value;
    }

    stopWarning() {
        this.hasTruncNotesError = false;
    }

    onSaveCaseSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: 'Case submitted for approval to the Operations & Implementation queue.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this.bannerService.sendAlert(successBannerData);
    }

    private addSubmissionModalValidators() {
        this.submissionForm.controls['name'].addValidators([Validators.required]);
        this.submissionForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.submissionForm.controls['effectiveDate'].addValidators([Validators.required]);
        this.submissionForm.controls['effectiveDate'].updateValueAndValidity({onlySelf: true});
    }
}
