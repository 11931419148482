import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavigationBehaviorOptions, Router} from '@angular/router';
import {
    BannerLink,
    CVSAlertType,
    CVSBannerComponentData,
    CVSBannerService,
    CVSBannerType
} from 'angular-component-library';
import {saveAs} from 'file-saver';
import {formatDate} from '@angular/common';
import {RowNode} from '@ag-grid-community/core';
import {finalize, take} from 'rxjs/operators';
import {IRecordStatusWithDesc, RECORD_STATUS, RECORD_STATUSES} from 'src/app/enum/RecordStatus';
import {RequirementReportRequest} from 'src/app/model/RequirementReportRequest';
import {AppService} from 'src/app/service/app/app.service';
import {ReportService} from 'src/app/service/report/report.service';
import {UserService} from 'src/app/service/user/user.service';
import {WorkflowService} from 'src/app/service/workflow/workflow.service';
import {IWorkflowTab, WORKFLOW_TABS} from './WorkflowTab';

@Component({
    selector: 'app-workflow-component',
    templateUrl: './workflow.component.html',
    styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit, OnDestroy {
    @ViewChild('customDialogFormRef') public customDialogFormRef: TemplateRef<any>;
    @ViewChild('approveAndSubmitFormRef') public approveAndSubmitFormRef: TemplateRef<any>;
    @ViewChild('statusOption') public statusOption;
    showMoveInWorkflow = true;
    showApproveSubmit = true;
    disableMoveInWorkflow = true;
    triggerSave = undefined;
    mainTabSelection = 'Medispan';
    subTabIndex = 0;
    selectedRowCount = 0;
    selectedRows: RowNode[] = [];
    RECORD_STATUS = RECORD_STATUS;
    subscriptions = [];
    statusCount = {};
    mainContentElement: any;
    moveInWorkflowTooltip = 'Select one or more rows to move';
    disableExportButton: Boolean = true;
    moveInWorkflowList: IRecordStatusWithDesc[] = [];
    moveInWorkflowMedispanMode = true;
    currentTab: IWorkflowTab;
    downloadSpinner = false;
    subTabs: IWorkflowTab[] = [];
    hasEditPermission: boolean;
    workFlowTabs = WORKFLOW_TABS;
    navigatedFromLink: string;
    showSpinner = true;
    hasRowsSelect: boolean;
    isSelectable: boolean;

    constructor(private elementRef: ElementRef,
        private userService: UserService,
        private appService: AppService,
        private router: Router,
        private bannerService: CVSBannerService,
        public workflowService: WorkflowService,
        private reportService: ReportService) {
        this.applyComponentNavigationState(this.router.getCurrentNavigation().extras.state);
        this.router.events.subscribe((res: any) => {
            if (res && res.url && res.constructor.name === 'NavigationStart') {
                this.bannerService.close();
            }
        });
        this.navigatedFromLink = this.router.url;
        Object.keys(WORKFLOW_TABS).forEach(val => {
            if (this.router.url.includes(WORKFLOW_TABS[val].link)) {
                if(val === 'TAB3OPERATIONS_AND_IMPLEMENTATION') {
                    const subTab = [];
                    subTab.push(WORKFLOW_TABS[val]?.subTabs[0]);
                    this.subTabs = subTab;
                } else {
                    this.subTabs = WORKFLOW_TABS[val]?.subTabs;
                }
                this.mainTabSelection = WORKFLOW_TABS[val].name;
                this.subTabIndex = WORKFLOW_TABS[val]?.subTabs?.find(subTab => subTab.link === this.router.url)?.subTabIndex;
            }
        });
    }

    ngAfterViewInit() {
        this.mainContentElement = this.elementRef.nativeElement?.parentElement?.parentElement;
        this.mainContentElement?.addEventListener('scroll', this.onMainContentScroll.bind(this));
    }

    ngOnInit(): void {
        this.appService.getInitializationSubject().subscribe(() => {
            if (!this.userService.hasEditPermission()) {
                this.showMoveInWorkflow = false;
                this.showApproveSubmit = false;
            }
            this.hasEditPermission = this.userService.hasEditPermission();
        });
        this.subscriptions.push(this.workflowService.statusCountProvider.subscribe(sc => {
            this.statusCount = sc;
        }));
        this.workflowService.initStatusCount();
        this.moveInWorkflowMedispanMode = this.mainTabSelection === WORKFLOW_TABS.TAB0MEDISPAN.name;
        this.currentTab = this.workflowService.getCurrentTab(this.mainTabSelection, this.subTabIndex);
        this.moveInWorkflowList = this.workflowService.buildModalRecords(this.mainTabSelection, this.subTabIndex)
            .filter(f => f.description !== this.currentTab.name);
        if (!this.userService.getUser()?.internalUser) {
            this.moveInWorkflowList = this.moveInWorkflowList.slice(0,6);
            const statuses = this.moveInWorkflowList.map((item) => item.status);
            if (this.mainTabSelection !=='No Action' && !statuses.includes(RECORD_STATUS.NO_ACTION)) {
                this.moveInWorkflowList.push(RECORD_STATUSES[12]);
            }
            this.moveInWorkflowList = this.moveInWorkflowList.filter(f => f.description !== this.currentTab.name);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
        this.mainContentElement.removeEventListener('scroll', this.onMainContentScroll);
    }

    medispanMoveInWorkflowClick() {
        this.triggerSave = !this.triggerSave;
    }

    isLoadingSpinner(value: boolean) {
        this.showSpinner = value;
    }

    onDisableMoveInWorkflowButton(event: boolean) {
        this.disableMoveInWorkflow = event;
    }

    isMainTabSelected(tabName: string) {
        return this.mainTabSelection === tabName;
    }

    hasRowsSelected() {
        if (this.selectedRows.length > 0) {
            this.hasRowsSelect = true;
        } else {
            this.hasRowsSelect = false;
        }
    }

    applyComponentNavigationState(state: any) {
        if (state) {
            Object.keys(state).forEach(k => {
                this[k] = state[k];
            });
        }
    }

    buildComponentNavigationState(newState?: {}) {
        return {
            mainTabSelection: this.mainTabSelection,
            ...newState
        };
    }

    showSuccessBannerForActionNoAction(event: any) {
        const bannerLinks: BannerLink[] = [];
        let message = '';
        const actionMessage = `${event.actionCount} item(s) moved to "In Research." `;
        const noActionMessage = `${event.noActionCount} item(s) moved to "No Action."`;

        if (event.actionCount !== 0) {
            message = message + actionMessage;
            bannerLinks.push({
                linkText: 'Go to In Research',
                linkFunc: this.doWorkflowNavigationFunction(
                    WORKFLOW_TABS.TAB1RESEARCH_AND_REQUIREMENTS.name,
                    WORKFLOW_TABS.TAB1RESEARCH_AND_REQUIREMENTS.subTabs[0].link
                )
            });
        }
        if (event.noActionCount !== 0) {
            message = message + noActionMessage;
            bannerLinks.push({
                linkText: 'Go to No Action',
                linkFunc: this.doWorkflowNavigationFunction(
                    WORKFLOW_TABS.TAB4NO_ACTION.name,
                    WORKFLOW_TABS.TAB4NO_ACTION.link
                )
            });
        }
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            hideX: false,
            outletId: '#successBanner',
            headline: 'Success',
            body: message,
            alertType: CVSAlertType.Success,
            bannerLinks: bannerLinks
        } as CVSBannerComponentData;
        this.bannerService.sendAlert(successBannerData);
    }

    buildId(name: string) {
        return name.trim().toLocaleLowerCase().replace(/ |&/g, '_');
    }

    doWorkflowNavigationFunction(mainTabSelection: string, url: string) {
        return () => {
            const extras: NavigationBehaviorOptions = {state: this.buildComponentNavigationState({mainTabSelection})};
            this.router.navigateByUrl(url, extras);
        };
    }

    setSelectedRows(rowNodes: RowNode[]) {
        this.selectedRows = rowNodes;
        this.selectedRowCount = rowNodes.length;
        this.disableMoveInWorkflow = rowNodes.length === 0;
        this.disableExportButton = rowNodes.length === 0;
        this.hasRowsSelected();
    }

    onMainContentScroll(e) {
        this.elementRef.nativeElement.style.setProperty('--mainScrollTop', `${this.mainContentElement.scrollTop}px`);
    }

    downloadRequirementReport() {
        const requirementReportRequest: RequirementReportRequest[] = [];
        this.selectedRows.forEach(row => {
            const reportRequest: RequirementReportRequest = {
                combinedId: row.data.combinedId,
                encryptedRecordId: row.data.encryptedRecordId
            };
            requirementReportRequest.push(reportRequest);
        });
        const filename = `Preliminary Requirements Report CY${formatDate(new Date(), 'YYYY_MM_dd', 'en-US')}.xlsx`;
        this.downloadSpinner = !this.downloadSpinner;
        this.reportService.getRequirementReport(this.appService.getSuperClientContext().id, requirementReportRequest)
            .pipe(take(1), finalize(() => {
                this.downloadSpinner = !this.downloadSpinner;
            }))
            .subscribe(
                {
                    next: (file) => {
                        this.saveAs(file, filename);
                    },
                    error: (error) => {
                        const errorBanner = {
                            bannerType: CVSBannerType.Error,
                            hideX: false,
                            outletId: '#successBanner',
                            headline: 'Error',
                            body: 'Failed To Generate Report',
                            alertType: CVSAlertType.Error,
                        } as CVSBannerComponentData;
                        this.bannerService.sendAlert(errorBanner);
                    }
                }
            );
    }

    saveAs(file: Blob, fileName: string) {
        return saveAs(new Blob([file], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}), fileName);
    }

    workflowMenuClick(item: IRecordStatusWithDesc) {
        const confirmCallback = (count) => this.disableMoveInWorkflow = true;
        this.workflowService.workflowMenuClick(item,
            this.selectedRows, this.customDialogFormRef, this.mainTabSelection, this.subTabIndex, confirmCallback);
    }

    approveAndRequirementButtonClick() {
        this.workflowService.showApproveAndSubmitDialog(this.selectedRows, this.approveAndSubmitFormRef);
    }

    closeModal(isApproved: boolean) {
        if (isApproved) {
            this.hasRowsSelect = false;
            const confirmCallback = (count) => this.disableMoveInWorkflow = true;
            this.workflowService.doMoveInWorkflowForCase(this.selectedRows,
                RECORD_STATUS.OPERATIONS_CHECKPOINT, WORKFLOW_TABS.TAB2APPROVAL_TRACKING.name,
                WORKFLOW_TABS.TAB2APPROVAL_TRACKING.subTabs[3].subTabIndex,
                confirmCallback, WORKFLOW_TABS.TAB2APPROVAL_TRACKING.subTabs[3].name);
        }
        this.workflowService.closeApproveAndSubmitDialog();
    }
}
