<mat-card class="parent-grid">
    <ng-container *ngFor="let ndcDetail of ndcDetails; let i=index">
        <div id="ndcDetail_{{i}}">
            <mat-card-title>{{ndcDetail.name}}</mat-card-title>
            <div class="child-grid">
                <ng-container *ngFor="let value of ndcDetail.value; let j=index">
                            <span class="ndcChildValue_{{i}}_{{j}}">
                                <mat-card-title style="font-weight: lighter">{{value.name}}</mat-card-title>
                                <mat-card-content style="font-weight: bold">{{value.value}}</mat-card-content>
                            </span>
                </ng-container>
            </div>
        </div>
    </ng-container>
</mat-card>
