/* eslint-disable max-len */
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    AuthenticationService,
    AuthCodePKCEService,
    CVSAlertModule,
    CVSBaseModule,
    CVSChipInputModule,
    CVSConfirmationDialogModule,
    CVSDateRangeModule,
    CVSSideNavModule,
    CVSToggleSwitchModule,
    CVSLoaderModule,
    CVSBannerModule,
    CVSAutocompleteSelectModule,
    TooltipModule,
    CVSPopoverButtonModule,
    PdsMatOptionModule, CVSInlineCalloutModule, PDSAutocompleteMultiSelectModule
} from 'angular-component-library';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSortModule} from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {CdkTableModule} from '@angular/cdk/table';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { ActivatedRouteSnapshot, RouterStateSnapshot, RouteReuseStrategy } from '@angular/router';
import {DatePipe} from '@angular/common';
import {CvsGridModule} from './common/cvs-grid/cvs-grid.module';
import {AgGridModule} from '@ag-grid-community/angular';
import {CustomDatePickerComponent} from './common/custom-date-picker/custom-date-picker.component';
import {FormularyListComponent} from './formulary-list/formulary-list.component';
import {FormularyPageComponent} from './formulary-page/formulary-page.component';
import {DrugListComponent} from './drug-list/drug-list.component';
import {FormularyDrugSearchComponent} from './formulary-drug-search/formulary-drug-search.component';
import {RouteStrategy} from './RouteStrategy';
import {MatTabsModule} from '@angular/material/tabs';
import {CustomNavigationComponent} from './common/custom-navigation/custom-navigation.component';
import {ProductOverviewComponent} from './product-overview/product-overview.component';
import { DrugUniverseListComponent } from './drug-universe-list/drug-universe-list.component';
import {ClientSelectionPageComponent} from './client-selection-page/client-selection-page.component';
import { CustomMultiselectDropdownComponent } from './common/custom-multiselect-dropdown/custom-multiselect-dropdown.component';
import { CustomToggleButtonComponent } from './common/custom-toggle-button/custom-toggle-button.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { CustomHeaderCheckboxComponent } from './common/custom-header-checkbox/custom-header-checkbox.component';
import {DeactivateGuard} from './guards/deactivate.guard';
import { ReportsPageComponent } from './reports-page/reports-page.component';
import {MatChipsModule} from '@angular/material/chips';
import { ReportCustomTooltipComponent } from './common/report-custom-tooltip/report-custom-tooltip.component';
import { DatePickerComponent } from './common/date-picker/date-picker.component';
import { ProductInformationComponent } from './product-overview/product-information/product-information.component';
import { FormularyInformationComponent } from './product-overview/formulary-information/formulary-information.component';
import { RecommendationsComponent } from './product-overview/recommendations/recommendations.component';
import { BusinessLobTabsComponent } from './common/business-lob-tabs/business-lob-tabs.component';
import { GridSelectCellRendererComponent } from './common/grid-select-cell-renderer/grid-select-cell-renderer.component';
import { MoveInWorkflowModalComponent } from './common/move-in-workflow-modal/move-in-workflow-modal.component';
import { CreateAdhocUpdateModalComponent } from './common/create-adhoc-update-modal/create-adhoc-update-modal.component';
import { CustomButtonComponent } from './common/custom-button/custom-button.component';
import { CustomWelcomeComponent} from './common/custom-welcome/custom-welcome.component';
import { KabobCellComponent} from './common/kabob-cell/kabob-cell.component';
import { RecommendationsFormComponent } from './product-overview/recommendations/recommendations-form/recommendations-form.component';
import { StatusChipComponent } from './common/status-chip/status-chip.component';
import { RecommendationLeftNavigationComponent } from
    './product-overview/recommendations/recommendation-left-navigation/recommendation-left-navigation.component';
import { MatListModule} from '@angular/material/list';
import {MatFormFieldModule} from '@angular/material/form-field';
import { NdcHeaderComponent } from './product-overview/ndc-header/ndc-header.component';
import { GpiHeaderComponent } from './product-overview/gpi-header/gpi-header.component';
import { TrackingDatesComponent } from './product-overview/tracking-dates/tracking-dates.component';
import { TradeComponent } from './product-overview/tracking-dates/trade/trade.component';
import { RecommendationComponent } from './product-overview/tracking-dates/recommendation/recommendation.component';
import { RegulatoryTrackingComponent } from './product-overview/tracking-dates/regulatory-tracking/regulatory-tracking.component';
import { SupplementalListsComponent } from './product-overview/tracking-dates/supplemental-lists/supplemental-lists.component';
import { CustomerRequirementLifecycleComponent } from './product-overview/tracking-dates/customer-requirement-lifecycle/customer-requirement-lifecycle.component';
import { CmsSubmissionTrackingComponent } from './product-overview/tracking-dates/cms-submission-tracking/cms-submission-tracking.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MultiSelectModalComponent } from './common/multi-select-modal/multi-select-modal.component';
import { SmartInputComponent } from './common/smart-input/smart-input.component';
import { GridDisplayModalComponent } from './common/grid-display-modal/grid-display-modal.component';
import { ConvertGpiToNdcListComponent } from './common/create-adhoc-update-modal/convert-gpi-to-ndc-list/convert-gpi-to-ndc-list.component';
import { CustomStateComponent } from './common/custom-state/custom-state.component';
import { WorkflowArchiveListComponent } from './workflow/workflow-archive-list/workflow-archive-list.component';
import { WorkflowMedispanListComponent } from './workflow/workflow-medispan-list/workflow-medispan-list.component';
import { WorkflowNoActionListComponent } from './workflow/workflow-no-action-list/workflow-no-action-list.component';
import { WorkflowOperationsAndImplementationListComponent } from './workflow/workflow-operations-and-implementation-list/workflow-operations-and-implementation-list.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { WorkflowApprovalTrackingListComponent } from './workflow/workflow-approval-tracking-list/workflow-approval-tracking-list.component';
import { WorkflowResearchAndRecommendationsListComponent } from './workflow/workflow-research-and-recommendations-list/workflow-research-and-recommendations-list.component';
import {ConfigurationComponent} from './configuration/configuration.component';
import { FormularySetupComponent } from './configuration/formulary-setup/formulary-setup.component';
import {MatStepperModule} from '@angular/material/stepper';
import {
    FormularyRequirementsComponent
} from './configuration/formulary-requirements/formulary-requirements.component';
import { RecommendationsNotesComponent } from './product-overview/recommendations/recommendations-notes/recommendations-notes.component';
import {LobWorkflowComponent} from './configuration/lob-workflow/lob-workflow.component';
import { MedispanSettingsComponent } from './workflow/medispan-settings/medispan-settings.component';
import { ReviewPageComponent } from './configuration/review-page/review-page.component';
import {ProductDrugRequirementsComponent} from './configuration/product-drug-requirements/product-drug-requirements.component';
import {ApproveAndSubmitModalComponent} from './common/appove-and-submit-modal/approve-and-submit-modal.component';
import {
    FormularyRequirementsModalComponent
} from './configuration/formulary-requirements/formulary-requirements-modal/formulary-requirements-modal.component';
import {
    ProductDrugCoverageComponent
} from './configuration/product-drug-requirements/product-drug-coverage/product-drug-coverage.component';
import {
    UmDrugProgramComponent
} from './configuration/product-drug-requirements/um-drug-program/um-drug-program.component';
import { RecommendationsFormularyRequirementsComponent } from './product-overview/recommendations/recommendations-formulary-requirements/recommendations-formulary-requirements.component';
import {
    MoveRankModalComponent
} from './common/move-rank-modal/move-rank-modal.component';
import {MatTreeModule} from '@angular/material/tree';
import {
    CodingTypeComponent
} from './configuration/formulary-requirements/formulary-requirements-modal/coding-type/coding-type.component';
import {AppAdministrationComponent} from './app-administration/app-administration.component';
import {SuperClientModalComponent} from './app-administration/super-client-modal/super-client-modal.component';
import {
    RecommendationsUmProgramComponent
} from './product-overview/recommendations/recommendations-um-program/recommendations-um-program.component';
import { RecommendationsProductAndDrugCoverageComponent } from './product-overview/recommendations/recommendations-product-and-drug-coverage/recommendations-product-and-drug-coverage.component';
import {
    FormularyFormEntryComponent
} from './product-overview/recommendations/recommendations-form/formulary-form-entry/formulary-form-entry.component';
import { UmProgramFormEntryComponent } from './product-overview/recommendations/recommendations-form/um-program-form-entry/um-program-form-entry.component';
import {
    ProductDrugCoverageFormEntryComponent
} from './product-overview/recommendations/recommendations-form/product-drug-coverage-form-entry/product-drug-coverage-form-entry.component';
import { StepTherapyFormEntryComponent } from './product-overview/recommendations/recommendations-form/um-program-form-entry/step-therapy-form-entry/step-therapy-form-entry.component';
import { PriorAuthFormEntryComponent } from './product-overview/recommendations/recommendations-form/um-program-form-entry/prior-auth-form-entry/prior-auth-form-entry.component';
import {
    QuantityLimitFormEntryComponent
} from './product-overview/recommendations/recommendations-form/um-program-form-entry/quantity-limit-form-entry/quantity-limit-form-entry.component';

@NgModule({
    declarations: [
        AppComponent,
        CustomDatePickerComponent,
        FormularyListComponent,
        FormularyPageComponent,
        DrugListComponent,
        WorkflowComponent,
        FormularyDrugSearchComponent,
        CustomNavigationComponent,
        ProductOverviewComponent,
        DrugUniverseListComponent,
        ClientSelectionPageComponent,
        WorkflowMedispanListComponent,
        WorkflowResearchAndRecommendationsListComponent,
        CustomMultiselectDropdownComponent,
        CustomToggleButtonComponent,
        CustomHeaderCheckboxComponent,
        ReportsPageComponent,
        ReportCustomTooltipComponent,
        DatePickerComponent,
        WorkflowOperationsAndImplementationListComponent,
        WorkflowNoActionListComponent,
        ProductInformationComponent,
        FormularyInformationComponent,
        RecommendationsComponent,
        BusinessLobTabsComponent,
        GridSelectCellRendererComponent,
        MoveInWorkflowModalComponent,
        CreateAdhocUpdateModalComponent,
        CustomButtonComponent,
        CustomWelcomeComponent,
        KabobCellComponent,
        RecommendationsFormComponent,
        RecommendationLeftNavigationComponent,
        StatusChipComponent,
        WorkflowApprovalTrackingListComponent,
        NdcHeaderComponent,
        GpiHeaderComponent,
        TrackingDatesComponent,
        TradeComponent,
        RecommendationComponent,
        RegulatoryTrackingComponent,
        SupplementalListsComponent,
        CustomerRequirementLifecycleComponent,
        CmsSubmissionTrackingComponent,
        MultiSelectModalComponent,
        CmsSubmissionTrackingComponent,
        WorkflowArchiveListComponent,
        GridDisplayModalComponent,
        SmartInputComponent,
        ConvertGpiToNdcListComponent,
        CustomStateComponent,
        ConfigurationComponent,
        FormularySetupComponent,
        FormularyRequirementsComponent,
        RecommendationsNotesComponent,
        LobWorkflowComponent,
        MedispanSettingsComponent,
        ReviewPageComponent,
        ProductDrugRequirementsComponent,
        ApproveAndSubmitModalComponent,
        FormularyRequirementsModalComponent,
        ProductDrugCoverageComponent,
        UmDrugProgramComponent,
        MoveRankModalComponent,
        CodingTypeComponent,
        AppAdministrationComponent,
        SuperClientModalComponent,
        RecommendationsFormularyRequirementsComponent,
        RecommendationsProductAndDrugCoverageComponent,
        RecommendationsUmProgramComponent,
        FormularyFormEntryComponent,
        UmProgramFormEntryComponent,
        ProductDrugCoverageFormEntryComponent,
        StepTherapyFormEntryComponent,
        PriorAuthFormEntryComponent,
        QuantityLimitFormEntryComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        CVSBaseModule,
        CVSDateRangeModule,
        MatSelectModule,
        MatCardModule,
        MatTableModule,
        MatOptionModule,
        CVSSideNavModule,
        CdkTableModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        FormsModule,
        MatInputModule,
        MatExpansionModule,
        MatRadioModule,
        CVSConfirmationDialogModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatSortModule,
        MatIconModule,
        CVSChipInputModule,
        CVSToggleSwitchModule,
        CVSAlertModule,
        MatMenuModule,
        MatSnackBarModule,
        MatDialogModule,
        MatButtonModule,
        BrowserAnimationsModule,
        CVSSideNavModule,
        CvsGridModule,
        AgGridModule,
        MatTabsModule,
        CVSLoaderModule,
        CVSBannerModule,
        MatButtonToggleModule,
        CVSAutocompleteSelectModule,
        MatChipsModule,
        TooltipModule,
        CVSPopoverButtonModule,
        MatListModule,
        MatFormFieldModule,
        MatGridListModule,
        PdsMatOptionModule,
        CVSInlineCalloutModule,
        MatStepperModule,
        PDSAutocompleteMultiSelectModule,
        MatTreeModule
    ],
    providers: [
        DatePipe,
        {provide: AuthenticationService, useClass: AuthCodePKCEService},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 4000}},
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
                window.location.href = (route.data as any).externalUrl;
            }
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteStrategy
        },
        DeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
