<app-cvs-ag-grid #workflowApprovalTrackingGrid id="workflowApprovalTrackingGrid"
                 style="width: 100%"
                 class="ag-theme-balham"
                 [gridOptions]="workflowApprovalTrackingGridOptions"
                 [modules]="modules"
                 [rowData]="workflowApprovalTrackingData"
                 [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                 (selectionChanged)="onSelectionChanged($event)"
                 [popupParent]="popupParent"
                 [disableCvsExport]="true"
                 (firstDataRendered)="onFirstDataRendered($event)"
                 (rowDataUpdated)="onRowDataUpdated($event)"
                 [style.height]="gridHeight">
</app-cvs-ag-grid>
