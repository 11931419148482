<ng-container>
  <mat-card-header>
    <mat-card-title>Customer Requirement LifeCycle</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Customer Requirement Form sent</mat-label>
      <input matInput [matDatepicker]="sentForm" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="sentForm.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #sentForm></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Coding Completed</mat-label>
      <input matInput [matDatepicker]="codingDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="codingDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #codingDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Testing Completed - Approved</mat-label>
      <input matInput [matDatepicker]="approvedDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="approvedDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #approvedDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
</ng-container>
