import {enableProdMode, Testability} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {ENVIRONMENT} from './app/environments/environment';

if (ENVIRONMENT.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

function ngIsStable() {
    // you need to select an element managed by Angular. Usually any div in your html body
    const test = (window as any).getAngularTestability(window.document.querySelector('body div')) as Testability;
    const stable = test.isStable();
    // if (!stable) {   // this console.log block is just for information, you can remove it
    //     console.log('Angular is ' + (stable ? '' : 'NOT ') + 'stable:', {
    //         pendingRequestCount: test.getPendingRequestCount(),
    //         _pendingCount: (test as any)._pendingCount,
    //         pendingTasks: (test as any).getPendingTasks(),
    //     });
    // }
    return stable;
}

window['ngIsStable'] = ngIsStable;
