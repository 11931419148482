import {NgModule} from '@angular/core';
import {CvsAgGridComponent} from './cvs-ag-grid/cvs-ag-grid.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {AgGridModule} from '@ag-grid-community/angular';


@NgModule({
    declarations: [CvsAgGridComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        AgGridModule
    ],
    exports: [CvsAgGridComponent]
})
export class CvsGridModule { }
