import {Component, ElementRef, HostBinding, Input, Optional, Self} from '@angular/core';
import {MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    FormGroupDirective,
    NgControl,
    NgForm
} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-smart-input',
    template: `
        <div class="inputContainer"
             role="group"
             [formGroup]="inputGroup"
             [attr.aria-describedby]="userAriaDescribedBy"
             [attr.aria-labelledby]="parentFormField?.getLabelId()"
             (focusin)="onFocusIn($event)"
             (focusout)="onFocusOut($event)">
            <input class="mat-input-element"
                   formControlName="textInput"
                   [maxlength]="_maxlength"
                   (paste)="onPaste($event)"
                   (keydown)="onKeydown($event)"
                   (change)="someChange($event)">
        </div>
    `,
    styles: [`
        div {
            display: flex;
        }

        .inputContainer {
        }

    `],
    providers: [{provide: MatFormFieldControl, useExisting: SmartInputComponent}],
})
export class SmartInputComponent implements MatFormFieldControl<string>, ControlValueAccessor {

    static nextId = 0;
    @Input() userAriaDescribedBy: string;
    @HostBinding() id = `app-smart-input-${SmartInputComponent.nextId++}`;
    controlType = 'app-smart-input';
    inputGroup: FormGroup;
    stateChanges = new Subject<void>();
    focused = false;
    _maxlength: string = undefined;
    _disabled = false;
    autofilled?: boolean;
    _errorState = false;
    _disableSpecialCharacters = false;
    _allowDigitAndPeriod = false;
    _allowDigits = false;
    NA = 'N/A';
    _defaultIfDisabled: string;
    parentForm: NgForm;
    touched = false;
    _disablePastingSpecialCharacters = false;

    private PASTE_ERROR_NAME = 'pastedValueTruncated';
    private SCRUB_SPECIAL_CHARACTER_ERROR = 'scrubSpecialCharacter';
    private SCRUB_AND_PASTE_ERROR = 'scrubAndPasteError';
    private _placeholder: string;
    private _required = false;
    private readonly allowedInputDigitRegex = new RegExp('^[0-9]*$');
    private readonly allowedInputDigitAndPeriod = new RegExp('^\\d{0,4}(\\.\\d{0,4})?$');
    private readonly allowedInputCharacterRegex = /^[^<>;]*$/;
    private readonly allowedInputCharacterRegexForReplace = /[<>;]/g;

    constructor(fb: FormBuilder,
        @Optional() @Self() public ngControl: NgControl,
        @Optional() private _parentForm: NgForm,
        @Optional() private _parentFormGroup: FormGroupDirective,
        @Optional() private _elementRef: ElementRef,
        @Optional() public parentFormField: MatFormField) {
        this.parentForm = _parentForm;
        this.inputGroup = fb.group({
            'textInput': undefined
        });
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }

    @HostBinding('class.floating')
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    get required() {
        return this._required;
    }

    @Input()
    get placeholder() {
        return this._placeholder;
    }

    @Input()
    get value() {
        const n = this.inputGroup.value;
        const allowedDefaults = this._defaultIfDisabled ? this._defaultIfDisabled : this.NA;
        if (allowedDefaults === n.textInput) {
            return undefined;
        }
        return n.textInput;
    }

    @Input()
    get empty() {
        const inputVal = this.inputGroup.value.textInput;
        return !inputVal;
    }

    @Input()
    get errorState(): boolean {
        return this._errorState;
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    @Input()
    get maxlength(): string {
        return this._maxlength;
    }

    @Input()
    get disablePastingSpecialCharacters(): boolean {
        return this._disablePastingSpecialCharacters;
    }

    @Input()
    get disableSpecialCharacters(): boolean {
        return this._disableSpecialCharacters;
    }

    @Input()
    get allowDigits(): boolean {
        return this._allowDigits;
    }

    @Input()
    get allowDigitAndPeriod(): boolean {
        return this._allowDigitAndPeriod;
    }

    @Input()
    get defaultIfDisabled(): string {
        return this._defaultIfDisabled;
    }

    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }

    set value(val: any) {
        if (val === undefined) {
            val = this.NA;
        }
        this.inputGroup.setValue({textInput: val});
        this.stateChanges.next();
    }

    set required(req: boolean) {
        this._required = req;
        this.stateChanges.next();
    }

    set disabled(value: boolean) {
        this.processDisabled(value);
    }

    set maxlength(value: string) {
        this._maxlength = value;
    }

    set disablePastingSpecialCharacters(val: boolean) {
        this._disablePastingSpecialCharacters = val;
    }

    set disableSpecialCharacters(val: boolean) {
        this._disableSpecialCharacters = val;
    }

    set allowDigits(val: boolean) {
        this._allowDigits = val;
    }

    set allowDigitAndPeriod(val: boolean) {
        this._allowDigitAndPeriod = val;
    }

    set defaultIfDisabled(value: string) {
        this._defaultIfDisabled = value;
    }

    onChange = (a) => {
    };
    onTouched = () => {
    };

    someChange(e: Event) {
        const htmlElement = e.target as HTMLInputElement;
        this.onChange(htmlElement.value);
    }

    setDescribedByIds(ids: string[]): void {
        const controlElement = this._elementRef.nativeElement
            .querySelector('.inputContainer');
        controlElement?.setAttribute('aria-describedby', ids.join(' '));
    }

    onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this._elementRef.nativeElement.querySelector('input').focus();
        }
    }

    writeValue(obj: any): void {
        this.inputGroup.setValue({textInput: !!obj ? `${obj}` : obj});
        if (obj !== undefined) {
            this.touched = true;
            this.stateChanges.next();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.processDisabled(isDisabled);
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    processDisableSpecialCharacters(val: KeyboardEvent) {
        if (!this.allowedInputCharacterRegex.test(val.key)) {
            val.preventDefault();
        }
    }

    processAllowDigitAndPeriod(val) {
        const targetValue = val.target.value;
        if (!this.isKeyAllowed(val)) {
            let newTargetValue = '';
            if (val.srcElement.selectionStart !== val.srcElement.selectionEnd) {
                newTargetValue = targetValue.substring(0, val.srcElement.selectionStart) + val.key
                    + targetValue.substring(val.srcElement.selectionEnd, targetValue.length);
            } else if (val.srcElement.selectionStart < targetValue.length) {
                newTargetValue = targetValue.substring(0, val.srcElement.selectionStart)
                    + val.key + targetValue.substring(val.srcElement.selectionStart, targetValue.length);
            } else {
                newTargetValue = targetValue + val.key;
            }
            if (!this.allowedInputDigitAndPeriod.test(newTargetValue)) {
                val.preventDefault();
            }
        }
    }

    processAllowDigits(val: KeyboardEvent) {
        if (!this.isKeyAllowed(val)) {
            if (!this.allowedInputDigitRegex.test(val.key)) {
                val.preventDefault();
            }
        }
    }

    onKeydown(val: KeyboardEvent) {
        if (this._disableSpecialCharacters) {
            this.processDisableSpecialCharacters(val);
            return;
        }

        if (this._allowDigitAndPeriod) {
            this.processAllowDigitAndPeriod(val);
            return;
        }

        if (this._allowDigits) {
            this.processAllowDigits(val);
            return;
        }
    }

    isKeyAllowed(keyBoardEvent: KeyboardEvent): boolean {
        if (
            keyBoardEvent.key === 'Backspace' ||
            keyBoardEvent.key === 'Enter' ||
            keyBoardEvent.key === 'Delete' ||
            keyBoardEvent.key === 'ArrowLeft' ||
            keyBoardEvent.key === 'ArrowRight' ||
            keyBoardEvent.key === 'Tab' ||
            keyBoardEvent.metaKey === true
        ) {
            return true;
        }
    }

    processDisabled(value: boolean) {
        this._disabled = value;
        if (this._disabled) {
            this.inputGroup.disable();
        } else {
            this.inputGroup.enable();
        }
        const allowedDefault = this._defaultIfDisabled ? this._defaultIfDisabled : this.NA;
        if (this._disabled) {
            this.inputGroup.setValue({textInput: allowedDefault});
        }
        this.stateChanges.next();
    }


    onFocusIn(event: FocusEvent) {
        if (!this.focused) {
            this.focused = true;
            this.stateChanges.next();
        }
    }

    onFocusOut(event: FocusEvent) {
        if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            if (this.ngControl.control.errors?.[this.PASTE_ERROR_NAME] ||
                this.ngControl.control.errors?.[this.SCRUB_AND_PASTE_ERROR] ||
                this.ngControl.control.errors?.[this.SCRUB_SPECIAL_CHARACTER_ERROR]) {
                this.ngControl.control.setErrors(undefined);
            }
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChanges.next();
        }
    }

    updateErrorState() {
        const parent = this._parentFormGroup || this.parentForm;
        const oldState = this._errorState;
        const newState = (this.ngControl?.invalid || this.inputGroup.invalid)
            && (this.touched || parent.submitted);
        if (oldState !== newState) {
            this._errorState = newState;
            this.stateChanges.next();
        }
    }

    onPaste(event: ClipboardEvent) {
        let hasSpecialCharacters = false;
        let hasAdditionalCharacters = false;
        let clipboardText = event.clipboardData.getData('Text');

        if (this.disablePastingSpecialCharacters) {
            if (!this.allowedInputCharacterRegex.test(event.clipboardData.getData('Text'))) {
                event.preventDefault();
                hasSpecialCharacters = true;
                const newValue = clipboardText.replace(this.allowedInputCharacterRegexForReplace, '');
                this.ngControl.control.setValue(newValue);
                clipboardText = newValue;
            }
        }
        if (this.maxlength && !this.allowDigits && !this.allowDigitAndPeriod) {
            const truncated = clipboardText.substring(0, +this.maxlength);
            if (clipboardText.length > truncated.length) {
                event.preventDefault();
                this.ngControl.control.setValue(truncated);
                hasAdditionalCharacters = true;
                clipboardText = truncated;
            }
        }

        if (hasAdditionalCharacters && hasSpecialCharacters) {
            this.ngControl.control.updateValueAndValidity();
            this.ngControl.control.setErrors({[this.SCRUB_AND_PASTE_ERROR]: +this.maxlength});
        } else if (hasSpecialCharacters) {
            this.ngControl.control.updateValueAndValidity();
            this.ngControl.control.setErrors({[this.SCRUB_SPECIAL_CHARACTER_ERROR]: true});
        } else if (hasAdditionalCharacters) {
            this.ngControl.control.updateValueAndValidity();
            this.ngControl.control.setErrors({[this.PASTE_ERROR_NAME]: +this.maxlength});
        }

        if (this.allowDigits) {
            this.processAllowPastingDigits(clipboardText, event);
        }
    }

    processDisablePastingSpecialCharacters(val: ClipboardEvent): boolean {
        if (!this.allowedInputCharacterRegex.test(val.clipboardData.getData('Text'))) {
            val.preventDefault();
            return true;
        }
        return false;
    }

    processAllowPastingDigits(clipboardText: string, val: ClipboardEvent) {
        if (!/^d+$/.test(clipboardText)) {
            val.preventDefault();
            let newValue = clipboardText.replace(/[^0-9]/g, '');
            if (newValue.length > 0 && newValue.length > +this.maxlength ) {
                newValue = newValue.substring(0, +this.maxlength);
            }
            this.ngControl.control.setValue(newValue);
            this.ngControl.control.updateValueAndValidity();
        }
    }
}
