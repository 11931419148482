/* eslint-disable */
export const ENVIRONMENT = {
    production: true,
    region: '',
    backendBaseUrl: 'https://prod.api.mypbm.caremark.com/clr-api-prod000/',
    authorizationUrl: 'https://prod.api.mypbm.caremark.com/clr-api-prod000/authorizedUser',
    ssoBaseUrl: 'https://ngamypbmprod.b2clogin.com/ngamypbmprod.onmicrosoft.com',
    issuer: 'https://ngamypbmprod.b2clogin.com/b172873e-54c3-49b7-86c6-16afb3756187/v2.0',
    clientId: '805f0157-2e5d-4bf1-afcd-1f1edf5f6b3f',
    tokenUrl: 'https://ngamypbmprod.b2clogin.com/ngamypbmprod.onmicrosoft.com/oauth2/v2.0/token?p=B2C_1A_signup_signin',
    accessDeniedMessage: 'It appears as though you do not have access to use this application. ' +
        'If you think this is an error, please contact a BRM or the Administrator of this tool',
    authenticationEnabled: true,
    useAccessToken: false,
    idle: 600,
    timeOut: 300,
    discoveryDocumentUrl: 'https://ngamypbmprod.b2clogin.com/ngamypbmprod.onmicrosoft.com/v2.0/.well-known/openid-configuration?p=B2C_1A_signup_signin',
    scope: 'openid offline_access',
    loginUrl: '/B2C_1A_signup_signin/oauth2/v2.0/authorize',
    logoutUrl: '/B2C_1A_signup_signin/oauth2/v2.0/logout',
    myPBMSiteURL: 'https://mypbm.caremark.com',
    agGridLicenseKey: 'CompanyName=Insight (Orem, UT)_on_behalf_of_CVS Health Corporation,LicensedGroup=myPBM,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=7,LicensedProductionInstancesCount=7,AssetReference=AG-037718,SupportServicesEnd=3_February_2024_[v2]_MTcwNjkxODQwMDAwMA==b17e6964e0fd5ae3082363fbe4a9dfb7',
    appDynamicKey:'AD-AAB-ACJ-HUM'
};
