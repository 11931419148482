import {
    AfterViewInit,
    Component, EventEmitter, Input, OnChanges,
    OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren,
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FORMULARY_DATE_BOUNDARY} from '../../../enum/FormularyDateBoundary';
import {validDateRange} from '../../product-drug-requirements/dateRangeValidation';
import {MatOptionSelectionChange} from '@angular/material/core';
import {FORMULARY_CODING_TYPE} from '../../../enum/FormularyCodingType';
import {Subscription, take} from 'rxjs';
import {COVERAGE_TYPES} from '../../../default-values/DefaultValues';
import {IDrugList} from '../formulary-requirements.component';
import {DRUG_LIST_CODES} from '../../../enum/DrugListCodes';
import {CODING_TYPES} from '../../../enum/CodingTypes';
import {
    CVSBannerComponentData,
    CVSBannerService,
    CVSBannerType,
    CVSSnackBarData, CVSSnackBarService
} from 'angular-component-library';
import {ConfigurationService} from '../../../service/configuration/configuration.service';
import {AppService} from '../../../service/app/app.service';
import {ClientFormularyRequirementRule} from '../../../model/ClientFormularyRequirementRule';
import {Formulary} from '../../../model/Formulary';
import {CodingTypeComponent} from './coding-type/coding-type.component';
import {utcDate} from '../../../utility/utility';

@Component({
    selector: 'app-formulary-requirements-modal',
    templateUrl: './formulary-requirements-modal.component.html',
    styleUrls: ['./formulary-requirements-modal.component.scss']
})
export class FormularyRequirementsModalComponent implements OnInit, OnChanges {
    @ViewChildren(CodingTypeComponent) codingTypeComponent: QueryList<CodingTypeComponent>;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() formulariesDrugListMap = new Map<string, IDrugList[]>();
    @Input() formularyData: Formulary[];
    @Input() formularyOpened;
    @Input() spinnerValueFromFormularyRequirement;
    @Input() isAnUpdate;
    @Input() formularyRequirementsState;
    planGPIAndJList = [];
    preferredGPIList = [];
    preferredNDCList =[];
    showSpinner = true;
    formularyRequirementForm: FormGroup;
    subscriptions: Subscription[] = [];
    coverageTypes = COVERAGE_TYPES;
    codingTypes = Object.values(CODING_TYPES);
    codingTypeSelected: string;

    readonly CODING_TYPES = CODING_TYPES;

    constructor(private fb: FormBuilder, private configurationService: ConfigurationService,
        private _appService: AppService, private _bannerService: CVSBannerService,  private _snackBarService: CVSSnackBarService
    ) {
        if(!this.isAnUpdate) {
            this.createFormularyRequirementsForm();
        }
    }

    ngOnInit(): void {
        if(this.isAnUpdate) {
            this.loadPrePopulatedFormularyRequirementForm();
        }
    }
    ngOnChanges(): void {
        if (this.spinnerValueFromFormularyRequirement === false) {
            this.showSpinner = false;
        }
    }

    public createFormularyRequirementsForm() {
        this.formularyRequirementForm = this.fb.group({
            name: [''],
            description: [''],
            viewStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            viewEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            category: [''],
            coding: [''],
            planGPIListID: [''],
            preferredNDCListID: [''],
            preferredGPIListID: ['']
        });
    }

    onModalCancel() {
        if(!this.isAnUpdate) {
            this.formularyRequirementForm.reset({
                viewStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
                viewEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE),
                effectiveStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
                effectiveEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE)
            });
            this.resetFormularyRequirementModalFieldValidators();
        }
        this.closeEvent.emit();
    }

    saveFormularyRequirement() {
        this.resetFormularyRequirementModalFieldValidators();
        this.addFormularyRequirementModalValidators();
        this.formularyRequirementForm.markAllAsTouched();
        if (this.formularyRequirementForm.valid && this.validateFormularyRequirementForm()) {
            const requirementRule: ClientFormularyRequirementRule = {
                id: this.isAnUpdate ? this.formularyRequirementsState.id : null,
                formularyId: this.isAnUpdate ? this.formularyRequirementsState.formularyId : this.formularyOpened.formularyId,
                formularyRequirementName: this.formularyRequirementForm.value.name,
                formularyDescription: this.formularyRequirementForm.value.description,
                formularyRequirementEffectiveEndDate: this.formularyRequirementForm.value.effectiveEndDate,
                formularyRequirementEffectiveStartDate: this.formularyRequirementForm.value.effectiveStartDate,
                formularyRequirementViewEndDate: this.formularyRequirementForm.value.viewEndDate,
                formularyRequirementViewStartDate: this.formularyRequirementForm.value.viewStartDate,
                lob:  this.isAnUpdate ? this.formularyRequirementsState.lob : this.formularyOpened.businessLobCode,
                codingSetupCd:  Object.keys(CODING_TYPES)[Object.values(CODING_TYPES).indexOf(this.formularyRequirementForm.value.coding)],
                formularyCoverageCategoryCd: this.formularyRequirementForm.value.category,
                planGpiListId: this.formularyRequirementForm.value.planGPIListID,
                preferredGpiListId: this.formularyRequirementForm.value.preferredGPIListID,
                preferredNdcListId: this.formularyRequirementForm.value.preferredNDCListID,
                rank: null,
            };
            if(!this.isAnUpdate) {
                this.configurationService.saveClientFormularyRequirementRule(this._appService.getSuperClientContext().id,
                    requirementRule).pipe(take(1)).subscribe({
                    next: () => {
                        this.closeEvent.emit(false);
                        this._bannerService.sendAlert({
                            bannerType: CVSBannerType.Success,
                            headline: 'Success',
                            body: `Formulary Requirement Rule has been created for 
                        ${this.formularyOpened.formularyId} - ${this.formularyOpened.formularyName}.`,
                            outletId: '#successBanner',
                        });
                    },
                    error: () => this.onSaveError()
                });
            }
            if(this.isAnUpdate) {
                this.configurationService.updateClientFormularyRequirementRule(this._appService.getSuperClientContext().id,
                    requirementRule)?.pipe(take(1)).subscribe({
                    next: () => {
                        this.closeEvent.emit(false);
                        this._snackBarService.open({
                            iconName: 'check-circle--s',
                            message: 'Formulary Requirement Rule updated successfully',
                            duration: 5,
                            verticalPosition: 'bottom',
                            horizontalPosition: 'center'
                        } as CVSSnackBarData);
                        this.formularyRequirementForm.markAsPristine();
                    },
                });
            }
        }
    }

    getButtonText() {
        return this.isAnUpdate ? 'Update Requirement Rule' : 'Create Requirement Rule';
    }

    onSaveError() {
        const errorBannerData = {
            bannerType: CVSBannerType.Error,
            outletId: '#modalErrorBanner',
            hideX: false,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again.',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveFormularyRequirement();
                    }
                }
            ]
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(errorBannerData);
    }

    selectedCoding(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.formularyRequirementForm.controls.planGPIListID.patchValue('');
            this.formularyRequirementForm.controls.preferredNDCListID.patchValue('');
            this.formularyRequirementForm.controls.preferredGPIListID.patchValue('');
            this.codingTypeSelected = event.source.value;
            if (this.codingTypeComponent) {
                this.codingTypeComponent.forEach((comp) => {
                    comp.drugListDescription = '';
                });
            }
            this.getDrugList();
        }
    }

    getDrugList() {
        const currentFormulary = this.isAnUpdate ? this.formularyRequirementsState.formularyId : this.formularyOpened.formularyId;
        if (this.formulariesDrugListMap && this.formulariesDrugListMap.size > 0 &&
                this.formulariesDrugListMap.get(currentFormulary) !== undefined) {
            this.planGPIAndJList = this.formulariesDrugListMap?.get(currentFormulary)
                .filter(f => (f.drugListCodes === DRUG_LIST_CODES[DRUG_LIST_CODES.PLNGPI]) ||
                    (f.drugListCodes === DRUG_LIST_CODES[DRUG_LIST_CODES.JLIST]));
            this.preferredGPIList = this.formulariesDrugListMap?.get(currentFormulary)
                .filter(f => f.drugListCodes === DRUG_LIST_CODES[DRUG_LIST_CODES.PRFGPI]);
            this.preferredNDCList = this.formulariesDrugListMap?.get(currentFormulary)
                .filter(f => f.drugListCodes === DRUG_LIST_CODES[DRUG_LIST_CODES.PRFNDC]);
            this.showSpinner = false;
        }
        if (this.codingTypeComponent) {
            this.codingTypeComponent.forEach((comp) => {
                comp.showDrugListSpinner =false;
            });
        }
    }

    public addFormularyRequirementModalValidators() {
        this.formularyRequirementForm.controls['name'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['description'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['category'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['category'].updateValueAndValidity({onlySelf: true});
        this.formularyRequirementForm.controls['coding'].addValidators([Validators.required]);
        this.formularyRequirementForm.controls['coding'].updateValueAndValidity({onlySelf: true});

        if (this.codingTypeSelected === CODING_TYPES.PLAN_GPI_WITH_RESTRICT_NDC_CD) {
            this.formularyRequirementForm.controls['planGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['planGPIListID'].updateValueAndValidity();
        } else if (this.codingTypeSelected === CODING_TYPES.STD_NDC_CD ) {
            this.formularyRequirementForm.controls['preferredNDCListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredNDCListID'].updateValueAndValidity();
        } else if (this.codingTypeSelected === CODING_TYPES.GPI_NO_RESTRICT_NDC_CD ||
            this.codingTypeSelected === CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD ) {
            this.formularyRequirementForm.controls['preferredGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredGPIListID'].updateValueAndValidity();
        } else if (this.codingTypeSelected === CODING_TYPES.STD_GPI_NDC_CD) {
            this.formularyRequirementForm.controls['preferredNDCListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredNDCListID'].updateValueAndValidity();
            this.formularyRequirementForm.controls['preferredGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredGPIListID'].updateValueAndValidity();
        }
    }
    public validateFormularyRequirementForm() {
        if(!this.formularyRequirementForm.controls['name'].errors?.required &&
            !this.formularyRequirementForm.controls['description'].errors?.required &&
            !this.formularyRequirementForm.controls['category'].errors?.required &&
            !this.formularyRequirementForm.controls['coding'].errors?.required &&
            !this.formularyRequirementForm.controls['planGPIListID'].errors?.required &&
            !this.formularyRequirementForm.controls['preferredNDCListID'].errors?.required &&
            !this.formularyRequirementForm.controls['preferredGPIListID'].errors?.required) {
            return true;
        } else {
            return false;
        }
    }
    private resetFormularyRequirementModalFieldValidators() {
        this.formularyRequirementForm.controls['name'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['name'].updateValueAndValidity();
        this.formularyRequirementForm.controls['description'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['description'].updateValueAndValidity();
        this.formularyRequirementForm.controls['category'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['category'].updateValueAndValidity();
        this.formularyRequirementForm.controls['coding'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['coding'].updateValueAndValidity();
        this.formularyRequirementForm.controls['planGPIListID'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['planGPIListID'].updateValueAndValidity();
        this.formularyRequirementForm.controls['preferredNDCListID'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['preferredNDCListID'].updateValueAndValidity();
        this.formularyRequirementForm.controls['preferredGPIListID'].removeValidators([Validators.required]);
        this.formularyRequirementForm.controls['preferredGPIListID'].updateValueAndValidity();
    }

    private loadPrePopulatedFormularyRequirementForm() {
        this.formularyRequirementForm = this.fb.group({
            name: [this.formularyRequirementsState.formularyRequirementName],
            description: [this.formularyRequirementsState.formularyDescription],
            viewStartDate: [new Date( utcDate(this.formularyRequirementsState.formularyRequirementViewStartDate)),
                [Validators.required, validDateRange]
            ],
            viewEndDate: [new Date(utcDate(this.formularyRequirementsState.formularyRequirementViewEndDate)),
                [Validators.required, validDateRange]
            ],
            effectiveStartDate: [new Date(utcDate(this.formularyRequirementsState.formularyRequirementEffectiveStartDate)),
                [Validators.required, validDateRange]
            ],
            effectiveEndDate: [new Date(utcDate(this.formularyRequirementsState.formularyRequirementEffectiveEndDate)),
                [Validators.required, validDateRange]
            ],
            category: [this.formularyRequirementsState.formularyCoverageCategoryCd],
            planGPIListID: this.formularyRequirementsState.planGpiListId === undefined ?
                [] : [this.formularyRequirementsState.planGpiListId],
            preferredNDCListID: this.formularyRequirementsState.preferredNdcListId === undefined ?
                [] : [this.formularyRequirementsState.preferredNdcListId],
            preferredGPIListID: this.formularyRequirementsState.preferredGpiListId === undefined ?
                [] : [this.formularyRequirementsState.preferredGpiListId],
            coding: CODING_TYPES[this.formularyRequirementsState.codingSetupCd]
        });
        this.codingTypeSelected = CODING_TYPES[this.formularyRequirementsState.codingSetupCd];
        this.getDrugList();
    }
}
