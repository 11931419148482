<div id="exportDataDiv">
    <app-date-picker id="noActionDateRangePicker"
                     [setDefaultDateRangePicker]=true
                     [currentDate]="endDate"
                     (dateEvent)="getDates($event)"
                     [dateRangeError]="dateRangeError"
                     [maxDate]="maxDate"
                     [parentSource]="'noAction'">
    </app-date-picker>
    <pds-toggle-switch #toggle class="margin-20"
                       title="Only show products with notes" id="noActionToggle"
                       (onToggle)="onShowProductNotesClick()"
                       [disabled]="filteredWorkflowNoActionData.length===0 && showProductNotesChecked !== true"
                       aria-label="Only show products with notes">
    </pds-toggle-switch>
    <div>
        <button id="cvsGhostBtn" mat-stroked-button color="primary" [disabled]="isDisabled" (click)="exportToExcel()">
            Export No Action Report
        </button>
    </div>
</div>
<cvs-loader-overlay-container id="loadingSpinner"
                              [isLoading]="isLoading"
                              [loadingMessage]="'Loading Content...'">
<app-cvs-ag-grid #workflowNoActionGrid id="workflowNoActionGrid"
                 style="width: 100%"
                 class="ag-theme-balham"
                 [gridOptions]="workflowNoActionGridOptions"
                 [modules]="modules"
                 [rowData]="filteredWorkflowNoActionData"
                 [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                 (selectionChanged)="onSelectionChanged($event)"
                 [popupParent]="popupParent"
                 [disableCvsExport]="true"
                 (firstDataRendered)="onFirstDataRendered($event)"
                 (rowDataUpdated)="onRowDataUpdated($event)"
                 [style.height]="gridHeight">
</app-cvs-ag-grid>
</cvs-loader-overlay-container>