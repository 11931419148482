import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {HttpDataCache} from '../HttpDataCache';
import {Observable} from 'rxjs';
import {MasterDrugResponse} from '../../model/MasterDrugResponse';

@Injectable({
    providedIn: 'root'
})
export class DrugUniverseApiService extends  HttpDataCache{

    constructor(private cvsHttpClient: CVSHttpClient) {
        super(cvsHttpClient);
    }

    getDrug(searchBy: string, searchByValue: string, includeMonyHistory: string, includeMedDHistory: string):
    Observable<MasterDrugResponse[]> {
    // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${this.getEnvironmentBackendBaseUrl()}drug/drugMaster/search/${searchBy}/${searchByValue}/${includeMonyHistory}/${includeMedDHistory}`);
    }
}
