<ng-container *ngIf="displayBlobTabs">
    <mat-tab-group *ngIf="!isPrimaryTab"
                   id="bLobTab" animationDuration="1ms" disableRipple color="accent"
                   (selectedTabChange)="onTabChange($event)"
                   [selectedIndex]="selectedIndex">
        <mat-tab *ngFor="let lob of superClientLobs | keyvalue"
                 [label]="lob.value.description"
                 [aria-label]="lob.value.code+''"></mat-tab>
    </mat-tab-group>

    <mat-tab-group  #primaryTabGroup *ngIf="isPrimaryTab"
                   id="primaryBlobTab" animationDuration="1ms" disableRipple class="pds-primary-tab"
                   aria-label="Primary Tab"
                   (selectedTabChange)="onTabChange($event, primaryTabGroup)">
        <mat-tab *ngFor="let lob of superClientLobs | keyvalue"
                 [label]="lob.value.description"
                 [aria-label]="lob.value.code+''"></mat-tab>
    </mat-tab-group>
</ng-container>
