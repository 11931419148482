<div class="formularySpinner" *ngIf="loadingTableData">
    <cvs-loader-overlay-container [isLoading]="loadingTableData"
                                  loadingMessage="Loading"></cvs-loader-overlay-container>
</div>
<ng-container *ngIf="!loadingTableData">
    <mat-label class="label">Select NDC(s) to Convert</mat-label>
    <app-cvs-ag-grid
        #convertToNdc
        id="ndc-items"
        [style.height]="gridHeight"
        class="ag-theme-balham grid"
        [rowData]="ndcData"
        [domLayout]="domLayout"
        [gridOptions]="gridOptions"
        [modules]="modules"
        [suppressMenuHide]="true"
        [disableCvsExport]="true"
        (selectionChanged)="onSelectionChange($event)">
    </app-cvs-ag-grid>
</ng-container>
