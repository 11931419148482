import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MoveRankData} from '../../model/MoveRankData';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {AppService} from '../../service/app/app.service';
import {take} from 'rxjs';
import {CLIENT_CONFIGURATION_FOR_RANK_CHANGE} from '../../enum/ClientConfigurationForRankChange';
import {CVSSnackBarData, CVSSnackBarService} from 'angular-component-library';

@Component({
    selector: 'app-move-rank-modal',
    templateUrl: './move-rank-modal.component.html',
    styleUrls: ['./move-rank-modal.component.scss']
})

export class MoveRankModalComponent implements OnInit {
    @Input() currentRankIndex = 0;
    @Input() selectedComponentName: string;
    @Input() rankList: number[];
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() moveRankData: MoveRankData;
    @Input() rankOptionSelection;
    moveRankForm: FormGroup;

    constructor(private fb: FormBuilder,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
        private _snackBarService: CVSSnackBarService) {
        this.createMoveRankForm();
    }

    ngOnInit() {
        this.resetMoveRankForm();
    }

    createMoveRankForm() {
        this.moveRankForm = this.fb.group({
            currentRank: [{value: this.currentRankIndex, disabled: true}, []],
            selectedRank: [''],
        });
    }

    saveMoveChanges() {
        this.addMoveRankFormValidators();
        this.moveRankForm.markAllAsTouched();
        const isFormValid = this.validateMoveRankForm() && this.moveRankForm.valid;
        if (isFormValid) {
            this.submitEvent.emit(this.moveRankForm.controls['selectedRank'].value);
            this.moveRankData.newRankToBeMovedTo = this.moveRankForm.controls['selectedRank'].value;
            this._configurationService.moveRankClientConfiguration(this._appService.getSuperClientContext().id,this.moveRankData)
                .pipe(take(1)).subscribe(() => {
                    this._snackBarService.open({
                        iconName: 'check-circle--s',
                        message: 'Rank moved successfully',
                        duration: 5,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                    } as CVSSnackBarData);
                    if (this.moveRankData.clientConfigurationPageForRankMovement ===
                        CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE) {
                        this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE);
                    } else if (this.moveRankData.clientConfigurationPageForRankMovement ===
                        CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM) {
                        this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM);
                    } else if (this.moveRankData.clientConfigurationPageForRankMovement ===
                        CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT) {
                        this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT);
                    }
                });
        }
    }

    onModalCancel() {
        this.closeEvent.emit(true);
    }

    addMoveRankFormValidators() {
        this.moveRankForm.controls['selectedRank'].addValidators([Validators.required]);
        this.moveRankForm.controls['selectedRank'].updateValueAndValidity({onlySelf: true});
    }

    resetMoveRankForm() {
        this.moveRankForm.reset({
            currentRank: this.currentRankIndex,
        });
        this.moveRankForm.controls['selectedRank'].removeValidators([Validators.required]);
        this.moveRankForm.controls['selectedRank'].updateValueAndValidity({onlySelf: true});
    }

    validateMoveRankForm() {
        if (!this.moveRankForm.controls['selectedRank'].errors?.required) {
            return true;
        } else {
            return false;
        }
    }
}
