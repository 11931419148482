import {Component, OnInit} from '@angular/core';
import {AppService} from '../../service/app/app.service';
import {
    CVSAlertType, CVSBannerService,
    CVSBannerType,
    CVSConfirmationDialogContentComponent,
    PDSInlineCalloutType
} from 'angular-component-library';
import {TrackerDataService} from '../../service/tracker-data/tracker-data.service';
import {Observable, take} from 'rxjs';
import {
    NEW_CLIENT_BLOB_GROUP,
    MEDISPAN_UPDATES,
    MedispanUpdate,
    MAP_MEDISPAN_UPDATES_TO_MEDISPAN_SETTING
} from '../../default-values/DefaultValues';
import {BUSINESS_LOB} from '../../enum/BusinessLob';
import {MatDialog} from '@angular/material/dialog';
import {MedispanSetting} from '../../model/MedispanSetting';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {dateTruncation} from '../../utility/utility';
import {IDeactivateComponent} from '../../guards/IDeactivateComponent';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';

@Component({
    selector: 'app-medispan-settings',
    templateUrl: './medispan-settings.component.html',
    styleUrls: ['./medispan-settings.component.scss']
})
export class MedispanSettingsComponent implements OnInit, IDeactivateComponent {
    inlineCalloutType = PDSInlineCalloutType;
    superClientContext;
    medispanUpdates: MedispanUpdate[];
    medispanSetting: MedispanSetting;
    selectedBusinessLob;
    disableSave: boolean;
    readonly UPDATE_REPACKAGER = 'updateRepackager';
    readonly VALUE = 'value';

    constructor(private _appService: AppService, private _trackerDataService: TrackerDataService, private _matDialog: MatDialog,
        private _bannerService: CVSBannerService) {
    }

    ngOnInit(): void {
        this.superClientContext = this._appService.getSuperClientContext();
    }

    populateMedispanSettingCheckboxes() {
        if (this.medispanSetting) {
            this.disableSave = true;
            this.setUpMedispanSettingsForExistingClient();
        } else {
            this.medispanUpdates = MEDISPAN_UPDATES;
            this.disableSave = false;
            this.setupMedispanSettingsForNewClient();
        }
    }

    setUpMedispanSettingsForExistingClient() {
        this.medispanUpdates = [
            {type: 'New GPI', value: this.medispanSetting?.newGpi, checked: this.medispanSetting?.newGpi === 'Y'},
            {type: 'New NDC', value: this.medispanSetting?.newNdc, checked: this.medispanSetting?.newNdc === 'Y'},
            {
                type: 'Maintenance Change', value: this.medispanSetting?.maintenanceChange,
                checked: this.medispanSetting?.maintenanceChange === 'Y'
            },
            {
                type: 'MONY (GI) Change', value: this.medispanSetting?.monyGiChange,
                updateRepackager: {
                    value: this.medispanSetting?.repackager,
                    checked: this.medispanSetting?.repackager === 'Y'
                },
                checked: this.medispanSetting?.monyGiChange === 'Y'
            },
            {
                type: 'Rx-OTC Change',
                value: this.medispanSetting?.rxOtcChange,
                checked: this.medispanSetting?.rxOtcChange === 'Y'
            },
            {
                type: 'GPI Change',
                value: this.medispanSetting?.gpiChange,
                checked: this.medispanSetting?.gpiChange === 'Y'
            },
            {
                type: 'Med D Indicator Change', value: this.medispanSetting?.medDIndicatorChange,
                checked: this.medispanSetting?.medDIndicatorChange === 'Y'
            },
            {
                type: 'FDA Application Change', value: this.medispanSetting?.fdaApplicationChange,
                checked: this.medispanSetting?.fdaApplicationChange === 'Y'
            },
            {
                type: 'Participating Labeler change',
                value: this.medispanSetting?.participatingLabelerChange,
                checked: this.medispanSetting?.participatingLabelerChange === 'Y'
            }
        ];
    }

    setupMedispanSettingsForNewClient() {
        const isMedicare = this.selectedBusinessLob.description === BUSINESS_LOB.MEDICARE;
        const targetBlobGroup = isMedicare ? NEW_CLIENT_BLOB_GROUP.MEDICARE : NEW_CLIENT_BLOB_GROUP.NON_MEDICARE;
        this.medispanUpdates?.forEach(medispanUpdate => {
            const currentBlobGroup = medispanUpdate?.blobGroup;
            const isPartOfTargetGroup = ((currentBlobGroup === targetBlobGroup) ||
                (currentBlobGroup === NEW_CLIENT_BLOB_GROUP.BOTH));
            medispanUpdate.value = isPartOfTargetGroup ? 'Y' : 'N';
            medispanUpdate.checked = isPartOfTargetGroup;
            if (medispanUpdate.hasOwnProperty(this.UPDATE_REPACKAGER)) {
                medispanUpdate.updateRepackager.value = 'N';
                medispanUpdate.updateRepackager.checked = false;
            }
        });
    }

    toggleCheckbox(medispanUpdate: MedispanUpdate, key: string): void {
        this.disableSave = false;
        this._trackerDataService.setMedispanSettingsModified(true);
        if (key === this.UPDATE_REPACKAGER) {
            medispanUpdate[key].value = medispanUpdate[key].value === 'Y' ? 'N' : 'Y';
            medispanUpdate[key].checked = !medispanUpdate[key].checked;
        } else {
            medispanUpdate[key] = medispanUpdate[key] === 'Y' ? 'N' : 'Y';
            medispanUpdate.checked = !medispanUpdate.checked;
            if (medispanUpdate.hasOwnProperty(this.UPDATE_REPACKAGER)) {
                medispanUpdate[this.UPDATE_REPACKAGER].value = 'N';
                medispanUpdate[this.UPDATE_REPACKAGER].checked = false;
            }
        }
    }

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        this.selectedBusinessLob = {description: event.tab.textLabel, code: event.tab.ariaLabel};
        this._trackerDataService.getMedispanSetting(this.superClientContext.id, this.selectedBusinessLob.code)
            .pipe(take(1)).subscribe(data => {
                this.medispanSetting = data;
                this.populateMedispanSettingCheckboxes();
            });
    }
    medispanSettingsDialog() {
        const confirmationDialog = this._matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Confirmation',
                body: 'The changes specified will apply only to future Medispan updates. ' +
                    'No <br/> changes will be made to updates in existing Medispan or Workflow tabs.',
                actionLabel: 'Confirm Configuration',
                cancelLabel: 'Cancel',
            }
        });
        confirmationDialog.componentInstance.onConfirmClick.subscribe(() => {
            const medispanSetting: MedispanSetting = this.buildMedispanSettingsObject();
            this._trackerDataService.saveMedispanSetting(this.superClientContext.id,this.selectedBusinessLob.code,medispanSetting)
                .subscribe({next:() => {
                    this._trackerDataService.setMedispanSettingsModified(false);
                    this.onSaveSuccess();
                }, error: () => {
                    this.onSaveError();
                }});
        });
        confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            confirmationDialog.close();
        });
    }

    onSaveSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: 'Medispan configurations saved.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(successBannerData);
    }

    onSaveError() {
        const errorBannerData = {
            bannerType: CVSBannerType.Error,
            outletId: '#errorBanner',
            hideX: false,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again.',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.medispanSettingsDialog();
                    }
                }
            ]
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(errorBannerData);
    }

    buildMedispanSettingsObject(): MedispanSetting {
        const medispanSetting: MedispanSetting = {} as MedispanSetting;
        this.medispanUpdates.forEach(medispanUpdate => {
            medispanSetting[MAP_MEDISPAN_UPDATES_TO_MEDISPAN_SETTING.get(medispanUpdate.type)] = medispanUpdate.value;
            if (medispanUpdate.hasOwnProperty(this.UPDATE_REPACKAGER)) {
                medispanSetting[MAP_MEDISPAN_UPDATES_TO_MEDISPAN_SETTING.get(this.UPDATE_REPACKAGER)] =
                    medispanUpdate.updateRepackager.value;
            }
        });
        medispanSetting.savedDate = dateTruncation(new Date(new Date().setHours(0, 0, 0, 0)).toISOString());
        return medispanSetting;
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this._trackerDataService.isMedispanSettingsModified();
    }

    setDeactivatingState(state: boolean) {
        this._trackerDataService.setMedispanSettingsModified(state);
    }
}
