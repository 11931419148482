import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractFormularyDrugPage } from '../abstract/AbstractFormularyDrugPage';
import { DrugListComponent } from '../drug-list/drug-list.component';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import { SEARCH_FIELD_ORDER } from '../formulary-drug-search/formulary-drug-search.component';
import { DrugUniverseApiService } from '../service/drug-universe/drug-universe-api.service';
import { CVSBannerService } from 'angular-component-library';
import { AppService } from '../service/app/app.service';
import { FormularyService } from '../service/formulary/formulary.service';

// eslint-disable-next-line no-shadow
export enum NAVIGATEDFROM {
    FORMULARY_RESEARCH = 'formularySearch',
    DRUG_RESEARCH = 'drugSearch'
}

@Component({
    selector: 'app-formulary-page',
    templateUrl: './formulary-page.component.html',
    styleUrls: ['./formulary-page.component.scss']
})
export class FormularyPageComponent extends AbstractFormularyDrugPage implements OnInit, OnDestroy {
    @ViewChild('drugListComponent') drugListComponent: DrugListComponent;
    navigatedFrom = undefined;

    displayOption = {
        fieldOrder: [SEARCH_FIELD_ORDER.LOB, SEARCH_FIELD_ORDER.FORMULARY, SEARCH_FIELD_ORDER.DRUG_INDICATOR, SEARCH_FIELD_ORDER.DRUG],
        hideSpyglass: true,
        displayAllWhenAllSelected: true,
        searchByEnterKey: false,
        hideSearchButton: false,
        hideAdditionalFilters: false
    };
    drugIndicatorList = ['GPI', 'RxCUI', 'NDC', 'Drug Name'];
    navigationSourcePage = {
        FORMULARY_RESEARCH: NAVIGATEDFROM.FORMULARY_RESEARCH,
        DRUG_RESEARCH: NAVIGATEDFROM.DRUG_RESEARCH
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        _appService: AppService,
        _formularyService: FormularyService,
        _router: Router,
        _drugUniverseService: DrugUniverseApiService,
        _bannerService: CVSBannerService,
    ) {
        super(_appService, _formularyService, _drugUniverseService, _router, _bannerService);
    }

    ngOnInit(): void {
        this.navigatedFrom = this.route.snapshot.data['mode'];
        if (this.navigatedFrom === NAVIGATEDFROM.DRUG_RESEARCH) {
            this.displayOption.fieldOrder = [SEARCH_FIELD_ORDER.DRUG_INDICATOR, SEARCH_FIELD_ORDER.DRUG];
            this.displayOption.hideAdditionalFilters = true;
        } else {
            this.retrieveData();
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }
}
