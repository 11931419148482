<mat-card class="card-content" [formGroup]="formularyRequirementForm">
    <mat-form-field>
        <mat-label #matLabel>{{selectedFormularyDrugListType}}</mat-label>
        <ng-container *ngIf="matLabel.textContent === formularyCodingType.PLAN_GPI_JLIST">
            <cvs-loader-overlay-container id="lobSpinner"
                                          [isLoading] = showDrugListSpinner
                                          [loadingMessage]="'Loading Content...'">
                <mat-select pdsMatOptionKeyboardFocus formControlName="planGPIListID" (selectionChange)="onDrugListTypeChange($event)">
                    <mat-option *ngFor="let drugType of planGPIIDAndJIDList" [value]="drugType">
                        {{drugType}}
                    </mat-option>
                </mat-select>
            </cvs-loader-overlay-container>
        </ng-container>
        <mat-error *ngIf="formularyRequirementForm.controls.planGPIListID.errors?.required && this.planGPIAndJList.length>0 && !showDrugListSpinner && matLabel.textContent === formularyCodingType.PLAN_GPI_JLIST">
            <mat-icon svgIcon="error-f--s"></mat-icon>Select a List ID
        </mat-error>
        <mat-error *ngIf="formularyRequirementForm.controls.planGPIListID.errors?.required && this.planGPIAndJList.length === 0 && !showDrugListSpinner && matLabel.textContent === formularyCodingType.PLAN_GPI_JLIST" class="noDataAvailableError">
            <mat-icon svgIcon="error-f--s"></mat-icon>No data available. Select <br> another Coding Setup.
        </mat-error>
        <ng-container *ngIf="matLabel.textContent === formularyCodingType.PREFERRED_NDC">
            <cvs-loader-overlay-container id="lobSpinner"
                                          [isLoading] = showDrugListSpinner
                                          [loadingMessage]="'Loading Content...'">
                <mat-select pdsMatOptionKeyboardFocus formControlName="preferredNDCListID" (selectionChange)="onDrugListTypeChange($event)">
                    <mat-option *ngFor="let drugType of preferredNDCIDList" [value]="drugType">
                        {{drugType}}
                    </mat-option>
                </mat-select>
            </cvs-loader-overlay-container>
        </ng-container>
        <mat-error *ngIf="formularyRequirementForm.controls.preferredNDCListID.errors?.required && this.preferredNDCList.length>0 && !showDrugListSpinner && matLabel.textContent === formularyCodingType.PREFERRED_NDC">
            <mat-icon svgIcon="error-f--s"></mat-icon>Select a List ID
        </mat-error>
    <mat-error *ngIf="formularyRequirementForm.controls.preferredNDCListID.errors?.required && this.preferredNDCList.length === 0 && !showDrugListSpinner &&  matLabel.textContent === formularyCodingType.PREFERRED_NDC" class="noDataAvailableError">
        <mat-icon svgIcon="error-f--s"></mat-icon>No data available. Select <br> another Coding Setup.
    </mat-error>
        <ng-container *ngIf="matLabel.textContent === formularyCodingType.PREFERRED_GPI">
            <cvs-loader-overlay-container id="lobSpinner"
                                          [isLoading] = showDrugListSpinner
                                          [loadingMessage]="'Loading Content...'">
                <mat-select pdsMatOptionKeyboardFocus formControlName="preferredGPIListID" (selectionChange)="onDrugListTypeChange($event)">
                    <mat-option *ngFor="let drugType of preferredGPIIDList" [value]="drugType">
                        {{drugType}}
                    </mat-option>
                </mat-select>
            </cvs-loader-overlay-container>
        </ng-container>
        <mat-error *ngIf="formularyRequirementForm.controls.preferredGPIListID.errors?.required && this.preferredGPIList.length > 0 && !showDrugListSpinner && matLabel.textContent === formularyCodingType.PREFERRED_GPI">
            <mat-icon svgIcon="error-f--s"></mat-icon>Select a List ID
        </mat-error>
        <mat-error *ngIf="this.preferredGPIList.length === 0 && formularyRequirementForm.controls.preferredGPIListID.errors?.required && !showDrugListSpinner && matLabel.textContent === formularyCodingType.PREFERRED_GPI" class="noDataAvailableError">
            <mat-icon svgIcon="error-f--s"></mat-icon>No data available. Select <br> another Coding Setup.
        </mat-error>
    </mat-form-field>
    <div class="list-name-field">
        <mat-hint class="list-name-label">List Name</mat-hint>
        <mat-label class="drug-description-label">{{drugListDescription}}</mat-label>
    </div>
    <app-cvs-ag-grid
            style="width: 100%;"
            class="ag-theme-balham"
            [gridOptions]="formularyRequirementsGridOptions"
            [rowData]="formularyRowData"
            [modules]="modules"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [domLayout]="'autoHeight'"
            [popupParent]="popupParent"
            [paginationEnabled]="true"
            [paginationPageSize]="5"
            [disableCvsExport]="true"
    >
    </app-cvs-ag-grid>
</mat-card>