import {MenuItemDef, ProcessRowParams} from '@ag-grid-community/core';
import {KabobMenuActionTitlesEnum} from '../formulary-requirements/KabobMenuActionTitlesEnum';

interface FormularyRequirementsKabobActions {
    MOVE_UP: MenuItemDef;
    MOVE_DOWN: MenuItemDef;
    MOVE_RANK: MenuItemDef;
    EDIT: MenuItemDef;
    // DELETE: MenuItemDef;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const getFormularyRequirementActions = (params: ProcessRowParams, rank): FormularyRequirementsKabobActions => ({
    MOVE_UP: {
        name: KabobMenuActionTitlesEnum.MOVE_UP,
        action: () => {
            params.context?.this.moveRankUp();
        },
    },
    MOVE_DOWN: {
        name: KabobMenuActionTitlesEnum.MOVE_DOWN,
        action: () => {
            params.context?.this.moveRankDown();
        },
    },
    MOVE_RANK: {
        name: KabobMenuActionTitlesEnum.MOVE_RANK,
        action: () => {
            params.context?.this.showMoveRank(rank, params.node.data, params.node.parent.allLeafChildren);
        },
    },
    EDIT: {
        name: KabobMenuActionTitlesEnum.EDIT,
        action: () => {
            params.context?.this.openUpdateFormularyRequirementsModal(params.context.this.formularyRequirementsFormRef);
        },
    },
    // DELETE: {
    //     name: KabobMenuActionTitlesEnum.DELETE,
    //     action: () => {
    //         params.context?.this.showDeleteFormularyRequirementsModal(params.context.this.formularyRequirementsState);
    //     },
    // }
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export  const formularyRequirementsContextMenuItems = (params: ProcessRowParams): MenuItemDef[] => {
    const formularyRequirementActions = getFormularyRequirementActions(params, params.node?.rowIndex+1);
    const options =
        [formularyRequirementActions.MOVE_UP, formularyRequirementActions.MOVE_DOWN, formularyRequirementActions.MOVE_RANK,
            formularyRequirementActions.EDIT,
            // formularyRequirementActions.DELETE
        ];
    if (params.node.firstChild && params.node.lastChild) {
        options.splice(0, 3);
    } else if (params.node.firstChild) {
        options.splice(0, 1);
    } else if (params.node.lastChild) {
        options.splice(1, 1);
    }
    return options;
};
