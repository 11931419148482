import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    ColDef,
    GridOptions,
    NewValueParams,
    RowNode,
    SuppressKeyboardEventParams
} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {CVSAlertService} from 'angular-component-library';
import {Subject, debounce, interval, take} from 'rxjs';
import {CustomHeaderCheckboxComponent} from 'src/app/common/custom-header-checkbox/custom-header-checkbox.component';
import {CustomNavigationComponent} from 'src/app/common/custom-navigation/custom-navigation.component';
import {
    CHECK_STATE,
    CustomToggleButtonComponent
} from 'src/app/common/custom-toggle-button/custom-toggle-button.component';
import {CvsAgGridComponent} from 'src/app/common/cvs-grid/cvs-ag-grid/cvs-ag-grid.component';
import {PRODUCT_OVERVIEW_SOURCE} from 'src/app/enum/ProductOverviewSource';
import {RECORD_STATUS} from 'src/app/enum/RecordStatus';
import {WORKFLOW_MESSAGE_ACTION} from 'src/app/enum/WorkflowMessageAction';
import {getUpdateTypeDisplayNameForMedispan} from 'src/app/mapper/hierarchyUpdateType';
import {MedispanTrackerResponse} from 'src/app/model/MedispanTrackerResponse';
import {TrackerUpdate} from 'src/app/model/TrackerUpdate';
import {User} from 'src/app/model/User';
import {AppService, APP_INITALIZATION} from 'src/app/service/app/app.service';
import {FormularyService} from 'src/app/service/formulary/formulary.service';
import {TrackerDataService} from 'src/app/service/tracker-data/tracker-data.service';
import {UserService} from 'src/app/service/user/user.service';
import {WorkflowService} from 'src/app/service/workflow/workflow.service';
import {dateTruncation, dateFormatter} from 'src/app/utility/utility';
import {WORKFLOW_TABS} from '../WorkflowTab';
import {CellKeyDownEvent} from '@ag-grid-community/core';
import {RecordService} from '../../service/record/record.service';

@Component({
    selector: 'app-workflow-medispan-list',
    templateUrl: './workflow-medispan-list.component.html',
    styleUrls: ['./workflow-medispan-list.component.scss']
})
export class WorkflowMedispanListComponent implements OnInit, OnChanges {

    @ViewChild('workflowMedispanGrid', {static: true}) workflowMedispanList: CvsAgGridComponent;
    @Input() mainTabName = '';
    @Input() subTabIndex = 0;
    @Input() hasEditPermission: boolean;
    @Input() navigatedFromLink = '';
    @Input() triggerSaveChild = undefined;
    @Output() disableMoveInWorkflowButton: EventEmitter<boolean> = new EventEmitter(true);
    @Output() actionNoActionCountForBannerMessage = new EventEmitter();
    @Output() isLoadingSpinner: EventEmitter<boolean> = new EventEmitter<boolean>();

    statuses = [RECORD_STATUS.DEFAULT, RECORD_STATUS.DEFAULT];
    editedRows: NewValueParams[] = [];
    user: User;
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];
    workflowMedispanData: MedispanTrackerResponse[];
    businessLobs;
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };
    popupParent: HTMLElement | null = document.querySelector('body');
    commonColumnDefs: ColDef[];
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    columnChangedDebounce = new Subject<RowNode[]>();
    nodeToRedraw: RowNode[] = [];
    gridHeaderUpdateEvent = new Subject();
    actionColDefs: ColDef[];
    workflowMedispanGridOptions: GridOptions;
    subscriptions = [];
    gridHeight = '60vh';

    private COMMERCIAL = 'BC';
    private MEDD = 'BM';

    constructor(private appService: AppService,
        private trackerService: TrackerDataService,
        private formularyService: FormularyService,
        private userService: UserService,
        private alertService: CVSAlertService,
        private workflowService: WorkflowService,
        private recordService: RecordService) {
        this.commonColumnDefs = [
            {
                headerName: 'Date', field: 'weekDate', valueGetter: params => dateTruncation(params.data.weekDate),
                valueFormatter: params => dateFormatter(params.data.weekDate), filter: 'agSetColumnFilter'
            },
            {
                headerName: 'NDC',
                field: 'ndc',
                filter: 'agSetColumnFilter',
                width: 80,
                cellRenderer: CustomNavigationComponent,
                cellRendererParams: {
                    source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_MEDISPAN,
                    mainTabName: this.mainTabName,
                    subTabIndex: this.subTabIndex,
                    navigatedFromLink: this.navigatedFromLink
                }
            },
            {
                headerName: 'Update Type', field: 'updateType', filter: 'agSetColumnFilter', width: 195,
                valueGetter: (params) => getUpdateTypeDisplayNameForMedispan(params.data.updateType),
                filterParams: {
                    comparator: (value1, value2) => {
                        if (value1.hierarchy === value2.hierarchy) {
                            return 0;
                        }
                        return value1.hierarchy > value2.hierarchy ? 1 : -1;
                    }
                }
            },
            {headerName: 'Drug Tag', field: 'drugTag', filter: 'agSetColumnFilter', width: 120},
            {headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', width: 80},
            {headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', width: 120},
            {headerName: 'Label Name', field: 'labelName', filter: 'agSetColumnFilter', width: 120},
            {headerName: 'Repackage', field: 'repackageInd', filter: 'agSetColumnFilter', width: 120},
            {headerName: 'CMK GEN ID', field: 'cmkGenInd', filter: 'agSetColumnFilter', width: 120},
            {
                headerName: 'MS GEN ID',
                valueGetter: params => this.displayMsGenId(params),
                filter: 'agSetColumnFilter',
                width: 120
            },
            {headerName: 'Type', field: 'type', filter: 'agSetColumnFilter', width: 80},
            {headerName: 'Rx/OTC', field: 'rxOtc', filter: 'agSetColumnFilter', width: 95},
            {headerName: 'Therapeutic Class', field: 'therapeuticClass', filter: 'agSetColumnFilter', width: 160},
            {headerName: 'MED D', field: 'medDFlag', filter: 'agSetColumnFilter', width: 95},
            {headerName: 'ANDA', field: 'andaFlag', filter: 'agSetColumnFilter', width: 95},
            {headerName: 'NDA', field: 'ndaFlag', filter: 'agSetColumnFilter', width: 80},
            {headerName: 'BLA', field: 'blaFlag', filter: 'agSetColumnFilter', width: 80},
            {headerName: 'Part Labeler', field: 'partLabelerFlag', filter: 'agSetColumnFilter', width: 120},
            {
                headerName: 'Third Party Exception',
                field: 'thirdPartyException',
                filter: 'agSetColumnFilter',
                width: 180
            },
            {headerName: 'MAINT', field: 'maint', filter: 'agSetColumnFilter', width: 80},
            {
                headerName: 'Caremark Before Generic Indicator', field: 'cmkgiBeforeGenericIndicator',
                filter: 'agSetColumnFilter', width: 257
            },
            {
                headerName: 'Caremark After Generic Indicator',
                field: 'cmkgiAfterGenericIndicator',
                filter: 'agSetColumnFilter',
                width: 250
            },
            {
                headerName: 'Medispan Before Generic Indicator', field: 'medispanGIBeforeGenericIndicator',
                filter: 'agSetColumnFilter', width: 257
            },
            {
                headerName: 'Medispan After Generic Indicator', field: 'medispanGIAfterGenericIndicator',
                filter: 'agSetColumnFilter', width: 250
            },
            {
                headerName: 'MED D Eligible Before',
                field: 'eligibleCovBeforeMedDIndicator',
                filter: 'agSetColumnFilter',
                width: 180
            },
            {
                headerName: 'MED D Eligible After',
                field: 'eligibleCovAfterMedDIndicator',
                filter: 'agSetColumnFilter',
                width: 165
            },
            {headerName: 'BEFORE FDA APP', field: 'beforeFdaApp', filter: 'agSetColumnFilter', width: 150},
            {headerName: 'AFTER FDA APP', field: 'afterFdaApp', filter: 'agSetColumnFilter', width: 145},
            {headerName: 'BEFORE PART LABELER', field: 'beforePartLabelerFlg', filter: 'agSetColumnFilter', width: 190},
            {headerName: 'AFTER PART LABELER', field: 'afterPartLabelerFlg', filter: 'agSetColumnFilter', width: 180},
            {headerName: 'BEFORE RX/OTC', field: 'beforeRxOtc', filter: 'agSetColumnFilter', width: 150},
            {headerName: 'AFTER PART RX/OTC', field: 'afterRxOtc', filter: 'agSetColumnFilter', width: 170},
            {headerName: 'Eff Date', field: 'effDt', filter: 'agSetColumnFilter', width: 100},
            {headerName: 'Unit Dose', field: 'unitDose', filter: 'agSetColumnFilter', width: 110},
            {headerName: 'AWP', field: 'awp', filter: 'agSetColumnFilter', width: 80},
            {headerName: 'Before GPI', field: 'beforeGpi', filter: 'agSetColumnFilter', width: 115},
            {headerName: 'After GPI', field: 'afterGpi', filter: 'agSetColumnFilter', width: 110}
        ];
        this.workflowMedispanGridOptions = {
            defaultColDef: this.defaultColDef,
            columnDefs: this.commonColumnDefs,
            pagination: true,
            enableCellTextSelection: true,
            excelStyles: [{
                id: 'excelString',
                dataType: 'String'
            }],
            defaultExcelExportParams: {
                sheetName: undefined
            }
        };
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.commonColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.mainTabName = this.mainTabName;
        this.commonColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.subTabIndex = this.subTabIndex;
        this.commonColumnDefs.find(cd => cd.field === 'ndc').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.columnChangedDebounce.pipe(debounce(() => interval(1))).subscribe((rowNodes) => {
            this.redrawRowNodes();
            this.gridHeaderUpdateEvent.next({});
        });
        this.appService.getInitializationSubject().subscribe((initData) => {
            this.user = initData.get(APP_INITALIZATION.USER);
        });

    }


    getMedispanCurrentWorkflow(superClientIndex: number) {
        this.workflowMedispanGridOptions.suppressNoRowsOverlay= true;
        this.trackerService.getCurrentReport(superClientIndex).pipe(take(1)).subscribe(data => {
            this.workflowMedispanData = data;
            this.workflowMedispanData?.forEach(wmd => {
                wmd.action = CHECK_STATE.NOT_SELECTED;
                wmd.noAction = CHECK_STATE.NOT_SELECTED;
            });
            this.workflowMedispanGridOptions.suppressNoRowsOverlay= false;
            this.workflowMedispanGridOptions.api.showNoRowsOverlay();
            this.isLoadingSpinner.emit(false);

        });
    }

    buildActionColDefs() {
        this.actionColDefs = [
            {
                headerName: 'Line of Business', field: 'businessLob',
                filter: 'agSetColumnFilter', width: 125, pinned: 'right'
            },
            {
                headerName: 'Action', field: 'action',
                hide: !this.hasEditPermission,
                headerComponent: CustomHeaderCheckboxComponent,
                headerComponentParams: {
                    headerUpdateEvent: () => this.gridHeaderUpdateEvent,
                    hasEditPermission: this.hasEditPermission,
                    id: 'header-select-all-action',
                    onCellValueChanged: (params) => this.handleCellValueChanged('action', 'noAction', params)
                },
                cellRenderer: CustomToggleButtonComponent,
                cellRendererParams: {
                    unCheckedIcon: 'check-circle--s',
                    checkedIcon: 'check-circle-f--s',
                    unCheckedClass: 'default-button',
                    checkedClass: 'green-button',
                    hasEditPermission: this.hasEditPermission
                },
                cellEditor: CustomToggleButtonComponent,
                onCellValueChanged: (params) => this.handleCellValueChanged('action', 'noAction', params),
                filter: 'agSetColumnFilter', width: 100, pinned: 'right',
            },
            {
                headerName: 'No Action', field: 'noAction',
                hide: !this.hasEditPermission,
                headerComponent: CustomHeaderCheckboxComponent,
                headerComponentParams: {
                    headerUpdateEvent: () => this.gridHeaderUpdateEvent,
                    hasEditPermission: this.hasEditPermission,
                    id: 'header-select-all-noAction',
                    onCellValueChanged: (params) => this.handleCellValueChanged('noAction', 'action', params)
                },
                cellRenderer: CustomToggleButtonComponent,
                cellEditor: CustomToggleButtonComponent,
                cellRendererParams: {
                    unCheckedIcon: 'close-circle--s',
                    checkedIcon: 'close-circle-f--s',
                    unCheckedClass: 'default-button',
                    checkedClass: 'red-button',
                    hasEditPermission: this.hasEditPermission
                },
                onCellValueChanged: (params) => this.handleCellValueChanged('noAction', 'action', params),
                filter: 'agSetColumnFilter', width: 120, pinned: 'right'
            }
        ];
    }

    handleCellValueChanged(fieldBeingSet: string, fieldBeingUnset: string, params: NewValueParams<any>) {
        this.processCellMutualSelectionChange(fieldBeingSet, fieldBeingUnset, params);
        this.onGridChange(params);
        this.setMoveInWorkflowButtonStatus();
    }

    buildColDefs(index) {
        if (index === 0) {
            this.buildActionColDefs();
            return this.commonColumnDefs.concat(this.actionColDefs);
        } else if (index === 1) {
            return this.commonColumnDefs;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const changedSubTabIndex = changes?.subTabIndex;
        this.disableMoveInWorkflowButton.emit(true);
        if (changedSubTabIndex !== undefined) {
            this.subTabIndex = changedSubTabIndex.currentValue ? changedSubTabIndex.currentValue : 0;
            this.workflowMedispanGridOptions.columnDefs = this.buildColDefs(this.subTabIndex);
            this.fetchWorkflowData(this.subTabIndex);
        } else if (changes.triggerSaveChild !== undefined) {
            this.saveTrackerChanges();
        }

        if (changes?.hasEditPermission?.currentValue) {
            this.workflowMedispanGridOptions?.api?.setColumnDefs(this.buildColDefs(this.subTabIndex));
        }
    }

    fetchWorkflowData(tabIndex) {
        this.workflowMedispanGridOptions.suppressNoRowsOverlay= true;
        this.workflowMedispanData = [];
        if (tabIndex === 0) {
            this.getMedispanCurrentWorkflow(this.appService.getSuperClientContext().id);
            this.workflowMedispanGridOptions.defaultExcelExportParams.sheetName =
                WORKFLOW_TABS.TAB0MEDISPAN.subTabs[0].name;
        }
        if (tabIndex === 1) {
            this.isLoadingSpinner.emit(true);
            this.trackerService.getPreviousReport().pipe(take(1))
                .subscribe(item => {
                    if (item) {
                        this.workflowMedispanData = item;
                    }
                    this.isLoadingSpinner.emit(false);
                    this.workflowMedispanGridOptions.suppressNoRowsOverlay= false;
                    this.workflowMedispanGridOptions.api.showNoRowsOverlay();
                });
            this.workflowMedispanGridOptions.defaultExcelExportParams.sheetName =
                WORKFLOW_TABS.TAB0MEDISPAN.subTabs[1].name;
        }
    }

    displayMsGenId(params) {
        return (params.data.genInd === undefined ? params.data.msgGenInd : params.data.genInd);
    }

    saveTrackerChanges() {
        this.triggerSaveChild = undefined;
        const changedRows: NewValueParams[] =
            this.editedRows.filter(i => i?.data?.action === CHECK_STATE.SELECTED || i?.data?.noAction === CHECK_STATE.SELECTED);
        if (changedRows.length !== 0) {
            const trackerUpdates: TrackerUpdate[] = changedRows.flatMap(i => ({
                // eslint-disable-next-line max-len, @typescript-eslint/naming-convention
                combinedId: Object.keys(i.data.combinedId).length > 1 ? {'Caremark GI Change' : i.data.combinedId['Caremark GI Change']}: i.data.combinedId,
                action: i.data.action === CHECK_STATE.SELECTED,
                noAction: i.data.noAction === CHECK_STATE.SELECTED,
                businessLob: i.data.businessLob
            }));
            this.trackerService.startWorkflow(trackerUpdates, this.appService.getSuperClientContext().id).subscribe(() => {
                const actionCount = trackerUpdates.filter(i => i.action).length;
                const noActionCount = trackerUpdates.filter(i => i.noAction).length;

                this.actionNoActionCountForBannerMessage.emit({actionCount, noActionCount});
                this.removeChangedStatusRows(changedRows);
                this.workflowMedispanGridOptions.api.setRowData(this.workflowMedispanData);
                this.editedRows = [];
                this.gridHeaderUpdateEvent.next({});
                if (actionCount > 0) {
                    this.workflowService.messageProvider.next(
                        this.workflowService.buildWorkflowMessage(WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE, RECORD_STATUS.IN_RESEARCH));
                    this.workflowService.adjustStatusCount(RECORD_STATUS.IN_RESEARCH, RECORD_STATUS.DEFAULT, actionCount);
                }
                if (noActionCount > 0) {
                    this.workflowService.messageProvider.next(
                        this.workflowService.buildWorkflowMessage(WORKFLOW_MESSAGE_ACTION.STATUS_CHANGE, RECORD_STATUS.NO_ACTION));
                    this.workflowService.adjustStatusCount(RECORD_STATUS.NO_ACTION, RECORD_STATUS.DEFAULT, noActionCount);
                }

            });
        }
    }

    removeChangedStatusRows(changedRows: NewValueParams[]) {
        changedRows.forEach(changedRow => {
            this.workflowMedispanData.filter((workflowData, i) => {
                if (changedRow.data.ndc === workflowData.ndc &&
                    changedRow.data.businessLob === workflowData.businessLob &&
                    changedRow.data.updateType === workflowData.updateType &&
                    changedRow.data.weekDate === workflowData.weekDate
                ) {
                    this.workflowMedispanData.splice(i, 1);
                }
            });
        });
    }

    onGridChange(newValueParams: NewValueParams) {
        const filteredEditRows = this.editedRows.filter(i => i.data.combinedId !== newValueParams.data.combinedId);
        filteredEditRows.push(newValueParams);
        this.editedRows = filteredEditRows;
    }

    processCellMutualSelectionChange(fieldBeingSet: string, fieldBeingUnset: string, e: NewValueParams) {
        e.data[fieldBeingSet] = e.newValue;
        if (this.isSelected(e.newValue) && (e.newValue === e.data[fieldBeingUnset])) {
            e.data[fieldBeingUnset] = this.isSelected(e.newValue) ? CHECK_STATE.NOT_SELECTED : CHECK_STATE.SELECTED;
            if (this.isRowDisplayedOnPage(e)) {
                e.api.redrawRows({rowNodes: [e.node]});
                this.nodeToRedraw.push(e.node as RowNode);
            }
            this.columnChangedDebounce.next(this.nodeToRedraw);
        }
    }

    isSelected(value: string) {
        return value === CHECK_STATE.SELECTED;
    }

    isRowDisplayedOnPage(nvp: NewValueParams) {
        if (nvp.node.displayed) {
            const currentPage = nvp.api.paginationGetCurrentPage();
            const pageSize = nvp.api.paginationGetPageSize();
            for (let i = (currentPage * pageSize); i <= ((currentPage + 1) * pageSize); i++) {
                if (nvp.api.getDisplayedRowAtIndex(i)?.id === nvp.node.id) {
                    return true;
                }
            }
            return false;
        }
    }

    redrawRowNodes() {
        this.workflowMedispanGridOptions.api.redrawRows({rowNodes: this.nodeToRedraw});
        this.nodeToRedraw = [];
    }

    setMoveInWorkflowButtonStatus() {
        if (this.editedRows.find(item => item.newValue === CHECK_STATE.SELECTED)) {
            this.disableMoveInWorkflowButton.emit(false);
        } else {
            this.disableMoveInWorkflowButton.emit(true);
        }
    }

    suppressTabKey(params: SuppressKeyboardEventParams) {
        if (params.event.key === 'Tab') {
            this.workflowMedispanGridOptions.api.stopEditing();
        }
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    onFirstDataRendered() {
        const columnIds: string[] = ['weekDate', 'ndc', 'gpi'];
        this.workflowMedispanGridOptions.columnApi.autoSizeColumns(columnIds, true);
    }

    onCellKeyDown(keydown: CellKeyDownEvent) {
        const keyboardEvent = keydown.event as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key === 'Enter' || key === ' ') {
            if (keydown.colDef.headerName === 'Action' || keydown.colDef.headerName === 'No Action') {
                const rowData = this.workflowMedispanData[keydown.rowIndex];
                const newValue = keydown.value === CHECK_STATE.SELECTED ? CHECK_STATE.NOT_SELECTED : CHECK_STATE.SELECTED;
                rowData[keydown.colDef.field] = newValue;
                this.workflowMedispanGridOptions.api.refreshCells(
                    {rowNodes: [this.workflowMedispanGridOptions.api.getDisplayedRowAtIndex(keydown.rowIndex)]});
                const fieldBeingSet = keydown.colDef.field;
                const fieldBeingUnset = fieldBeingSet === 'action' ? 'noAction' : 'action';
                const newValueParam = {...keydown, newValue: newValue} as unknown as NewValueParams<any>;
                this.handleCellValueChanged(fieldBeingSet, fieldBeingUnset, newValueParam);
                this.gridHeaderUpdateEvent.next({});
            }
        }
    }
}
