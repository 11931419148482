<div id="saveGridViewDiv">
    <div>
        <div id="adHocButton">
            <button type="button"
                    class="cvs-btn-ghost"
                    *ngIf="hasEditPermission"
                    [disabled]="adHocButtonDisabled"
                    (click)="onClickCreateAdHocUpdate()">Create Ad Hoc Update{{adhocUpdateCount}}</button>

        </div>
        <div class="attachment-results">{{getAttachmentResultMessage()}}</div>
    </div>
    <div class="view-button-area">
        <button class="cvs-btn-text-only" (click)="saveGridView();restoreGridView()">
            <mat-icon id="save-grid-view" svgIcon="eye-open--s"></mat-icon>
            Save View
        </button>
        <button class="cvs-btn-text-only" id="resetButton" (click)="resetDefaultView()">
            <mat-icon svgIcon="override--s"></mat-icon>
            Reset to CVS Default View
        </button>
    </div>
</div>
<app-cvs-ag-grid
    #drugListGrid
    id="drug-list"
    style="width: 100%"
    class="ag-theme-balham"
    [rowData]="formularyDrugAttachmentResponse"
    [gridOptions]="drugListGridOptions"
    [modules]="modules"
    [getRowClass]="drugListGridOptions.getRowClass"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [detailCellRendererParams]="detailCellRendererParams"
    [masterDetail]="masterDetail"
    (columnResized)="setDebounceColumnWidth($event)"
    (columnGroupOpened)="setColumnGroupOpened($event)"
    (columnMoved)="setColumnMoved($event)"
    (columnVisible)="setColumnVisible($event)"
    (selectionChanged)="onSelectionChange($event)"
    [popupParent]="popupParent"
    (rowGroupOpened)="onRowGroupOpened($event)"
    [disableCvsExport]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
    [style.height]="gridHeight">
</app-cvs-ag-grid>

<ng-template #adhocModalRef>
    <app-create-adhoc-update-modal
            [createAdHocUpdateModalRef]="createAdHocUpdateModalRef"
            [convertAttachmentNDCTypeToGPI]="convertAttachmentNDCTypeToGPI"
            [convertAttachmentGPITypeToNDC]="convertAttachmentGPITypeToNDC"
            [selectedGpi]="selectedGpi"
            [selectedLobs]="selectedLobs"
            [selectedGPIAttachmentPayload]="selectedGPIAttachmentPayload"
            [selectedGapiAttachmentCount]="selectedGapiAttachmentCount">
    </app-create-adhoc-update-modal>
</ng-template>
<ng-template #adhocDuplicateGridModalRef>
    <app-grid-display-modal>
    </app-grid-display-modal>
</ng-template>
