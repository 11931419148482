// eslint-disable-next-line no-shadow
export enum PRODUCT_OVERVIEW_SOURCE {
    DRUG_SEARCH,
    FORMULARY_SEARCH ,
    WORKFLOW_MEDISPAN ,
    WORKFLOW_RESEARCH_AND_REQUIREMENTS,
    WORKFLOW_OPERATIONS_AND_IMPLEMENTATION,
    RECOMMENDATION_FORM,
    WORKFLOW_REGULATORY_TRACKING,
    WORKFLOW_NO_ACTION,
    WORKFLOW_ARCHIVE,
    GPI_TO_NDC_ADHOC
}
