/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {RecordService} from '../../service/record/record.service';
import {TrackingDate} from '../../model/TrackingDate';
import {RECORD_STATUS} from 'src/app/enum/RecordStatus';
import {take} from 'rxjs/operators';

export interface RegulatoryTrackingFields {
    pendingUmDate: Date;
    frcDate: Date;
    pendingPTDate: Date;
    pendingSubmissionDate: Date;
    notes: string;
}

@Component({
    selector: 'app-tracking-dates',
    templateUrl: './tracking-dates.component.html',
    styleUrls: ['./tracking-dates.component.scss']
})
export class TrackingDatesComponent implements OnInit, OnChanges {
    @Input() medispanTrackerResponse!: MedispanTrackerResponse;
    recommendationData!: TrackingDate;
    trackingData!: RegulatoryTrackingFields;

    constructor(private recordService: RecordService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.recordService.getApprovalDateAndNotes(changes.medispanTrackerResponse?.currentValue.encryptedRecordId).pipe(take(1))
            .subscribe((data) => {
                this.populateRecommendationTrackingData(data);
                this.populateTrackingData(data);
            });
    }

    ngOnInit(): void {
    }

    populateRecommendationTrackingData(trackingDates: TrackingDate[]) {
        this.recommendationData = this.findTrackingDate(trackingDates, RECORD_STATUS.READY_FOR_DISCUSSION);
    }

    populateTrackingData(trackingDates: TrackingDate[]) {
        const trackerValue = {
            pendingUmDate: undefined,
            frcDate: undefined,
            pendingPTDate: undefined,
            pendingSubmissionDate: undefined,
            notes: ''
        };

        this.setTrackingValue(trackerValue, 'pendingUmDate', this.findTrackingDate(trackingDates, RECORD_STATUS.PENDING_UM));
        this.setTrackingValue(trackerValue, 'frcDate', this.findTrackingDate(trackingDates,
            RECORD_STATUS.PENDING_FORMULARY));
        this.setTrackingValue(trackerValue, 'pendingPTDate', this.findTrackingDate(trackingDates, RECORD_STATUS.PENDING_PT));
        // eslint-disable-next-line max-len
        this.setTrackingValue(trackerValue, 'pendingSubmissionDate', this.findTrackingDate(trackingDates, RECORD_STATUS.PENDING_SUBMISSION));
        this.trackingData = trackerValue;
    }

    findTrackingDate(trackingDates: TrackingDate[], status: RECORD_STATUS): TrackingDate {
        return trackingDates.find(trackingDate => trackingDate.statusId === status);
    }

    setTrackingValue(trackerValue: RegulatoryTrackingFields, dateKey: string, trackingDate: TrackingDate) {
        trackingDate?.approvalDt && (trackerValue[dateKey] = trackingDate?.approvalDt);
        trackingDate?.approvalNotes && (trackerValue.notes += trackingDate?.approvalNotes + '\n');
    }

}
