import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {
    ColDef,
    ColumnGroupOpenedEvent, ColumnMovedEvent,
    ColumnResizedEvent, ColumnVisibleEvent,
    FirstDataRenderedEvent,
    GridOptions,
    GridReadyEvent, RowGroupOpenedEvent, GridSizeChangedEvent, RowDataUpdatedEvent, RowSelectedEvent
} from '@ag-grid-community/core';
import {LicenseManager} from '@ag-grid-enterprise/core';
import {ENVIRONMENT} from '../../../environments/environment';
import {CellKeyDownEvent} from '@ag-grid-community/core';


LicenseManager.setLicenseKey(ENVIRONMENT.agGridLicenseKey);
@Component({
    selector: 'app-cvs-ag-grid',
    templateUrl: './cvs-ag-grid.component.html',
    styleUrls: ['./cvs-ag-grid.component.scss']
})
export class CvsAgGridComponent implements OnInit, AfterViewInit {
    @Input() paginationEnabled = false;
    @Input() paginationPageSize: number;
    @Input() customRowStyle: any;
    @Input() defaultColDef: any;
    @Input() columnDefs: ColDef[];
    @Input() masterDetail: boolean;
    @Input() detailCellRendererParams;
    @Input() groupDefaultExpanded;
    @Input() modules;
    @Input() detailRowAutoHeight: any;
    @Input() domLayout: any;
    @Input() suppressPaginationPanel: boolean;
    @Input() rowData: any;
    @Input() detectRowChange = false;
    @Input() disableCvsExport!: boolean;
    @Input() suppressLoadingOverlay!: boolean;

    @Output() firstDataRendered: EventEmitter<any> = new EventEmitter<any>();

    @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowGroupOpened: EventEmitter<RowGroupOpenedEvent> = new EventEmitter<RowGroupOpenedEvent>();
    @Output() rowValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() gridSizeChanged: EventEmitter<any> = new EventEmitter<any>();

    @Output() rowDataUpdated: EventEmitter<any> = new EventEmitter<any>();


    @Output() doubleClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() rowDragEnd: EventEmitter<any> = new EventEmitter<any>();
    @Output() cellValueChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() columnResized: EventEmitter<ColumnResizedEvent> = new EventEmitter<ColumnResizedEvent>();
    @Output() columnGroupOpened: EventEmitter<ColumnGroupOpenedEvent> = new EventEmitter<ColumnGroupOpenedEvent>();
    @Output() columnMoved: EventEmitter<ColumnMovedEvent> = new EventEmitter<ColumnMovedEvent>();
    @Output() columnVisible: EventEmitter<ColumnVisibleEvent> = new EventEmitter<ColumnVisibleEvent>();
    @Output() rowSelected: EventEmitter<RowSelectedEvent> = new EventEmitter<RowSelectedEvent>();
    @Output() cellKeyDown: EventEmitter<CellKeyDownEvent> = new EventEmitter<CellKeyDownEvent>();

    @Input() gridOptions: GridOptions;
    @Input() getRowClass;
    @Input() overlayLoadingTemplate;
    @Input() overlayNoRowsTemplate;
    @Input() sidebarValue = false;
    @Input() suppressMenuHide = true;
    @Input() popupParent;
    @Input() getContextMenuItems;
    @Input() context;
    @Input() customFrameworkComponents;
    cacheBlockElements: any;
    private agGridRows;
    private disabledSelection = false;
    private exportFilename: string;
    private columnKeys: string[] = [];
    private fileNameSubject = new BehaviorSubject<string>('');
    private columnKeysSubject = new BehaviorSubject<string[]>([]);


    constructor() {
        this.gridOptions = {} as GridOptions;
        this.fileNameSubject.subscribe(s => {
            this.exportFilename = s;
        });
        this.columnKeysSubject.subscribe(keys => {
            this.columnKeys = keys;
        });
    }

    ngOnInit() {
    }

    disableSelection = () => this.disabledSelection;

    ngAfterViewInit(): void {
    }

    doubleClick(event: any) {
        this.doubleClicked.emit(event);
    }

    onFirstDataRendered(params: FirstDataRenderedEvent) {
        params.api?.sizeColumnsToFit();
        this.firstDataRendered.emit(params);
    }

    onGridReady(params: GridReadyEvent) {
        params.api?.sizeColumnsToFit();
    }

    onPaginationChanged(event) {
    }

    onSelectionChanged(event) {
        this.selectionChanged.emit(event);
    }

    onRowGroupOpened(event) {
        this.rowGroupOpened.emit(event);
    }

    onRowValueChanged(event) {
        this.rowValueChanged.emit(event);
    }

    onRowDataChanged($event: any) {
    }

    onRowDragEnd(event: any) {
    }

    onFilterChange(event: any) {
    }

    isGridPopulated() {
        return this.agGridRows && this.agGridRows.length > 0;
    }

    clearAllFilters() {
        this.gridOptions.api.setFilterModel(null);
    }

    onCellValueChanged($event: any) {
        this.cellValueChanged.emit($event);
        if (this.detectRowChange) {
            this.onRowValueChanged($event);
        }
    }

    onColumnResized($event: ColumnResizedEvent) {
        this.columnResized.emit($event);
    }

    onColumnGroupOpened($event: ColumnGroupOpenedEvent){
        this.columnGroupOpened.emit($event);
    }

    onColumnMoved($event: ColumnMovedEvent) {
        this.columnMoved.emit($event);
    }

    onGridSizeChanged($event: GridSizeChangedEvent) {
        this.gridSizeChanged.emit($event);
    }

    onColumnVisible($event: ColumnVisibleEvent) {
        this.columnVisible.emit($event);
    }

    onRowSelected($event: RowSelectedEvent){
        this.rowSelected.emit($event);
    }

    onRowDataUpdated($event: RowDataUpdatedEvent) {
        this.rowDataUpdated.emit($event);
    }

    onCellKeyDown(event: CellKeyDownEvent) {
        this.cellKeyDown.emit(event);
    }
}
