import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import {RecommendationService} from '../../../../service/recommendation/recommendation.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {MedispanTrackerResponse} from '../../../../model/MedispanTrackerResponse';
import {
    ATTACHMENT_LEVEL,
    ATTACHMENT_MONY,
    RX_OTC,
    MED_D,
    ATTACHMENT, BUSINESS_LOB_CD, BUSINESS_LOB_DESC_CD
} from '../../../../default-values/DefaultValues';
import {BUSINESS_LOB} from '../../../../enum/BusinessLob';
import {Subscription} from 'rxjs';
import {ClientProductDrugCoverage} from '../../../../model/ClientProductDrugCoverage';
import {map, take, tap} from 'rxjs/operators';
import {ProductDrugCoverageRequirements} from '../../../../model/ProductDrugCoverageRequirements';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {RecommendationsFormService} from '../../../../service/recommendations-form/recommendations-form.service';

@Component({
    selector: 'app-product-drug-coverage-form-entry',
    templateUrl: './product-drug-coverage-form-entry.component.html',
    styleUrls: ['./product-drug-coverage-form-entry.component.scss'],
})
export class ProductDrugCoverageFormEntryComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('attachmentMony') attachmentMony: QueryList<MatSelect>;
    @ViewChild('attachmentMonyCheckbox') attachmentMonyCheckbox;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() blob: string;
    @Input() productDrugCoverage: any;
    @Output() isPdcFormSaved: EventEmitter<boolean> = new EventEmitter(false);
    productDrugCoverageFormGroups: { [key: string]: FormGroup } = {};
    monyIndeterminates= new Map<string, boolean>();
    savedProductDrugCoverages: ProductDrugCoverageRequirements[] = [];
    productDrugCoverages: ClientProductDrugCoverage[] = [];
    attachments = ATTACHMENT;
    attachmentTypes = [];
    attachmentMonyList = ATTACHMENT_MONY;
    attachmentLevels: [key: string, value: string][] = [];
    attachmentLevelsKey: {[key: string]: [key: string, value: string][]} = {};
    rxOtcList = RX_OTC;
    medDList = MED_D;
    checked = true;
    panelOpenState = true;
    rejectionMessage: string;
    mappedStatus = new Map<string, string>();
    private subscriptions: Subscription[] = [];

    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected readonly BUSINESS_LOB = BUSINESS_LOB;

    constructor(private _recommendationService: RecommendationService, private fb: FormBuilder,
        private bannerService: CVSBannerService, private recommendationsFormService: RecommendationsFormService) {}

    ngOnInit(): void {
        this.recommendationsFormService.setMedispanTrackerResponse(this.medispanTrackerResponse);
        this.recommendationsFormService.setAttachmentType();
        this.attachmentTypes = this.recommendationsFormService.getAttachmentType();
        this.productDrugCoverages = this._recommendationService.getActiveProductDrugCoveragesByLOBTab(
            BUSINESS_LOB_DESC_CD.get(this.blob?.toString()));
        this.createFormGroups();
        this.getSavedProductDrugCoverages();
    }

    ngAfterViewInit() {
        this.scrollToFormulary(
            `product-drug-coverage-${this.productDrugCoverage?.productDrugCoverageName}-${this.productDrugCoverage?.rank}`);
    }

    scrollToFormulary(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }

    getSavedProductDrugCoverages() {
        this.subscriptions.push(this._recommendationService
            .getProductDrugCoverageRequirements(this.medispanTrackerResponse.encryptedRecordId,
                this.medispanTrackerResponse.businessLobCd)
            .pipe(
                map(data => data?.map(d => ({
                    encryptedRecordId: d.encryptedRecordId,
                    productDrugCoverageName: d.productDrugCoverageName,
                    businessLobCd: d.businessLobCd,
                    statusCd: d.statusCd,
                    attachment: d.attachment,
                    attachmentType: d.attachmentType,
                    attachmentLevel: d.attachmentLevel,
                    attachmentMony: d.attachmentMony,
                    attachmentRxOtcCd: d.attachmentRxOtcCd,
                    attachmentMedDCd: d.attachmentMedDCd,
                    clientProductDrugCoverageId: d.clientProductDrugCoverageId,
                    applyDefaultAttachment: d.applyDefaultAttachment,
                    pdNotes: d.pdNotes,
                    rejectionMessage: d.rejectionMessage
                }))),
                tap(pdcs => {
                    this.savedProductDrugCoverages.push(...pdcs);
                })
            )
            .subscribe({
                next: () => {
                    this.setFormGroup();
                }, error: () => {},
            }));
    }

    setFormGroup() {
        this.savedProductDrugCoverages.forEach(pdc => {
            if (this.productDrugCoverages.filter(item => item.id === Number(pdc.clientProductDrugCoverageId)).length === 1) {
                this.loadSavedProductDrugCoverages(pdc);
            }
        });
    }

    loadSavedProductDrugCoverages(pdc: ProductDrugCoverageRequirements) {
        let mony: string[] = [];
        const formArrayFormGroup = this.productDrugCoverageFormGroups[pdc.clientProductDrugCoverageId];
        if (formArrayFormGroup) {
            this.enableTextAreas({value: pdc.attachment} as MatSelectChange,
                pdc.clientProductDrugCoverageId.toString());
            formArrayFormGroup.controls.attachment.patchValue(pdc.attachment, {emitEvent: false});
            formArrayFormGroup.controls.attachmentType.patchValue(pdc.attachmentType, {emitEvent: false});
            this.buildAttachmentLevels(pdc.attachmentType, pdc.clientProductDrugCoverageId, false);
            if (pdc.attachmentMony) {
                mony = pdc.attachmentMony.split('');
                if (mony.length === this.attachmentMonyList.length) {
                    formArrayFormGroup.controls.attachmentMonyCheckbox.setValue(true);
                }
            }
            formArrayFormGroup.controls.attachmentMonyDD.patchValue([...mony.map(m => m)],
                {emitEvent: false});
            formArrayFormGroup.controls.monyCode.patchValue('N/A', {emitEvent: false});
            formArrayFormGroup.controls.pdNotes.patchValue(pdc.pdNotes, {emitEvent: false});
            formArrayFormGroup.controls.attachmentLevel.patchValue(pdc.attachmentLevel, {emitEvent: false});
            formArrayFormGroup.controls.customClaimRejectMessage.patchValue(pdc.rejectionMessage, {emitEvent: false});
            formArrayFormGroup.controls.coverageMiscRxOtc.patchValue(pdc.attachmentRxOtcCd, {emitEvent: false});
            formArrayFormGroup.controls.coverageMiscMedD.patchValue(pdc.attachmentMedDCd, {emitEvent: false});
            if (pdc.applyDefaultAttachment==='Yes' || !pdc.applyDefaultAttachment) {
                formArrayFormGroup.controls.applyDefaultCheckBox.patchValue(true, {emitEvent: false});
                this.setDefaultSelection(pdc.clientProductDrugCoverageId);
            } else {
                formArrayFormGroup.controls.applyDefaultCheckBox.patchValue(false, {emitEvent: false});
                this.setDefaultSelection(pdc.clientProductDrugCoverageId);
            }
            this.onAttachmentMonyDDChange(pdc.clientProductDrugCoverageId);
            this.mappedStatus[pdc.clientProductDrugCoverageId.toString()] = pdc.statusCd;
        }
        formArrayFormGroup.updateValueAndValidity();
    }

    createFormGroups() {
        if (Object.keys(this.productDrugCoverageFormGroups).length === 0) {
            this.productDrugCoverages.forEach(pd => {
                this.productDrugCoverageFormGroups[pd.id] = this.fb.group({
                    [`attachment`]: new FormControl({value: this.attachments[0].code, disabled: false}),
                    [`customClaimRejectMessage`]: new FormControl({value: '', disabled: true}),
                    [`pdNotes`]: new FormControl({value: '', disabled: true}),
                    [`attachmentType`]: new FormControl({value: this.attachmentTypes[0], disabled: true},
                        {validators: Validators.required}),
                    [`attachmentLevel`]: new FormControl({value: '', disabled: true}, {validators: Validators.required}),
                    [`attachmentMonyDD`]: new FormControl({value: '', disabled: true}, {validators: Validators.required}),
                    [`monyCode`]: new FormControl({value: '', disabled: true}),
                    [`coverageMiscRxOtc`]: new FormControl({value: '3', disabled: true}),
                    [`coverageMiscMedD`]: new FormControl({value: '3', disabled: true}),
                    [`applyDefaultCheckBox`]: new FormControl({value: true, disabled: true}),
                    [`attachmentMonyCheckbox`]: new FormControl(false)
                });
                this.mappedStatus[pd.id] = '0';
                this.monyIndeterminates[pd.id] = false;
                this.buildAttachmentLevels(this.attachmentTypes[0], pd.id, true);
                this.productDrugCoverageFormGroups[pd.id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
                this.productDrugCoverageFormGroups[pd.id].controls.attachmentMonyDD
                    .patchValue([...this.attachmentMonyList.map(m => m.code)], {emitEvent: false});
                this.productDrugCoverageFormGroups[pd.id].controls.attachmentMonyDD.disable();
            });
        }
    }

    buildAttachmentLevels(value: any, id, isReset: boolean) {
        const controls = this.productDrugCoverageFormGroups[id].controls;
        this.recommendationsFormService.buildAttachmentLevels(value, controls, isReset);
        this.attachmentLevelsKey[id.toString()] = this.recommendationsFormService.getAttachmentLevels();
    }

    toggleAllSelection(id) {
        if (this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox?.value) {
            this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
                {emitEvent: false});
        } else {
            this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.patchValue([], {emitEvent: false});
        }
    }

    onAttachmentMonyDDChange(id) {
        const attachmentMonyDDFCLength = this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.value.length;
        this.monyIndeterminates[id] =
            (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        if ((!this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox &&
                attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            (this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox &&
                attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox.setValue(false);
        }
        if (attachmentMonyDDFCLength === this.attachmentMonyList.length) {
            this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox.setValue(true);
        }
    }

    setDefaultSelection(id) {
        if (this.productDrugCoverageFormGroups[id].controls.applyDefaultCheckBox.value === true) {
            this.clearAllSelections(id);
        } else {
            this.enableAll(id);
        }
        this.productDrugCoverageFormGroups[id].updateValueAndValidity();
    }

    enableAll(id) {
        this.productDrugCoverageFormGroups[id].controls['attachmentMonyDD'].markAsUntouched();
        this.productDrugCoverageFormGroups[id].controls['attachmentType'].markAsUntouched();
        this.productDrugCoverageFormGroups[id].controls['attachmentLevel'].markAsUntouched();
        this.productDrugCoverageFormGroups[id].controls.attachmentType.enable();
        this.productDrugCoverageFormGroups[id].controls.attachmentLevel.enable();
        this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.enable();
        this.productDrugCoverageFormGroups[id].controls.coverageMiscRxOtc.enable();
        this.productDrugCoverageFormGroups[id].controls.coverageMiscMedD.enable();
    }

    enableTextAreas(attachment: MatSelectChange, id) {
        if (attachment.value === ATTACHMENT[0].code) {
            this.productDrugCoverageFormGroups[id].controls.applyDefaultCheckBox.setValue(true);
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage.patchValue('', {emitEvent: false});
            this.productDrugCoverageFormGroups[id].controls.pdNotes.patchValue('', {emitEvent: false});
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage.disable();
            this.productDrugCoverageFormGroups[id].controls.pdNotes.disable();
            this.productDrugCoverageFormGroups[id].controls.applyDefaultCheckBox.disable();
            this.clearAllSelections(id);
        } else if (attachment.value === ATTACHMENT[1].code) {
            const rejectionMessage = this.productDrugCoverages?.filter(item => item.id === id)[0]?.rejectionMessage;
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage
                .setValue(rejectionMessage, {emitEvent: false});
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage.enable();
            this.productDrugCoverageFormGroups[id].controls.pdNotes.enable();
            this.productDrugCoverageFormGroups[id].controls.applyDefaultCheckBox.enable();
        } else {
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage.patchValue('', {emitEvent: false});
            this.productDrugCoverageFormGroups[id].controls.customClaimRejectMessage.disable();
            this.productDrugCoverageFormGroups[id].controls.pdNotes.enable();
            this.productDrugCoverageFormGroups[id].controls.applyDefaultCheckBox.enable();
        }
        this.productDrugCoverageFormGroups[id].updateValueAndValidity();
    }

    clearAllSelections(id) {
        this.productDrugCoverageFormGroups[id].controls.attachmentType.patchValue(this.attachmentTypes[0], {emitEvent: false});
        this.buildAttachmentLevels(this.attachmentTypes[0], id, true);
        this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
        this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
            {emitEvent: false});
        this.productDrugCoverageFormGroups[id].controls.coverageMiscRxOtc.patchValue('3', {emitEvent: false});
        this.productDrugCoverageFormGroups[id].controls.coverageMiscMedD.patchValue('3', {emitEvent: false});
        this.productDrugCoverageFormGroups[id].controls.attachmentType.disable();
        this.productDrugCoverageFormGroups[id].controls.attachmentLevel.disable();
        this.productDrugCoverageFormGroups[id].controls.attachmentMonyDD.disable();
        this.productDrugCoverageFormGroups[id].controls.monyCode.disable();
        this.productDrugCoverageFormGroups[id].controls.attachmentMonyCheckbox.disable();
        this.productDrugCoverageFormGroups[id].controls.coverageMiscRxOtc.disable();
        this.productDrugCoverageFormGroups[id].controls.coverageMiscMedD.disable();
    }

    saveProductDrugCoverages() {
        const productDrugsForSave = this.buildProductDrugCoverages();
        if (productDrugsForSave.length > 0) {
            this.subscriptions.push(this._recommendationService.saveProductDrugCoverageRequirements(productDrugsForSave)
                .pipe(take(1))
                .subscribe({
                    next: () => this.onSaveSuccess(productDrugsForSave),
                    error: () => this.onSaveError(),
                }));
        }
    }

    buildProductDrugCoverages(): ProductDrugCoverageRequirements[]{
        const builtPdcs: ProductDrugCoverageRequirements[] = [];
        Object.keys(this.productDrugCoverageFormGroups).forEach((key) => {
            const pdcGroup = this.productDrugCoverageFormGroups[key];
            const pdc: ProductDrugCoverageRequirements = {
                encryptedRecordId: null,
                productDrugCoverageName: null,
                businessLobCd: null,
                statusCd: '0',
                attachment: null,
                attachmentType: null,
                attachmentLevel: null,
                attachmentMony: null,
                attachmentRxOtcCd: null,
                attachmentMedDCd: null,
                clientProductDrugCoverageId: null,
                applyDefaultAttachment: null,
                pdNotes: null,
                rejectionMessage: null
            };
            pdc.encryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
            pdc.businessLobCd = this.medispanTrackerResponse.businessLobCd;
            pdc.clientProductDrugCoverageId = key;
            pdc.attachment = pdcGroup.controls.attachment.value;
            if (pdcGroup.controls.attachment.value === ATTACHMENT[1].code) {
                pdc.pdNotes = pdcGroup.controls.pdNotes.value;
                pdc.rejectionMessage = pdcGroup.controls.customClaimRejectMessage.value;
            } else if (pdcGroup.controls.attachment.value === ATTACHMENT[2].code) {
                pdc.pdNotes = pdcGroup.controls.pdNotes.value;
            }
            if (pdcGroup.controls.attachment.value !== ATTACHMENT[0].code) {
                pdc.attachmentType = pdcGroup.controls.attachmentType.value;
                pdc.attachmentLevel = pdcGroup.controls.attachmentLevel.value;
                pdc.attachmentMony = pdcGroup.controls.attachmentMonyDD.value === 'N/A' ? null
                    : pdcGroup.controls.attachmentType.value === ATTACHMENT_LEVEL.GPI ?
                        // eslint-disable-next-line max-len
                        Array.isArray(pdcGroup.controls.attachmentMonyDD.value) ? pdcGroup.controls.attachmentMonyDD.value?.join('') : pdcGroup.controls.attachmentMonyDD.value : null;
                pdc.attachmentRxOtcCd = pdcGroup.controls.coverageMiscRxOtc.value;
                pdc.attachmentMedDCd = this.medispanTrackerResponse.businessLobCd === BUSINESS_LOB_CD.MEDICARE ?
                    pdcGroup.controls.coverageMiscMedD.value: null;
                pdc.applyDefaultAttachment = pdcGroup.controls.applyDefaultCheckBox.value ? 'Yes' : 'N/A';
            }
            if ((pdcGroup.controls.attachmentType.value === ATTACHMENT_LEVEL.GPI &&
                pdcGroup.controls['attachmentMonyDD'].errors?.required) || pdcGroup.controls['attachmentType'].errors?.required
                || pdcGroup.controls['attachmentLevel'].errors?.required) {
                pdcGroup.controls['attachmentMonyDD'].markAsTouched();
                pdcGroup.controls['attachmentType'].markAsTouched();
                pdcGroup.controls['attachmentLevel'].markAsTouched();
            }
            if (pdc.attachment === ATTACHMENT[0].code) {
                pdc.statusCd = '0';
            } else if (pdc.applyDefaultAttachment !== 'Yes' && (!pdc.attachmentType ||
                    (pdc.attachmentType === ATTACHMENT_LEVEL.GPI && (pdc.attachmentMony === null || pdc.attachmentMony === '')))) {
                pdc.statusCd = '1';
            } else {
                pdc.statusCd = '2';
            }
            builtPdcs.push(pdc);
        });
        return builtPdcs;
    }

    onSaveError() {
        this.bannerService.sendAlert({
            bannerType: CVSBannerType.Error,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again',
            outletId: '#recommendationsBanner',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveProductDrugCoverages();
                    }
                }
            ]
        });
    }

    onSaveSuccess(pdcs: ProductDrugCoverageRequirements[]) {
        if (pdcs.filter(f => f.statusCd==='1').length===0) {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Success,
                headline: 'Success',
                body: 'Changes have been saved',
                outletId: '#recommendationsBanner'
            });
        } else {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Warning,
                headline: 'Saved with incomplete fields',
                body: 'Form was saved, but there are incomplete fields.',
                outletId: '#recommendationsBanner'
            });
        }
        pdcs.forEach(pdc =>{
            this.mappedStatus[pdc.clientProductDrugCoverageId] = pdc.statusCd;
        });
        this.isPdcFormSaved.emit(true);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub=> sub.unsubscribe());
    }
}
