import { RECORD_STATUS } from '../enum/RecordStatus';

export interface IWorkflowTab {
    name?: string;
    link?: string;
    status?: RECORD_STATUS;
    chipValue?: string;
    subTabs?: IWorkflowTab[];
    subTabIndex?: number;
    mainTab?: string;
}

export const WORKFLOW_TABS: { [tabGroup: string]: IWorkflowTab } = {
    TAB0MEDISPAN: {
        name: 'Medispan',
        link: '/workflow/medispan',
        subTabs: [
            {
                name: 'Current Report',
                link: '/workflow/medispan/currentReport',
                status: RECORD_STATUS.DEFAULT,
                subTabIndex: 0,
            },
            {
                name: 'Previous Reports',
                link: '/workflow/medispan/previousReport',
                status: RECORD_STATUS.DEFAULT,
                subTabIndex: 1,
            },
        ],
    },
    TAB1RESEARCH_AND_REQUIREMENTS: {
        name: 'Research & Requirements',
        link: '/workflow/research',
        subTabs: [
            {
                name: 'In Research',
                link: '/workflow/research/inResearch',
                chipValue: 'inResearchDataCount',
                status: RECORD_STATUS.IN_RESEARCH,
                subTabIndex: 0,
            },
            {
                name: 'Ready for Clinical Requirement',
                link: '/workflow/research/readyForClinicalR',
                chipValue: 'clinicalRecommendationDataCount',
                status: RECORD_STATUS.READY_FOR_CLINICAL_REQUIREMENT,
                subTabIndex: 1,
            },
            {
                name: 'Ready for Discussion',
                link: '/workflow/research/readyForDiscussion',
                chipValue: 'readyForDiscussionDataCount',
                status: RECORD_STATUS.READY_FOR_DISCUSSION,
                subTabIndex: 2,
            },
        ],
    },
    TAB2APPROVAL_TRACKING: {
        name: 'Approval Tracking',
        link: '/workflow/approvalTracking',
        subTabs: [
            {
                name: 'Pending UM',
                chipValue: 'pendingUMDataCount',
                link: '/workflow/approvalTracking/pendingUM',
                status: RECORD_STATUS.PENDING_UM,
                subTabIndex: 0,
            },
            {
                name: 'Pending Formulary',
                chipValue: 'pendingFormularyDataCount',
                link: '/workflow/approvalTracking/pendingFormulary',
                status: RECORD_STATUS.PENDING_FORMULARY,
                subTabIndex: 1,
            },
            {
                name: 'Pending P&T',
                chipValue: 'pendingPTDataCount',
                link: '/workflow/approvalTracking/pendingPT',
                status: RECORD_STATUS.PENDING_PT,
                subTabIndex: 2,
            },
            {
                name: 'Pending Submission',
                chipValue: 'pendingSubmissionDataCount',
                link: '/workflow/approvalTracking/pendingSubmission',
                status: RECORD_STATUS.PENDING_SUBMISSION,
                subTabIndex: 3,
            },
        ],
    },
    TAB3OPERATIONS_AND_IMPLEMENTATION: {
        name: 'Operations & Implementation',
        link: '/workflow/operations',
        subTabs: [
            {
                name: 'Operations Checkpoint',
                chipValue: 'operationsCheckpointDataCount',
                link: '/workflow/operations/operationsCheckpoint',
                status: RECORD_STATUS.OPERATIONS_CHECKPOINT,
                subTabIndex: 0,
            },
            {
                name: 'Pending Coding',
                chipValue: 'pendingCodingDataCount',
                link: '/workflow/operations/pendingCoding',
                status: RECORD_STATUS.PENDING_CODING,
                subTabIndex: 1,
            },
            {
                name: 'Pending Testing',
                chipValue: 'pendingTestingDataCount',
                link: '/workflow/operations/pendingTesting',
                status: RECORD_STATUS.PENDING_TESTING,
                subTabIndex: 2,
            },
            {
                name: 'Pending Approval',
                chipValue: 'pendingApprovalDataCount',
                link: '/workflow/operations/pendingApproval',
                status: RECORD_STATUS.PENDING_APPROVAL,
                subTabIndex: 3,
            },
        ],
    },
    TAB4NO_ACTION: {
        name: 'No Action',
        link: '/workflow/noAction',
        status: RECORD_STATUS.NO_ACTION,
    },
    TAB5ARCHIVE: {
        name: 'Archive',
        link: '/workflow/archive',
        status: RECORD_STATUS.ARCHIVE,
    },
};
