<div class="search-results">{{searchResultMessage}}</div>
<app-cvs-ag-grid
    #drugUniverseListGrid
    id="drug-universe-list"
    style="width: 100%"
    class="ag-theme-balham"
    [rowData]="universalDrugData"
    [gridOptions]="drugUniverseListGridOptions"
    [modules]="modules"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
    [popupParent]="popupParent"
    [disableCvsExport]="true"
    [style.height]="gridHeight">
</app-cvs-ag-grid>

