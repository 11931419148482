import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {ENVIRONMENT} from '../../environments/environment';

import {
    Observable,
    Subject,
} from 'rxjs';

import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {GpiLikeProduct} from '../../model/GpiLikeProduct';
import {Formulary} from '../../model/Formulary';
import {ClientUmProgram} from '../../model/ClientUmProgram';
import {ClientProductDrugCoverage} from '../../model/ClientProductDrugCoverage';
import {FormularyRequirements} from '../../model/FormularyRequirements';
import {ProductDrugCoverageRequirements} from '../../model/ProductDrugCoverageRequirements';
import {StepTherapyRequirements} from '../../model/StepTherapyRequirements';
import {PriorAuthorizationRequirements} from '../../model/PriorAuthorizationRequirements';
import {QuantityLimitRequirements} from '../../model/QuantityLimitRequirements';
import {
    GPILikeTargetDataForPost
} from '../../product-overview/recommendations/recommendations-formulary-requirements/recommendations-formulary-requirements.component';

@Injectable({
    providedIn: 'root'
})
export class RecommendationService {
    medispanTrackerResponse: MedispanTrackerResponse;
    NA = 'N/A';
    NO_CHANGE = 'No Change';
    private loadRecommendationsComponentGridDataSubject: Subject<string> = new Subject<string>();
    private loadProductDrugCoverageRecommendationsComponentGridDataSubject: Subject<string> = new Subject<string>();
    private loadUmProgramRecommendationsComponentGridDataSubject: Subject<string> = new Subject<string>();
    private listOfActiveFormulariesSubject: Map<string, Formulary[]> = new Map<string, Formulary[]>();
    private listOfActiveUmProgramsSubject:  Map<string, ClientUmProgram[]> = new Map<string, ClientUmProgram[]>();
    private listOfActiveProductDrugCoveragesSubject:  Map<string, ClientProductDrugCoverage[]> =
        new Map<string, ClientProductDrugCoverage[]>();
    constructor(private cvsHttpClient: CVSHttpClient) {}

    // eslint-disable-next-line max-len
    copyRequirementsToLikeGpiProducts(sourceRecordId: string, targetRecordIds: string[], gpiLikeProductTargetData: GPILikeTargetDataForPost[]) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/copyRequirementsLikeGpiProducts`,{
            sourceRecordId, targetRecordIds, gpiLikeProductTargetData
        });
    }

    copyRequirements(superclientIndex: number,sourceRecordId: string, sourceFormularyId: string, targetFormularyIds: string[] ) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/copyRequirements/${superclientIndex}`,{
            sourceRecordId, sourceFormularyId, targetFormularyIds
        });
    }

    setMedispanTrackerResponse(medispanTrackerResponse: MedispanTrackerResponse): void {
        this.medispanTrackerResponse = medispanTrackerResponse;
    }

    getLoadRecommendationsComponentGridDataObservable(): Observable<any> {
        return this.loadRecommendationsComponentGridDataSubject;
    }

    newGetLoadRecommendationsComponentGridDataEvent(event) {
        this.loadRecommendationsComponentGridDataSubject.next(event);
    }

    getLoadProductDrugCoverageRecommendationsComponentGridDataObservable(): Observable<any> {
        return this.loadProductDrugCoverageRecommendationsComponentGridDataSubject;
    }

    newGetLoadProductDrugCoverageRecommendationsComponentGridDataEvent(event) {
        this.loadProductDrugCoverageRecommendationsComponentGridDataSubject.next(event);
    }

    getLoadUmProgramRecommendationsComponentGridDataObservable(): Observable<any> {
        return this.loadUmProgramRecommendationsComponentGridDataSubject;
    }

    newGetLoadUmProgramRecommendationsComponentGridDataEvent(event) {
        this.loadUmProgramRecommendationsComponentGridDataSubject.next(event);
    }

    setNAOrZerosToUndefinedOnRecommendationChanges(rc) {
        Object.keys(rc).forEach(key => {
            if (rc[key] === this.NA || rc[key] === this.NO_CHANGE) {
                rc[key] = undefined;
            }
        });
        return rc;
    }

    getGpiLikeProducts(superclientIndex: number,encryptedRecordId: string, lob: string, gpi: string): Observable<GpiLikeProduct[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/gpiLikeProducts/${superclientIndex}/${lob}/${gpi}/${encryptedRecordId}`);
    }

    getFormulariesForRequirementsTabWithActiveRequirementRules(superclientIndex: number): Observable<Formulary[]>{
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/formulariesForWorkflowRequirements/${superclientIndex}`);
    }

    setActiveFormulariesByLOBTab(blob: string, formularies: Formulary[]){
        if (blob) {
            this.listOfActiveFormulariesSubject[blob] = formularies;
        }
    }

    getActiveFormulariesByLOBTab(blob: string) {
        if (blob) {
            return this.listOfActiveFormulariesSubject[blob];
        }
    }

    setActiveUmProgramsByLOBTab(blob: string, umPrograms: ClientUmProgram[]){
        if (blob) {
            this.listOfActiveUmProgramsSubject[blob] = umPrograms;
        }
    }

    getActiveUmProgramsByLOBTab(blob: string) {
        if (blob) {
            return this.listOfActiveUmProgramsSubject[blob];
        }
    }

    setActiveProductDrugCoveragesByLOBTab(blob: string, productDrugCoverages: ClientProductDrugCoverage[]){
        if (blob) {
            this.listOfActiveProductDrugCoveragesSubject[blob] = productDrugCoverages;
        }
    }

    getActiveProductDrugCoveragesByLOBTab(blob: string) {
        if (blob) {
            return this.listOfActiveProductDrugCoveragesSubject[blob];
        }
    }

    getActiveUmProgram(superclientIndex: number): Observable<ClientUmProgram[]>{
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getActiveUmProgram/${superclientIndex}`);
    }

    getDistinctRequirementFormulariesByBusinessLobs(bLob: string , allFormularyList: Formulary[]) {
        const formularyList = [];
        allFormularyList?.forEach(formulary => {
            formulary.linesOfBusiness?.forEach(formularyLob => {
                if (formularyLob.code === bLob) {
                    formularyList.push(formulary);
                }
            });
        });

        return formularyList;
    }


    getActiveProductDrugCoverage(superclientIndex: number): Observable<ClientProductDrugCoverage[]>{
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getActiveProductDrugCoverage/${superclientIndex}`);
    }

    saveFormularyRequirements(formularyRequirements: FormularyRequirements[]): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/saveFormularyRequirements`, formularyRequirements);
    }

    getFormularyRequirements(encryptedRecordId: string, businessLobCd: string): Observable<FormularyRequirements[]>{
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getFormularyRequirements/${encryptedRecordId}/${businessLobCd}`);
    }

    saveProductDrugCoverageRequirements(productDrugCoverageRequirements: ProductDrugCoverageRequirements[]): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/saveProductDrugCoverageRequirements`, productDrugCoverageRequirements);
    }

    getProductDrugCoverageRequirements(encryptedRecordId: string, businessLobCd: string): Observable<ProductDrugCoverageRequirements[]>{
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getProductDrugCoverageRequirements/${encryptedRecordId}/${businessLobCd}`);
    }

    saveStepTherapyRequirements(stepTherapyRequirements: StepTherapyRequirements[]): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/saveStepTherapyRequirements`, stepTherapyRequirements);
    }

    getStepTherapyRequirements(encryptedRecordId: string, businessLobCd: string): Observable<StepTherapyRequirements[]> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getStepTherapyRequirements/${encryptedRecordId}/${businessLobCd}`);
    }

    savePriorAuthorizationRequirements(priorAuthorizationRequirements: PriorAuthorizationRequirements[]): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/savePriorAuthorizationRequirements`, priorAuthorizationRequirements);
    }

    getPriorAuthorizationRequirements(encryptedRecordId: string, businessLobCd: string): Observable<PriorAuthorizationRequirements[]>{
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getPriorAuthorizationRequirements/${encryptedRecordId}/${businessLobCd}`);
    }

    saveQuantityLimitRequirements(quantityLimitRequirements: QuantityLimitRequirements[]): Observable<any> {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}recommendations/saveQuantityLimitRequirements`, quantityLimitRequirements);
    }

    getQuantityLimitRequirements(encryptedRecordId: string, businessLobCd: string): Observable<QuantityLimitRequirements[]>{
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}recommendations/getQuantityLimitRequirements/${encryptedRecordId}/${businessLobCd}`);
    }
}
