<ng-container>
    <form [formGroup]="umProgramForm" (ngSubmit)="saveUmProgram()">
        <div id="umProgramModalErrorBanner"></div>
        <div class="program-form">
            <p>All fields marked with an asterisk (*) are required.</p>
            <mat-form-field>
                <mat-label>UM Program Type</mat-label>
                <mat-select pdsMatOptionKeyboardFocus formControlName="type">
                    <mat-option *ngFor="let programType of programTypes" [value]="programType.code">
                        {{programType.description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="umProgramForm.controls.type.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Select a UM Program Type
                </mat-error>
            </mat-form-field>

            <mat-form-field  [ngClass]="{'form-warning' :umProgramForm.controls.name.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="UM Program Name *"
                        maxlength="30"
                        formControlName="name">
                </app-smart-input>
                <mat-error *ngIf="umProgramForm.controls.name.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a UM Program Name
                </mat-error>
                <mat-error *ngIf="umProgramForm.controls.name.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{umProgramForm.controls.name.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field [ngClass]="{'form-warning' :umProgramForm.controls.description.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="UM Program Description *"
                        maxlength="50"
                        formControlName="description">
                </app-smart-input>
                <mat-error *ngIf="umProgramForm.controls.description.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a UM Program Description
                </mat-error>
                <mat-error *ngIf="umProgramForm.controls.description.errors?.pastedValueTruncated"  class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{umProgramForm.controls.description.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>View Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="viewPicker">
                    <input matStartDate formControlName="viewStartDate">
                    <input matEndDate formControlName="viewEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="vdr1DateRangePicker" matSuffix [for]="viewPicker">
                    <mat-icon id="vdr1DateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #viewPicker></mat-date-range-picker>
                <mat-error *ngIf="umProgramForm.controls.viewStartDate.errors || umProgramForm.controls.viewEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field class="date-padding">
                <mat-label>Effective Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="effectiveStartDate">
                    <input matEndDate formControlName="effectiveEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="edr1DateRangePicker" matSuffix [for]="picker">
                    <mat-icon id="edr1DateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="umProgramForm.controls.effectiveStartDate.errors || umProgramForm.controls.effectiveEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="rejection-padding">
                <mat-label>Default Custom Rejection Message</mat-label>
                <textarea
                        matInput
                        cdkTextareaAutosize
                        (input)="onRejectionMessageUmChanges()"
                        [cdkAutosizeMinRows]="3"
                        [cdkAutosizeMaxRows]="6"
                        formControlName="rejectionMessage"
                        (focusout)="stopWarning()"
                ></textarea>
                <mat-hint *ngIf="!hasTruncRejectMessageError" class="mat-hint-rejection">(optional)</mat-hint>
                <mat-hint *ngIf="hasTruncRejectMessageError" class="warning-error-rejection-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{umProgramForm.controls['rejectionMessage'].value?.length}} characters
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="form-buttons">
            <button class="pds-btn-white" (click)="onModalCancel()" type="reset">Cancel</button>
            <button *ngIf="!isAnUpdate" class="pds-btn-primary" type="submit">Create UM Program</button>
            <button *ngIf="isAnUpdate == true" class="pds-btn-primary" type="submit">Update UM Program</button>
        </div>
    </form>
</ng-container>