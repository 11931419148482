<div height="50%">
    <mat-label class="label">Select status</mat-label>
    <span class="adhoc-group">
        <mat-radio-group class="next-status-radio-group"
                         (change)="setValueObject('statusId', $event.value)">
            <mat-radio-button *ngFor="let option of adhocRadioOptions"
                              [checked]="selectedStatus.status === option.status"
                              class="workflow-options"
                              [value]="option.status">{{option.description}}</mat-radio-button>
        </mat-radio-group>
    </span>

    <div class="adhoc-group" *ngIf="superClientLobs.length>1">
        <mat-form-field class="select-width">
            <mat-label>Line of Business</mat-label>
            <mat-select multiple [formControl]="businessLob" #adhocBLOBMultiSelect>
                <mat-checkbox class="checkbox-margin" #adhocBLOBCheckBox (change)="toggleAllSelection()"
                              [indeterminate]="indeterminate">Select All
                </mat-checkbox>
                <mat-option
                        *ngFor="let lob of superClientLobs"
                        [value]="lob.code"
                        (onSelectionChange)="setValueObjectBusinessLob(lob.code, $event.source.selected)">
                    {{lob.description}}
                </mat-option>
            </mat-select>
            <mat-error aria-label="error">
                <mat-icon svgIcon="error-f--s" matPrefix></mat-icon>
                Select 1 or more Line of Business
            </mat-error>
            <mat-hint>Select 1 or more Line of Business</mat-hint>
        </mat-form-field>
    </div>

    <div class="margin-top-20"
         *ngIf="(convertAttachmentNDCTypeToGPI && !convertAttachmentGPITypeToNDC) || (!convertAttachmentNDCTypeToGPI && convertAttachmentGPITypeToNDC && selectedGapiAttachmentCount===1)">
        <mat-label class="label">Convert Attachment Type</mat-label>
        <mat-radio-group class="pds-radio-group--vertical" (change)="radioButtonChange($event)">
            <mat-radio-button [checked]="true" value="N">Do not convert</mat-radio-button>
            <span class="button-tooltip-group">
                <mat-radio-button [checked]="false"
                                  value="Y">{{ convertAttachmentNDCTypeToGPI ? 'Convert NDC(s) to GPI' : 'Convert GPI to NDC'}}</mat-radio-button>
                <button style="margin-left: -25px;"
                        mat-icon-button
                        aria-label="Information"
                        [cvsTooltip]="toolTipMessage"
                        [placement]="'bottom'"
                        [showArrow]="true"
                        [smallTooltip]="true">
                    <mat-icon svgIcon="info-circle--s" role="img"></mat-icon>
                </button>
            </span>
        </mat-radio-group>
    </div>
    <p class="info-paragraph"
       *ngIf="(convertAttachmentNDCTypeToGPI && convertAttachmentGPITypeToNDC) || selectedGapiAttachmentCount>1">
        <mat-icon class="info-icon" svgIcon="info-circle--s" role="img"></mat-icon>
        To convert Attachment Type, select 1 or more NDCs or only 1 GPI
    </p>
    <app-convert-gpi-to-ndc-list
            *ngIf="displayGpiToNdcList"
            [selectedGpi]="selectedGpi"
            [selectedGPIAttachmentPayload]="selectedGPIAttachmentPayload">
    </app-convert-gpi-to-ndc-list>

    <div>
        <mat-label id="noteLabel">Notes</mat-label>
        <textarea
            #textAreaElement
            type="text"
            class="adHocNotes"
            (change)="setValueObject('notes', textAreaElement.value)"></textarea>
    </div>
</div>
