<app-cvs-ag-grid
    #workflowResearchAndRecGrid
    id="workflowResearchAndRecGrid"
    style="width: 100%;"
    class="ag-theme-balham"
    [gridOptions]="workflowResearchAndRecGridOptions"
    [modules]="modules"
    [rowData]="workflowResearchAndRecommendationTabData"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    (selectionChanged)="onSelectionChanged($event)"
    [popupParent]="popupParent"
    [disableCvsExport]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
    [style.height]="gridHeight"
>
</app-cvs-ag-grid>
