<div id="successBanner"></div>
<div class="app-admin-container">
    <h1>App Administration</h1>
    <h2>Super Client Management</h2>
    <p>Manage and add super clients below:</p>
    <button class="pds-btn-primary" (click)="openDialog(superClientFormRef)">Add Super Client</button>
    <div class="grid-container">
        <app-cvs-ag-grid id="CreateSuperClientGrid"
                         class="app-admin-grid"
                         [gridOptions]="superClientGridOptions"
                         [modules]="modules"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [rowData]="superClientRowData"
                         [getContextMenuItems]="getContextMenuItems"
        >
        </app-cvs-ag-grid>
    </div>
    <h2>Refresh Line of Business</h2>
    <p>Automatically refreshes on Saturday at 10:00 PM CT</p>
    <button class="pds-btn-ghost">Refresh Line of Business</button>
</div>
<ng-template #superClientFormRef>
    <app-super-client-modal (closeEvent)="closeModal($event)"></app-super-client-modal>
</ng-template>