import {MenuItemDef, ProcessRowParams} from '@ag-grid-community/core';
import {KabobMenuActionTitlesEnum} from './KabobMenuActionTitlesEnum';

interface ProductDrugRequirementsKabobActions {
    MOVE_UP: MenuItemDef;
    MOVE_DOWN: MenuItemDef;
    MOVE_RANK: MenuItemDef;
    EDIT: MenuItemDef;
    // DELETE: MenuItemDef;
}

interface FormularyRequirementsKabobActions {
    EDIT: MenuItemDef;
    DELETE: MenuItemDef;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const getUMProgramActions = (params: ProcessRowParams, rank): ProductDrugRequirementsKabobActions => ({
    MOVE_UP: {
        name: KabobMenuActionTitlesEnum.MOVE_UP,
        action: () => {
            params.context?.this.moveRankUp();
        },
    },
    MOVE_DOWN: {
        name: KabobMenuActionTitlesEnum.MOVE_DOWN,
        action: () => {
            params.context?.this.moveRankDown();
        },
    },
    MOVE_RANK: {
        name: KabobMenuActionTitlesEnum.MOVE_RANK,
        action: () => {
            params.context?.this.showMoveRank(rank);
        },
    },
    EDIT: {
        name: KabobMenuActionTitlesEnum.EDIT,
        action: () => {
            params.context?.this.openUpdateUmProgramModal(params.context.this.umProgramFormRef);
        },
    },
    // DELETE: {
    //     name: KabobMenuActionTitlesEnum.DELETE,
    //     action: () => {
    //         params.context?.this.showDeleteUmProgramModal(params.context.this.umProgramState);
    //     },
    // }
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const getProductDrugRequirementsActions = (params: ProcessRowParams, rank): ProductDrugRequirementsKabobActions => ({
    MOVE_UP: {
        name: KabobMenuActionTitlesEnum.MOVE_UP,
        action: () => {
            params.context?.this.moveRankUp();
        },
    },
    MOVE_DOWN: {
        name: KabobMenuActionTitlesEnum.MOVE_DOWN,
        action: () => {
            params.context?.this.moveRankDown();
        },
    },
    MOVE_RANK: {
        name: KabobMenuActionTitlesEnum.MOVE_RANK,
        action: () => {
            params.context?.this.showMoveRank(rank);
        },
    },
    EDIT: {
        name: KabobMenuActionTitlesEnum.EDIT,
        action: () => {
            params.context?.this.openUpdateProductDrugCoverageModal(params.context.this.productDrugCoverageFormRef);
        },
    },
    // DELETE: {
    //     name: KabobMenuActionTitlesEnum.DELETE,
    //     action: () => {
    //         params.context?.this.showDeleteProductDrugCoverageModal(params.context.this.productDrugCoverageState);
    //     },
    // }
});

// eslint-disable-next-line @typescript-eslint/naming-convention
export  const umProgramContextMenuItems = (params: ProcessRowParams): MenuItemDef[] => {
    const productDrugRequirementActions = getUMProgramActions(params, params.node?.rowIndex+1);
    const options =
        [productDrugRequirementActions.MOVE_UP, productDrugRequirementActions.MOVE_DOWN, productDrugRequirementActions.MOVE_RANK,
            productDrugRequirementActions.EDIT,
            // productDrugRequirementActions.DELETE
        ];
    if (params.node.firstChild && params.node.lastChild) {
        options.splice(0, 3);
    } else if (params.node.firstChild) {
        options.splice(0, 1);
    } else if (params.node.lastChild) {
        options.splice(1, 1);
    }
    return options;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const productAndDrugCoverageContextMenuItems = (params: ProcessRowParams): MenuItemDef[] => {
    const productDrugRequirementActions = getProductDrugRequirementsActions(params, params.node?.rowIndex+1);
    const options =
        [productDrugRequirementActions.MOVE_UP, productDrugRequirementActions.MOVE_DOWN, productDrugRequirementActions.MOVE_RANK,
            productDrugRequirementActions.EDIT,
            // productDrugRequirementActions.DELETE
        ];
    if (params.node.firstChild && params.node.lastChild) {
        options.splice(0, 3);
    } else if (params.node.firstChild) {
        options.splice(0, 1);
    } else if (params.node.lastChild) {
        options.splice(1, 1);
    }
    return options;
};
