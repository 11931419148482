import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CVSConfirmationDialogContentComponent, NavListItem} from 'angular-component-library';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {MatDialog} from '@angular/material/dialog';
import { BUSINESS_LOB } from 'src/app/enum/BusinessLob';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {NavigationEnd, Router} from '@angular/router';
import {Formulary} from '../../../model/Formulary';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {BUSINESS_LOB_DESC_CD, UM_PROGRAM_TYPE} from '../../../default-values/DefaultValues';
import {
    FlatNode,
    NavTree,
    TREE_DATA_FORMULARY,
    TREE_DATA_PRODUCT_DRUG_COVERAGE,
    TREE_DATA_UM_PROGRAM, TREE_LEVELS
} from './TreeData';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';

@Component({
    selector: 'app-recommendation-left-navigation',
    templateUrl: './recommendation-left-navigation.component.html',
    styleUrls: ['./recommendation-left-navigation.component.scss']
})
export class RecommendationLeftNavigationComponent implements OnInit, OnChanges {

    @Input() selectedFormularyNode: Formulary;
    @Input() selectedUmProgramNode: ClientUmProgram;
    @Input() selectedProductDrugCoverageNode: ClientProductDrugCoverage;
    @Input() programIndex: number;
    @Output() selectedFormularyEmitter = new EventEmitter();
    @Output() selectedUmProgramEmitter = new EventEmitter();
    @Output() selectedParentEmitter = new EventEmitter();
    @Output() selectedProductDrugCoverageEmitter = new EventEmitter();
    @Input() lob: string;
    TREE_LEVELS = TREE_LEVELS;
    formularies: Formulary[] = [];
    umPrograms: ClientUmProgram[] = [];
    productDrugCoverages: ClientProductDrugCoverage[] = [];
    formularyTree;
    umProgramTree;
    productDrugCoverageTree;

    content: string;
    childClickIndex = -1;
    toolTipMessage = `Must save requirement changes before navigating to other requirements.`;
    clickedFormulary = '';
    clickedUmProgram = '';
    clickedProductDrugCoverage = '';
    clickedParent = '';
    clickedParentCode: number;
    clickedRank: number;

    constructor(private _recommendationService: RecommendationService, private dialog: MatDialog,
        private router: Router) {
    }
    ngOnInit(): void {
        this.clickedParentCode = this.programIndex;
        this.formularies = this._recommendationService.getActiveFormulariesByLOBTab(BUSINESS_LOB_DESC_CD.get(this.lob?.toString()));
        this.umPrograms = this._recommendationService.getActiveUmProgramsByLOBTab(BUSINESS_LOB_DESC_CD.get(this.lob?.toString()));
        this.productDrugCoverages = this._recommendationService.getActiveProductDrugCoveragesByLOBTab(
            BUSINESS_LOB_DESC_CD.get(this.lob?.toString()));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.content = event.url;
            }
        });
        this.initializeFormularyTree();
        this.initializeUmProgramTree();
        this.initializeProductDrugCoverageTree();
        this.setParentName(this.clickedParentCode);
        if (this.selectedFormularyNode && this.clickedParentCode === TREE_LEVELS.FORMULARY.code) {
            this.clickedFormulary = this.selectedFormularyNode?.formularyNumber;
            this.treeControlFormulary.expandAll();
        } else if (this.selectedUmProgramNode && this.clickedParentCode > TREE_LEVELS.FORMULARY.code
            && this.clickedParentCode < TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code) {
            this.clickedUmProgram = this.selectedUmProgramNode?.umProgramName;
            this.clickedRank = this.selectedUmProgramNode?.rank;
            this.treeControlUmProgram.expandAll();
        } else {
            this.clickedProductDrugCoverage = this.selectedProductDrugCoverageNode?.productDrugCoverageName;
            this.clickedRank = this.selectedProductDrugCoverageNode?.rank;
            this.treeControlProductDrugCoverage.expandAll();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedFormularyNumber) {
            this.childClickIndex = -1;
        }
    }

    initializeFormularyTree() {
        this.formularyTree = TREE_DATA_FORMULARY[0];
        if (this.formularies?.length >= 0) {
            this.formularyTree.name = `Formularies (${this.formularies.length})`;
            this.formularyTree.children = [];
            this.formularies?.forEach((formulary) => {
                this.formularyTree.children.push({
                    name: `${formulary.clientFormularyName.toUpperCase()} ${formulary.formularyNumber}`,
                    link: `formulary-${formulary.formularyNumber}`,
                    formulary: formulary
                });
            });
        }
        this.dataSourceFormulary.data = TREE_DATA_FORMULARY;
    }

    initializeUmProgramTree() {
        this.umProgramTree = TREE_DATA_UM_PROGRAM[0];
        this.umProgramTree.children[0].children = [];
        this.umProgramTree.children[1].children = [];
        this.umProgramTree.children[2].children = [];
        if (this.umPrograms?.length >= 0) {
            this.umPrograms?.forEach((umProgram) => {
                if (umProgram.umProgramTypeCd === UM_PROGRAM_TYPE[0].code) {
                    this.umProgramTree.children[0].children.push({
                        name: `PA ${umProgram.umProgramName}`,
                        link: `pa-${umProgram.umProgramName}-${umProgram.rank}`, umProgram: umProgram
                    });
                } else if (umProgram.umProgramTypeCd === UM_PROGRAM_TYPE[1].code) {
                    this.umProgramTree.children[1].children.push({
                        name: `Step Therapy ${umProgram.umProgramName}`,
                        link: `step-therapy-${umProgram.umProgramName}-${umProgram.rank}`, umProgram: umProgram
                    });
                } else {
                    this.umProgramTree.children[2].children.push({
                        name: `Quantity Limit ${umProgram.umProgramName}`,
                        link: `quantity-limit-${umProgram.umProgramName}-${umProgram.rank}`, umProgram: umProgram
                    });
                }
            });
        }
        this.umProgramTree.children[0].name = `Prior Authorization (${this.umProgramTree.children[0].children.length})`;
        this.umProgramTree.children[1].name = `Step Therapy (${this.umProgramTree.children[1].children.length})`;
        this.umProgramTree.children[2].name = `Quantity Limit (${this.umProgramTree.children[2].children.length})`;
        this.dataSourceUmProgram.data = TREE_DATA_UM_PROGRAM;
    }

    initializeProductDrugCoverageTree() {
        this.productDrugCoverageTree = TREE_DATA_PRODUCT_DRUG_COVERAGE[0];
        if (this.productDrugCoverages?.length >= 0) {
            this.productDrugCoverageTree.name = `Product & Drug Coverage (${this.productDrugCoverages.length})`;
            this.productDrugCoverageTree.children = [];
            this.productDrugCoverages?.forEach((productDrugCoverage) => {
                this.productDrugCoverageTree.children.push({
                    name: `${productDrugCoverage.productDrugCoverageName}`,
                    link: `product-drug-coverage-${productDrugCoverage.productDrugCoverageName}-${productDrugCoverage.rank}`,
                    productDrugCoverage: productDrugCoverage
                });
            });
        }
        this.dataSourceProductDrugCoverage.data = TREE_DATA_PRODUCT_DRUG_COVERAGE;
    }

    selectedFormulary(node: Formulary) {
        this.clickedUmProgram = '';
        this.clickedProductDrugCoverage = '';
        this.selectedFormularyEmitter.emit(node);
        this.clickedFormulary = node.formularyNumber;
        this.clickedParent = this.formularyTree.name;
        this.clickedParentCode = TREE_LEVELS.FORMULARY.code;
        this.selectedParentEmitter.emit(this.clickedParentCode);
    }

    selectedUmProgram(node) {
        this.clickedRank = node.umProgram?.rank;
        this.clickedFormulary = '';
        this.clickedProductDrugCoverage = '';
        this.selectedUmProgramEmitter.emit(node);
        this.clickedUmProgram = node.umProgram?.umProgramName;
        this.updateParentNodeUmProgram(node);
        this.selectedParentEmitter.emit(this.clickedParentCode);
    }

    selectedProductDrugCoverage(node) {
        this.clickedRank = node.productDrugCoverage?.rank;
        this.clickedFormulary = '';
        this.clickedUmProgram = '';
        this.selectedProductDrugCoverageEmitter.emit(node);
        this.clickedProductDrugCoverage = node.productDrugCoverage?.productDrugCoverageName;
        this.clickedParent = this.productDrugCoverageTree.name;
        this.clickedParentCode = TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code;
        this.selectedParentEmitter.emit(this.clickedParentCode);
    }

    updateParentNodeUmProgram(node) {
        if (node.umProgram?.umProgramTypeCd === UM_PROGRAM_TYPE[0].code) {
            this.clickedParentCode = TREE_LEVELS.PRIOR_AUTH.code;
            this.clickedParent = this.umProgramTree.children[0].name;
        } else if (node.umProgram?.umProgramTypeCd === UM_PROGRAM_TYPE[1].code) {
            this.clickedParentCode = TREE_LEVELS.STEP_THERAPY.code;
            this.clickedParent = this.umProgramTree.children[1].name;
        } else {
            this.clickedParentCode = TREE_LEVELS.QUANTITY_LIMIT.code;
            this.clickedParent = this.umProgramTree.children[2].name;
        }
    }

    setParentName(programIndex: number) {
        if (programIndex === TREE_LEVELS.FORMULARY.code) {
            this.clickedParent = this.formularyTree.name;
        } else if (programIndex === TREE_LEVELS.PRIOR_AUTH.code) {
            this.clickedParent = this.umProgramTree.children[0].name;
        } else if (programIndex === TREE_LEVELS.STEP_THERAPY.code) {
            this.clickedParent = this.umProgramTree.children[1].name;
        } else if (programIndex === TREE_LEVELS.QUANTITY_LIMIT.code) {
            this.clickedParent = this.umProgramTree.children[2].name;
        } else {
            this.clickedParent = this.productDrugCoverageTree.name;
        }
    }
    isSubtree = (_: number, node: FlatNode) => node.expandable && node.level > 0;
    isNodeEnd = (_: number, node: FlatNode) => (!node.expandable && node.level < 2);
    isParent = (_: number, node: FlatNode) => node.level === 0;
    private _transformer = (node: NavTree, level: number) => ({
        expandable: !!node.children && node.children.length > 0,
        name: node.name,
        level: level,
        link: node.link,
        formulary: node.formulary,
        umProgram: node.umProgram,
        productDrugCoverage: node.productDrugCoverage
    });
    // eslint-disable-next-line @typescript-eslint/member-ordering
    treeControlFormulary = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );
    // eslint-disable-next-line @typescript-eslint/member-ordering
    treeControlUmProgram = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );
    // eslint-disable-next-line @typescript-eslint/member-ordering
    treeControlProductDrugCoverage = new FlatTreeControl<FlatNode>(
        node => node.level,
        node => node.expandable,
    );
    // eslint-disable-next-line @typescript-eslint/member-ordering
    treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node.level,
        node => node.expandable,
        node => node.children,
    );
    // eslint-disable-next-line @typescript-eslint/member-ordering
    dataSourceFormulary = new MatTreeFlatDataSource(this.treeControlFormulary, this.treeFlattener);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    dataSourceUmProgram = new MatTreeFlatDataSource(this.treeControlUmProgram, this.treeFlattener);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    dataSourceProductDrugCoverage = new MatTreeFlatDataSource(this.treeControlProductDrugCoverage, this.treeFlattener);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected readonly TREE_DATA = TREE_DATA_FORMULARY;
}
