import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {IHeaderAngularComp} from '@ag-grid-community/angular';
import {CellValueChangedEvent, IAfterGuiAttachedParams, IHeaderParams, NewValueParams, SortDirection} from '@ag-grid-community/core';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {CHECK_STATE} from '../custom-toggle-button/custom-toggle-button.component';
import {fromEvent, interval, Subject, Subscription, timer} from 'rxjs';
import {debounce, debounceTime, take, takeLast, takeUntil, tap} from 'rxjs/operators';
import {CVSPopoverButtonService, CVSPopoverParams, Direction} from 'angular-component-library';
import {CVSPopoverRef} from 'angular-component-library/completed/popover-button/popover-ref';
import _ from 'lodash';
import {MatIcon} from '@angular/material/icon';

export interface HeaderComponentParams {
    headerUpdateEvent: () => Subject<any>;
    hasEditPermission: boolean;
    id: string;
    tooltip?: string;
    onCellValueChanged?: (nvp: NewValueParams) => {};
    popoverTemplateRef(): TemplateRef<any>;
};

@Component({
    selector: 'app-custom-header-checkbox',
    templateUrl: './custom-header-checkbox.component.html',
    styleUrls: ['./custom-header-checkbox.component.scss']
})
export class CustomHeaderCheckboxComponent implements IHeaderAngularComp, OnDestroy, AfterViewInit {
    @ViewChild('menuButton', {read: ElementRef}) public menuButton;
    @ViewChild('checkbox') public checkBox: MatCheckbox;
    @ViewChild('popoverIcon', {read: ElementRef}) public popoverIcon: ElementRef;
    params: IHeaderParams & HeaderComponentParams;
    ascSort: boolean;
    descSort: boolean;
    noSort: boolean;
    cellValueChangeDebounce = new Subject();
    popoverRef: CVSPopoverRef;
    private subscriptions: Subscription[] = [];

    constructor(public cvsPopoverButtonService: CVSPopoverButtonService) {
    }

    refresh(params: IHeaderParams): boolean {
        throw new Error('Method not implemented.');
    }

    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error('Method not implemented.');
    }

    agInit(params: IHeaderParams & HeaderComponentParams): void {
        this.params = params;
        this.cellValueChangeDebounce.pipe(debounce(() => interval(15))).subscribe((cell: CellValueChangedEvent) => {
            this.cellValueChanged(cell);
        });
        if (params.headerUpdateEvent) {
            this.subscriptions.push(params.headerUpdateEvent().subscribe(() => {
                this.setHeaderCheckbox(params.column.getId());
            }));
        }
        params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
        params.api.addEventListener('cellValueChanged', this.cellValueChangedDebounce.bind(this));
        this.onSortChanged();
    }

    ngAfterViewInit() {
        if (this.popoverIcon) {
            fromEvent(this.popoverIcon.nativeElement, 'mouseenter').pipe(
                debounceTime(250),
                tap((event) => {
                    this.showPopover(this.params.popoverTemplateRef(), event);
                })
            ).subscribe();
            fromEvent(this.popoverIcon.nativeElement, 'mouseleave').pipe(
                debounceTime(250),
                tap((event) => {
                    this.closePopover();
                })
            ).subscribe();
        }
    }

    getValue(): any {
    }

    onMenuClicked() {
        this.params.showColumnMenu(this.menuButton.nativeElement);
    };

    onSortChanged() {
        this.ascSort = this.descSort = this.noSort = false;
        if (this.params.column.isSortAscending()) {
            this.ascSort = true;
        } else if (this.params.column.isSortDescending()) {
            this.descSort = true;
        } else {
            this.noSort = true;
        }
    }

    onSortRequested(event: MouseEvent) {
        if (this.params.enableSorting) {
            this.params.setSort(this.getNextSort(), event.shiftKey);
        }
    }

    getNextSort(): SortDirection {
        if (this.noSort) {
            return 'asc';
        }
        if (this.ascSort) {
            return 'desc';
        }
        if (this.descSort) {
            return null;
        }
    }

    headerCheckChange(event: MatCheckboxChange) {
        this.params.api.forEachNode(i => {
            if (i.displayed) {
                i.data[this.params.column.getId()] = event.checked ? CHECK_STATE.SELECTED : CHECK_STATE.NOT_SELECTED;
                const newValue = event.checked ? CHECK_STATE.SELECTED : CHECK_STATE.NOT_SELECTED;
                i.setDataValue(this.params.column, newValue, 'cellValueChanged');
                if (this.params.onCellValueChanged) {
                    this.params.onCellValueChanged({data: i.data, newValue, api: this.params.api, node: i} as NewValueParams<any>);
                }
            }
        });
    }

    cellValueChangedDebounce(cell: CellValueChangedEvent) {
        this.cellValueChangeDebounce.next(cell);
    }

    cellValueChanged(cell: CellValueChangedEvent) {
        if (cell.colDef.field === this.params.column.getColDef().field) {
            this.setHeaderCheckbox(cell.colDef.field);
        }
    }

    setHeaderCheckbox(cellField: string) {
        let selectedCount = 0;
        let notSelectedCount = 0;
        this.params.api.forEachNode(i => {
            if (i.displayed) {
                if (i.data[cellField] === CHECK_STATE.SELECTED) {
                    selectedCount++;
                }
                if (i.data[cellField] === CHECK_STATE.NOT_SELECTED) {
                    notSelectedCount++;
                }
            }
        });
        if (this.checkBox) {
            if (!this.checkBox?.indeterminate && selectedCount !== 0 && notSelectedCount !== 0) {
                this.checkBox.indeterminate = true;
            }
            if ((!this.checkBox?.checked || this.checkBox?.indeterminate) && selectedCount !== 0 && notSelectedCount === 0) {
                this.checkBox.indeterminate = false;
                this.checkBox.checked = true;
            }
            if ((this.checkBox?.checked || this.checkBox?.indeterminate) && selectedCount === 0 && notSelectedCount !== 0) {
                this.checkBox.indeterminate = false;
                this.checkBox.checked = false;
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.subscriptions = [];
    }

    closePopover() {
        if (this.popoverRef) {
            timer(500).pipe(takeLast(1)).subscribe(() => {
                this.popoverRef.close();
                this.popoverRef = undefined;
            });
        }
    }

    showPopover(popoverTemplate: TemplateRef<any>, eventOrigin: any) {
        this.popoverRef?.close();
        const adjustedPositionEvent = new MouseEvent('adjustedPositionEvent', {
            clientX: eventOrigin.target.getBoundingClientRect().x + (eventOrigin.target.offsetWidth / 2),
            clientY: eventOrigin.target.getBoundingClientRect().y + (eventOrigin.target.offsetHeight / 2) - 20
        });
        this.popoverRef = this.cvsPopoverButtonService.open({
            origin: adjustedPositionEvent as any,
            content: popoverTemplate,
            direction: Direction.BOTTOM,
            hasBackdrop: false,
            data: {
                hideX: true,
            }
        } as CVSPopoverParams<any>);
    };
}
