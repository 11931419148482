import {
    Component, EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ColDef, GridOptions, MenuItemDef} from '@ag-grid-community/core';
import {KabobCellComponent} from '../../../common/kabob-cell/kabob-cell.component';
import {CustomStateComponent} from '../../../common/custom-state/custom-state.component';
import {
    BUSINESS_LOB_DESC_CD,
    BUSINESS_LOB_NAME, COVERAGE_TYPES, MED_D, RX_OTC
} from '../../../default-values/DefaultValues';
import {MedispanTrackerResponse} from '../../../model/MedispanTrackerResponse';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {Record} from '../../../model/Record';
import {Observable, Subject, Subscription} from 'rxjs';
import {PRODUCT_OVERVIEW_SOURCE} from '../../../enum/ProductOverviewSource';
import {AppService} from '../../../service/app/app.service';
import {UserService} from '../../../service/user/user.service';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {Router} from '@angular/router';
import {RecordService} from '../../../service/record/record.service';
import {
    MultiSelectListItem,
    MultiSelectModalService
} from '../../../common/multi-select-modal/multi-select-modal.component';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {GridDisplayModalService} from '../../../common/grid-display-modal/grid-display-modal.component';
import {BUSINESS_LOB} from '../../../enum/BusinessLob';
import {Formulary} from '../../../model/Formulary';
import {GpiLikeProduct} from '../../../model/GpiLikeProduct';
import {buildTabSelectionChangeEvent, dateFormatter, setGridHeight} from '../../../utility/utility';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MasterDrugResponse} from '../../../model/MasterDrugResponse';
import {take} from 'rxjs/operators';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {TREE_LEVELS} from '../recommendation-left-navigation/TreeData';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';
import {FormularyRequirements} from '../../../model/FormularyRequirements';
import {ClientFormularyRequirementRule} from '../../../model/ClientFormularyRequirementRule';
import {ConfigurationService} from '../../../service/configuration/configuration.service';
import {FormularyFormEntryComponent} from '../recommendations-form/formulary-form-entry/formulary-form-entry.component';
import {ADHOC_UPDATE_TYPES} from '../../../service/tracker-data/tracker-data.service';

export interface RecommendationFormState {
    formulary?: Formulary;
    umProgram?: ClientUmProgram;
    productDrugCoverage?: ClientProductDrugCoverage;
    medispanTrackerResponse?: MedispanTrackerResponse;
    tabs?: (string | number)[];
    drugData?: MasterDrugResponse;
    programIndex?: number;
}

export interface GPILikeTargetDataForPost {
    encryptedRecordId?: string;
    gpi?: string;
    ndc?: string;
}


@Component({
    selector: 'app-recommendations-formulary-requirements',
    templateUrl: './recommendations-formulary-requirements.component.html',
    styleUrls: ['./recommendations-formulary-requirements.component.scss']
})
export class RecommendationsFormularyRequirementsComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('copyPasteModalRef') public copyPasteModalRef: TemplateRef<any>;
    @ViewChild('massCopyGridModalRef') public massCopyGridModalRef: TemplateRef<any>;
    @Output() showRequirementSpinnerEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() mainTabName: string;
    @Input() subTabIndex: number;
    @Input() fromSource;
    @Output() productDetailEmitter: EventEmitter<any> = new EventEmitter<any>();
    ndcBusinessLob;
    recommendationFormState: RecommendationFormState;
    selectedBusinessLobCd;
    selectedBusinessLobName;
    requirementDrugInfo: any[];
    formulariesWithMatchingRequirementRules: Formulary[];
    BUSINESS_LOB = BUSINESS_LOB;
    NA = 'N/A';
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, RichSelectModule];
    activeGridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressSizeToFit: true,
        },
        enableCellTextSelection: true,
        suppressLoadingOverlay: true
    };
    context = {this: this};
    hasEditPermission = false;
    popupParent: HTMLElement | null = document.querySelector('body');
    productRecordDetail!: Record[];
    disableCopyRecommendationsToLikeProducts = true;
    isStatusPastReadyForDiscussion = false;
    tableAction: { icon: string; description: string } = {
        icon: 'paper-edit--s',
        description: 'Edit Requirements'
    };
    subscriptions: Subscription[] = [];
    gridHeight: any = 'auto';
    showRequirementSpinner = true;
    savedFormularyRequirements: FormularyRequirements[] = [];
    rxOtcList = RX_OTC;
    medDList = MED_D;
    formularyRequirementRuleCoverages: ClientFormularyRequirementRule[] = [];
    isAnAdhoc: boolean;
    private readonly PAST_RECOMMENDATION_STATUSES = [PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING,
        PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION, PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE];

    constructor(private _appService: AppService,
        private userService: UserService,
        private _recommendationService: RecommendationService,
        private _router: Router,
        private _recordService: RecordService,
        private _multiSelectModalService: MultiSelectModalService,
        private _bannerService: CVSBannerService,
        public _gridDisplayModalService: GridDisplayModalService,
        private configurationService: ConfigurationService
    ) {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.hasEditPermission = this.userService.hasEditPermission();
        if (changes.medispanTrackerResponse?.currentValue) {
            const trackerValue = changes.medispanTrackerResponse.currentValue;
            this.medispanTrackerResponse = trackerValue;
            this.ndcBusinessLob = this.medispanTrackerResponse.businessLob;
            this._recommendationService.setMedispanTrackerResponse(trackerValue);

            this.subscriptions.push(this._recordService.getProductRecordDetails(
                trackerValue.encryptedRecordId,
                trackerValue.updateType, this._appService.getSuperClientContext().id).pipe(take(1))
                .subscribe({
                    next: (productRecordDetail) => this.productRecordDetail = productRecordDetail,
                    complete: () => {
                        this.onBusinessLobTabSelectionChange(buildTabSelectionChangeEvent(
                            BUSINESS_LOB_DESC_CD.get(trackerValue.businessLob),
                            trackerValue.businessLob));
                        this.productDetailEmitter.emit(this.productRecordDetail);
                    }
                })
            );
        }
        if (changes.fromSource?.currentValue) {
            this.fromSource = changes.fromSource.currentValue;
            this.isStatusPastReadyForDiscussion = this.PAST_RECOMMENDATION_STATUSES.includes(this.fromSource);
            this.tableAction.icon = this.isStatusPastReadyForDiscussion ? 'file-search--s' : 'paper-edit--s';
            this.tableAction.description = this.isStatusPastReadyForDiscussion ? 'View Requirement' : 'Edit Requirement';
        }
    }

    ngOnInit(): void {
        this.isAnAdhoc = this.isAnAdhocUpdateType();
        const index = this._appService.getSuperClientContext().id;
        this.subscriptions.push(this._recommendationService.getLoadRecommendationsComponentGridDataObservable().subscribe((recordId) => {
            this.subscriptions.push(this._recommendationService.getFormulariesForRequirementsTabWithActiveRequirementRules(index)
                .pipe(take(1))
                .subscribe({
                    next: (formularyResponse) => this.formulariesWithMatchingRequirementRules = formularyResponse,
                    complete: () => this.getFormularyByBLOB(this.selectedBusinessLobCd, true, recordId)
                }));
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        const index = this._appService.getSuperClientContext().id;
        this.showRequirementSpinner = true;
        this.requirementDrugInfo = [];
        this.selectedBusinessLobCd = event.tab.ariaLabel;
        this.selectedBusinessLobName = event.tab.textLabel as BUSINESS_LOB;
        this.buildColDefs();
        if (this.formulariesWithMatchingRequirementRules === undefined) {
            // eslint-disable-next-line max-len
            this.subscriptions.push(this._recommendationService.getFormulariesForRequirementsTabWithActiveRequirementRules(index)
                .pipe(take(1))
                .subscribe({
                    next: (formularyResponse) => this.formulariesWithMatchingRequirementRules = formularyResponse,
                    complete: () => this.getFormularyByBLOB(this.selectedBusinessLobCd, false)
                }));
        } else {
            this.getFormularyByBLOB(this.selectedBusinessLobCd, false);
        }
        this.activeGridOptions.suppressContextMenu = !this.allowEditing();
    }

    getFormularyByBLOB(businessLobCd: string, backNavigation: boolean, encryptedRecordId?: string) {
        let formularies: Formulary[] = [];
        let record: Record;
        formularies = this._recommendationService
            .getDistinctRequirementFormulariesByBusinessLobs(businessLobCd, this.formulariesWithMatchingRequirementRules);
        this._recommendationService.setActiveFormulariesByLOBTab(businessLobCd, formularies);
        if (!backNavigation) {
            record = this.productRecordDetail?.find(product => product.businessLobCd === businessLobCd);
            if (record) {
                encryptedRecordId = record.encryptedRecordId;
            }
        }
        if (encryptedRecordId) {
            this.subscriptions.push(this._recommendationService.getFormularyRequirements(encryptedRecordId, businessLobCd)
                .pipe(take(1))
                .subscribe({
                    next: (formularyRecommendation) => {
                        this.disableCopyRecommendationsToLikeProducts =
                            formularyRecommendation ? (formularyRecommendation?.length === 0 ||
                                this.isAnAdhoc) : true;
                        this.savedFormularyRequirements = formularyRecommendation;
                        this.getActiveClientFormularyRequirementRules(businessLobCd, formularies);
                    }
                }));
        } else {
            this.buildFormularyRequirementInfo(formularies);
        }
        if (this.ndcBusinessLob === this.selectedBusinessLobName) {
            this.buildRecommendationFormState(formularies[0]);
        }
        this.showRequirementSpinner = false;
        this.showRequirementSpinnerEmitter.emit(true);
    }

    buildFormularyRequirementInfo(formularies: Formulary[]) {
        this.requirementDrugInfo = [];
        let formularyRule = null;
        formularies.forEach(formulary => {
            let formularyRequirements: FormularyRequirements;
            if (this.savedFormularyRequirements) {
                const requirements = this.savedFormularyRequirements
                    ?.filter((f) => f.formularyId === formulary.formularyNumber && f.clientFormularyReqRuleId !== null);
                if (requirements.length > 0) {
                    formularyRequirements = requirements[0];
                }
            }
            if (formularyRequirements !== undefined) {
                formularyRule = this.formularyRequirementRuleCoverages
                    .filter(f => f.id === Number(formularyRequirements.clientFormularyReqRuleId))[0];
            }
            this.requirementDrugInfo.push({
                formularyNumber: formulary.formularyNumber,
                clientFormularyName: formulary.clientFormularyName,
                encryptedRecordId: this.medispanTrackerResponse.encryptedRecordId,
                noChangeFlag: 'N',
                statusCd: formularyRequirements === undefined ? '0' : formularyRequirements.statusCd,
                attachmentType: formularyRequirements === undefined ? 'N/A' : formularyRequirements.attachmentType === undefined ?
                    'N/A' : formularyRequirements.attachmentType,
                // eslint-disable-next-line max-len
                attachmentLevel: formularyRequirements === undefined ? 'N/A' : formularyRequirements.attachmentLevel === undefined ?
                    'N/A' : '(' + formularyRequirements.attachmentType + ' ' + formularyRequirements.attachmentLevel.length + ') ' +
                    formularyRequirements.attachmentLevel,
                monyCode: formularyRequirements === undefined ? 'N/A' : formularyRequirements.attachmentMony === undefined ?
                    'N/A' : formularyRequirements.attachmentMony,
                coverageMiscRxOtc: formularyRequirements === undefined ? 'N/A' : this.rxOtcList
                    .filter(rxOtc => rxOtc.code === formularyRequirements.attachmentRxOtcCd)[0].description,
                coverageMiscMedD: formularyRequirements === undefined ? 'N/A' : formularyRequirements.businessLobCd !== 'BM' ? 'N/A'
                    : this.medDList.filter(rxOtc => rxOtc.code ===
                        formularyRequirements.attachmentMedDCd)[0].description,
                formularyCoverage: formularyRequirements === undefined ? 'N/A' : formularyRule.formularyRequirementName,
                formularyCoverageCategory: formularyRequirements === undefined ?
                    'N/A' : this.getFormularyCoverageCategory(formularyRule.formularyCoverageCategoryCd),
                formularyRequirementDescription: formularyRequirements === undefined ? 'N/A' : formularyRule.formularyDescription,
                negativeChange: formularyRequirements === undefined ? 'N/A' : formularyRequirements.negativeChange,
                rejectionMessaging: formularyRequirements === undefined ? 'N/A' : formularyRequirements.rejectionMessage,
            });

        });
        this.gridHeight = setGridHeight(this.requirementDrugInfo);
    }

    isEditable(params: { data: [] }, fieldName: string, validValues: string[]): boolean {
        return this.allowEditing() && validValues.includes(params.data[fieldName]);
    }

    allowEditing(): boolean {
        return (this.ndcBusinessLob === this.selectedBusinessLobName) && this.userService.hasEditPermission();
    }

    buildColDefs() {
        let colDef: ColDef[];
        {
            const allowEditing = this.allowEditing();
            if (this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
                colDef = [
                    {
                        headerName: 'Action',
                        field: 'kabob',
                        cellRenderer: KabobCellComponent,
                        editable: false,
                        width: 65,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'},
                        pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Formulary Name',
                        field: 'clientFormularyName',
                        filter: 'agSetColumnFilter',
                        width: 160,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyNumber',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Requirement State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC',
                        field: 'coverageMiscRxOtc',
                        filter: 'agSetColumnFilter',
                        width: 160
                    },
                    {
                        headerName: 'Attachment Med D',
                        field: 'coverageMiscMedD',
                        filter: 'agSetColumnFilter',
                        width: 160
                    },
                    {
                        headerName: 'Formulary Coverage',
                        field: 'formularyCoverage',
                        filter: 'agSetColumnFilter',
                        width: 165
                    },
                    {
                        headerName: 'Formulary Coverage Category', field: 'formularyCoverageCategory',
                        filter: 'agSetColumnFilter', width: 220
                    },
                    {
                        headerName: 'Formulary Requirement Description', field: 'formularyRequirementDescription',
                        filter: 'agSetColumnFilter', width: 260
                    },
                    {
                        headerName: 'Negative Change', field: 'negativeChange', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Rejection Messaging',
                        field: 'rejectionMessaging',
                        filter: 'agSetColumnFilter',
                        width: 175
                    },
                ];
            }

            if (this.selectedBusinessLobName === BUSINESS_LOB.COMMERCIAL ||
                this.selectedBusinessLobName === BUSINESS_LOB.EXCHANGE ||
                this.selectedBusinessLobName === BUSINESS_LOB.MEDICAID) {
                colDef = [
                    {
                        headerName: 'Action',
                        field: 'kabob',
                        cellRenderer: KabobCellComponent,
                        editable: false,
                        width: 65,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'},
                        pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Formulary Name',
                        field: 'clientFormularyName',
                        filter: 'agSetColumnFilter',
                        width: 160,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyNumber',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Requirement State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC',
                        field: 'coverageMiscRxOtc',
                        filter: 'agSetColumnFilter',
                        width: 160
                    },
                    {
                        headerName: 'Formulary Coverage',
                        field: 'formularyCoverage',
                        filter: 'agSetColumnFilter',
                        width: 165
                    },
                    {
                        headerName: 'Formulary Coverage Category', field: 'formularyCoverageCategory',
                        filter: 'agSetColumnFilter', width: 220
                    },
                    {
                        headerName: 'Formulary Requirement Description', field: 'formularyRequirementDescription',
                        filter: 'agSetColumnFilter', width: 260
                    },
                    {
                        headerName: 'Negative Change', field: 'negativeChange', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Rejection Messaging',
                        field: 'rejectionMessaging',
                        filter: 'agSetColumnFilter',
                        width: 175
                    },
                ];
            }
        }

        if (this.activeGridOptions.api) {
            this.activeGridOptions.api.setColumnDefs(colDef);
        } else {
            this.activeGridOptions.columnDefs = colDef;
        }
    }

    getContextMenuItems(params): MenuItemDef[] {
        const name = params.context.this.isStatusPastReadyForDiscussion ? 'View Requirement' : 'Edit Requirement';
        const options: MenuItemDef[] = [
            {
                name: name,
                action: () => {
                    params.context.this._router.navigate(['/recommendationsForm'],
                        {state: params.context.this.recommendationFormState});
                }
            }];

        if (params.api.getRenderedNodes().length > 1 && params.node.data.hasRecommendation &&
            !params.context.this.isStatusPastReadyForDiscussion) {
            options.push({
                name: 'Copy Requirements to Formularies',
                action: () => {
                    params.context.this.copyRecommendationToFormularies(params);
                }
            });
        }

        return options;
    }

    public kabobClicked(params: any) {
        this.buildRecommendationFormState(params.data);
        params.api.contextMenuFactory.showMenu(
            params.node, params.column, params.value, params.event
        );
    }

    buildRecommendationFormState(formulary?: Formulary) {
        this.recommendationFormState = {
            formulary: formulary,
            medispanTrackerResponse: this.medispanTrackerResponse,
            tabs: [this.mainTabName, this.subTabIndex],
            programIndex: TREE_LEVELS.FORMULARY.code,
        };
    }

    copyRecommendationToFormularies(params) {
        this._bannerService.close();
        const listItems: MultiSelectListItem[] =
            params.api.getRenderedNodes()
                .filter(m => m.data.formularyNumber !== params.node.data.formularyNumber)
                .map(m => ({name: m.data.clientFormularyName, value: m.data.formularyNumber}));
        const bodyMessage =
            `Copy Requirements for ${params.node.data.clientFormularyName} (${params.node.data.formularyNumber}) to:`;
        this._multiSelectModalService.show(
            this.copyPasteModalRef,
            'Copy Requirements',
            bodyMessage,
            'Paste',
            listItems)
            .pipe(take(1)).subscribe((selectedItems) => {
                if (selectedItems) {
                    const sourceFormularyId = params.node.data.formularyNumber;
                    const sourceEncryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
                    const targetFormularyIds = selectedItems;
                    const index = this._appService.getSuperClientContext().id;
                    this._recommendationService.copyRequirements(index, sourceEncryptedRecordId, sourceFormularyId, targetFormularyIds)
                        .pipe(take(1)).subscribe({
                            next: () => this.getFormularyByBLOB(this.selectedBusinessLobCd, false),
                            error: () => {
                                this._bannerService.sendAlert({
                                    headline: 'Error when saving from copy/paste operation.',
                                    body: 'Error when saving from copy/paste operation.',
                                    bannerType: CVSBannerType.Error,
                                    outletId: '#successBanner',
                                    hideX: true
                                });
                            }
                        });
                }
            });
    }

    copyRecommendationsToLikeProducts() {
        this._gridDisplayModalService.showSpinner = true;
        this.findGPILikeProducts().pipe(take(1)).subscribe(likeProducts => {
            likeProducts.map(likeProduct => {
                likeProduct.businessLob = BUSINESS_LOB_NAME.get(likeProduct.businessLob);
                likeProduct.businessLobCd = BUSINESS_LOB_DESC_CD.get(likeProduct.businessLob);
            });
            this.showCopyRecommendationsToLikeProductsModal(likeProducts);
        });
    }

    findGPILikeProducts(): Observable<GpiLikeProduct[]> {
        const index = this._appService.getSuperClientContext().id;
        return this._recommendationService.getGpiLikeProducts(index,
            this.medispanTrackerResponse.encryptedRecordId,
            BUSINESS_LOB_DESC_CD.get(this.medispanTrackerResponse.businessLob),
            this.medispanTrackerResponse.gpi
        );
    }

    showCopyRecommendationsToLikeProductsModal(gpiLikeProducts: GpiLikeProduct[]) {
        if (this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_REQUIREMENTS ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_ARCHIVE ||
            this.fromSource === PRODUCT_OVERVIEW_SOURCE.GPI_TO_NDC_ADHOC) {
            this._bannerService.close();
            const columnDefs: ColDef[] = [{
                headerName: 'Date', field: 'weekDt',
                valueFormatter: params => dateFormatter(params.data.weekDt)
            },
            {headerName: 'GPI', field: 'gpi'},
            {headerName: 'GPI Name', field: 'gpiName'},
            {headerName: 'NDC', field: 'ndc'},
            {headerName: 'Label Name', field: 'labelName'},
            {headerName: 'Line of Business', field: 'businessLob'},
            {headerName: 'MONY', field: 'mony'}];
            const bodyMessage =
                // eslint-disable-next-line max-len
                `Displaying all GPI 12 matches currently in Research and/or Ready for Clinical Requirement for ${this.ndcBusinessLob}. ` +
                'Select GPI(s) below for current\nrequirement(s) to copy to:';
            this._gridDisplayModalService.show(
                this.massCopyGridModalRef,
                'Copy Requirements to Like Products',
                bodyMessage,
                'Paste to selected GPIs',
                gpiLikeProducts,
                {columnDefs} as GridOptions,
                true)
                .pipe(take(1)).subscribe((selectedNodes) => {
                    if (selectedNodes) {
                        const sourceEncryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
                        const targetRecordIds = selectedNodes.map(selectedNode => selectedNode.data.encryptedRecordId);
                        const gpiLikeProductTargetData = this.getGPILikeDataForTargetRecordIds(targetRecordIds,gpiLikeProducts);
                        this._recommendationService
                            .copyRequirementsToLikeGpiProducts(sourceEncryptedRecordId, targetRecordIds, gpiLikeProductTargetData)
                            .pipe(take(1)).subscribe(() => this._bannerService.sendAlert({
                                bannerType: CVSBannerType.Success,
                                headline: 'Success',
                                body: `GPI requirements copied to ${selectedNodes.length} related GPI(s)`,
                                outletId: '#successBanner',
                                isHorizontalBannerLinks: false,
                                bannerLinks: selectedNodes.map(node => ({
                                    linkText: this.getLinkText(node.data),
                                    linkFunc: () => {
                                        this._router.onSameUrlNavigation = 'reload';
                                        this._router.navigate(
                                            ['/productOverview'],
                                            {
                                                state: {
                                                    medispanTrackerData: {
                                                        ...node.data,
                                                    },
                                                    navigationSource: '/workflow/research/inResearch',
                                                    source: 3,
                                                    mainTabName: 'Research & Requirements',
                                                    subTabIndex: node.data.statusId === 100 ? 0 : 1
                                                },
                                            });
                                    }
                                }))
                            }), error1 =>
                                this._bannerService.sendAlert({
                                    bannerType: CVSBannerType.Error,
                                    headline: 'Server Error: Unable to paste requirements',
                                    // eslint-disable-next-line max-len
                                    body: `Oops, it looks like there was an issue copying and saving your requirements. Please try again.`,
                                    outletId: '#successBanner'
                                })
                            );
                    }
                });
        }
    }

    getGPILikeDataForTargetRecordIds(targetRecordIds: string[], gpiLikeProducts: GpiLikeProduct[]) {
        const gpiLikeDataForTargetRecordIds: GPILikeTargetDataForPost[] = [];
        targetRecordIds.forEach( targetId => {
            gpiLikeProducts.filter(product => product.encryptedRecordId === targetId).map( rdata => {
                const data: GPILikeTargetDataForPost = {};
                data.ndc = rdata.ndc;
                data.gpi = rdata.gpi;
                data.encryptedRecordId = rdata.encryptedRecordId;
                gpiLikeDataForTargetRecordIds.push(data);
            });
        });
        return gpiLikeDataForTargetRecordIds;
    }


    getLinkText(data: GpiLikeProduct): string {
        if (data.ndc) {
            return `NDC: ${data.ndc} - ${data.labelName}`;
        }
        return `GPI: ${data.gpi} - ${data.gpiName}`;
    }

    getActiveClientFormularyRequirementRules(businessLobCd: string, formularies: Formulary[]) {
        this.formularyRequirementRuleCoverages = null;
        this.configurationService.getActiveClientFormularyRequirementRules(this._appService.getSuperClientContext().id, businessLobCd)
            .pipe(take(1))
            .subscribe({
                next: (clientFormularyRule) => this.formularyRequirementRuleCoverages = clientFormularyRule,
                error: () => this.formularyRequirementRuleCoverages = null,
                complete: () => this.buildFormularyRequirementInfo(formularies)
            });
    }

    public getFormularyCoverageCategory(code) {
        return COVERAGE_TYPES[COVERAGE_TYPES.findIndex(i => i.code === code)].description;
    }

    isAnAdhocUpdateType() {
        // eslint-disable-next-line max-len
        if (this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_TO_NDC_ADHOC) {
            return true;
        } else {
            return false;
        }

    }
}
