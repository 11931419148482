import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-custom-button',
    templateUrl: './custom-button.component.html',
    styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {

    @Input() disabled = true;
    @Input() tooltipText = '';
    @Input() id;
    @Input() buttonText;
    @Input() placement;
    @Input() class;
    @Input() showArrow;
    @Input() svgIcon;
    @Output() onclick: EventEmitter<MouseEvent> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    onClick(event: MouseEvent) {
        if(!this.disabled){
            this.onclick.emit(event);
            return;
        }

        event.stopPropagation();
    }
}
