<div class="grid-container">
  <ag-grid-angular
                   [ngClass]="'ag-theme-balham cvs-grid'"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="defaultColDef"
                   [gridOptions]="gridOptions"
                   (cellDoubleClicked)="doubleClick($event)"
                   customRowStyle = "customRowStyle"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRowDataChanged($event)"
                   (rowDragEnd)="onRowDragEnd($event)"
                   (filterChanged)="onFilterChange($event)"
                   [pagination]="paginationEnabled"
                   rowMultiSelectWithClick=true
                   [suppressDragLeaveHidesColumns]="true"
                   [rowDragManaged]="true"
                   rowSelection="multiple"
                   [suppressRowClickSelection]="true"
                   [suppressCellSelection]="disableSelection()"
                   (cellValueChanged)="onCellValueChanged($event)"
                   [masterDetail]="masterDetail"
                   [detailCellRendererParams]="detailCellRendererParams"
                   [groupDefaultExpanded]="groupDefaultExpanded"
                   [modules]="modules"
                   [detailRowAutoHeight]="detailRowAutoHeight"
                   [cacheBlockSize]="cacheBlockElements"
                   [paginationPageSize]="paginationPageSize"
                   (paginationChanged) ="onPaginationChanged($event)"
                   [suppressScrollOnNewData]="true"
                   [suppressPaginationPanel]="suppressPaginationPanel"
                   [domLayout]="domLayout"
                   [rowData]="rowData"
                   [getRowClass]=getRowClass
                   [sideBar]="sidebarValue"
                   [suppressMenuHide]="suppressMenuHide"
                   [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                   [popupParent] = popupParent
                   (columnResized)="onColumnResized($event)"
                   (columnGroupOpened)="onColumnGroupOpened($event)"
                   (columnMoved)="onColumnMoved($event)"
                   (columnVisible)="onColumnVisible($event)"
                   (rowSelected)="onRowSelected($event)"
                   (selectionChanged)="onSelectionChanged($event)"
                   (rowValueChanged)="onRowValueChanged($event)"
                   [getContextMenuItems]="getContextMenuItems"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   (rowDataUpdated)="onRowDataUpdated($event)"
                   [context]="context"
                   [suppressCsvExport]=disableCvsExport
                   (rowGroupOpened)="onRowGroupOpened($event)"
                   (cellKeyDown) = "onCellKeyDown($event)"
                   [suppressLoadingOverlay]="suppressLoadingOverlay"
  >
  </ag-grid-angular>
</div>
