import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {take} from 'rxjs/operators';
import {AppService} from '../../service/app/app.service';
import {Subscription} from 'rxjs';
import {FormularyService} from '../../service/formulary/formulary.service';
import {TrackerDataService} from '../../service/tracker-data/tracker-data.service';
import {buildTabSelectionChangeEvent} from 'src/app/utility/utility';
import {BUSINESS_LOB_DESC_CD} from 'src/app/default-values/DefaultValues';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog} from '@angular/material/dialog';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import { BusinessLob } from 'src/app/model/BusinessLob';


@Component({
    selector: 'app-business-lob-tabs',
    templateUrl: './business-lob-tabs.component.html',
    styleUrls: ['./business-lob-tabs.component.scss']
})
export class BusinessLobTabsComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('primaryTabGroup', { static: false }) primaryTabGroup: MatTabGroup;
    @Output() selectedTab = new EventEmitter();
    @Input() ndcBusinessLob;
    @Input() selectedLOBS;
    @Input() isPrimaryTab: boolean = undefined;
    @Input() isUpdated: boolean;
    @Input() lobLastToggled: string;
    displayBlobTabs: boolean;
    superClientLobs = [];
    subscriptions: Subscription[] = [];
    selectedIndex = 0;
    private debounceTimer: any;

    constructor(private _appService: AppService, private _formularyService: FormularyService,
        private _trackerService: TrackerDataService,
        private _matDialog: MatDialog) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        if(this.debounceTimer){
            clearTimeout(this.debounceTimer);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.retrieveSuperClientLinesOfBusiness();
    }

    retrieveSuperClientLinesOfBusiness() {
        const index = this._appService.getSuperClientContext().id;
        this.subscriptions.push(this._formularyService.getSuperClientBusinessLobs(index).pipe(take(1))
            .subscribe(lobResponse => {
                this.superClientLobs = lobResponse;
                this.displayBlobTabs = this.superClientLobs.length > 1;
                if (this.ndcBusinessLob) {
                    this.selectedIndex = lobResponse.findIndex(lob => lob.description === this.ndcBusinessLob);
                } else if (this.selectedLOBS) {
                    const selectedLobs = this.selectedLOBS.map(lob => lobResponse.find(tab => tab.code === lob));
                    const sortedLobs = selectedLobs.length === 0 ? this.sortLobs([...lobResponse]) : this.sortLobs(selectedLobs);
                    const lobIndex = lobResponse.findIndex(tab => tab === sortedLobs[0]);

                    this.onTabChange(buildTabSelectionChangeEvent(
                        BUSINESS_LOB_DESC_CD.get(sortedLobs[0].description),
                        sortedLobs[0].description,
                        lobIndex
                    ), null, true);
                } else {
                    let lobDesc = lobResponse[0]?.description;
                    if (this.lobLastToggled) {
                        const idx = lobResponse.findIndex(lob => lob.description === this.lobLastToggled);
                        lobDesc = lobResponse[idx].description;
                        this.selectedIndex = idx;
                    }
                    this.onTabChange(buildTabSelectionChangeEvent(
                        BUSINESS_LOB_DESC_CD.get(lobDesc), lobDesc,
                        this.selectedIndex
                    ), null, true);
                }
            })
        );
    }

    sortLobs(businessLobs: BusinessLob[]) {
        return businessLobs.sort((a, b) => a.description.localeCompare(b.description));
    }

    onTabChange(event?: MatTabChangeEvent, matTabGroup?: MatTabGroup, firstEvent?: boolean) {
        if(this.debounceTimer){
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = setTimeout( () =>{
            if (event.index !== this.selectedIndex || firstEvent) {
                if (this._trackerService.isMedispanSettingsModified()) {
                    const dialog = this.openNavigateWithoutSaveDialog();
                    dialog.componentInstance.onConfirmClick.subscribe(() => {
                        this.selectedTab.emit(event);
                        this.selectedIndex = event.index;
                        this._trackerService.setMedispanSettingsModified(false);
                    });
                    dialog.componentInstance.onCancelClick.subscribe(() => {
                        matTabGroup.selectedIndex = this.selectedIndex;
                        matTabGroup.focusTab(this.selectedIndex);

                    });
                } else {
                    this.selectedIndex = event.index;
                    this.selectedTab.emit(event);
                    this.primaryTabFocus(event);
                }
            }
        });
    }

    primaryTabFocus(event: MatTabChangeEvent) {
        if (this.primaryTabGroup) {
            this.primaryTabGroup.selectedIndex = event.index;
            this.primaryTabGroup.focusTab(event.index);
        }
    }

    openNavigateWithoutSaveDialog(): any {
        return this._matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Exit without saving changes?',
                body: 'Any changes made will not be saved when navigating out of this page.',
                actionLabel: 'Exit Without Saving',
                cancelLabel: 'Continue Editing',
            }, disableClose: true
        });
    }
}
