<ng-container>
  <mat-card-header>
    <mat-card-title>CMS Submission Tracking</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>CMS Date</mat-label>
      <input matInput [matDatepicker]="cmsDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="cmsDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #cmsDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>CMS Effective Date</mat-label>
      <input matInput [matDatepicker]="effectiveDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="effectiveDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #effectiveDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Negative Change Request Date</mat-label>
      <input matInput [matDatepicker]="requestDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="requestDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #requestDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Effective Date for Negative Change</mat-label>
      <input matInput [matDatepicker]="negativeDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="negativeDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #negativeDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Member Mailing Drop Date</mat-label>
      <input matInput [matDatepicker]="mailDropDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="mailDropDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #mailDropDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
</ng-container>
