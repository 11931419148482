<ng-container>
  <mat-card-header>
    <mat-card-title>Supplemental Lists</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>BvD Added</mat-label>
      <input matInput [matDatepicker]="bvdDate" />
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="bvdDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #bvdDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
</ng-container>
