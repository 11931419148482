import {Component, OnInit} from '@angular/core';
import {UserService} from '../../service/user/user.service';

@Component({
    selector: 'app-custom-welcome',
    template: `<ng-container *ngIf="firstName !== '' || lastName !== ''">
            Welcome, {{firstName}} {{lastName}}
        </ng-container>`,
    styles: []
})
export class CustomWelcomeComponent implements OnInit {
    firstName = '';
    lastName = '';
    constructor(private userService: UserService) {
        userService.userSubject.subscribe( user => {
            this.firstName = user.basicUserInfo.firstName;
            this.lastName = user.basicUserInfo.lastName;
        });
    }

    ngOnInit(): void {
    }
}
