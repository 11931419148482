import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../model/User';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {UserService} from '../../service/user/user.service';
import {RecordService} from '../../service/record/record.service';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {take} from 'rxjs/operators';
import {HEADERS} from '../product-overview.component';
import {DetailHeader} from 'src/app/model/DetailHeader';
import {getHierarchyUpdateTypeForWorkflow} from '../../mapper/hierarchyUpdateType';

@Component({
    selector: 'app-gpi-header',
    templateUrl: './gpi-header.component.html',
    styleUrls: ['./gpi-header.component.scss']
})
export class GpiHeaderComponent implements OnInit {
    @Input() fromSourceInput: any;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;

    user: User;
    groupGPISelection = {
        protectedClass: null
    };
    hasEditPermission = false;
    gpiDetailHeader: DetailHeader[] = [];
    ndcBusinessLob;

    constructor(
        private _userService: UserService,
        private _recordService: RecordService
    ){
        this.hasEditPermission = this._userService.hasEditPermission();
    }

    ngOnInit(): void {
        this.gpiDetailHeader = this.buildGPIDetailHeader();
        if (this.fromSourceInput !== PRODUCT_OVERVIEW_SOURCE.RECOMMENDATION_FORM) {
            this.addEditableFields();
        }
    }

    addEditableFields() {
        this.gpiDetailHeader.push({
            name: HEADERS.PROTECTED_CLASS, field: 'protectedClass'
        });
    }

    buildGPIDetailHeader(): DetailHeader[] {
        let newUpdateType;
        if (this.medispanTrackerResponse && this.medispanTrackerResponse.updateType) {
            newUpdateType = getHierarchyUpdateTypeForWorkflow(this.medispanTrackerResponse.updateType).newUpdateTypeName;
        }
        const gpiInfo: DetailHeader[] = [
            {name: HEADERS.GPI, value: this.medispanTrackerResponse.gpi},
            {name: HEADERS.GPI_NAME, value: this.medispanTrackerResponse.gpiName}
        ];

        if (this.fromSourceInput !== PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH &&
            this.fromSourceInput !== PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH) {
            gpiInfo.push({name: HEADERS.BUSINESS_LOB, value: this.medispanTrackerResponse?.businessLob});
            this.ndcBusinessLob = this.medispanTrackerResponse?.businessLob;
            gpiInfo.push({name: HEADERS.EMPTY_STRING, value: undefined});
            gpiInfo.push({name: HEADERS.EMPTY_STRING, value: undefined});
            gpiInfo.push({name: HEADERS.EMPTY_STRING, value: undefined});
            gpiInfo.push({name: HEADERS.EMPTY_STRING, value: undefined});
        }
        gpiInfo.splice(3, 1, {name: HEADERS.UPDATE_TYPE, value: newUpdateType});

        if (this.fromSourceInput === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_REQUIREMENTS ||
            this.fromSourceInput === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION) {
            this._recordService.getRecord(this.medispanTrackerResponse.encryptedRecordId)
                .pipe(take(1))
                .subscribe((recordData) => {
                    this.groupGPISelection.protectedClass = recordData.protectedClassFlg;
                });
        }
        return gpiInfo;
    }

    saveSelections() {
        this._recordService.saveProtectedClassAndNewMolecularEntity(this.medispanTrackerResponse.encryptedRecordId,
            this.groupGPISelection.protectedClass,
            null)
            .pipe(take(1))
            .subscribe(() => {
            });
    }

}


