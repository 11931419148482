import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridOptions } from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import { FORMULARY_CODING_TYPE } from 'src/app/enum/FormularyCodingType';
import {MatSelectChange} from '@angular/material/select';
import {IDrugList} from '../../formulary-requirements.component';
import {CODING_TYPES} from '../../../../enum/CodingTypes';
import {Formulary} from '../../../../model/Formulary';
import {dateFormatter} from '../../../../utility/utility';


@Component({
    selector: 'app-coding-type',
    templateUrl: './coding-type.component.html',
    styleUrls: ['./coding-type.component.scss'],
})
export class CodingTypeComponent implements OnInit, OnChanges{
    @Input() planGPIAndJList: IDrugList[] = [];
    @Input() preferredNDCList: IDrugList[] = [];
    @Input() preferredGPIList: IDrugList[] = [];
    @Input() codingTypeSelected: string;
    @Input() codingTypeStdGpiNdcCdPreferredGPI = '';
    @Input() formularyRequirementForm: FormGroup;
    @Input() formularyData: Formulary[];
    @Input() showDrugListSpinner = true;
    @Input() isAnUpdate;
    selectedFormularyDrugListType = '';
    planGPIIDAndJIDList: string[];
    preferredNDCIDList: string[];
    preferredGPIIDList: string[];
    drugListDescription = '';
    formularyRowData = [];
    modules = [
        SetFilterModule,
        ColumnsToolPanelModule,
        ClientSideRowModelModule,
        MenuModule,
        ClipboardModule,
        ExcelExportModule
    ];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };

    columnDefs: ColDef[] = [
        {
            headerName: 'Associated Formulary Number(s)', field: 'associatedFormulary', filter: 'agSetColumnFilter', width: 290,
            valueGetter: (params: any) => `${params.data.formularyNumber} - ${params.data.name}`
        },
        {
            headerName: 'Line of Business', field: 'linesOfBusiness', filter: 'agSetColumnFilter', width: 250,
            valueGetter: (params: any) => `${params.data.linesOfBusiness[0].description}`
        },
        {
            headerName: 'Formulary List Attachment Date', field: 'thruDate', filter: 'agSetColumnFilter', width: 270,
            valueGetter: (params: any) => this.formularyListAttachmentDateFormatter(params)
        },
    ];

    formularyRequirementsGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined
        }
    };
    popupParent: HTMLElement | null = document.querySelector('body');
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    protected formularyCodingType = FORMULARY_CODING_TYPE;

    constructor() { }

    ngOnInit(): void {
    }

    onDrugListTypeChange($event?: MatSelectChange, currentDrugListType?: any) {
        this.formularyRowData = [];
        this.formularyRequirementsGridOptions.api?.setRowData(this.formularyRowData);
        const value = $event !== undefined && $event !== null ? $event.value : currentDrugListType;
        if (this.codingTypeSelected === CODING_TYPES.PLAN_GPI_WITH_RESTRICT_NDC_CD ) {
            if (this.planGPIAndJList.find(dl => dl.drugListID === value)) {
                this.drugListDescription = this.planGPIAndJList.find(dl => dl.drugListID === value).drugListDescription;
            }
        } else if (this.codingTypeSelected === CODING_TYPES.STD_NDC_CD ||( this.codingTypeSelected === CODING_TYPES.STD_GPI_NDC_CD
            && this.codingTypeStdGpiNdcCdPreferredGPI !== CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD)
        ) {
            if (this.preferredNDCList.find(dl => dl.drugListID === value)) {
                this.drugListDescription = this.preferredNDCList.find(dl => dl.drugListID === value).drugListDescription;
            }
        } else if (this.codingTypeSelected === CODING_TYPES.GPI_NO_RESTRICT_NDC_CD ||
        this.codingTypeSelected === CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD) {
            if (this.preferredGPIList.find(dl => dl.drugListID === value)) {
                this.drugListDescription = this.preferredGPIList.find(dl => dl.drugListID === value).drugListDescription;
            }
        } else if(this.codingTypeSelected === CODING_TYPES.STD_GPI_NDC_CD
            && this.codingTypeStdGpiNdcCdPreferredGPI === CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD) {
            if (this.preferredGPIList.find(dl => dl.drugListID === value)) {
                this.drugListDescription = this.preferredGPIList.find(dl => dl.drugListID === value).drugListDescription;
            }
        }
        this.codingTypeRowDataCall(value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.formularyRowData = [];
        this.formularyRequirementsGridOptions.api?.setRowData(this.formularyRowData);
        if (this.isAnUpdate) {
            if (this.formularyRequirementForm.controls.planGPIListID.value !== null &&
                this.formularyRequirementForm.controls.planGPIListID.value !== '') {
                this.onDrugListTypeChange(null, this.formularyRequirementForm.controls.planGPIListID.value);
            }
            if (this.formularyRequirementForm.controls.preferredNDCListID.value !== null &&
                this.formularyRequirementForm.controls.preferredNDCListID.value !== '') {
                this.onDrugListTypeChange(null, this.formularyRequirementForm.controls.preferredNDCListID.value);
            }  if (this.formularyRequirementForm.controls.preferredGPIListID.value !== null &&
                this.formularyRequirementForm.controls.preferredGPIListID.value !== '') {
                this.onDrugListTypeChange(null, this.formularyRequirementForm.controls.preferredGPIListID.value);
            }
        }

        if (changes.codingTypeSelected?.currentValue === CODING_TYPES.PLAN_GPI_WITH_RESTRICT_NDC_CD) {
            this.selectedFormularyDrugListType = FORMULARY_CODING_TYPE.PLAN_GPI_JLIST;
            this.formularyRequirementForm.controls['planGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['planGPIListID'].updateValueAndValidity();
        } else if (changes.codingTypeSelected?.currentValue === CODING_TYPES.STD_NDC_CD ||
            (changes.codingTypeSelected?.currentValue === CODING_TYPES.STD_GPI_NDC_CD &&
                this.codingTypeStdGpiNdcCdPreferredGPI !== CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD)) {
            this.selectedFormularyDrugListType = FORMULARY_CODING_TYPE.PREFERRED_NDC;
            this.formularyRequirementForm.controls['preferredNDCListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredNDCListID'].updateValueAndValidity();
        } else if (changes.codingTypeSelected?.currentValue === CODING_TYPES.GPI_NO_RESTRICT_NDC_CD ||
            changes.codingTypeSelected?.currentValue === CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD ) {
            this.selectedFormularyDrugListType = FORMULARY_CODING_TYPE.PREFERRED_GPI;
            this.formularyRequirementForm.controls['preferredGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredGPIListID'].updateValueAndValidity();
        } else if (changes.codingTypeSelected?.currentValue === CODING_TYPES.STD_GPI_NDC_CD &&
            this.codingTypeStdGpiNdcCdPreferredGPI === CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD) {
            this.selectedFormularyDrugListType = FORMULARY_CODING_TYPE.PREFERRED_GPI;
            this.formularyRequirementForm.controls['preferredGPIListID'].addValidators([Validators.required]);
            this.formularyRequirementForm.controls['preferredGPIListID'].updateValueAndValidity();
        }
        if (changes.planGPIAndJList && changes.planGPIAndJList.currentValue != null ) {
            this.planGPIIDAndJIDList = changes.planGPIAndJList.currentValue.map(m=>m.drugListID);
        } else {
            this.formularyRequirementForm.controls.planGPIListID.setValidators(Validators.required);
        }
        if (changes.preferredNDCList && changes.preferredNDCList.currentValue != null) {
            this.preferredNDCIDList = changes.preferredNDCList.currentValue.map(m=>m.drugListID);
        } else {
            this.formularyRequirementForm.controls.preferredNDCListID.setValidators(Validators.required);
        }
        if (changes.preferredGPIList && changes.preferredGPIList.currentValue != null) {
            this.preferredGPIIDList = changes.preferredGPIList.currentValue.map(m=>m.drugListID);
        } else {
            this.formularyRequirementForm.controls.preferredGPIListID.setValidators(Validators.required);
        }
        if((changes.preferredNDCList && changes.preferredNDCList.currentValue != null)
            && (changes.preferredGPIList && changes.preferredGPIList.currentValue != null)){
            this.preferredNDCIDList = changes.preferredNDCList.currentValue.map(m=>m.drugListID);
            this.preferredGPIIDList = changes.preferredGPIList.currentValue.map(m=>m.drugListID);
        } else {
            this.formularyRequirementForm.controls.preferredNDCListID.setValidators(Validators.required);
            this.formularyRequirementForm.controls.preferredGPIListID.setValidators(Validators.required);
        }
    }

    codingTypeRowDataCall(selectedDrugList){
        this.formularyRowData = [];
        this.formularyData?.forEach((formulary) => formulary.drugLists.forEach((drugList) => {
            if(drugList.id === selectedDrugList){
                this.formularyRowData.push(formulary);
                this.formularyRowData.sort((formularyA , formularyB) => formularyA.formularyNumber - formularyB.formularyNumber);
            }
        }));
        this.formularyRequirementsGridOptions.api?.setRowData(this.formularyRowData);
    }

    formularyListAttachmentDateFormatter(params){
        const fromDate = dateFormatter(params.data.fromDate);
        const thruDate = dateFormatter(params.data.thruDate);
        return `${fromDate} - ${thruDate}`;
    }
}
