import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {AppService} from '../../service/app/app.service';
import {FormularyService} from '../../service/formulary/formulary.service';
import {take} from 'rxjs/operators';
import {BusinessLob} from '../../model/BusinessLob';
import {RECORD_STATUS, RECORD_STATUSES} from '../../enum/RecordStatus';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatCheckbox} from '@angular/material/checkbox';
import {FormControl, Validators} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {timer} from 'rxjs';
import {MatRadioChange} from '@angular/material/radio';
import {AttachmentData} from 'src/app/model/AttachmentData';
import {ConvertGpiToNdcService} from './convert-gpi-to-ndc-list/convert-gpi-to-ndc-list.component';

export interface CLRConfirmationDialogValueObject {
    statusId: number;
    notes: string;
    businessLob: string[];
    convertNdcToGpi: boolean;
    convertGpiToNdc: boolean;
}

export class CLRConfirmationDialogContentComponent extends CVSConfirmationDialogContentComponent {
    valueObject: CLRConfirmationDialogValueObject;
}

@Component({
    selector: 'app-create-adhoc-update-modal',
    templateUrl: './create-adhoc-update-modal.component.html',
    styleUrls: ['./create-adhoc-update-modal.component.scss']
})
export class CreateAdhocUpdateModalComponent implements OnInit, OnChanges {
    @ViewChild('adhocBLOBCheckBox') public adhocBLOBCheckBox: MatCheckbox;
    @ViewChild('adhocBLOBMultiSelect', {static: false}) public adhocBLOBMultiSelect: MatSelect;
    @Input() createAdHocUpdateModalRef: CLRConfirmationDialogContentComponent;
    @Input() convertAttachmentNDCTypeToGPI: boolean;
    @Input() convertAttachmentGPITypeToNDC: boolean;
    @Input() selectedGpi: string;
    @Input() selectedGPIAttachmentPayload: AttachmentData[];
    @Input() selectedGapiAttachmentCount: number;
    @Input() selectedLobs: string[];

    superClientLobs: BusinessLob[] = [];
    selectedStatus;
    adhocRadioOptions = [RECORD_STATUSES.find(s => s.status === RECORD_STATUS.IN_RESEARCH),
        RECORD_STATUSES.find(s => s.status === RECORD_STATUS.READY_FOR_CLINICAL_REQUIREMENT)];

    businessLob = new FormControl(null, {validators: [Validators.required]});
    indeterminate = false;
    toolTipMessage = 'Once coding goes into effect the selected NDC level coding will be termed when converted to GPI level coding';
    displayGpiToNdcList = false;
    selectedGridItemList;

    constructor(private _appService: AppService, private _formularyService: FormularyService,
        private _convertNdcToGpiService: ConvertGpiToNdcService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.convertAttachmentGPITypeToNDC.currentValue) {
            this.toolTipMessage = 'Once coding goes into effect the selected GPI level will be termed when converted to NDC level coding';
        }
    }

    ngOnInit(): void {
        this._convertNdcToGpiService.ndcItemsSelected.subscribe(items => {
            this.selectedGridItemList = items;
            this.setConfirmationDisable(this.enableDisableConfirmationButton());
        });
        this.selectedStatus = RECORD_STATUSES.find(s => s.status === RECORD_STATUS.IN_RESEARCH);
        const index = this._appService.getSuperClientContext().id;
        this.createAdHocUpdateModalRef.valueObject = {
            statusId: this.selectedStatus.status, notes: '', businessLob: [],
            convertNdcToGpi: false, convertGpiToNdc: false
        };
        this._formularyService.getSuperClientBusinessLobs(index).pipe(take(1)).subscribe(lobResponse => {
            this.superClientLobs = lobResponse;
            if (this.superClientLobs.length === 1) {
                this.createAdHocUpdateModalRef?.valueObject?.businessLob.push(this.superClientLobs[0].code);
            } else if (this.selectedLobs) {
                this.businessLob.patchValue(this.selectedLobs);
            } else {
                this.businessLob.patchValue(this.superClientLobs.map( sb => sb.code));
            }
        });
    }

    setValueObject(key: string, newValue: any) {
        this.createAdHocUpdateModalRef.valueObject = {...this.createAdHocUpdateModalRef.valueObject, ...{[key]: newValue}};
    }

    setValueObjectBusinessLob(value: string, add: boolean) {
        if (add) {
            this.createAdHocUpdateModalRef.valueObject.businessLob.push(value);
        } else {
            this.createAdHocUpdateModalRef.valueObject.businessLob =
                this.createAdHocUpdateModalRef.valueObject.businessLob.filter(f => f !== value);
        }
        const selectedList = this.adhocBLOBMultiSelect.options.filter(opt => opt.selected).length;
        this.setConfirmationDisable(this.enableDisableConfirmationButton());
        this.indeterminate = (selectedList > 0 && selectedList < this.superClientLobs.length);
        if ((!this.adhocBLOBCheckBox.checked && selectedList === this.superClientLobs.length) ||
            (this.adhocBLOBCheckBox.checked && selectedList < this.superClientLobs.length)) {
            this.adhocBLOBCheckBox.toggle();
        }
    }

    toggleAllSelection() {
        if (this.adhocBLOBCheckBox?.checked){
            this.businessLob.patchValue([...this.superClientLobs.map(i => i.code)]);
        } else {
            this.createAdHocUpdateModalRef.valueObject.businessLob = [];
            this.businessLob.patchValue([]);
        }
    }

    radioButtonChange(evt: MatRadioChange) {
        if (evt.value === 'Y') {
            if (this.convertAttachmentNDCTypeToGPI) {
                this.createAdHocUpdateModalRef.valueObject.convertNdcToGpi = true;
                this.createAdHocUpdateModalRef.valueObject.convertGpiToNdc = false;
                this.displayGpiToNdcList = false;
            } else {
                this.createAdHocUpdateModalRef.valueObject.convertNdcToGpi = false;
                this.createAdHocUpdateModalRef.valueObject.convertGpiToNdc = true;
                this.displayGpiToNdcList = true;
            }
        } else {
            this._convertNdcToGpiService.setNdcSelectedItems(undefined);
            this.selectedGridItemList = undefined;
            this.displayGpiToNdcList = false;
            this.createAdHocUpdateModalRef.valueObject.convertNdcToGpi = false;
            this.createAdHocUpdateModalRef.valueObject.convertGpiToNdc = false;
        }
        this.setConfirmationDisable(this.enableDisableConfirmationButton());
    }

    enableDisableConfirmationButton(): boolean {
        let disableFlag;
        if (this.createAdHocUpdateModalRef.valueObject.convertGpiToNdc) {
            disableFlag = this.selectedGridItemList === undefined || this.selectedGridItemList.length === 0 ||
                this.createAdHocUpdateModalRef.valueObject.businessLob.length === 0;
        } else {
            disableFlag = this.createAdHocUpdateModalRef.valueObject.businessLob.length === 0;
        }
        return disableFlag;
    }

    setConfirmationDisable(disable: boolean) {
        try {
            const theButton = document.getElementsByClassName('mat-raised-button')[0] as HTMLButtonElement;
            theButton.disabled = disable;
        } catch (e) {
        }
    }
}
