<ng-container>
  <mat-card-header>
    <mat-card-title>Regulatory Tracking</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>UM Approval Date</mat-label>
      <input matInput [matDatepicker]="umApprovalDate" [formControl]="umDate"/>
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="umApprovalDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #umApprovalDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Formulary Review Committee Date</mat-label>
      <input matInput [matDatepicker]="reviewDate" [formControl]="frcDate"/>
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="reviewDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #reviewDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-form-field>
      <mat-label>P&T Approval Date</mat-label>
      <input matInput [matDatepicker]="ptApprovalDate"  [formControl]="ptDate"/>
      <mat-icon
        class="icon"
        svgIcon="calendar--s"
        matSuffix
        (click)="ptApprovalDate.open()"
      >
      </mat-icon>
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker #ptApprovalDate></mat-datepicker>
    </mat-form-field>
  </mat-card-content>
  <mat-card-content>
    <mat-label class="notes-label">Regulatory Tracking Notes</mat-label>
    <textarea>{{regulatoryTrackingValue?.notes}}</textarea>
  </mat-card-content>
</ng-container>
