import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {IDeactivateComponent} from './IDeactivateComponent';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {MatDialog} from '@angular/material/dialog';
@Injectable({
    providedIn: 'root'
})

export class DeactivateGuard implements CanDeactivate<IDeactivateComponent> {

    actionTaken: Subject<boolean> = new Subject<boolean>();
    navigateWithoutSaveDialog;
    component;

    constructor(private matDialog: MatDialog, public router: Router) {
    }

    canDeactivate(
        component: IDeactivateComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.component = component;
        if (component.canDeactivate && component.canDeactivate()) {
            this.waitForAction();
            return this.actionTaken.asObservable();
        }
        return true;
    }

    waitForAction = () => {
        this.navigateWithoutSaveDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Exit without saving changes?',
                body: 'Any changes made will not be saved when navigating out of this page.',
                actionLabel: 'Exit Without Saving',
                cancelLabel: 'Continue Editing',
            },disableClose: true
        });

        this.navigateWithoutSaveDialog.componentInstance.onConfirmClick.subscribe(() => {
            this.actionTaken.next(true);
            this.component.setDeactivatingState(false);
        });

        this.navigateWithoutSaveDialog.componentInstance.onCancelClick.subscribe(() => {
            this.actionTaken.next(false);
        });
    };
}
