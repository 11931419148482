<ng-container>
    <form [formGroup]="superClientForm" (ngSubmit)="saveSuperClientForm()">
        <div class="super-client-form">
            <mat-form-field  [ngClass]="{'form-warning' :superClientForm.controls.superClientId.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Super Client ID *"
                        maxlength="15"
                        formControlName="superClientId">
                </app-smart-input>
                <mat-hint class="mat-hint">Enter a Super Client ID</mat-hint>
                <mat-error *ngIf="superClientForm.controls.superClientId.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Super Client ID
                </mat-error>
                <mat-error *ngIf="superClientForm.controls.superClientId.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{superClientForm.controls.superClientId.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field  [ngClass]="{'form-warning' :superClientForm.controls.name.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Super Client Name *"
                        maxlength="20"
                        formControlName="name">
                </app-smart-input>
                <mat-hint class="mat-hint">Enter a Super Client Name</mat-hint>
                <mat-error *ngIf="superClientForm.controls.name.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Super Client Name
                </mat-error>
                <mat-error *ngIf="superClientForm.controls.name.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{superClientForm.controls.name.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label class="mat-label-padding">Super Client Description *</mat-label>
                <textarea
                        matInput
                        (input)="onDescriptionChanges()"
                        class="description-textarea-submission"
                        formControlName="description"
                        (focusout)="stopWarning()"
                ></textarea>
                <mat-hint *ngIf="!hasTruncDescriptionError" class="mat-hint-description">Enter a Super Client Description</mat-hint>
                <mat-hint *ngIf="hasTruncDescriptionError" class="warning-error-description-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{superClientForm.controls['description'].value?.length}} characters
                </mat-hint>
                <mat-error *ngIf="superClientForm.controls.description.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Super Client Description
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-buttons">
            <button class="pds-btn-white" (click)="onModalCancel()" type="reset">Cancel</button>
            <button class="pds-btn-primary" type="submit">Add Super Client</button>
        </div>
    </form>
</ng-container>