import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {dateFormatter, dateTruncation} from '../../utility/utility';
import {MatDateRangeInput} from '@angular/material/datepicker';
import {MatRadioChange} from '@angular/material/radio';
import {FORMULARY_DATE_BOUNDARY} from '../../enum/FormularyDateBoundary';
import moment from 'moment';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
    @ViewChild('dateRange') dateRange: MatDateRangeInput<any>;
    @ViewChild('inputStartDate') inputStartDateRef;
    @ViewChild('inputEndDate') inputEndDateRef;
    @Output() dateEvent = new EventEmitter();
    @Input() dateRadioGroupHeader = 'Date';
    @Input() dateRangeHeader = 'Date Range';
    @Input() currentDate: Date;
    @Input() showHighestPriorityChecked: boolean = undefined;
    @Input() setDefaultDateRangePicker = false;
    @Input() singleDatePickerLabelText = 'Single Date';
    @Input() displayDatePickerRadioBtns = true;
    @Input() singleDateRadioButtonSelected = true;
    @Input() dateRangeError = 'Error: Provide a valid date range within 01/01/1995 to 12/31/2039';
    @Input() maxDate: any = FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE;
    @Input() parentSource: string;
    @Input() disableValidation = false;

    isDateRadioDisabled = false;
    minDate = FORMULARY_DATE_BOUNDARY.MINIMUM_DATE;
    singleDate;
    datePickerStartDate;
    datePickerEndDate;
    dateRangePickerHasValue = false;
    singleDateError = 'Error: Provide a valid date within 01/01/1995 to 12/31/2039';

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.showHighestPriorityChecked) {
            this.singleDateRadioButtonSelected = true;
            this.isDateRadioDisabled = changes.showHighestPriorityChecked.currentValue;
        }
    }

    ngOnInit(): void {
        this.singleDate = this.currentDate;
        if(this.setDefaultDateRangePicker){
            this.setupDefaultDateRangePicker();
        }
    }

    setupDefaultDateRangePicker() {
        this.displayDatePickerRadioBtns = false;
        this.singleDateRadioButtonSelected = false;
        this.datePickerStartDate = moment(this.currentDate).subtract(29, 'days').toDate();
        this.datePickerEndDate = this.currentDate;
    }

    isValidDate(date) {
        return date !== undefined && (new Date(date) >= new Date(dateFormatter(dateTruncation(this.minDate)))) &&
            (new Date(date) <= new Date(dateFormatter(dateTruncation(this.maxDate))));
    }

    onSingleDateFocusOut(event) {
        if (!this.disableValidation && !this.isValidDate(this.singleDate)) {
            event.target.focus();
        }
    }

    onSingleDateChange() {
        this.dateEvent.emit([this.singleDate, null, true]);
    }

    onDateRangeFocusOut() {
        if (this.parentSource === 'noAction' || this.parentSource === 'archive'){
            return;
        }
        if (!this.isValidDate(this.datePickerStartDate)) {
            this.inputStartDateRef.nativeElement.focus();
        }else if(!this.isValidDate(this.datePickerEndDate)) {
            this.inputEndDateRef.nativeElement.focus();
        }else if(new Date(this.datePickerStartDate) > new Date(this.datePickerEndDate)){
            this.inputEndDateRef.nativeElement.focus();
        }
    }

    onDateRangeChange() {
        this.dateRangePickerHasValue = this.datePickerStartDate !== undefined && this.datePickerEndDate !== undefined;
        if (this.dateRangePickerHasValue) {
            this.dateEvent.emit([this.datePickerStartDate, this.datePickerEndDate, false]);
        }
    }

    onDateRadioChange(event: MatRadioChange) {
        this.singleDateRadioButtonSelected = event.value === 'singleDate';
        if (this.singleDateRadioButtonSelected) {
            this.dateEvent.emit([this.singleDate, null, true]);
        } else {
            this.dateEvent.emit([this.datePickerStartDate, this.datePickerEndDate, false]);
        }
    }
}
