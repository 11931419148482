import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AgGridEvent, ColDef,
    FirstDataRenderedEvent, GridOptions, IRowNode, RowDataUpdatedEvent, SuppressKeyboardEventParams
} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {take} from 'rxjs/operators';
import { CvsAgGridComponent } from 'src/app/common/cvs-grid/cvs-ag-grid/cvs-ag-grid.component';
import { PRODUCT_OVERVIEW_SOURCE } from 'src/app/enum/ProductOverviewSource';
import { RECORD_STATUS } from 'src/app/enum/RecordStatus';
import { WorkflowResponse } from 'src/app/model/WorkflowResponse';
import { AppService } from 'src/app/service/app/app.service';
import { TrackerDataService } from 'src/app/service/tracker-data/tracker-data.service';
import { UserService } from 'src/app/service/user/user.service';
import { WorkflowService} from 'src/app/service/workflow/workflow.service';
import {dateTruncation, dateFormatter, gridHyperLinkSelectorByUpdateType, gridHyperLinkSelector} from 'src/app/utility/utility';
import {getUpdateTypeDisplayNameForWorkflow} from '../../mapper/hierarchyUpdateType';
import { WORKFLOW_TABS } from '../WorkflowTab';
import {RecordService} from '../../service/record/record.service';

@Component({
    selector: 'app-workflow-research-and-recommendations-list',
    templateUrl: './workflow-research-and-recommendations-list.component.html',
    styleUrls: ['./workflow-research-and-recommendations-list.component.scss']
})
export class WorkflowResearchAndRecommendationsListComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('workflowResearchAndRecGrid', {static: true}) workflowPreMeetingGrid: CvsAgGridComponent;
    @Input() mainTabName = '';
    @Input() subTabIndex = 0;
    @Input() navigatedFromLink = '';
    @Output() isLoadingSpinner = new EventEmitter<boolean>();
    @Output() selectedRows = new EventEmitter<any>();

    statuses = [RECORD_STATUS.IN_RESEARCH, RECORD_STATUS.READY_FOR_CLINICAL_REQUIREMENT, RECORD_STATUS.READY_FOR_DISCUSSION];
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];
    superClientIndex;
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };
    workflowResearchAndRecommendationTabData: WorkflowResponse[] = [];
    popupParent: HTMLElement | null = document.querySelector('body');
    columnDefs: ColDef[] = [
        {
            headerName: 'Date', field: 'weekDate', valueGetter: params => dateTruncation(params.data.weekDate),
            valueFormatter: params => dateFormatter(params.data.weekDate), filter: 'agSetColumnFilter', width: 130,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        },
        {
            headerName: 'Update Type', field: 'updateType', filter: 'agSetColumnFilter', width: 195,
            valueGetter:  (params) => getUpdateTypeDisplayNameForWorkflow(params.data.updateType),
            filterParams: {
                comparator: (value1, value2) => {
                    if (value1.hierarchy === value2.hierarchy) {
                        return 0;
                    }
                    return value1.hierarchy > value2.hierarchy ? 1 : -1;
                }
            }
        },
        {
            headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelectorByUpdateType(params.data.updateType),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_REQUIREMENTS,
                mainTabName: this.mainTabName,
                subTabIndex: this.subTabIndex,
                navigatedFromLink: this.navigatedFromLink
            }
        },
        {headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', width: 200},
        {
            headerName: 'NDC', field: 'ndc', filter: 'agSetColumnFilter', width: 150,
            cellRendererSelector: (params) => gridHyperLinkSelector(params.data.ndc, 'N/A'),
            cellRendererParams: {
                source: PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_REQUIREMENTS,
                mainTabName: this.mainTabName,
                subTabIndex: this.subTabIndex,
                navigatedFromLink: this.navigatedFromLink
            },
        },
        {headerName: 'Label Name', field: 'labelName', filter: 'agSetColumnFilter', width: 200},
        {headerName: 'Line of Business', field: 'businessLob', filter: 'agSetColumnFilter', width: 140},
        {
            headerName: 'Notes', field: 'note', filter: 'agSetColumnFilter', width: 200,
            cellEditor: 'agLargeTextCellEditor',
            cellEditorPopup: true, editable: (params) => this.userService.hasEditPermission(),
            cellEditorPopupPosition: 'under',
            cellEditorParams: {cols: 80, maxLength: 5000},
            onCellValueChanged:(params) =>  this.recordService
                .saveNote(params.data.encryptedRecordId, params.data.note, this.userService.getSuperClientContext().id).subscribe(),
            suppressKeyboardEvent: (params) => this.suppressTabKey(params),
            tooltipField: 'note',
            tooltipComponent: 'customTooltipComponent',
            tooltipComponentParams: {title: 'Notes'}
        }];

    workflowResearchAndRecGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs,
        enableCellTextSelection: true,
        excelStyles: [{
            id: 'excelString',
            dataType: 'String'
        }],
        defaultExcelExportParams: {
            sheetName: undefined
        }
    };
    subscriptions = [];
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    gridHeight: string;

    private currentRowNodeSelection: IRowNode[];

    constructor(
        private recordService: RecordService,
        private trackerDataService: TrackerDataService,
        private appService: AppService,
        private workflowService: WorkflowService,
        private userService: UserService) {
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.subTabIndex = this.subTabIndex;
        this.columnDefs.find(cd => cd.field === 'ndc').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.mainTabName = this.mainTabName;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.subTabIndex = this.subTabIndex;
        this.columnDefs.find(cd => cd.field === 'gpi').cellRendererParams.navigatedFromLink = this.navigatedFromLink;
        this.subscribeToWorkflowMessage();
    }

    subscribeToWorkflowMessage() {
        this.subscriptions.push(this.workflowService.getMessageProvider().subscribe(workflowMessage => {
            this.workflowService.processWorkflowMessage(this.mainTabName, this.subTabIndex, this.statuses,
                this.workflowResearchAndRecGridOptions, this.workflowResearchAndRecommendationTabData,
                workflowMessage, () => this.getWorkFlowData(this.subTabIndex));
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getWorkFlowData(changes.subTabIndex?.currentValue);
    }

    setGridRowHeight(rows: number) {
        if (rows <= 20) {
            this.workflowResearchAndRecGridOptions.domLayout = 'autoHeight';
            this.gridHeight = '';
        } else if (this.subTabIndex !== 2) {
            this.gridHeight = '62.6vh';
        } else {
            this.gridHeight = '58vh';
        }
    }

    getWorkFlowData(subTabIndex: number) {
        this.workflowResearchAndRecGridOptions.suppressNoRowsOverlay = true;

        this.superClientIndex = this.appService.getSuperClientContext().id;
        this.trackerDataService.getWorkflowData(this.statuses[subTabIndex], this.superClientIndex).pipe(take(1))
            .subscribe(response => {
                if(response) {
                    this.workflowResearchAndRecommendationTabData = response;
                }
                this.isLoadingSpinner.emit(false);
                this.workflowResearchAndRecGridOptions.suppressNoRowsOverlay = false;
                this.workflowResearchAndRecGridOptions.api.showNoRowsOverlay();

            }, () => {
                this.isLoadingSpinner.emit(false);
            });
        this.exportToExcelTabName(subTabIndex);
    }

    exportToExcelTabName(subTabIndex: number) {
        if (subTabIndex === 0) {
            this.workflowResearchAndRecGridOptions.defaultExcelExportParams.sheetName =
               WORKFLOW_TABS.TAB1RESEARCH_AND_REQUIREMENTS.subTabs[0].name;
        } else if (subTabIndex === 1) {
            this.workflowResearchAndRecGridOptions.defaultExcelExportParams.sheetName = 'Ready for Clinical Recommend';
        } else if (subTabIndex === 2) {
            this.workflowResearchAndRecGridOptions.defaultExcelExportParams.sheetName =
               WORKFLOW_TABS.TAB1RESEARCH_AND_REQUIREMENTS.subTabs[2].name;
        }
    }

    onSelectionChanged(event: AgGridEvent) {
        this.currentRowNodeSelection = event.api.getSelectedNodes();
        this.selectedRows.emit(event.api.getSelectedNodes());
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(item => item.unsubscribe());
    }

    suppressTabKey(params: SuppressKeyboardEventParams) {
        if (params.event.key === 'Tab') {
            this.workflowResearchAndRecGridOptions.api.stopEditing();
        }
        return false;
    }

}
