/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RegulatoryTrackingFields } from '../tracking-dates.component';
import { FormControl } from '@angular/forms';
import {utcDate} from '../../../utility/utility';

@Component({
    selector: 'app-regulatory-tracking',
    templateUrl: './regulatory-tracking.component.html',
    styleUrls: ['./regulatory-tracking.component.scss']
})
export class RegulatoryTrackingComponent implements OnChanges {
    @Input() regulatoryTrackingValue!: RegulatoryTrackingFields;
    umDate = new FormControl();
    frcDate = new FormControl();
    ptDate = new FormControl();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        const trackerData = changes.regulatoryTrackingValue.currentValue;
        trackerData?.pendingUmDate && this.umDate.setValue(new Date(utcDate(trackerData?.pendingUmDate)));
        trackerData?.frcDate && this.frcDate.setValue(new Date(utcDate(trackerData?.frcDate)));
        trackerData?.pendingPTDate && this.ptDate.setValue(new Date(utcDate(trackerData?.pendingPTDate)));
    }

}
