<div class="header">
    <div style="width: 60%">
        <h2 class="page-heading">Products & Drug Coverage</h2>
        <span>Indicate whether the drug should be applied to the following below:</span>
    </div>
</div>
<div class="margin-top drugCoverageGrid">
    <cvs-loader-overlay-container id="drugCoverageSpinnerSpinner" [isLoading]="showSpinner"
                                  loadingMessage="Loading">
        <app-cvs-ag-grid id="requirementsGrid"
                         class="ag-theme-balham margin-top"
                         [gridOptions]="activeGridOptions"
                         [modules]="modules"
                         [rowData]="requirementDrugInfo"
                         [getContextMenuItems]="getContextMenuItems"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [context]="context"
                         [ngStyle]="{'height': gridHeight}"

        >
        </app-cvs-ag-grid>
    </cvs-loader-overlay-container>
</div>