<div [formGroup]="formularyForm" class="form-group-base floating-box">
    <div style="margin-left: 5px; margin-bottom: 10px">
        <div class="formulary-hdr-container">
            <h1 id="formularyHeader">Formularies ({{formularies?.length}})</h1>
            <span class="right-justified">
                <button class="pds-btn-primary back-and-save-button" (click)="saveFormularyRequirements()">
                    Save Requirements</button>
            </span>
        </div>
    </div>
    <div id="fieldCardsContainer"  style="overflow-y:auto;height: 63vh">
        <div class="formulary-hdr-button">
            <p id="tierStatement">All fields marked with an asterisk (*) are required</p>
            <span class="right-justified-btn">
                <button class="pds-btn-white">
                    <mat-icon svgIcon="collapse--xs"></mat-icon>
                    Collapse All
                </button>
                <button class="pds-btn-white">
                    <mat-icon svgIcon="expand--xs"></mat-icon>
                    Expand All
                </button>
            </span>
        </div>
        <ng-container formArrayName="formularyArray">
            <div *ngFor="let f of formularyRequirement.controls; let i=index">
            <mat-accordion multi>
                <mat-expansion-panel [@.disabled]="true" #panel [expanded]="panelOpenState" class="formulary-mat-expansion">
                    <mat-expansion-panel-header class="formulary-collapse-hdr">
                        <mat-panel-title id="formularyTitle">
                            <div class="formulary-div-hdr"><h2>{{formularies[i].clientFormularyName}} {{formularies[i].formularyNumber}}</h2></div>
                            <div class="formulary-div-hdr-icon"><app-custom-state id="recommendationStatusAll" [status]="mappedStatus[formularies[i].formularyNumber]"></app-custom-state></div>
                            <div id="formulary-{{formularies[i].formularyNumber}}" class="anchorTarget"></div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div [formGroupName]="i">
                        <mat-card>
                            <div id="tierFormularyGridAll" class="sub-field-font">
                                <mat-card-content *ngIf="!isLoading">
                                    <mat-form-field>
                                        <mat-select placeholder="Formulary Coverage" formControlName="formularyCoverageId" >
                                            <mat-option *ngFor="let coverage of getMappedFormularyRuleCoverages(formularies[i].formularyNumber)" [value]= "coverage.id"  (click)="formularyCoverageSelected(coverage, i)">
                                                {{coverage.formularyRequirementName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content>
                                    <mat-form-field id="formularyRequirementsDescriptionAll">
                                        <app-smart-input
                                                placeholder="Formulary Requirement Description"
                                                maxlength="100"
                                                disablePastingSpecialCharacters="true"
                                                disableSpecialCharacters="true"

                                                formControlName="formularyReqDesc">
                                        </app-smart-input>

                                        <mat-error>
                                            <mat-icon svgIcon="error-f--xs"></mat-icon>Formulary Requirement Description</mat-error>
                                        <mat-error>
                                            <mat-icon svgIcon="warning-circle--xs"></mat-icon> Pasted data truncated to 100 characters
                                        </mat-error>
                                        <mat-error>
                                            <mat-icon svgIcon="warning-circle--xs"></mat-icon>Special characters ( < > ; ) removed</mat-error>
                                        <mat-error class="longError-width">
                                            <mat-icon class="longError-icon" svgIcon="warning-circle--xs"></mat-icon>Pasted data truncated to 100 characters and special characters ( < > ; ) removed</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content>
                                    <mat-form-field id="formularyRequirementsCategoryAll">
                                        <app-smart-input
                                                placeholder="Formulary Requirement Category"
                                                maxlength="100"
                                                disablePastingSpecialCharacters="true"
                                                disableSpecialCharacters="true"

                                                formControlName="formularyReqCategory">
                                        </app-smart-input>

                                        <mat-error>
                                            <mat-icon svgIcon="error-f--xs"></mat-icon>Formulary Requirement Category</mat-error>
                                        <mat-error>
                                            <mat-icon svgIcon="warning-circle--xs"></mat-icon> Pasted data truncated to 100 characters
                                        </mat-error>
                                        <mat-error>
                                            <mat-icon svgIcon="warning-circle--xs"></mat-icon>Special characters ( < > ; ) removed</mat-error>
                                        <mat-error class="longError-width">
                                            <mat-icon class="longError-icon" svgIcon="warning-circle--xs"></mat-icon>Pasted data truncated to 100 characters and special characters ( < > ; ) removed</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                            <div id="tierRejectionMessagingAll" class="sub-field-font">
                                <mat-card-content>
                                    <mat-form-field>
                                        <app-smart-input
                                                placeholder="Rejection Messaging"
                                                maxlength="100"
                                                disablePastingSpecialCharacters="true"
                                                disableSpecialCharacters="true"

                                                formControlName="rejectionMessaging">
                                        </app-smart-input>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                            <div id="tierAttachmentGridAll" class="sub-field-font">
                                <mat-card-content>
                                    <mat-form-field>
                                        <mat-select placeholder="Attachment Type" formControlName="attachmentType">
                                            <mat-option *ngFor="let attachmentType of attachmentTypesKey[i]" [value]="attachmentType">
                                                {{attachmentType}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Type</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content>
                                    <mat-form-field>
                                        <mat-select placeholder="Attachment Level" formControlName="attachmentLevel">
                                            <mat-option *ngFor="let level of attachmentLevelsKey[i]" [value]="level[1]">({{(level[0].replace('_', ' '))}}) {{level[1]}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content *ngIf="['GPI'].includes(formularyForm.value['formularyArray'][i]['attachmentType'])">
                                    <mat-form-field>
                                        <mat-select #attachmentMony
                                                    multiple
                                                    placeholder="Attachment MONY"
                                                    formControlName="attachmentMonyDD">
                                            <mat-checkbox #attachmentMonyCheckbox
                                                          class="margin"
                                                          formControlName="attachmentMonyCheckbox"
                                                          [indeterminate]=" monyIndeterminates[i]"
                                                          click="$event.preventDefault()"
                                                          (change)="toggleAllSelection(i)">
                                                Select All
                                            </mat-checkbox>
                                            <mat-option *ngFor="let data of attachmentMonyList"
                                                        [value]="data.code"
                                                        (click)="onAttachmentMonyDDChange(i)">{{data.description}}</mat-option>
                                        </mat-select>
                                        <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content *ngIf="[undefined, null,'NDC'].includes(formularyForm.value['formularyArray'][i]['attachmentType'])">
                                    <mat-form-field>
                                        <app-smart-input
                                                placeholder="Attachment MONY"
                                                formControlName="monyCode">
                                        </app-smart-input>
                                        <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                            <div id="tierAttachmentRxOtcAll" class="sub-field-font">
                                <mat-card-content>
                                    <mat-form-field>
                                        <mat-select matInput placeholder="Attachment Rx/OTC" formControlName="coverageMiscRxOtc">
                                            <mat-option *ngFor="let data of rxOtcList"
                                                        [value]="data.code">{{data.description}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content *ngIf="blob === BUSINESS_LOB.MEDICARE">
                                    <mat-form-field>
                                        <mat-select  matInput placeholder="Attachment Med D" formControlName="coverageMiscMedD">
                                            <mat-option *ngFor="let data of medDList"
                                                        [value]="data.code">{{data.description}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                            <div>
                                <mat-checkbox #checkbox id="negativeChangeAll{{i}}" formControlName="negativeChange">Is this a negative change?</mat-checkbox>
                            </div>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            </div>
        </ng-container>
    </div>
</div>
