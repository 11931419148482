<div>
    <div>{{multiSelectModalService.bodyMessage}}</div>
    <div class="scrollable-area">
        <mat-selection-list #matSelectionList (selectionChange)="selectionChange($event)">
            <mat-list-option color="primary" checkboxPosition="before" (selectedChange)="selectAllChange($event)">
                Select All
            </mat-list-option>
            <mat-list-option *ngFor="let l of multiSelectModalService.listItems"
                             checkboxPosition="before"
                             color="primary"
                             [value]="l.value">
                {{l.name}} ({{l.value}})
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
