<div id="errorBanner"></div>
<div id="reportAlertBanner"></div>
<h1 class="title">Reports</h1>
<h2 id="requestReportsSubheader">Request Reports</h2>
<cvs-loader-overlay-container id="reportSpinner"
                              [isLoading]="downloadCount!==0"
                              [loadingMessage]="'Downloading Report(s)...'">
    <p id="reportDescription" class="padding-top-bottom font-size-h5">Report time may vary depending on file size and number of
        requests
        in the queue.</p>

    <form class="container" [formGroup]="reportFormGroup">
        <mat-form-field  id="reportTypeFormField" class="width padding-right">
            <mat-label id="reportTypeLabel">Report Type</mat-label>
            <mat-select #reportTypeFormField formControlName="reportType">
                <mat-option *ngFor="let reportType of reportsTypes; index as i;"
                            id="reportType-{{i}}"
                            [value]="reportType.code">{{reportType.description}}</mat-option>
            </mat-select>
            <mat-error id="reportTypeError">Error: Select report type</mat-error>
        </mat-form-field>
        <pds-autocomplete-select #formularyList
                                id="formulary"
                                 class="padding-right"
                                 [dropDownOptions]="formularyOptions"
                                 placeholder="Formulary"
                                 [required]="true"
                                 [errorMsgs]="formularyErrorMessage"
                                 formControlName="formulary"
                                 displayNameKey="description">
        </pds-autocomplete-select>

        <app-date-picker (dateEvent)="getDates($event)"
                         [currentDate]="fromDate">
        </app-date-picker>

        <button id="requestReportButton"
                class="cvs-btn-ghost margin-left"
                (click)="submitReportRequest()"
                > Request Report
        </button>
    </form>

    <div class="margin-top">
        <h2 id="reportRepositorySubHeader">Report Repository</h2>
        <p id="reportRepositorytext" class="padding-top-bottom font-size-h5">
            Once generated, reports will only be available for 7 days. The table will refresh automatically every minute.
        </p>
        <div class="align-center">
            <button class="cvs-btn-ghost"
                    id="downloadButton"
                    [attr.aria-disabled]="downloadButtonDisabled"
                    [cvsTooltip]="downloadButtonDisabled ? downloadReportTooltip : ''"
                    [placement]="'right'"
                    [tooltip-class]="'download-tooltip'"
                    showArrow
                    (click)="downloadAllSelectedReports()"
                    [style]="downloadButtonDisabled? 'border: gray' : ''">Download
            </button>
        </div>
    </div>

    <app-cvs-ag-grid #reportsPageGrid
                     id="reportRepositoryGrid"
                     class="ag-theme-balham margin-top"
                     [gridOptions]="reportsPageGridOptions"
                     [modules]="modules"
                     [rowData]="reportsPageData"
                     [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                     [popupParent]="popupParent"
                     [disableCvsExport]="true"
                     (selectionChanged)="onSelectionChanged($event)"></app-cvs-ag-grid>
</cvs-loader-overlay-container>
