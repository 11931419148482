<cvs-loader-overlay-container id="copyRecommendationSpinner" [isLoading]="_gridDisplayModalService.showSpinner" loadingMessage="Loading"></cvs-loader-overlay-container>
<app-business-lob-tabs (selectedTab)="onBusinessLobTabSelectionChange($event)" [ndcBusinessLob]="ndcBusinessLob" [isPrimaryTab]="false"
>
</app-business-lob-tabs>
<app-recommendations-formulary-requirements [medispanTrackerResponse]="medispanTrackerResponse"
                                             [mainTabName]="mainTabName"
                                             [subTabIndex]="subTabIndex"
                                             [fromSource]="fromSource"
                                             (showRequirementSpinnerEmitter)="updateLoadUm($event)"
                                            (productDetailEmitter)=getProductDetails($event);
></app-recommendations-formulary-requirements>

<app-recommendations-um-program [medispanTrackerResponse]="medispanTrackerResponse"
                                [mainTabName]="mainTabName"
                                [subTabIndex]="subTabIndex"
                                [fromSource]="fromSource"
                                [updateLoading]="formualryRequirementsGridUpdated"
                                [productDetail] ="productDetails"
></app-recommendations-um-program>
<app-recommendations-product-and-drug-coverage [medispanTrackerResponse]="medispanTrackerResponse"
                                               [mainTabName]="mainTabName"
                                               [subTabIndex]="subTabIndex"
                                               [fromSource]="fromSource"
                                               [updateLoading]="formualryRequirementsGridUpdated"
                                               [productDetail] ="productDetails"
></app-recommendations-product-and-drug-coverage>