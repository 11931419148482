import { Injectable } from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {HttpDataCache} from '../HttpDataCache';
import {Observable} from 'rxjs';
import _ from 'lodash';
import {map, tap} from 'rxjs/operators';

// eslint-disable-next-line no-shadow
export enum FEATURE_NAME {
    WORKFLOW_PAGE = 'WORKFLOW_PAGE',
    REPORTS_PAGE = 'REPORTS_PAGE',
    WORKFLOW_PAGE_EXTERNAL = 'WORKFLOW_PAGE_EXTERNAL'
}

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService extends HttpDataCache {
    private featureFlags = {};
    private URL = 'featureFlags';

    constructor(private cvsHttpClient: CVSHttpClient) {
        super(cvsHttpClient);
    }

    getFeatureFlags(): Observable<{}> {
        return this.getCachedHttpData(`${this.URL}`)
            .pipe(
                tap( response => this.featureFlags = _.keyBy(response, 'featureName')),
                map( response => this.featureFlags)
            );
    }

    getFeatureFlag(featureName: FEATURE_NAME): Observable<boolean> {
        return this.getCachedHttpData(`${this.URL}/${featureName}`);
    }

    isFeatureEnabled(featureName: FEATURE_NAME): boolean {
        return this.featureFlags[featureName]?.enabled === true;
    }

}
