<ng-container>
    <form [formGroup]="formularyRequirementForm" (ngSubmit)="saveFormularyRequirement()" class="modal-wrapper">
        <div class="formulary-requirement-form">
            <p>Provide formulary requirement name, rule date range and description below. All fields marked with an asterisk (*) are required.</p>
            <mat-form-field [ngClass]="{'form-warning' :formularyRequirementForm.controls.name.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Formulary Requirement Name *"
                        maxlength="30"
                        formControlName="name">
                </app-smart-input>
                <mat-error *ngIf="formularyRequirementForm.controls.name.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Formulary Requirement Name
                </mat-error>
                <mat-error *ngIf="formularyRequirementForm.controls.name.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{formularyRequirementForm.controls.name.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field [ngClass]="{'form-warning' :formularyRequirementForm.controls.description.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Formulary Requirement Description *"
                        maxlength="50"
                        formControlName="description">
                </app-smart-input>
                <mat-error *ngIf="formularyRequirementForm.controls.description.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Formulary Requirement Description
                </mat-error>
                <mat-error *ngIf="formularyRequirementForm.controls.description.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{formularyRequirementForm.controls.description.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Formulary Requirement View Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="viewPicker">
                    <input matStartDate formControlName="viewStartDate">
                    <input matEndDate formControlName="viewEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="vdrDateRangePicker" matSuffix [for]="viewPicker">
                    <mat-icon id="vdrDateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #viewPicker></mat-date-range-picker>
                <mat-error *ngIf="formularyRequirementForm.controls.viewStartDate.errors || formularyRequirementForm.controls.viewEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Formulary Requirement Effective Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="effectiveStartDate">
                    <input matEndDate formControlName="effectiveEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="edrDateRangePicker" matSuffix [for]="picker">
                    <mat-icon id="edrDateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="formularyRequirementForm.controls.effectiveStartDate.errors || formularyRequirementForm.controls.effectiveEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Formulary Coverage Category</mat-label>
                <mat-select pdsMatOptionKeyboardFocus formControlName="category">
                    <mat-option *ngFor="let coverageType of coverageTypes" [value]="coverageType.code">
                        {{coverageType.description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formularyRequirementForm.controls.category.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Select a Formulary Coverage Category
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Coding Setup</mat-label>
                <mat-select pdsMatOptionKeyboardFocus formControlName="coding" >
                    <mat-option *ngFor="let codingType of codingTypes" [value]="codingType" (onSelectionChange)="selectedCoding($event)">
                        {{codingType}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formularyRequirementForm.controls.coding.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Select a Coding Setup
                </mat-error>
            </mat-form-field>
        </div>

        <div class="cards-content" *ngIf="codingTypeSelected && codingTypeSelected != CODING_TYPES.REMOVE_CD">
            <app-coding-type
                    [formularyData]="formularyData"
                    [formularyRequirementForm]="formularyRequirementForm"
                    [codingTypeSelected]="codingTypeSelected"
                    [planGPIAndJList] = "planGPIAndJList"
                    [preferredGPIList] = "preferredGPIList"
                    [preferredNDCList] = "preferredNDCList"
                    [showDrugListSpinner]="showSpinner"
                    [isAnUpdate]="isAnUpdate"
            >
            </app-coding-type>
            <app-coding-type *ngIf="codingTypeSelected === CODING_TYPES.STD_GPI_NDC_CD" [formularyData]="formularyData"
                    [formularyRequirementForm]="formularyRequirementForm"
                    [codingTypeSelected]="codingTypeSelected"
                             [showDrugListSpinner]="showSpinner"
                    [codingTypeStdGpiNdcCdPreferredGPI]="CODING_TYPES.GPI_WITH_RESTRICT_NDC_CD"
                    [preferredGPIList] = "preferredGPIList"
                             [isAnUpdate]="isAnUpdate"
                             >
            </app-coding-type>
        </div>
        <div class="form-buttons">
            <button class="pds-btn-white" (click)="onModalCancel()" type="reset">Cancel</button>
            <button class="pds-btn-primary" type="submit" >{{getButtonText()}}</button>
        </div>
    </form>
</ng-container>