
<div class="requirements-header">
    <h1>Requirements</h1>
    <mat-icon id="requirementsHeaderIcon"
              svgIcon="info-circle--s"
              [matTooltip]="toolTipMessage"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'requirements-left-nav-tooltip'">
    </mat-icon>
</div>
<div style="overflow-y:auto; height: 63vh">
    <mat-tree [dataSource]="dataSourceFormulary" [treeControl]="treeControlFormulary" class="pds-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <a href="recommendationsForm#{{node.link}}"
               (click)="selectedFormulary(node.formulary)"
               [style]="clickedFormulary===node.formulary?.formularyNumber ? 'color: #c00;background-color: lightgrey;font-weight: 700;' : 'color: black'">
                {{node.name}}
            </a>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isParent" matTreeNodePadding [style]="clickedParentCode === TREE_LEVELS.FORMULARY.code ? 'font-weight: bold' : 'font-weight: normal'">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon *ngIf=node.expandable class="mat-icon-rtl-mirror">
                    {{treeControlFormulary.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.name}}
        </mat-tree-node>
    </mat-tree>

    <mat-tree [dataSource]="dataSourceUmProgram" [treeControl]="treeControlUmProgram" class="pds-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <a href="recommendationsForm#{{node.link}}"
               (click)="selectedUmProgram(node)"
               [style]="clickedUmProgram===node.umProgram?.umProgramName && clickedRank === node.umProgram?.rank ? 'color: #c00;background-color: lightgrey;font-weight: 700;' : 'color: black'">
                {{node.name}}
            </a>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isParent" matTreeNodePadding [style]="(clickedParentCode > TREE_LEVELS.FORMULARY.code && clickedParentCode < TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code) ? 'font-weight: bold' : 'font-weight: normal'">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon *ngIf=node.expandable class="mat-icon-rtl-mirror">
                    {{treeControlUmProgram.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.name}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isSubtree" matTreeNodePadding [style]="(clickedParent===node.name && clickedParentCode > TREE_LEVELS.FORMULARY.code && clickedParentCode < TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code) ? 'font-weight: bold' : 'font-weight: normal'">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControlUmProgram.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.name}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isNodeEnd" matTreeNodePadding [style]="'padding-left:75px'">
            {{node.name}}
        </mat-tree-node>
    </mat-tree>

    <mat-tree [dataSource]="dataSourceProductDrugCoverage" [treeControl]="treeControlProductDrugCoverage" class="pds-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <a href="recommendationsForm#{{node.link}}"
                (click)="selectedProductDrugCoverage(node)"
                [style]="clickedProductDrugCoverage===node.productDrugCoverage?.productDrugCoverageName && clickedRank === node.productDrugCoverage?.rank? 'color: #c00;background-color: lightgrey;font-weight: 700;' : 'color: black'">
                {{node.name}}
            </a>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: isParent" matTreeNodePadding [style]="clickedParentCode === TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code ? 'font-weight: bold' : 'font-weight: normal'">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon *ngIf=node.expandable class="mat-icon-rtl-mirror">
                    {{treeControlProductDrugCoverage.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            {{node.name}}
        </mat-tree-node>
    </mat-tree>
</div>