// eslint-disable-next-line no-shadow
export interface SearchOptionsData {
    code: string;
    formattedSearchResult: string;
    altSearchParam?: string;
}
// eslint-disable-next-line no-shadow
export enum NEW_CLIENT_BLOB_GROUP {
    MEDICARE,
    NON_MEDICARE,
    BOTH
}

export interface DefaultCodeDescription {
    code: string;
    description: string;
}

export interface MedispanUpdate {
    type: string;
    value: string;
    checked: boolean;
    updateRepackager?: { value: string; checked: boolean };
    blobGroup?: NEW_CLIENT_BLOB_GROUP;
}

export const NA = 'N/A';

// eslint-disable-next-line no-shadow
export enum QL_TYPE {
    BLANK = '',
    NO_CHANGE = 'No Change',
    ZERO = '0 - None',
    ONE = '1 - Max Daily Dose',
    TWO = '2 - Quantity Over Time (QVT)'
}

// eslint-disable-next-line no-shadow
export enum ATTACHMENT_LEVEL {
    GPI = 'GPI',
    NDC = 'NDC'
}

// eslint-disable-next-line no-shadow
export enum ATTACHMENT_LEVEL_CATEGORIES {
    GPI_14 = 'GPI_14',
    GPI_12 = 'GPI_12',
    GPI_10 = 'GPI_10',
    GPI_8 = 'GPI_8',
    NDC_11 = 'NDC_11',
    NDC_5 = 'NDC_5',
    NDC_9 = 'NDC_9'
}

export const ATTACHMENT: DefaultCodeDescription[] = [
    {
        code: '0',
        description: 'No Change',
    },
    {
        code: '1',
        description: 'Attach',
    },
    {
        code: '2',
        description: 'Remove Attachment',
    }
];

export const ATTACHMENT_MONY: DefaultCodeDescription[] = [
    {
        code: 'M',
        description: 'M'
    },
    {
        code: 'O',
        description: 'O'
    },
    {
        code: 'N',
        description: 'N'
    },
    {
        code: 'Y',
        description: 'Y'
    }
];

export const PA_SELECTION_MEDICARE = {
    NO_CHANGE: {
        code: NA,
        description: 'No Change',
        gridDisplay: ''
    },
    ZERO: {
        code: '0',
        description: '0 - None',
        gridDisplay: '0 - None'
    },
    ONE: {
        code: '1',
        description: '1 - PA for all',
        gridDisplay: '1 - PA'
    },
    TWO: {
        code: '2',
        description: '2 - PA for new starts only',
        gridDisplay: '2 - PA'
    },
    THREE: {
        code: '3',
        description: '3 - B vs D'
        ,
        gridDisplay: '3 - PA'
    },
    ONEPLUSTHREE: {
        code: '1+3',
        description: '1+3 - PA 1 (embedded BvD)',
        gridDisplay: '1+3 - PA'
    },
    TWOPLUSTHREE: {
        code: '2+3',
        description: '2+3 - PA 2 (embedded BvD)',
        gridDisplay: '2+3 - PA'
    }
};
export const PA_SELECTION_COM_EX_MED: DefaultCodeDescription[] = [
    {
        code: NA,
        description: 'No Change'
    },
    {
        code: '1',
        description: 'Prior Authorization'
    },
    {
        code: '2',
        description: 'Age Min Prior Authorization'
    },
    {
        code: '3',
        description: 'Age Max Prior Authorization'
    },
    {
        code: '4',
        description: 'Age Min and Max Prior Authorization'
    },
    {
        code: '5',
        description: 'Exclude Male Prior Authorization'
    }, {
        code: '6',
        description: 'Exclude Female Prior Authorization'
    },
    {
        code: '7',
        description: 'Remove Prior Authorization'
    },


];
export const STEP_TYPE_MEDICARE: DefaultCodeDescription[] = [
    {
        code: '0',
        description: 'No Change'
    },
    {
        code: '1',
        description: '0 - None'
    },
    {
        code: '2',
        description: '1 - Step Therapy for all'
    },
    {
        code: '3',
        description: '2 - Step Therapy for new starts only'
    }
];
export const STEP_TYPE_OTHERS: DefaultCodeDescription[] = [
    {
        code: '0',
        description: 'No Change'
    },
    {
        code: '1',
        description: 'Step Therapy Target'
    },
    {
        code: '2',
        description: 'Remove Step Therapy'
    }
];
export const NOT_APPLICABLE = 'N/A';
export const NA_LIST_ITEM = [NOT_APPLICABLE];
export const QL_TYPE_MEDICARE: DefaultCodeDescription[] = [
    {
        code: NA,
        description: QL_TYPE.NO_CHANGE
    },
    {
        code: '0',
        description: QL_TYPE.ZERO
    },
    {
        code: '1',
        description: QL_TYPE.ONE
    },
    {
        code: '2',
        description: QL_TYPE.TWO
    }
];

export const QL_TYPE_FOR_ALL: DefaultCodeDescription[] = [
    {
        code: NA,
        description: 'No Change'
    },
    {
        code: '1',
        description: 'Max Daily Dose'
    },
    {
        code: '2',
        description: 'Quantity Max'
    },
    {
        code: '3',
        description: 'Day Supply Max'
    },
    {
        code: '4',
        description: 'Quantity Over Time (QVT)'
    },
    {
        code: '5',
        description: 'Day Supply Over Time'
    },
    {
        code: '6',
        description: 'Max Fill Limit'
    },
    {
        code: '7',
        description: 'Remove Max Daily Dose'
    },
    {
        code: '8',
        description: 'Remove Quantity Max'
    },
    {
        code: '9',
        description: 'Remove Day Supply Max'
    },
    {
        code: '10',
        description: 'Remove Quantity Over Time (QVT)'
    },
    {
        code: '11',
        description: 'Remove Day Supply Over Time'
    },
    {
        code: '12',
        description: 'Remove Max Fill Limit'
    },

];


export const QL_TYPE_COM_EX_MED_EDITABLE = [
    'No Change',
    'Min Daily Dose',
    'Max Daily Dose',
    'Quantity Min',
    'Quantity Max',
    'Day Supply Min',
    'Day Supply Max',
    'Quantity Over Time (QVT)',
    'Day Supply Over Time',
    'Max Fill Limit',
    'Cumulative Quantity (Must Wildcard)',
    'Remove Min Daily Dose',
    'Remove Max Daily Dose',
    'Remove Quantity Min',
    'Remove Quantity Max',
    'Remove Day Supply Min',
    'Remove Day Supply Max',
    'Remove Quantity Over Time (QVT)',
    'Remove Max Fill Limit',
    'Remove Cumulative Quantity (Must Wildcard)'
];

export const RX_OTC: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Rx'
    },
    {
        code: '2',
        description: 'OTC'
    },
    {
        code: '3',
        description: 'All'
    }
];

export const MED_D: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Include Med D Only'
    },
    {
        code: '2',
        description: 'Include Non Med D Only'
    },
    {
        code: '3',
        description: 'All Products'
    }
];

export const AGE_LIMIT: DefaultCodeDescription[] = [
    {
        code: NA,
        description: 'No Change'
    },
    {
        code: '0',
        description: '0 - None'
    },
    {
        code: '1',
        description: 'Min'
    },
    {
        code: '2',
        description: 'Max'
    },
    {
        code: '3',
        description: 'Min & Max'
    },
    {
        code: '4',
        description: 'Remove Age Limits'
    }
];

export const AGE_LIMIT_VALUES: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Month(s)'
    },
    {
        code: '2',
        description: 'Year(s)'
    }
];

export const SEX_EXCLUSIONS = [
    {
        code: NA,
        description: 'No Change'
    },
    {
        code: '0',
        description: '0 - None'
    },
    {
        code: '2',
        description: 'Exclude Female'
    },
    {
        code: '3',
        description: 'Exclude Male'
    },
    {
        code: '4',
        description: 'Remove Gender Edits'
    }
];

export const BUSINESS_LOB_DESC_CD = new Map<string, string>([
    ['Medicare', 'BM'],
    ['Commercial', 'BC'],
    ['Exchange', 'BX'],
    ['Medicaid', 'BE']
]);

export const BUSINESS_LOB_NAME = new Map<string, string>([
    ['BM', 'Medicare'],
    ['BC', 'Commercial'],
    ['BX', 'Exchange'],
    ['BE', 'Medicaid']
]);

export const BUSINESS_LOB_CD = {
    MEDICARE: 'BM',
    COMMERCIAL: 'BC',
    EXCHANGE: 'BX',
    MEDICAID: 'BE'
};

export const MEDISPAN_UPDATES: MedispanUpdate[] = [
    {type: 'New GPI', value: undefined, checked: undefined, blobGroup: NEW_CLIENT_BLOB_GROUP.BOTH},
    {type: 'New NDC', value: undefined, checked: undefined, blobGroup: NEW_CLIENT_BLOB_GROUP.BOTH},
    {type: 'Maintenance Change', value: undefined, checked: undefined, blobGroup: NEW_CLIENT_BLOB_GROUP.BOTH},
    {
        type: 'MONY (GI) Change', value: undefined, checked: undefined,
        updateRepackager: {value: undefined, checked: undefined}, blobGroup: NEW_CLIENT_BLOB_GROUP.BOTH
    },
    {type: 'Rx-OTC Change', value: undefined, checked: undefined, blobGroup: NEW_CLIENT_BLOB_GROUP.BOTH},
    {type: 'GPI Change', value: undefined, checked: undefined},
    {type: 'Med D Indicator Change', value: undefined, checked: undefined, blobGroup: NEW_CLIENT_BLOB_GROUP.MEDICARE},
    {type: 'FDA Application Change', value: undefined, checked: undefined},
    {
        type: 'Participating Labeler change',
        value: undefined,
        checked: undefined,
        blobGroup: NEW_CLIENT_BLOB_GROUP.MEDICARE
    }
];

export const MAP_MEDISPAN_UPDATES_TO_MEDISPAN_SETTING: Map<string, string> = new Map<string, string>([
    ['New GPI', 'newGpi'],
    ['New NDC', 'newNdc'],
    ['Maintenance Change', 'maintenanceChange'],
    ['MONY (GI) Change', 'monyGiChange'],
    ['updateRepackager', 'repackager'],
    ['Rx-OTC Change', 'rxOtcChange'],
    ['GPI Change', 'gpiChange'],
    ['Med D Indicator Change', 'medDIndicatorChange'],
    ['FDA Application Change', 'fdaApplicationChange'],
    ['Participating Labeler change', 'participatingLabelerChange'],
]);

export const CLIENT_MULTI_SOURCE_SETUP = [
    {
        code: 'CR',
        description: 'Caremark'
    },
    {
        code: 'CM',
        description: 'Caremark & Medispan'
    }
];

export const UM_PROGRAM_TYPE: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Prior Authorization'
    },
    {
        code: '2',
        description: 'Step Therapy'
    },
    {
        code: '3',
        description: 'Quantity Limit'
    }
];

export const PRODUCT_DRUG_COVERAGE_TYPE: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Drug Coverage',
    },
    {
        code: '2',
        description: 'Health Care Reform',
    },
    {
        code: '3',
        description: 'Vaccine',
    },
    {
        code: '4',
        description: 'Benefit',
    },
    {
        code: '5',
        description: 'Regulatory',
    },
    {
        code: '6',
        description: 'Other',
    }
];

// @ts-ignore
export const COVERAGE_TYPES: DefaultCodeDescription[] = [
    {
        code: '1',
        description: 'Coverage'
    },
    {
        code: '2',
        description: 'Non-Formulary',
    },
    {
        code: '3',
        description: 'Exclusion',
    }
];

export const QL_UNIT_OF_MEASURE: SearchOptionsData[]= [

    {
        code: '1',
        formattedSearchResult: 'tab(s)'
    },

    {
        code: '2',
        formattedSearchResult: 'cap(s)'
    },
    {
        code: '3',
        formattedSearchResult: 'mL(s)'
    },
    {
        code: '4',
        formattedSearchResult: 'gram(s)'
    },
    {
        code: '5',
        formattedSearchResult: 'unit(s)'
    },
    {
        code: '6',
        formattedSearchResult: 'syringe(s)'
    },
    {
        code: '7',
        formattedSearchResult: 'injection(s)'
    },
    {
        code: '8',
        formattedSearchResult: 'auto injector(s)'
    },
    {
        code: '9',
        formattedSearchResult: 'vial(s)'
    },
    {
        code: '10',
        formattedSearchResult: 'pen(s)'
    },
    {
        code: '11',
        formattedSearchResult: 'package(s)'
    },
    {
        code: '12',
        formattedSearchResult: 'packet(s)'
    },
    {
        code: '13',
        formattedSearchResult: 'bottle(s)'
    },
    {code: '14', formattedSearchResult: 'inhaler(s)'},
    {
        code: '15',
        formattedSearchResult: 'box(es)'
    },
    {
        code: '16',
        formattedSearchResult: 'kit(s)'
    },
    {
        code: '17',
        formattedSearchResult: 'pack(s)'
    },
    {
        code: '18',
        formattedSearchResult: 'patch(es)'
    },
    {
        code: '19',
        formattedSearchResult: 'container(s)'
    },
    {
        code: '20',
        formattedSearchResult: 'test strip(s)'
    },
    {
        code: '21',
        formattedSearchResult: 'starter pack(s)'
    },
    {
        code: '22',
        formattedSearchResult: 'lozenge(s)'
    },
    {
        code: '23',
        formattedSearchResult: 'film(s)'
    },
    {
        code: '24',
        formattedSearchResult: 'carton(s)'
    },
    {
        code: '25',
        formattedSearchResult: 'cartridge(s)'
    },
    {
        code: '26',
        formattedSearchResult: 'spray(s)'
    },
    {
        code: '27',
        formattedSearchResult: 'ampule(s)'
    },
    {
        code: '28',
        formattedSearchResult: 'pellet(s)'
    }
];
