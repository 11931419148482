<div class="margin-bottom">
    <ng-container *ngFor="let bodyMessage of gridDisplayModalService.bodyMessageParts">
        {{bodyMessage}}
        <br/>
    </ng-container>
</div>
<app-cvs-ag-grid
        id="massCopyGrid"
        style="width: 100%"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [style.height]="gridHeight"
        [modules]="modules"
        [rowData]="gridDisplayModalService.rowData"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (selectionChanged)="onSelectionChanged($event)"
        [disableCvsExport]="true">
</app-cvs-ag-grid>
<div class="footerMessageMargin">
    <ng-container>{{gridDisplayModalService.footerMessage}}</ng-container>
</div>
