<div>
    <app-date-picker id="archiveDateRangePicker"
                     [setDefaultDateRangePicker]=true
                     [currentDate]="endDate"
                     (dateEvent)="getDates($event)"
                     [dateRangeError]="dateRangeError"
                     [maxDate]="maxDate"
                     [parentSource]="'archive'">
    </app-date-picker>
</div>

<cvs-loader-overlay-container id="loadingSpinner"
                              [isLoading]="isLoading"
                              [loadingMessage]="'Loading Content...'">
    <app-cvs-ag-grid #workflowArchiveGrid id="workflowArchiveGrid"
                    style="width: 100%"
                    class="ag-theme-balham"
                    [gridOptions] = "workflowArchiveGridOptions"
                    [modules]="modules"
                    [rowData]="workflowArchiveData"
                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                    [popupParent]="popupParent"
                    [disableCvsExport]="true"
                    (firstDataRendered)="onFirstDataRendered($event)"
                    (rowDataUpdated)="onRowDataUpdated($event)"
                    [style.height]="gridHeight">
    </app-cvs-ag-grid>
</cvs-loader-overlay-container>

