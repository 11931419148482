import {Component, OnInit} from '@angular/core';
import {ICellEditorAngularComp} from '@ag-grid-community/angular';
import {ICellEditorParams, ICellRenderer, ICellRendererParams} from '@ag-grid-community/core';
import {MatButtonToggle} from '@angular/material/button-toggle';

// eslint-disable-next-line no-shadow
export enum CHECK_STATE {
    SELECTED = 'Y', NOT_SELECTED = 'N'
}

@Component({
    selector: 'app-custom-checkmark',
    templateUrl: './custom-toggle-button.component.html',
    styleUrls: ['./custom-toggle-button.component.scss']
})
export class CustomToggleButtonComponent implements OnInit, ICellEditorAngularComp, ICellRenderer {
    icon = '';
    class = '';
    value: string = CHECK_STATE.NOT_SELECTED;
    params;

    constructor() {
    }

    ngOnInit(): void {
    }

    agInit(params: ICellEditorParams & {
        checkedClass: string; unCheckedClass: string; checkedIcon: string; unCheckedIcon: string; hasEditPermission: boolean;
    }): void {
        this.params = params;
        this.class = params.unCheckedClass;
        this.value = params.data[this.params.column.getColDef().field];
        if (this.value === CHECK_STATE.SELECTED) {
            this.icon = params.checkedIcon;
            if (params.checkedClass) {
                this.class = params.checkedClass;
            }
        } else {
            this.icon = params.unCheckedIcon;
            this.class = params.unCheckedClass;
        }
    }

    getValue(): any {
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    onClick(toggleButton: MatButtonToggle) {
        if (this.params.hasEditPermission) {
            this.params.setValue(toggleButton.value === CHECK_STATE.SELECTED ? CHECK_STATE.NOT_SELECTED : CHECK_STATE.SELECTED);
        }
    }
}
