import {Injectable} from '@angular/core';
import {AuthorizationService} from 'angular-component-library';
import {UserService} from '../user/user.service';
import {FeatureFlagService} from '../feature-flag/feature-flag.service';
import {UserPreferenceService} from '../user-preference/user-preference.service';
import {Observable, of, ReplaySubject, Subject, zip} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {SuperClient} from '../../model/SuperClient';

// eslint-disable-next-line no-shadow
export enum APP_INITALIZATION {
    USER = 'USER',
    FEATURE_FLAGS = 'FEATURE_FLAGS',
    USER_PREFERENCE = 'USER_PREFERENCE'
}

@Injectable({
    providedIn: 'root'
})
export class AppService {

    initSubject = new ReplaySubject<Map<string, any>>();

    constructor(private authorizationService: AuthorizationService,
        private userService: UserService,
        private featureFlagService: FeatureFlagService,
        private userPreferenceService: UserPreferenceService) {
    }

    initializeServices(): Observable<Map<string, any>> {
        this.authorizationService.getAuthorizedUser()
            .pipe(tap(user => {
                this.userService.setUser(user);
            }))
            .subscribe(user => {
                zip(this.userService.getSuperClientContextSubject(),
                    this.featureFlagService.getFeatureFlags(),
                    this.userPreferenceService.loadUserPreference())
                    .pipe(take(1))
                    .subscribe((results) => {
                        const result = new Map<string, any>();
                        result.set(APP_INITALIZATION.USER, user);
                        result.set(APP_INITALIZATION.FEATURE_FLAGS, results[1]);
                        result.set(APP_INITALIZATION.USER_PREFERENCE, results[2]);
                        this.initSubject.next(result);
                    });
            });
        return this.initSubject;
    }

    getInitializationSubject() {
        return this.initSubject;
    }

    getSuperClientContext(): SuperClient {
        return this.userService.getSuperClientContext();
    }

}
