<ng-container>
    <form [formGroup]="moveRankForm" (ngSubmit)="saveMoveChanges()">
        <p>Select a rank to move to for {{ selectedComponentName }}</p>
        <div class="program-form">
            <mat-form-field appearance='outline' >
                <mat-label>Current Rank</mat-label>
                <input matInput formControlName="currentRank" [value]="currentRankIndex.toString()">
            </mat-form-field>
            <p class="move-to-label">Move to</p>
            <mat-form-field appearance='outline'>
                <mat-label>New Rank</mat-label>
                <mat-select formControlName="selectedRank" pdsMatOptionKeyboardFocus>
                    <mat-option *ngFor="let rank of rankList" [value]="rank">
                        {{rank}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="moveRankForm.controls.selectedRank.errors?.required" class="selected-error">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Select a Rank to Move to
                </mat-error>
            </mat-form-field>
            <div class="form-buttons">
                <button class="pds-btn-white" (click)="onModalCancel()">Cancel</button>
                <button class="pds-btn-primary" type="submit">Move</button>
            </div>
        </div>
    </form>
</ng-container>