import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BUSINESS_LOB} from '../../enum/BusinessLob';
import {
    ATTACHMENT_LEVEL, ATTACHMENT_LEVEL_CATEGORIES, ATTACHMENT_MONY,
    MED_D,
    RX_OTC,
} from '../../default-values/DefaultValues';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {ADHOC_UPDATE_TYPES} from '../tracker-data/tracker-data.service';

@Injectable({
    providedIn: 'root'
})
export class RecommendationsFormService {
    medispanTrackerResponse: MedispanTrackerResponse;
    attachmentTypes = [];
    attachmentLevels: [key: string, value: string][] = [];
    attachmentMonyList = ATTACHMENT_MONY;
    rxOtcList = RX_OTC;
    medDList = MED_D;
    constructor() { }

    setMedispanTrackerResponse(medispanTrackerResponse) {
        this.medispanTrackerResponse = medispanTrackerResponse;
    }
    applyDefaultSelections(controls: FormGroup) {
        if (controls.get('applyDefaultCheckBox').value === false) {
            this.patchAndDisable(controls.get('attachmentMonyDD'), ['']);
            this.patchAndDisable(controls.get('attachmentType'), '');
            this.patchAndDisable(controls.get('attachmentLevel'), '');
            this.patchAndDisable(controls.get('monyCode'), ['']);
            this.patchAndDisable(controls.get('coverageMiscRxOtc'), this.rxOtcList[2].code);
            controls.get('attachmentMonyCheckbox').disable();
            controls.updateValueAndValidity();
            if (this.medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE &&
          controls.get('applyDefaultCheckBox').value === false) {
                this.patchAndDisable(controls.get('coverageMiscMedD'), this.medDList[2].code);
                this.patchAndDisable(controls.get('attachmentMonyDD'), ['']);
            }
        } else {
            controls.get('attachmentType').enable();
            controls.get('attachmentLevel').enable();
            controls.get('coverageMiscRxOtc').enable();
            if (this.medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE) {
                controls.get('coverageMiscMedD').enable();
            }
            controls.updateValueAndValidity();
        }
    }

    buildAttachmentLevels(value: any, controls, isReset) {
        this.attachmentLevels = [];
        if (this.medispanTrackerResponse.businessLob) {
            if ((value?.attachmentType || value) === ATTACHMENT_LEVEL.NDC) {
                controls.attachmentMonyDD.disable();
                controls.monyCode.disable();
                const attachmentLevelsCategories = [
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_11,
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_9,
                    ATTACHMENT_LEVEL_CATEGORIES.NDC_5
                ];
                let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.NDC_11;
                if (controls.attachmentLevel.value) {
                    // eslint-disable-next-line max-len
                    attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === controls.attachmentLevel.value?.toString().length);
                }

                this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'ndc', controls, false);
            } else if ((value?.attachmentType || value) === ATTACHMENT_LEVEL.GPI) {
                controls.attachmentMonyDD.enable();
                controls.monyCode.enable();
                controls.attachmentMonyCheckbox.enable();
                const attachmentLevelsCategories = [
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_14,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_12,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_10,
                    ATTACHMENT_LEVEL_CATEGORIES.GPI_8
                ];

                let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.GPI_14;
                if (controls.attachmentLevel.value) {
                    // eslint-disable-next-line max-len
                    attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === controls.attachmentLevel.value?.toString().length);
                }

                this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'gpi', controls, isReset);
            }
        }
    }

    createdAttachmentLevels(
        attachmentLevelCategories: ATTACHMENT_LEVEL_CATEGORIES[],
        attachmentLevelCategory: ATTACHMENT_LEVEL_CATEGORIES,
        attachmentType: string, controls, isReset
    ) {
        const gpiOrNdcValue =this.medispanTrackerResponse?.[attachmentType];
        this.attachmentLevels = [];
        if (gpiOrNdcValue !== 'N/A') {
            attachmentLevelCategories.forEach(key => {
                this.attachmentLevels.push([key, gpiOrNdcValue?.substring(0, +key.split('_')[1])]);
            });

            const savedValue = this.attachmentLevels.find(lvl => lvl[0] === attachmentLevelCategory);
            controls.attachmentLevel.patchValue(
                (savedValue && !isReset) ? savedValue[1] : this.attachmentLevels[0][1],
                {emitEvent: false}
            );
        }
    }

    toggleAllSelection(controls) {
        if (controls.attachmentMonyCheckbox.value === true){
            controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
                {emitEvent: false});
        } else {
            controls.attachmentMonyDD.patchValue([], {emitEvent: false});
        }
    }
    getAttachmentLevels() {
        return  this.attachmentLevels;
    }

    patchAndDisable(control, value) {
        control.patchValue(value, {emitEvent: false});
        control.disable();
    };

    setAttachmentType() {
        if(this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC
            || this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC){
            this.attachmentTypes = [ATTACHMENT_LEVEL.GPI];
        } else {
            this.attachmentTypes = [ATTACHMENT_LEVEL.GPI, ATTACHMENT_LEVEL.NDC];
        }
    }
    getAttachmentType(){
        return this.attachmentTypes;
    }
}
