import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {
    ColDef,
    GridOptions,
    MenuItemDef,
    ProcessRowParams,
} from '@ag-grid-community/core';
import {KabobCellComponent} from '../common/kabob-cell/kabob-cell.component';
import {AppAdministrationService} from '../service/app-administration/app-administration.service';
import {SuperClientData} from '../model/SuperClientData';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';

interface AppAdminKabobActions {
    SET_MEDISPAN: MenuItemDef;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
const getAppAdminKabobActions = (params: ProcessRowParams): AppAdminKabobActions => ({
    SET_MEDISPAN : {
        name: KabobMenuActionTitlesEnum.SET_MEDISPAN ,
        action: () => {},
    },
});

// eslint-disable-next-line @typescript-eslint/naming-convention,no-shadow
export enum KabobMenuActionTitlesEnum {
    SET_MEDISPAN = 'Set Default Medispan Settings',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
let clientsMedispan= [];
@Component({
    selector: 'app-app-administration',
    templateUrl: './app-administration.component.html',
    styleUrls: ['./app-administration.component.scss']
})
export class AppAdministrationComponent implements OnInit {
    @ViewChild('superClientFormRef') public superClientFormRef: TemplateRef<any>;
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    superClientRowData = [];
    superClients: SuperClientData[] = [];

    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
    };

    superClientGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, width: 50, suppressMenu: true},
            {headerName: 'Super Client ID', field: '', filter: 'agSetColumnFilter', width: 100,
                valueGetter: (params) => params.data?.superclientId},
            {headerName: 'Super Client Name', field: '', filter: 'agSetColumnFilter', width: 170,
                valueGetter: (params) => params.data?.name},
            {headerName: 'Super Client Description', field: '', filter: 'agSetColumnFilter', width: 170,
                valueGetter: (params) => params.data?.description},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
        onCellClicked: (params) => {
            // @ts-ignore
            if (params.column.colDef.field === 'kabob') {
                // @ts-ignore
                params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event);
            }
        },
    };

    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    popupParent: HTMLElement | null = document.querySelector('body');
    confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;

    constructor(private appAdminService: AppAdministrationService, private dialog: MatDialog) {}

    ngOnInit(): void {
        this.getSuperClientData();
        this.appAdminService.getSuperClientWithMedispanSaved().subscribe(clientsWithMedispan => {
            clientsMedispan = clientsWithMedispan;
        });
    }

    getContextMenuItems(params: ProcessRowParams): MenuItemDef[] {
        let hasMedispan = false;
        const appAdminActions = getAppAdminKabobActions(params);
        clientsMedispan?.forEach((client) => {
            if (client === params.node.data.superclientId) {
                hasMedispan = true;
            }
        });
        let options = [appAdminActions.SET_MEDISPAN];
        if (hasMedispan) {
            options = options.splice(1, 1);
        }
        return options;
    }

    getSuperClientData() {
        this.appAdminService.getAllSuperClients().subscribe(clientList => {
            this.superClients = clientList;
            this.superClients.forEach((client) => {
                this.superClientRowData.push(client);
            });
            this.superClientGridOptions.api.setRowData(this.superClientRowData);
        });
    }

    openDialog(modalRef: TemplateRef<any>) {
        this.confirmationDialog = this.dialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Add Super Client Details',
                templatePortalContent: modalRef
            }
        });

        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.confirmationDialog.close();
        });
    }

    closeModal(isApproved: boolean) {
        if (isApproved) {
            this.superClientRowData = [];
            this.getSuperClientData();
        }
        this.confirmationDialog.close();
    }
}
