// eslint-disable-next-line no-shadow
import {Formulary} from '../../../model/Formulary';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {NA} from '../../../default-values/DefaultValues';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';

export interface NavTree {
    name: string;
    formulary?: Formulary;
    umProgram?: ClientUmProgram;
    productDrugCoverage?: ClientProductDrugCoverage;
    children?: NavTree[];
    link?: string;
}

/** Flat node with expandable and level information */
export interface FlatNode {
    expandable: boolean;
    name: string;
    link?: string;
    formulary?: Formulary;
    umProgram?: ClientUmProgram;
    productDrugCoverage?: ClientProductDrugCoverage;
    level: number;
}


export const TREE_DATA_FORMULARY: NavTree[] = [
    {
        name: 'Formularies',
        children: [],
    },
];

export const TREE_DATA_UM_PROGRAM: NavTree[] = [
    {
        name: 'UM Programs',
        children: [
            {
                name: 'Prior Authorization',
                children: [],
            },
            {
                name: 'Step Therapy',
                children: [],
            },
            {
                name: 'Quantity Limit',
                children: [],
            },
        ],
    },
];

export const TREE_DATA_PRODUCT_DRUG_COVERAGE: NavTree[] = [
    {
        name: 'Products & Drug Coverage',
        children: [],
        link: 'product-drug-coverage'
    },
];

export const TREE_LEVELS = {
    FORMULARY: {
        code: 0,
    },
    PRIOR_AUTH: {
        code: 1,
    },
    STEP_THERAPY: {
        code: 2,
    },
    QUANTITY_LIMIT: {
        code: 3,
    },
    PRODUCT_DRUG_COVERAGE: {
        code: 4,
    },
};
