import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {Observable} from 'rxjs';
import {SuperClientData} from '../../model/SuperClientData';
import {ENVIRONMENT} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppAdministrationService {
    constructor(private cvsHttpClient: CVSHttpClient) {
    }
    getAllSuperClients(): Observable<SuperClientData[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}superClient/allSuperClients`);
    }

    getSuperClientWithMedispanSaved(): Observable<string[]> {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}superClient/superClientsWithMedispan`);
    }

    saveSuperClient(superClientData: SuperClientData){
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}superClient/saveSuperClient`, superClientData);
    }
}
