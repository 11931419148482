import {
    Component,
    OnInit,
    Input,
    ViewChild
} from '@angular/core';import {GridDisplayModalService} from '../../common/grid-display-modal/grid-display-modal.component';
import {
    RecommendationsFormularyRequirementsComponent
} from './recommendations-formulary-requirements/recommendations-formulary-requirements.component';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {RecommendationsUmProgramComponent} from './recommendations-um-program/recommendations-um-program.component';
import {
    RecommendationsProductAndDrugCoverageComponent
} from './recommendations-product-and-drug-coverage/recommendations-product-and-drug-coverage.component';
import {Record} from '../../model/Record';



export interface SupplementalListItem {
    name: string;
    header: string;
    value: string;
}

@Component({
    selector: 'app-recommendations',
    templateUrl: './recommendations.component.html',
    styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit{
    // eslint-disable-next-line max-len
    @ViewChild(RecommendationsFormularyRequirementsComponent, {static: true}) recFormularyRequirements: RecommendationsFormularyRequirementsComponent;
    @ViewChild(RecommendationsUmProgramComponent, {static: true}) recUmProgram: RecommendationsUmProgramComponent;
    // eslint-disable-next-line max-len
    @ViewChild(RecommendationsProductAndDrugCoverageComponent, {static: true}) productAndDrugCoverage: RecommendationsProductAndDrugCoverageComponent;
    @Input() ndcBusinessLob;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() mainTabName: string;
    @Input() subTabIndex: number;
    @Input() fromSource;
    formualryRequirementsGridUpdated = false;
    productDetails: Record[];

    constructor(
        public _gridDisplayModalService: GridDisplayModalService
    ) {
    }

    ngOnInit(): void {
    }

    onBusinessLobTabSelectionChange(event: any) {
        this.recFormularyRequirements.onBusinessLobTabSelectionChange(event);
        this.recUmProgram.filterClientUmProgram(event);
        this.productAndDrugCoverage.filterProductAndDrugCoverage(event);
    }

    updateLoadUm(event: boolean) {
        this.formualryRequirementsGridUpdated = event;
    }

    getProductDetails(productDetails: Record[]) {
        this.productDetails = productDetails;
    }
}
