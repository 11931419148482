<ng-container>
    <form [formGroup]="workflowForm" (ngSubmit)="saveWorkflowForm()">
    <p>Are you sure you want to move {{workflowService.dialogData.selectedRows.length}}
        item(s) to {{workflowService.dialogData.item.description}}?</p>
    <div *ngIf="workflowService.dialogData.showForwardStatusDetails">
        <cvs-inline-callout aria-label="info text" [type]="inlineCalloutType.info"
                            [message]="messageText"
                            [hidden]="hidden"
                            isIconFilled>
        </cvs-inline-callout>
        <div>
            <mat-form-field class="approval-date" >
                <mat-label>{{workflowService.dialogData.approvalDateLabel?? 'Approval Date'}}</mat-label>
                <input matInput [required]="!dateValidation" [min]="minDate" [max]="maxDate" [matDatepicker]="approvalDate" formControlName="approvalDate"/>
                <mat-icon
                        class="icon"
                        svgIcon="calendar--s"
                        matSuffix
                        (click)="approvalDate.open()"
                        onkeydown="approvalDate.open()"
                >
                </mat-icon>
                <mat-hint>Provide a date between 1/1/1995 - 12/31/2039</mat-hint>
                <mat-datepicker #approvalDate></mat-datepicker>
                <mat-error *ngIf="workflowForm.controls.approvalDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date between 01/1/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field id="approvalNotes" class="notes-textarea-approval" appearance="outline">
                <mat-label>{{workflowService.dialogData.approvalNoteLabel ?? 'Approval Notes'}}</mat-label>
                <textarea
                        id="approvalNotesTextArea"
                        matInput
                        type="text"
                        (input)="onNotesChanges()"
                        formControlName="approvalNotes"
                        (focusout)="stopWarning()"
                ></textarea>
                <mat-hint *ngIf="!hasTruncNotesError" class="mat-hint-notes">Provide a description for this case</mat-hint>
                <mat-hint *ngIf="hasTruncNotesError" class="warning-error-notes-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{workflowForm.controls['notes'].value?.length}} characters
                </mat-hint>
            </mat-form-field>
        </div>
    </div>
        <mat-dialog-actions class="form-buttons">
            <button class="pds-btn-white" mat-dialog-close>Cancel</button>
            <button class="pds-btn-primary" type="submit">{{ workflowService.dialogData.actionLabel }}</button>
        </mat-dialog-actions>
    </form>
</ng-container>
