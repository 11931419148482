import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { WORKFLOW_MESSAGE_ACTION } from 'src/app/enum/WorkflowMessageAction';
import { WorkflowService } from 'src/app/service/workflow/workflow.service';
import {Subscription} from 'rxjs';
import {RecordService} from '../../../service/record/record.service';
import {UserService} from '../../../service/user/user.service';

@Component({
    selector: 'app-recommendations-notes',
    templateUrl: './recommendations-notes.component.html',
    styleUrls: ['./recommendations-notes.component.scss']
})
export class RecommendationsNotesComponent implements OnChanges, OnDestroy, OnInit {
    @Input() isDisabled;
    @Input() medispanTrackerResponse;
    @Input() mainTabName;
    @Input() subTabIndex;
    @Input() myParent;
    note: string | number;
    subscriptions: Subscription[] = [];

    constructor(
        private recordService: RecordService,
        public workflowService: WorkflowService,
        private userService: UserService) { }

    ngOnInit() {
        this.subscribeToWorkflowMessage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.note = this.medispanTrackerResponse.note;
    }

    saveNote() {
        if (this.medispanTrackerResponse.note !== this.note) {
            this.medispanTrackerResponse.note = this.note;
            this.recordService.saveNote(this.medispanTrackerResponse.encryptedRecordId,
                this.medispanTrackerResponse.note, this.userService.getSuperClientContext().id).subscribe(response => {
                this.workflowService.messageProvider.next(
                    this.workflowService.buildWorkflowMessage(WORKFLOW_MESSAGE_ACTION.NOTE_CHANGE,
                        this.medispanTrackerResponse.note, this.medispanTrackerResponse.ndc,
                        this.medispanTrackerResponse.updateType, this.medispanTrackerResponse.weekDate,
                        this.mainTabName, this.subTabIndex, this.medispanTrackerResponse.gpi));
            });
        }
    }

    subscribeToWorkflowMessage() {
        this.subscriptions.push(this.workflowService.getMessageProvider().subscribe(workflowMessage => {
            if (workflowMessage.action === WORKFLOW_MESSAGE_ACTION.NOTE_CHANGE) {
                this.note = workflowMessage.newValue;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach( sub => sub.unsubscribe());
    }
}
