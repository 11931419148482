import {Component, Input, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {MasterDrugResponse} from '../../model/MasterDrugResponse';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {UserService} from '../../service/user/user.service';
import {DrugUniverseApiService} from '../../service/drug-universe/drug-universe-api.service';
import {RecordService} from '../../service/record/record.service';
import {PRODUCT_OVERVIEW_SOURCE} from '../../enum/ProductOverviewSource';
import {HEADERS} from '../product-overview.component';
import {DetailHeader} from 'src/app/model/DetailHeader';
import {getHierarchyUpdateTypeForMedispan, getHierarchyUpdateTypeForWorkflow} from '../../mapper/hierarchyUpdateType';

@Component({
    selector: 'app-ndc-header',
    templateUrl: './ndc-header.component.html',
    styleUrls: ['./ndc-header.component.scss']
})
export class NdcHeaderComponent implements OnInit {
    @Input() fromSourceInput: any;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    drugData: MasterDrugResponse;
    ndcDetailHeader: DetailHeader[] = [];
    groupSelection = {
        protectedClass: null,
        newMolecularEntity: null
    };
    hasEditPermission = false;
    ndcBusinessLob;
    NDC = {
        name: 'ndcs',
        value: ''
    };

    constructor(
        private _userService: UserService,
        private _drugUniverseService: DrugUniverseApiService,
        private _recordService: RecordService,
    ) {
        this.hasEditPermission = this._userService.hasEditPermission();
    }

    ngOnInit(): void {
        if (this.medispanTrackerResponse) {
            const isNdc = this.medispanTrackerResponse.ndc !== 'N/A';
            this.NDC.value = this.medispanTrackerResponse.ndc;
            if (isNdc) {
                this._drugUniverseService.getDrug(this.NDC.name, this.NDC.value, 'true', 'true')
                    .pipe(take(1))
                    .subscribe(data => {
                        if (data) {
                            this.drugData = data.filter(i => i.ndc === this.NDC.value)[0];
                            this.ndcDetailHeader = this.buildNDCDetailHeader();
                        }
                        if (this.fromSourceInput === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_RESEARCH_AND_REQUIREMENTS ||
                            this.fromSourceInput === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_OPERATIONS_AND_IMPLEMENTATION ||
                            this.fromSourceInput === PRODUCT_OVERVIEW_SOURCE.WORKFLOW_REGULATORY_TRACKING
                        ) {
                            this.generateProtectedClassAndNewMolecularEntityRadioButtons();
                        }
                    });
            }
        }
    }

    addEditableFields() {
        this.ndcDetailHeader.push({name: HEADERS.PROTECTED_CLASS, field: 'protectedClass'});
        this.ndcDetailHeader.push({name: HEADERS.NEW_MOLECULAR_ENTITY, field: 'newMolecularEntity'});
    }

    buildNDCDetailHeader(): DetailHeader[] {
        const ndcInfo = [
            {name: HEADERS.GPI, value: this.drugData?.gpi},
            {name: HEADERS.GPI_NAME, value: this.drugData?.gpiName},
            {name: HEADERS.NDC, value: (this.drugData?.ndc === undefined) ? this.medispanTrackerResponse?.ndc : this.drugData.ndc},
            {name: HEADERS.LABEL_NAME, value: this.drugData?.labelName},
            {name: HEADERS.PRODUCT_NAME, value: this.drugData?.productName},
            {name: HEADERS.MULTISOURCE_CODE, value: this.drugData?.multiSourceCode},
        ];
        if (this.fromSourceInput !== PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH &&
            this.fromSourceInput !== PRODUCT_OVERVIEW_SOURCE.FORMULARY_SEARCH) {
            let newUpdateType;
            if (this.medispanTrackerResponse && this.medispanTrackerResponse.updateType) {
                newUpdateType = getHierarchyUpdateTypeForMedispan(this.medispanTrackerResponse.updateType)?.newUpdateTypeName
                    || getHierarchyUpdateTypeForWorkflow(this.medispanTrackerResponse.updateType)?.newUpdateTypeName;
            }
            ndcInfo.push({name: HEADERS.BUSINESS_LOB, value: this.medispanTrackerResponse?.businessLob});
            ndcInfo.push({name: HEADERS.UPDATE_TYPE, value: newUpdateType});
            ndcInfo.push({name: HEADERS.DRUG_TAG, value: ''});
            this.ndcBusinessLob = this.medispanTrackerResponse?.businessLob;
        }

        return ndcInfo;
    }

    saveSelections() {
        this._recordService.saveProtectedClassAndNewMolecularEntity(this.medispanTrackerResponse.encryptedRecordId,
            this.groupSelection.protectedClass,
            this.groupSelection.newMolecularEntity)
            .pipe(take(1))
            .subscribe(() => {
            });
    }

    generateProtectedClassAndNewMolecularEntityRadioButtons() {
        this.addEditableFields();
        this._recordService.getRecord(this.medispanTrackerResponse.encryptedRecordId)
            .pipe(take(1))
            .subscribe((recordData) => {
                this.groupSelection.protectedClass = recordData.protectedClassFlg;
                this.groupSelection.newMolecularEntity = recordData.newMolecularEntityFlg;
            });
    }
}
