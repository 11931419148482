<div tabindex="0" class="child-grid" *ngIf="displayDatePickerRadioBtns">
    <h5 class="button-titles padding-bottom"
        id="dateRadioGroupHeader">{{dateRadioGroupHeader}}</h5>
    <mat-radio-group (change)="onDateRadioChange($event)">
        <mat-radio-button class="padding-right" id="singleDateRadio" value="singleDate"
                          [checked]="singleDateRadioButtonSelected"
                          [disabled]="isDateRadioDisabled"
                          cvsTooltip="Turn Off Only Show Highest Priority to Edit"
                          [placement]="showHighestPriorityChecked ? 'bottom' : 'nope'"
                          [showArrow]="true"
        >Single Date
        </mat-radio-button>
        <mat-radio-button
                class="padding-right" id="dateRangeRadio" value="dateRange"
                [disabled]="isDateRadioDisabled"
                cvsTooltip="Turn Off Only Show Highest Priority to Edit"
                [placement]="showHighestPriorityChecked ? 'bottom' : 'nope'"
                [showArrow]="true"
        >Date Range
        </mat-radio-button>
    </mat-radio-group>
</div>

<mat-form-field *ngIf="singleDateRadioButtonSelected" id="singleDatePicker">
    <mat-label id="singleDatePickerLabel">{{singleDatePickerLabelText}}</mat-label>
    <input id="dateInput" matInput [matDatepicker]="picker"
           [(ngModel)]="singleDate" [min]="minDate" [max]="maxDate"
           (focusout)="onSingleDateFocusOut($event)"
           [required]="!disableValidation" (dateChange)="onSingleDateChange()">
    <mat-error id="singleDateError">{{singleDateError}}</mat-error>
    <mat-icon class="dateFocus" id = "singleDateIcon"
              svgIcon="calendar--s"
              matSuffix
              tabindex="0"
              (keydown.enter)="picker.open()"
              (click)="picker.open()">
    </mat-icon>
    <mat-hint id="singleDateHint" >MM/DD/YYYY</mat-hint>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-form-field *ngIf="!singleDateRadioButtonSelected" id="dateRangeField">
    <mat-label id="dateRangeHeader">{{dateRangeHeader}}</mat-label>
    <mat-date-range-input #dateRange [rangePicker]="picker"
                          [min]="minDate" [max]="maxDate"
                          (focusout)="onDateRangeFocusOut()">
        <input #inputStartDate matStartDate placeholder="Start date"
               [(ngModel)]=datePickerStartDate required="true"
               (dateChange)="onDateRangeChange()">
        <input #inputEndDate id="endDate" matEndDate placeholder="End date"
               [(ngModel)]=datePickerEndDate required="true"
               (dateChange)="onDateRangeChange()">
    </mat-date-range-input>
    <mat-error id="dateRangeError">{{dateRangeError}}</mat-error>
    <mat-hint id="dateRangeHint" *ngIf="!showHighestPriorityChecked">MM/DD/YYYY - MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle tabindex="-1"  id="dateRangePicker" matSuffix [for]="picker">
        <mat-icon class="dateFocus" id="dateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s" tabindex="0" ></mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-hint *ngIf="showHighestPriorityChecked">Turn Off Only Show Highest Priority to Edit</mat-hint>
</mat-form-field>
