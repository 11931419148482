
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MedispanTrackerResponse} from '../../../../model/MedispanTrackerResponse';
import {RecommendationService} from '../../../../service/recommendation/recommendation.service';
import {ClientUmProgram} from '../../../../model/ClientUmProgram';
import {BUSINESS_LOB} from '../../../../enum/BusinessLob';
import {StepTherapyFormEntryComponent} from './step-therapy-form-entry/step-therapy-form-entry.component';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {PriorAuthFormEntryComponent} from './prior-auth-form-entry/prior-auth-form-entry.component';
import {QuantityLimitFormEntryComponent} from './quantity-limit-form-entry/quantity-limit-form-entry.component';

@Component({
    selector: 'app-um-program-form-entry',
    templateUrl: './um-program-form-entry.component.html',
    styleUrls: ['./um-program-form-entry.component.scss']
})
export class UmProgramFormEntryComponent implements OnInit {
    @ViewChild(StepTherapyFormEntryComponent) stepTherapyRequirements: StepTherapyFormEntryComponent;
    @ViewChild(PriorAuthFormEntryComponent) priorAuthFormEntryComponent: PriorAuthFormEntryComponent;
    @ViewChild(QuantityLimitFormEntryComponent) quantityLimitFormEntryComponent: QuantityLimitFormEntryComponent;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() umProgram: any;
    @Input() programIndexSelected: any;
    @Output() isUmProgramFormSaved: EventEmitter<boolean> = new EventEmitter(false);
    umPrograms: ClientUmProgram[] = [];
    stepTherapyUMPrograms: ClientUmProgram[] = [];
    qlUmPrograms: ClientUmProgram[] = [];

    panelOpenState = true;
    title: string;
    isSavedRequirementIncomplete = false;

    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected readonly BUSINESS_LOB = BUSINESS_LOB;

    constructor(private _recommendationService: RecommendationService,private bannerService: CVSBannerService,) {
    }

    ngOnInit(): void {
        this.umPrograms = this._recommendationService.getActiveUmProgramsByLOBTab(this.medispanTrackerResponse?.businessLobCd);
        this.stepTherapyUMPrograms = this.umPrograms.filter(data => data.umProgramTypeCd === '2');
        this.qlUmPrograms =this.umPrograms.filter(data => data.umProgramTypeCd === '3');
    }

    getUmProgramTypeTitle(): string {
        if(this.programIndexSelected === 1) {
            return 'Prior Authorization';
        } else if(this.programIndexSelected === 2) {
            return 'Step Therapy';
        } else if (this.programIndexSelected === 3) {
            return 'Quantity Limit';
        }
    }

    onSaveClicked() {
        if(this.programIndexSelected === 2) {
            this.stepTherapyRequirements.saveStepTherapy();
        }
        if(this.programIndexSelected === 1) {
            this.priorAuthFormEntryComponent.savePriorAuthorization();
        }
        if(this.programIndexSelected === 3) {
            this.quantityLimitFormEntryComponent.saveQuantityLimits();
        }
    }

    onSaveError() {
        this.bannerService.sendAlert({
            bannerType: CVSBannerType.Error,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again',
            outletId: '#recommendationsBanner',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.onSaveClicked();
                    }
                }
            ]
        });
    }

    onSaveSuccess() {
        if (!this.isSavedRequirementIncomplete) {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Success,
                headline: 'Success',
                body: 'Changes have been saved',
                outletId: '#recommendationsBanner'
            });
        } else {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Warning,
                headline: 'Saved with incomplete fields',
                body: 'Form was saved, but there are incomplete fields.',
                outletId: '#recommendationsBanner'
            });
        }
        this.isUmProgramFormSaved.emit(true);
    }

    onRequirementSaved(isSaved: any) {
        if (isSaved) {
            this.onSaveSuccess();
        } else {
            this.onSaveError();
        }
    }

    onIncompleteRequirementSaved(isSavedRequirementIncomplete: boolean) {
        this.isSavedRequirementIncomplete = isSavedRequirementIncomplete;
    }
}
