<h2>{{ clientName }} CLR Setup Review</h2>
<p>Formulary Name Setup</p>
<app-cvs-ag-grid id="formularyNameGrid"
                 class="ag-theme-balham grid"
                 style="max-width: 1350px"
                 [rowData]="clientFormularyData"
                 [domLayout]="domLayout"
                 [gridOptions]="gridOptions"
                 [modules]="modules"
                 [paginationEnabled] = "true"
                 [paginationPageSize]="5"
                 [suppressMenuHide]="true"
                 [disableCvsExport]="true"
>
</app-cvs-ag-grid>
<div *ngIf="showSpinner">
    <cvs-loader-overlay-container [isLoading]="showSpinner" [loadingMessage]="'Downloading Report...'"
                                  [opacity]="0.5"></cvs-loader-overlay-container>
</div>
<ng-container *ngIf="displayLobAndClientMultiSourceSetup">
    <p class="margin-32 font-size-16">Line of Business Workflow Setup</p>
    <ng-container *ngFor="let clientLobWorkflow of clientLobWorkflowData">
        <p class="font-size-14">{{clientLobWorkflow}}</p>
    </ng-container>

    <p class="margin-32 font-size-16">Client Multi-Source Code Setup</p>
    <p class="font-size-14">{{clientMultiSourceSetup}}</p>
</ng-container>
<ng-container *ngIf="!displayLobAndClientMultiSourceSetup">
    <p class="margin-32 font-size-16">Line of Business Workflow Setup</p>
    <img src="/assets/error-f--s.svg" class="image" width="25px" height="25px">
    <p class="image-text">No Results Found</p>

    <p class="margin-32 font-size-16">Client Multi-Source Code Setup</p>
    <img src="/assets/error-f--s.svg" class="image" width="25px" height="25px">
    <p class="image-text">No Results Found</p>
</ng-container>
