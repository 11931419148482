import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {
    ATTACHMENT_LEVEL,
    ATTACHMENT_MONY,
    MED_D, NA,
    PA_SELECTION_COM_EX_MED, PA_SELECTION_MEDICARE,
    RX_OTC
} from '../../../../../default-values/DefaultValues';
import {BUSINESS_LOB} from '../../../../../enum/BusinessLob';
import {ClientUmProgram} from '../../../../../model/ClientUmProgram';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MedispanTrackerResponse} from '../../../../../model/MedispanTrackerResponse';
import {IRecommendationRules} from '../../RecommendationFormRules';
import {MatSelect} from '@angular/material/select';
import {MatCheckbox} from '@angular/material/checkbox';
import {RecommendationsFormService} from '../../../../../service/recommendations-form/recommendations-form.service';
import {SearchOptionsData} from 'angular-component-library/completed/autocomplete-select/autocomplete-select.interface';
import {of, Subscription} from 'rxjs';
import {CVSAutocompleteSelectComponent, PDSInlineCalloutType} from 'angular-component-library';
import {PriorAuthorizationRequirements} from '../../../../../model/PriorAuthorizationRequirements';
import {RecommendationService} from '../../../../../service/recommendation/recommendation.service';
import {map, take, tap} from 'rxjs/operators';

@Component({
    selector: 'app-prior-auth-form-entry',
    templateUrl: './prior-auth-form-entry.component.html',
    styleUrls: ['./prior-auth-form-entry.component.scss']
})
export class PriorAuthFormEntryComponent implements OnInit {
    @ViewChildren('attachmentMony') attachmentMony: QueryList<MatSelect>;
    @ViewChildren('attachmentMonyCheckbox') attachmentMonyCheckbox:  QueryList<MatCheckbox>;
    @ViewChildren('ageLimitMin') ageLimitMin:  QueryList<CVSAutocompleteSelectComponent>;
    @ViewChildren('ageLimitMax') ageLimitMax:  QueryList<CVSAutocompleteSelectComponent>;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() umPrograms: ClientUmProgram[] = [];
    @Input() umProgram: any;
    @Output() isPriorAuthSavedEmitter = new EventEmitter<boolean>();
    @Output() isPriorAuthIncompleteEmitter = new EventEmitter<boolean>();
    priorAuths: ClientUmProgram[] = [];
    priorAuthFormGroups: { [key: string]: FormGroup } = {};
    attachmentLevels: [key: string, value: string][] = [];
    attachmentLevelsKey: {[key: string]: [key: string, value: string][]} = {};
    attachmentMonyList = ATTACHMENT_MONY;
    rxOtcList = RX_OTC;
    medDList = MED_D;
    paSelection = Object.values(PA_SELECTION_MEDICARE);
    paSelectionNonMedicare = PA_SELECTION_COM_EX_MED;
    attachmentTypes = [];
    monyIndeterminates = new Map<string, boolean>();
    NA = NA;
    rules: IRecommendationRules;
    panelOpenState = true;
    ageLimitOptions: SearchOptionsData[] = Array.from({length: 101}, (_, i) => ({
        formattedSearchResult: i.toString()
    }));
    observableAgeLimitOptions = of(this.ageLimitOptions);
    inputAppearance = 'standard';
    mappedStatus = new Map<string, string>();
    savedPriorAuthRequirements: PriorAuthorizationRequirements[] = [];
    inlineCalloutType = PDSInlineCalloutType;
    hasAgeLimitError = false;
    ageLimitError: {[key: string]:  boolean} = {};
    displayAgeLimitMinKey: {[key: string]:  boolean} = {};
    displayAgeLimitMaxKey: {[key: string]:  boolean} = {};
    addAgeLimitMaxGapKey: {[key: string]:  boolean} = {};
    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected readonly BUSINESS_LOB = BUSINESS_LOB;
    private subscriptions: Subscription[] = [];
    constructor(private fb: FormBuilder, private recommendationsFormService: RecommendationsFormService,
        private recommendationService: RecommendationService) { }

    ngOnInit(): void {
        this.recommendationsFormService.setMedispanTrackerResponse(this.medispanTrackerResponse);
        this.recommendationsFormService.setAttachmentType();
        this.attachmentTypes = this.recommendationsFormService.getAttachmentType();
        this.createFormGroups();
        this.getPriorAuthorizationRequirements();
    }

    ngAfterViewInit() {
        this.scrollToFormulary(`pa-${this.umProgram?.umProgramName}-${this.umProgram?.rank}`);
    }
    scrollToFormulary(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }
    createFormGroups(): void {
        if (Object.keys(this.priorAuthFormGroups).length === 0) {
            if (this.umPrograms?.filter(eachProgram => eachProgram.umProgramTypeCd === '1')) {
                this.priorAuths = this.umPrograms?.filter(eachProgram => eachProgram.umProgramTypeCd === '1');
                this.priorAuths.forEach(pa => {
                    this.priorAuthFormGroups[pa.id] = this.fb.group({
                        [`priorAuthType`]: new FormControl({value: this.paSelection[0].code, disabled: false}),
                        [`ageLimitMin`]: new FormControl({value: '',disabled: true}, [Validators.required,Validators.min(0)]),
                        [`ageLimitMax`]: new FormControl({value: '',disabled: true}, [Validators.required,Validators.max(100)]),
                        [`customClaimRejectMessage`]: new FormControl({value: '', disabled: true}),
                        [`paNotes`]: new FormControl({value: '', disabled: true}),
                        [`attachmentType`]: new FormControl({value: this.attachmentTypes[0], disabled: true},
                            {validators: Validators.required}),
                        [`attachmentLevel`]: new FormControl({value: '', disabled: true}, {validators: Validators.required}),
                        [`attachmentMonyDD`]: new FormControl({value: '', disabled: true}, {validators: Validators.required}),
                        [`monyCode`]: new FormControl({value: [''], disabled: true}),
                        [`coverageMiscRxOtc`]: new FormControl({value: '3', disabled: true}),
                        [`coverageMiscMedD`]: new FormControl({value: '3', disabled: true}),
                        [`applyDefaultCheckBox`]: new FormControl({value: true, disabled: true}),
                        [`attachmentMonyCheckbox`]: new FormControl(false)
                    });
                    this.mappedStatus[pa.id] = '0';
                    this.monyIndeterminates[pa.id] = false;
                    this.buildAttachmentLevels(this.attachmentTypes[0], pa.id, true);
                    this.priorAuthFormGroups[pa.id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
                    this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD
                        .patchValue([...this.attachmentMonyList.map(m => m.code)], {emitEvent: false});
                    this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD.disable();
                });
            }
        }
    }

    buildAttachmentLevels(value: any, paId: number, isReset: boolean) {
        const controls = this.priorAuthFormGroups[paId].controls;
        this.recommendationsFormService.buildAttachmentLevels(value, controls, isReset);
        this.attachmentLevels[paId.toString()] = this.recommendationsFormService.getAttachmentLevels();
    }

    toggleAllSelection(umProgramId: number) {
        const controls = this.priorAuthFormGroups[umProgramId].controls;
        this.recommendationsFormService.toggleAllSelection(controls);
    }

    onAttachmentMonyDDChange(umProgramId: number) {
        const controls = this.priorAuthFormGroups[umProgramId].controls;
        const attachmentMonyDDFCLength = controls.attachmentMonyDD.value.length;
        this.monyIndeterminates[umProgramId] = (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        // eslint-disable-next-line max-len
        if ((!controls.attachmentMonyCheckbox && attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            // eslint-disable-next-line max-len
            (controls.attachmentMonyCheckbox && attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            controls.attachmentMonyCheckbox.setValue(false);
        }
        if (attachmentMonyDDFCLength === this.attachmentMonyList.length) {
            controls.attachmentMonyCheckbox.setValue(true);
        }
    }

    onPriorAuthTypeChange(umProgramId: number){
        const controls = this.priorAuthFormGroups[umProgramId].controls;
        this.displayAgeLimitMinKey[umProgramId] = ((controls.priorAuthType.value === this.paSelectionNonMedicare[2].code
            // eslint-disable-next-line max-len
            || controls.priorAuthType.value === this.paSelectionNonMedicare[4].code)  && this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE);
        this.displayAgeLimitMaxKey[umProgramId] =((controls.priorAuthType.value === this.paSelectionNonMedicare[3].code
            // eslint-disable-next-line max-len
            || controls.priorAuthType.value === this.paSelectionNonMedicare[4].code) && this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE);
        this.addAgeLimitMaxGapKey[umProgramId] = controls.priorAuthType.value === this.paSelectionNonMedicare[4].code;
        const currentPriorAuthUMProgram =this.priorAuths.filter(data => data.id === umProgramId)[0];
        if(controls.priorAuthType.value === this.paSelection[1].code
                || controls.priorAuthType.value === this.paSelectionNonMedicare[7].code) {
            controls.paNotes.enable();
            controls.applyDefaultCheckBox.enable();
            controls.customClaimRejectMessage.disable();
            controls.customClaimRejectMessage.patchValue('', {emit: false});
        } else {
            controls.customClaimRejectMessage.patchValue(currentPriorAuthUMProgram?.rejectionMessage);
            controls.customClaimRejectMessage.enable();
            controls.paNotes.enable();
            controls.applyDefaultCheckBox.enable();
        }
        if(controls.priorAuthType.value === this.paSelection[0].code
                || controls.priorAuthType.value === this.paSelectionNonMedicare[0].code) {
            this.priorAuthFormGroups[umProgramId].disable();
            this.priorAuthFormGroups[umProgramId].controls.priorAuthType.enable();
            this.priorAuthFormGroups[umProgramId].controls.applyDefaultCheckBox.patchValue(true);
            this.priorAuthFormGroups[umProgramId].controls.customClaimRejectMessage.patchValue('');
            this.priorAuthFormGroups[umProgramId].controls.paNotes.patchValue('');
            this.clearAllSelections(umProgramId);
        }
        if(this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE) {
            if (this.paSelectionNonMedicare[4].code !== controls.priorAuthType.value) {
                this.editAgeValue(umProgramId);
                controls.ageLimitMin.patchValue('', {emit: false});
                controls.ageLimitMax.patchValue('', {emit: false});
            }
        }
        this.enableAgeLimitSelects(umProgramId);
    }

    setDefaultSelection(id) {
        if (this.priorAuthFormGroups[id].controls.applyDefaultCheckBox.value === true) {
            this.clearAllSelections(id);
        } else {
            this.enableAll(id);
        }
        this.priorAuthFormGroups[id].updateValueAndValidity();
    }

    enableAll(id) {
        this.priorAuthFormGroups[id].controls['attachmentMonyDD'].markAsUntouched();
        this.priorAuthFormGroups[id].controls['attachmentType'].markAsUntouched();
        this.priorAuthFormGroups[id].controls['attachmentLevel'].markAsUntouched();
        this.priorAuthFormGroups[id].controls.attachmentType.enable();
        this.priorAuthFormGroups[id].controls.attachmentLevel.enable();
        this.priorAuthFormGroups[id].controls.attachmentMonyDD.enable();
        this.priorAuthFormGroups[id].controls.coverageMiscRxOtc.enable();
        this.priorAuthFormGroups[id].controls.coverageMiscMedD.enable();
    }

    clearAllSelections(id) {
        this.priorAuthFormGroups[id].controls.attachmentType.patchValue(this.attachmentTypes[0], {emitEvent: false});
        this.buildAttachmentLevels(this.attachmentTypes[0], id, true);
        this.priorAuthFormGroups[id].controls.attachmentMonyCheckbox.patchValue(true, {emitEvent: false});
        this.priorAuthFormGroups[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
            {emitEvent: false});
        this.priorAuthFormGroups[id].controls.coverageMiscRxOtc.patchValue('3', {emitEvent: false});
        this.priorAuthFormGroups[id].controls.coverageMiscMedD.patchValue('3', {emitEvent: false});
        this.priorAuthFormGroups[id].controls.attachmentType.disable();
        this.priorAuthFormGroups[id].controls.attachmentLevel.disable();
        this.priorAuthFormGroups[id].controls.attachmentMonyDD.disable();
        this.priorAuthFormGroups[id].controls.monyCode.disable();
        this.priorAuthFormGroups[id].controls.attachmentMonyCheckbox.disable();
        this.priorAuthFormGroups[id].controls.coverageMiscRxOtc.disable();
        this.priorAuthFormGroups[id].controls.coverageMiscMedD.disable();
    }

    getPriorAuthorizationRequirements() {
        this.recommendationService.getPriorAuthorizationRequirements(this.medispanTrackerResponse.encryptedRecordId,
            this.medispanTrackerResponse.businessLobCd)
            .pipe(
                map(data => data?.map(d => ({
                    encryptedRecordId: d.encryptedRecordId,
                    clientUmProgramId: d.clientUmProgramId,
                    businessLobCd: d.businessLobCd,
                    statusCd: d.statusCd,
                    priorAuthId: d.priorAuthId,
                    ageLimitMin: d.ageLimitMin,
                    ageLimitMax: d.ageLimitMax,
                    customRejectMessage: d.customRejectMessage,
                    notes: d.notes,
                    applyDefaultAttachment: d.applyDefaultAttachment,
                    attachmentType: d.attachmentType,
                    attachmentLevel: d.attachmentLevel,
                    attachmentMony: d.attachmentMony,
                    attachmentRxOtcCd: d.attachmentRxOtcCd,
                    attachmentMedDCd: d.attachmentMedDCd
                }))),
                tap(priorAuthorizationRequirements => {
                    this.savedPriorAuthRequirements.push(...priorAuthorizationRequirements);
                })
            )
            .subscribe({
                next: () => {
                    this.loadSavedPriorAuthorizationRequirements();
                },
                error: () => {},
            });
    }

    loadSavedPriorAuthorizationRequirements() {
        this.savedPriorAuthRequirements.forEach(paAuth => {
            if (this.priorAuths.filter(item => item.id === Number(paAuth.clientUmProgramId)).length === 1) {
                if(this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.priorAuthType.value === this.paSelection[0].code
                    // eslint-disable-next-line max-len
                    || this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.priorAuthType.value === this.paSelectionNonMedicare[0].code) {
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].disable();
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.priorAuthType.enable();
                    this.clearAllSelections(paAuth.clientUmProgramId);
                }
                if (paAuth.priorAuthId !== undefined) {
                    // eslint-disable-next-line max-len
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.priorAuthType.patchValue(paAuth.priorAuthId, {emitEvent: false});
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.customClaimRejectMessage.enable();
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.paNotes.enable();
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.applyDefaultCheckBox.enable();
                    if (paAuth.businessLobCd !== 'BM') {
                        if (paAuth.priorAuthId === '2') {
                            this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMin.enable();
                        } else  if (paAuth.priorAuthId === '3') {
                            this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMax.enable();
                        } else  if (paAuth.priorAuthId === '4') {
                            this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMin.enable();
                            this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMax.enable();
                        }
                    }
                }
                // eslint-disable-next-line max-len
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMin.patchValue(paAuth.ageLimitMin, {emitEvent: false});
                // eslint-disable-next-line max-len
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.ageLimitMax.patchValue(paAuth.ageLimitMax, {emitEvent: false});
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.customClaimRejectMessage.
                    patchValue(paAuth.customRejectMessage, {emitEvent: false});
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.paNotes.patchValue(paAuth.notes, {emitEvent: false});
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.applyDefaultCheckBox.
                    patchValue(paAuth.applyDefaultAttachment==='Yes' ? true : false, {emitEvent: false});
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.attachmentType.
                    patchValue(paAuth.attachmentType, {emitEvent: false});
                this.buildAttachmentLevels(paAuth.attachmentType, Number(paAuth.clientUmProgramId), false);
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.attachmentLevel.
                    patchValue(paAuth.attachmentLevel, {emitEvent: false});
                if (paAuth.attachmentMony) {
                    const mony = paAuth.attachmentMony.split('');
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.attachmentMonyDD.
                        patchValue([...mony.map(m => m)],{emitEvent: false});
                }
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.coverageMiscRxOtc.
                    patchValue(paAuth.attachmentRxOtcCd, {emitEvent: false});
                this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.coverageMiscMedD.
                    patchValue(paAuth.attachmentMedDCd, {emitEvent: false});
                if (paAuth.applyDefaultAttachment==='Yes' || !paAuth.applyDefaultAttachment) {
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.applyDefaultCheckBox.patchValue(true, {emitEvent: false});
                    this.setDefaultSelection(paAuth.clientUmProgramId);
                } else {
                    this.priorAuthFormGroups[paAuth.clientUmProgramId].controls.applyDefaultCheckBox.patchValue(false, {emitEvent: false});
                    this.setDefaultSelection(paAuth.clientUmProgramId);
                }
                this.mappedStatus[paAuth.clientUmProgramId] = paAuth.statusCd;
                this.displayAgeLimitMinKey[paAuth.clientUmProgramId] = ((paAuth.priorAuthId === this.paSelectionNonMedicare[2].code
                    // eslint-disable-next-line max-len
                    || paAuth.priorAuthId === this.paSelectionNonMedicare[4].code)  && paAuth.businessLobCd !== 'BM');
                this.displayAgeLimitMaxKey[paAuth.clientUmProgramId] =((paAuth.priorAuthId === this.paSelectionNonMedicare[3].code
                    // eslint-disable-next-line max-len
                    || paAuth.priorAuthId === this.paSelectionNonMedicare[4].code) && paAuth.businessLobCd !== 'BM');
                this.addAgeLimitMaxGapKey[paAuth.clientUmProgramId] = paAuth.priorAuthId === this.paSelectionNonMedicare[4].code;
            }
        });
    }

    enableAgeLimitSelects(umProgramId: number) {
        const controls = this.priorAuthFormGroups[umProgramId].controls;
        if (controls.priorAuthType.value === this.paSelectionNonMedicare[2].code) {
            controls.ageLimitMin.enable();
            controls.ageLimitMin.patchValue('');
            setTimeout(() => {
                this.ageLimitMin.toArray()[0]?.inputField.nativeElement.focus();
            });
        } else if (controls.priorAuthType.value === this.paSelectionNonMedicare[3].code) {
            controls.ageLimitMax.enable();
            controls.ageLimitMax.patchValue('');

        } else if (controls.priorAuthType.value === this.paSelectionNonMedicare[4].code) {
            controls.ageLimitMin.enable();
            controls.ageLimitMin.patchValue('');
            controls.ageLimitMax.enable();
            controls.ageLimitMax.patchValue('');
            setTimeout(() => {
                this.ageLimitMin.toArray()[0]?.inputField.nativeElement.focus();
            });

        }
    }

    editAgeValue(umProgramId: number) {
        this.ageLimitError[umProgramId] = false;
        this.hasAgeLimitError = false;
    }

    savePriorAuthorization() {
        const priorAuthorizationRequirements = this.buildPriorAuthorization();
        if (priorAuthorizationRequirements.length > 0 && !this.hasAgeLimitError) {
            this.recommendationService.savePriorAuthorizationRequirements(priorAuthorizationRequirements)
                .pipe(take(1))
                .subscribe({
                    error: ()=> {
                        this.isPriorAuthSavedEmitter.emit(false);
                    },
                    complete:() => {
                        const isIncomplete = !priorAuthorizationRequirements.every(data => data.statusCd !== '1');
                        this.isPriorAuthIncompleteEmitter.emit(isIncomplete);
                        this.isPriorAuthSavedEmitter.emit(true);
                    },
                });
        }
    }

    buildPriorAuthorization(): PriorAuthorizationRequirements[] {
        const priorAuthorizationRequirements: PriorAuthorizationRequirements[] = [];
        this.priorAuths.forEach(pa => {
            this.priorAuthFormGroups[pa.id].markAllAsTouched();
            const priorAuthorizationRequirement: PriorAuthorizationRequirements = {
                encryptedRecordId: null,
                clientUmProgramId: null,
                businessLobCd: null,
                statusCd: null,
                priorAuthId: null,
                ageLimitMin: null,
                ageLimitMax: null,
                customRejectMessage: null,
                notes: null,
                applyDefaultAttachment: null,
                attachmentType: null,
                attachmentLevel: null,
                attachmentMony: null,
                attachmentRxOtcCd: null,
                attachmentMedDCd: null
            };

            priorAuthorizationRequirement.encryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
            priorAuthorizationRequirement.clientUmProgramId = pa.id.toString();
            priorAuthorizationRequirement.businessLobCd = this.medispanTrackerResponse.businessLobCd;
            priorAuthorizationRequirement.priorAuthId =
                this.priorAuthFormGroups[pa.id].controls.priorAuthType.value === 'N/A' ? null :
                    this.priorAuthFormGroups[pa.id].controls.priorAuthType.value === undefined ? null :
                        this.priorAuthFormGroups[pa.id].controls.priorAuthType.value;
            priorAuthorizationRequirement.ageLimitMin = this.validateAgeValue(this.priorAuthFormGroups[pa.id].controls.ageLimitMin.value);
            priorAuthorizationRequirement.ageLimitMax = this.validateAgeValue(this.priorAuthFormGroups[pa.id].controls.ageLimitMax.value);
            if (this.medispanTrackerResponse.businessLobCd !== 'BM' && priorAuthorizationRequirement.priorAuthId === '4') {
                if (priorAuthorizationRequirement.ageLimitMin !== null && priorAuthorizationRequirement.ageLimitMax !== null) {
                    if (Number(priorAuthorizationRequirement.ageLimitMin) > Number(priorAuthorizationRequirement.ageLimitMax)) {
                        this.ageLimitError[pa.id] = true;
                        this.hasAgeLimitError = true;
                    } else {
                        this.ageLimitError[pa.id] = false;
                    }
                } else {
                    if (priorAuthorizationRequirement.ageLimitMin !== null && priorAuthorizationRequirement.ageLimitMax === null) {
                        this.ageLimitError[pa.id] = true;
                        this.hasAgeLimitError = true;
                    } else {
                        this.ageLimitError[pa.id] = false;
                    }
                }
            }
            priorAuthorizationRequirement.customRejectMessage = this.priorAuthFormGroups[pa.id].controls.customClaimRejectMessage.value;
            priorAuthorizationRequirement.notes = this.priorAuthFormGroups[pa.id].controls.paNotes.value;
            if (this.priorAuthFormGroups[pa.id].controls.priorAuthType.value !== this.paSelection[0].code) {
                priorAuthorizationRequirement.applyDefaultAttachment =
                    this.priorAuthFormGroups[pa.id].controls.applyDefaultCheckBox.value===true ? 'Yes' : 'N/A';
                priorAuthorizationRequirement.attachmentType =
                    this.priorAuthFormGroups[pa.id].controls.attachmentType.value === '' ? null :
                        this.priorAuthFormGroups[pa.id].controls.attachmentType.value;
                priorAuthorizationRequirement.attachmentLevel = this.priorAuthFormGroups[pa.id].controls.attachmentLevel.value;
                priorAuthorizationRequirement.attachmentMony = (this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD.value !== null
                    && this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD.value !== '')
                    ? this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD.value?.join('') === 'N/A' ? null
                        : this.priorAuthFormGroups[pa.id].controls.attachmentType.value === ATTACHMENT_LEVEL.GPI ?
                            this.priorAuthFormGroups[pa.id].controls.attachmentMonyDD.value?.join('') : null : null;
                priorAuthorizationRequirement.attachmentRxOtcCd = this.priorAuthFormGroups[pa.id].controls.coverageMiscRxOtc.value;
                priorAuthorizationRequirement.attachmentMedDCd = priorAuthorizationRequirement.businessLobCd !== 'BM' ? null :
                    this.priorAuthFormGroups[pa.id].controls.coverageMiscMedD.value;
            }
            priorAuthorizationRequirement.statusCd = this.getStatusCd(priorAuthorizationRequirement);
            this.mappedStatus[pa.id] = priorAuthorizationRequirement.statusCd;
            priorAuthorizationRequirements.push(priorAuthorizationRequirement);
        });
        return priorAuthorizationRequirements;
    }

    validateAgeValue(age: any): string {
        let ageValue = '';
        if (isNaN(age)) {
            if (age !== undefined) {
                ageValue = age['formattedSearchResult'];
            } else {
                ageValue = null;
            }
        } else {
            if (age === '') {
                ageValue = null;
            } else {
                ageValue = age;
            }
        }
        return ageValue;
    }

    getStatusCd(priorAuth: PriorAuthorizationRequirements): string {
        let priorAuthStatus = '0';
        if (priorAuth.priorAuthId !== null) {
            if (priorAuth.applyDefaultAttachment === 'Yes') {
                if (priorAuth.businessLobCd !== 'BM' && ['2','3','4'].includes(priorAuth.priorAuthId)) {
                    priorAuthStatus = priorAuth.priorAuthId === '2' ? this.setPriorAuthStatus(priorAuth.ageLimitMin) :
                        priorAuth.priorAuthId === '3' ? this.setPriorAuthStatus(priorAuth.ageLimitMax) :
                            priorAuth.priorAuthId === '4' ?
                                this.setPriorAuthStatusMinMax(priorAuth.ageLimitMin, priorAuth.ageLimitMax) : '2';
                } else {
                    priorAuthStatus = '2';
                }
            } else {
                priorAuthStatus = priorAuth.attachmentType === ATTACHMENT_LEVEL.GPI ?
                    this.setPriorAuthStatusAttachment(priorAuth.attachmentType, priorAuth.attachmentLevel, priorAuth.attachmentMony) :
                    priorAuth.attachmentType === ATTACHMENT_LEVEL.NDC ?
                        priorAuthStatus = this.setPriorAuthStatus(priorAuth.attachmentLevel) : '1';
            }
        }
        return priorAuthStatus;
    }

    setPriorAuthStatus(obj) {
        return obj !== null ? '2' : '1';
    }

    setPriorAuthStatusMinMax(min, max) {
        return min !== null ? max != null ? '2' : '1' : '1';
    }

    setPriorAuthStatusAttachment(type, level, mony) {
        return type !== null ? level !== null ? mony !== '' ? '2' : '1' : '1' : '1';
    }

}
