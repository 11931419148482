import {Router} from '@angular/router';
import {PRODUCT_OVERVIEW_SOURCE} from '../../../enum/ProductOverviewSource';
import {Component, Injectable, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {Title} from '@angular/platform-browser';
import { MedispanTrackerResponse } from 'src/app/model/MedispanTrackerResponse';
import {ADHOC_UPDATE_TYPES} from '../../../service/tracker-data/tracker-data.service';
import {UM_PROGRAM_TYPE} from '../../../default-values/DefaultValues';
import {Formulary} from '../../../model/Formulary';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {TREE_LEVELS} from '../recommendation-left-navigation/TreeData';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-recommendations-form',
    templateUrl: './recommendations-form.component.html',
    styleUrls: ['./recommendations-form.component.scss']
})
export class RecommendationsFormComponent implements OnInit {
    @ViewChild('exitRecommendationsModalRef') public exitRecommendationsModalRef: TemplateRef<any>;
    TREE_LEVELS = TREE_LEVELS;
    subscriptions: Subscription[] = [];
    selectedFormulary;
    selectedUmProgram;
    selectedProductDrugCoverage;
    businessLobCD;
    statusId = undefined;
    blob;
    fromSource: PRODUCT_OVERVIEW_SOURCE = PRODUCT_OVERVIEW_SOURCE.RECOMMENDATION_FORM;
    disableSaveButton = true;
    saveRecommendationsTooltip = 'Edit the form to save';
    isFormularySavedButtonClicked = false;
    isPdcSavedButtonClicked = false;
    isUmProgramSavedButtonClicked = false;
    medispanTrackerResponse: MedispanTrackerResponse;
    tabs: string[];
    isUpdateTypeGpiAdHoc!: boolean;
    programIndexSelected: number;

    constructor(
        private _router: Router,
        private _recommendationService: RecommendationService,
        private _titleService: Title
    ) {
        this.initialize();
    }

    initialize() {
        this.selectedFormulary = this._router.getCurrentNavigation().extras.state?.formulary;
        this.selectedUmProgram = this._router.getCurrentNavigation().extras.state?.umProgram;
        this.selectedProductDrugCoverage = this._router.getCurrentNavigation().extras.state?.productDrugCoverage;
        this.medispanTrackerResponse = this._router.getCurrentNavigation().extras?.state?.medispanTrackerResponse;
        this.businessLobCD = this._router.getCurrentNavigation().extras.state?.medispanTrackerResponse.businessLobCD;
        this.blob = this._router.getCurrentNavigation().extras.state?.medispanTrackerResponse.businessLob;
        this.tabs = this._router.getCurrentNavigation().extras?.state?.tabs;
        this.programIndexSelected = this._router.getCurrentNavigation().extras?.state?.programIndex;
        this.statusId = this.medispanTrackerResponse?.statusId;
        this.isUpdateTypeGpiAdHoc = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC ||
            this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC;
    }

    ngOnInit(): void {
        if (this.selectedFormulary && this.programIndexSelected === TREE_LEVELS.FORMULARY.code) {
            this._router.navigateByUrl(`/recommendationsForm#formulary-${this.selectedFormulary?.formularyNumber}`);
        } else if (this.selectedUmProgram && this.programIndexSelected > TREE_LEVELS.FORMULARY.code
            && this.programIndexSelected < TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code) {
            // eslint-disable-next-line max-len
            const anchorAbbr = this.selectedUmProgram.umProgramTypeCd === UM_PROGRAM_TYPE[0].code ? 'pa' :
                (this.selectedUmProgram.umProgramTypeCd === UM_PROGRAM_TYPE[1].code ? 'step-therapy' : 'quantity-limit');
            // eslint-disable-next-line max-len
            this._router.navigateByUrl(`/recommendationsForm#${anchorAbbr}-${this.selectedUmProgram?.umProgramName}-${this.selectedUmProgram?.rank}`);
        } else {
            // eslint-disable-next-line max-len
            this._router.navigateByUrl(`/recommendationsForm#product-drug-coverage-${this.selectedProductDrugCoverage?.productDrugCoverageName}-${this.selectedProductDrugCoverage?.rank}`);
        }
        this.setHtmlPageTitle();
    }

    setHtmlPageTitle(): void {
        this._titleService.setTitle(this.isUpdateTypeGpiAdHoc? 'CLR - GPI Requirement Form' : 'CLR - Product Requirement Form');
    }

    onPanelFormularySelectionChange(formulary: Formulary) {
        this.selectedFormulary = formulary;
    }

    onPanelUmProgramSelectionChange(umProgram: ClientUmProgram) {
        this.selectedUmProgram = umProgram;
    }

    onPanelProductDrugCoverageSelectionChange(productDrugCoverage: ClientProductDrugCoverage) {
        this.selectedProductDrugCoverage = productDrugCoverage;
    }

    navigateToRecommendationsTable() {
        if (this.isFormularySavedButtonClicked) {
            this._recommendationService.newGetLoadRecommendationsComponentGridDataEvent(this.medispanTrackerResponse.encryptedRecordId);
        }
        if (this.isPdcSavedButtonClicked) {
            this._recommendationService
                .newGetLoadProductDrugCoverageRecommendationsComponentGridDataEvent(this.medispanTrackerResponse.encryptedRecordId);
        }
        if (this.isUmProgramSavedButtonClicked) {
            this._recommendationService
                .newGetLoadUmProgramRecommendationsComponentGridDataEvent(this.medispanTrackerResponse.encryptedRecordId);
        }
        this._router.navigate(['productOverview'], {queryParams: {back: 'back'}});
    }

    isFormularyFormSaved(event: boolean) {
        this.isFormularySavedButtonClicked = event;
    }

    isProductDrugCoverageSaved(event: boolean) {
        this.isPdcSavedButtonClicked = event;
    }

    isUmProgramFormSaved(event: boolean) {
        this.isUmProgramSavedButtonClicked = event;
    }

    onPanelTreeChange(updatedIndex: number) {
        this.programIndexSelected = updatedIndex;
    }
}
