<div id="errorBanner"></div>
<div id="successBanner"></div>
<h1 *ngIf="navigatedFrom === navigationSourcePage.FORMULARY_RESEARCH">Formulary Search</h1>
<h1 *ngIf="navigatedFrom === navigationSourcePage.DRUG_RESEARCH">Drug Search</h1>
<span>
    <app-formulary-drug-search (selectedLobs)="filterFormularyGrid($event)"
                               (selectedFormularies)="filterFormularyGrid($event)"
                               (drugSearchCriteria)="findDrugs({criteria : $event})"
                               [showLobSpinner]="showLobSpinner"
                               [showFormularySpinner]="showFormularySpinner"
                               [linesOfBusiness]="linesOfBusiness"
                               [formularies]="formularyData"
                               [displayOption]="displayOption"
                               [drugIndicatorList]="drugIndicatorList">
    </app-formulary-drug-search>
</span>
<cvs-loader-overlay-container id="spinner" [isLoading]="showSpinner" loadingMessage="Loading">
    <ng-container  *ngIf="showFormularyDrug">
        <app-drug-list
            #drugListComponent
            [selectedLobs]="selectedLobs"
            [formularyDrugAttachmentResponse]="formularyDrugAttachmentResponse"
            [detailCellRendererParams]="detailCellRendererParams"
            [masterDetail]="true"
            [filteredFormularyData]="filteredFormularyData">
        </app-drug-list>
    </ng-container >
    <ng-container *ngIf="showUniverseList">
        <app-drug-universe-list
            #drugUniverseListComponent
            [universalDrugData]="masterDrugResponses">
        </app-drug-universe-list>
    </ng-container>
</cvs-loader-overlay-container>
