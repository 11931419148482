import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cms-submission-tracking',
    templateUrl: './cms-submission-tracking.component.html',
    styleUrls: ['./cms-submission-tracking.component.scss']
})
export class CmsSubmissionTrackingComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
