import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {Observable} from 'rxjs';
import {MedispanTrackerResponse} from '../../model/MedispanTrackerResponse';
import {ENVIRONMENT} from '../../environments/environment';
import {TrackerUpdate} from '../../model/TrackerUpdate';
import {WorkflowResponse} from '../../model/WorkflowResponse';
import {CommonCommon} from '../../model/CommonCommon';
import {AdhocCreationDetail} from '../../model/AdhocCreationDetail';
import {AdhocCreation} from '../../model/AdhocCreation';
import {map} from 'rxjs/operators';
import {getHierarchyUpdateTypeForMedispan, getHierarchyUpdateTypeForWorkflow} from '../../mapper/hierarchyUpdateType';
import {MedispanSetting} from '../../model/MedispanSetting';
import {ApprovalTrackingCase} from '../../model/ApprovalTrackingCase';
import {RECORD_STATUS} from '../../enum/RecordStatus';
import {ArchiveRecordsWithCaseDetails} from '../../model/ArchiveRecordsWithCaseDetails';

// eslint-disable-next-line no-shadow
export enum ADHOC_UPDATE_TYPES {
    NDC_TO_GPI_ADHOC = 'NDC to GPI Ad Hoc',
    GPI_ADHOC = 'GPI Ad Hoc',
    NDC_ADHOC = 'NDC Ad Hoc',
    GPI_TO_NDC_ADHOC = 'GPI to NDC Ad Hoc'
}

export interface MedispanSettingFlag {
    data: string;
}

@Injectable({
    providedIn: 'root'
})

export class TrackerDataService {

    NDC_TO_GPI_ADHOC_TYPE = 'NDC_TO_GPI';
    private _isMedispanSettingsModified = false;

    constructor(private cvshttp: CVSHttpClient) {
    }

    getCurrentReport(index: number): Observable<MedispanTrackerResponse[]> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/currentReport/${index}`).pipe(
            map(currentReportData => {
                currentReportData?.forEach(item => {
                    const hierarchyNewUpdateType = getHierarchyUpdateTypeForMedispan(item.updateType);
                    item.hierarchy = hierarchyNewUpdateType.hierarchy;
                });
                return currentReportData?.sort((v1, v2) => v1.hierarchy - v2.hierarchy);
            }));
    }

    getPreviousReport(): Observable<MedispanTrackerResponse[]> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/previousReport`).pipe(
            map(previousReportData => {
                previousReportData?.forEach(item => {
                    const hierarchyNewUpdateType = getHierarchyUpdateTypeForMedispan(item.updateType);
                    item.hierarchy = hierarchyNewUpdateType.hierarchy;
                });
                return previousReportData?.sort((v1, v2) => v1.hierarchy - v2.hierarchy);
            }));
    }

    getWorkflowData(status: number, superClientIndex: number, startDate?: string, endDate?: string): Observable<WorkflowResponse[]> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/workflowData/${status}/${superClientIndex}` +
            `?startDate=${startDate}&endDate=${endDate}`).pipe(
            map(workflowData => {
                workflowData?.forEach(item => {
                    item.ndc ??= 'N/A';
                    item.labelName ??= 'N/A';
                    const hierarchyNewUpdateType = getHierarchyUpdateTypeForWorkflow(item.updateType);
                    item.hierarchy = hierarchyNewUpdateType.hierarchy;
                });
                return workflowData?.sort((v1, v2) => v1.hierarchy - v2.hierarchy);
            })
        );
    }

    startWorkflow(trackerUpdateList: TrackerUpdate[], superclientIndex: number): Observable<any> {
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/startWorkflow`, {
            trackerUpdateList,
            superclientIndex
        });
    }

    transformDrugRowDataToAdhoc(rowData: CommonCommon[], convertNdcToGpi: boolean, businessLob?: string): AdhocCreationDetail[] {
        const adhoc: AdhocCreationDetail[] = [];
        rowData.forEach(rd => {
            adhoc.push({
                gpi: rd.drugAttachmentIndicator,
                gpiName: rd.drugMasterResponse.genericName,
                ndc: convertNdcToGpi ? null : rd.drugMasterResponse.ndc,
                labelName: rd.drugMasterResponse.labelName,
                adhocType: convertNdcToGpi ? this.NDC_TO_GPI_ADHOC_TYPE : rd.drugAttachmentIndicatorType
            });
        });
        return adhoc;
    }

    createAdhoc(superClientIndex: number, adhocCreationBody: AdhocCreation) {
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/adhoc/create/${superClientIndex}`, adhocCreationBody);
    }

    createAdhocByDetail(superClientIndex: number, adhocCreationBody: AdhocCreation) {
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/adhoc/createByDetail/${superClientIndex}`, adhocCreationBody);
    }

    findAdhocDuplicates(superClientIndex: number, adhocCreationBody: AdhocCreation): Observable<MedispanTrackerResponse[]> {
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/adhoc/findDuplicates/${superClientIndex}`, adhocCreationBody);
    }

    saveMedispanSetting(index: number, blobCd: string, medispanSetting: MedispanSetting) {
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/saveSettings/${index}/${blobCd}`, medispanSetting);
    }

    getMedispanSetting(index: number, blobCd: string): Observable<MedispanSetting> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/settings/${index}/${blobCd}`);
    }

    isMedispanSettingsModified(): boolean {
        return this._isMedispanSettingsModified;
    }

    setMedispanSettingsModified(value: boolean) {
        this._isMedispanSettingsModified = value;
    }

    createCaseAndUpdateStatus(approvalTrackingCase: ApprovalTrackingCase, superclientIndex: number) {
        // eslint-disable-next-line max-len
        return this.cvshttp.post(`${ENVIRONMENT.backendBaseUrl}medispan/createCaseAndUpdateStatus/${superclientIndex}`, approvalTrackingCase);
    }

    getRecordsForStatusWithCase(recordStatus: RECORD_STATUS, superClientIndex: number) {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/getRecordsForStatusWithCase/${recordStatus}/${superClientIndex}`);
    }
    // eslint-disable-next-line max-len
    getArchiveWorkflowData(status: number, superClientIndex: number, startDate?: string, endDate?: string): Observable<ArchiveRecordsWithCaseDetails[]> {
        return this.cvshttp.get(`${ENVIRONMENT.backendBaseUrl}medispan/archiveStatusRecords/${status}/${superClientIndex}` +
            `?startDate=${startDate}&endDate=${endDate}`).pipe(
            map(workflowData => {
                workflowData?.forEach(item => {
                    item.ndc ??= 'N/A';
                    item.labelName ??= 'N/A';
                    const hierarchyNewUpdateType = getHierarchyUpdateTypeForWorkflow(item.updateType);
                    item.hierarchy = hierarchyNewUpdateType.hierarchy;
                });
                return workflowData?.sort((v1, v2) => v1.hierarchy - v2.hierarchy);
            })
        );
    }
}
