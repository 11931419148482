import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, of, timer} from 'rxjs';
import {AgGridEvent, ColDef, GridOptions, RowNode, ValueFormatterParams} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {AppService} from '../service/app/app.service';
import {FormularyService} from '../service/formulary/formulary.service';
import {MatSelect} from '@angular/material/select';
import {ReportService} from '../service/report/report.service';
import {CVSAutocompleteSelectComponent, CVSBannerService, CVSBannerType} from 'angular-component-library';
import {UserService} from '../service/user/user.service';
import {SubmittedReportResponse} from '../model/SubmittedReportResponse';
import {convertDateToMidnightISO, dateFormatter, dateTruncation, isValidDate} from '../utility/utility';
import {last} from 'rxjs/operators';
import {REPORT_STATUS} from '../enum/ReportStatus';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
// eslint-disable-next-line max-len
import {
    CVSAutocompleteSelectComponentErrorTypes
} from 'angular-component-library/completed/autocomplete-select/autocomplete-select.component';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import { FORMULARY_DATE_BOUNDARY } from '../enum/FormularyDateBoundary';
import moment from 'moment';

@Component({
    selector: 'app-reports',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.scss']
})
export class ReportsPageComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('reportTypeFormField', {static: false}) reportTypeFormFieldComponent: MatSelect;
    @ViewChild('formularyList', {static: false}) formularyListComponent: CVSAutocompleteSelectComponent;
    formularyOptions = new Observable();
    errorToolTipText = 'Please request a new report';
    reportsPageData: SubmittedReportResponse[] = [];
    subscriptions = [];
    fromDate = new Date();
    toDate;
    minDate = '1995-01-01T06:00:00';
    maxDate = '2039-12-31T06:00:00';
    singleDateSelected = true;
    selectedFormulary = null;
    selectedReportType = null;
    downloadButtonDisabled = true;
    downloadCount = 0;
    SUCCESS_HEADLINE = 'Report successfully requested';
    ERROR_HEADLINE = 'Error: Unable to submit the report request';
    ERROR_BODY = 'A problem occurred in the submission process. Please try again.';
    errorHeaderText = 'Error: Invalid required fields';
    errorMessage = 'Please complete or correct all errored fields in order to request report.';
    downloadReportTooltip = 'Select at least 1 report to download';
    formularyErrorMessage = {required: 'Error: Select a formulary'} as CVSAutocompleteSelectComponentErrorTypes;
    reportFormGroup = new UntypedFormGroup({
        reportType: new UntypedFormControl(null, [Validators.required]),
        formulary: new UntypedFormControl(null)
    });
    popupParent: HTMLElement | null = document.querySelector('body');

    reportsTypes = [{
        code: 'COMPLETE_FORMULARY',
        description: 'Complete Formulary'
    },
    {
        code: 'FDDS',
        description: 'Formulary Detail Data Set (FDDS)'
    },
    {
        code: 'DRUG_LIST_CONTENT',
        description: 'Drug List Content'
    }
    ];

    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: true,
        autoHeight: true
    };

    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];

    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;

    columnDef: ColDef[] = [
        {
            headerName: 'Report Type', field: 'reportType', filter: 'agSetColumnFilter', width: 276,
            headerCheckboxSelection: true,
            checkboxSelection: true
        },
        {
            headerName: 'Status', field: 'status', filter: 'agSetColumnFilter', width: 145,
            cellRenderer: this.displayStatus,
            cellEditorPopupPosition: 'under',
            tooltipField: 'status',
            tooltipComponent: 'reportCustomTooltipComponent',
        },
        {
            headerName: 'Requested Date', field: 'createdTs', filter: 'agSetColumnFilter', width: 145,
            valueFormatter: params => dateFormatter(params.data.createdTs)
        },
        {
            headerName: 'Completed Date', field: 'modifiedTs', filter: 'agSetColumnFilter', width: 145,
            valueFormatter: params => this.getCompletedDate(params)
        },
        {
            headerName: 'File Name', field: 'fileName', filter: 'agSetColumnFilter', width: 900,
            valueFormatter: params => this.getFileName(params)
        },
    ];

    reportsPageGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDef,
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
        isRowSelectable: (params: RowNode) => this.isCheckboxEnabled(params)
    };

    constructor(private appService: AppService,
        private formularyDataAPIService: FormularyService,
        private reportService: ReportService,
        private alertService: CVSBannerService,
        private userService: UserService) {
    }

    ngOnInit(): void {
        this.retrieveAllFormularies();
        this.subscriptions.push(timer(0, 60000).subscribe(x =>
            this.refreshReports()
        ));
    }

    ngAfterViewInit() {
        (this.formularyListComponent as any).focus = () => document.getElementById('formulary').getElementsByTagName('input')[0].focus();
    }

    retrieveAllFormularies() {
        const superClientIndex = this.appService.getSuperClientContext().id;
        this.subscriptions.push(this.formularyDataAPIService.getFormularyData(superClientIndex)
            .subscribe(formularyResponse => {
                if (formularyResponse) {
                    formularyResponse.forEach(formulary => {
                        formulary.description = `${formulary.formularyNumber} - ${formulary.clientFormularyName}`;
                    });
                }
                this.formularyOptions = of(formularyResponse);
            }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.alertService.close();
    }

    getDates(event) {
        this.fromDate = event[0];
        this.toDate = event[1];
        this.singleDateSelected = event[2];
    }

    submitReportRequest() {
        const superClientIndex = this.appService.getSuperClientContext().id;
        const fromDate = this.fromDate;
        const toDate = this.toDate;
        this.selectedReportType = this.reportFormGroup.controls['reportType'].value;
        this.selectedFormulary = this.reportFormGroup.controls['formulary'].value;

        if (!this.displayErrorBannerWhenFieldsAreEmpty()) {
            if (this.selectedReportType && this.selectedFormulary &&
                (this.singleDateSelected && this.isDateValid(fromDate))
                || (!this.singleDateSelected && this.isDateValid(fromDate) && this.isDateValid(toDate))) {
                this.subscriptions.push(this.reportService.submitReport(
                    superClientIndex,
                    this.selectedFormulary.formularyNumber,
                    this.selectedReportType,
                    convertDateToMidnightISO(fromDate),
                    convertDateToMidnightISO(toDate)
                ).pipe(last())
                    .subscribe(() => {
                        this.retrieveSubmittedReports();
                    },
                    () => {
                        this.onReportError();
                        this.retrieveSubmittedReports();
                    },
                    () => {
                        this.onReportComplete();
                    }));
            }
        }
        return true;
    }

    isDateValid(date: Date) {
        const maximumDate = new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE);
        return date !== undefined && date !== null && !moment(date).isBefore(moment(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE))
            && !moment(date).isAfter(moment(maximumDate));
    }

    displayErrorBannerWhenFieldsAreEmpty(): boolean {
        let errorAsChanged = false;
        this.reportFormGroup.controls.reportType.markAsTouched();
        this.reportFormGroup.controls.formulary.markAsTouched();
        const fromDate = this.changeDateToIsoString(this.fromDate);
        const toDate = this.changeDateToIsoString(this.toDate);
        if (this.reportFormGroup.controls.reportType.invalid) {
            errorAsChanged = true;
        } else if (this.reportFormGroup.controls.formulary.invalid) {
            errorAsChanged = true;
        } else if (this.singleDateSelected && (!isValidDate(fromDate))) {
            errorAsChanged = true;
        } else if (!this.singleDateSelected && ((!isValidDate(fromDate) || !isValidDate(toDate)) ||
            (new Date(fromDate) > new Date(toDate)))) {
            errorAsChanged = true;
        }

        if (errorAsChanged) {
            const errorBannerData = {
                bannerType: CVSBannerType.Error,
                hideX: false,
                outletId: '#errorBanner',
                headline: this.errorHeaderText,
                body: this.errorMessage,
            } as CVSBannerComponentData;
            this.alertService.sendAlert(errorBannerData);
        }
        return errorAsChanged;
    }

    changeDateToIsoString(date: Date) {
        const value = date ? dateTruncation(date.toISOString()) : null;
        return value;
    }

    displayStatus(params) {
        if (params.value === REPORT_STATUS.PENDING) {
            return '<span><i style="color:#E67300; font-size: 10px; padding-right: 5px;" class="material-icons">' +
                'fiber_manual_record</i>' + params.value + '</span>';
        } else if (params.value === REPORT_STATUS.IN_PROGRESS) {
            return '<span><i style="color:#FFD216; font-size: 10px; padding-right: 5px;" class="material-icons">' +
                'fiber_manual_record</i>' + params.value + '</span>';
        } else if (params.value === REPORT_STATUS.COMPLETED) {
            return '<span><i style="color:#A7CE39; font-size: 10px; padding-right: 5px;" class="material-icons">' +
                'fiber_manual_record</i>' + params.value + '</span>';
        } else if (params.value === REPORT_STATUS.ERROR) {
            return '<div style="display: flex">' +
                '<div><i style="color:#eb0000; font-size: 10px; padding-right: 5px;" class="material-icons">' +
                'fiber_manual_record</i></div>' +
                '<div>' + params.value + '</div>' +
                '<i id="errorIcon" class="material-icons" style="font-size:20px; padding-top: 4px; color: #333333">info_outline</i>' +
                ' </div>';
        }
        return params.value;
    }

    onSelectionChanged(event: AgGridEvent) {
        this.downloadButtonDisabled = event.api.getSelectedNodes().length === 0;
    }

    downloadAllSelectedReports() {
        if (!this.downloadButtonDisabled) {
            this.reportsPageGridOptions.api.getSelectedNodes().forEach(n => {
                this.downloadReport(n.data.fileName, n.data.downloadLink);
                n.setSelected(false);
            });
        }
    }

    downloadReport(fileName: string, downloadLink: string) {
        this.downloadCount++;
        this.reportService.downloadReport(downloadLink).subscribe((data) => {
            const downloadURL = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = fileName;
            link.click();
            this.downloadCount--;
        });
    }

    refreshReports() {
        this.retrieveSubmittedReports();
        this.reportsPageGridOptions.api.redrawRows();
    }

    private onReportError() {
        const alert = {
            headline: this.ERROR_HEADLINE,
            body: this.ERROR_BODY,
            bannerType: CVSBannerType.Error,
            hideX: true,
            outletId: '#reportAlertBanner',
        };
        this.alertService.sendAlert(alert);
    }

    private onReportComplete() {
        const alert = {
            headline: this.SUCCESS_HEADLINE,
            body: 'An email will be sent to ' + this.userService.getUser().basicUserInfo.email + ' when the report is ready.',
            bannerType: CVSBannerType.Success,
            outletId: '#reportAlertBanner',
        };
        this.alertService.sendAlert(alert);
    }

    private isCheckboxEnabled(params): boolean {
        return params.data.status === REPORT_STATUS.COMPLETED;
    }

    private retrieveSubmittedReports() {
        this.subscriptions.push(this.reportService.loadSubmittedReportInformation().subscribe(data => {
            this.reportsPageData = data;
        }));
    }

    private getCompletedDate(params: ValueFormatterParams) {
        if (params.data.status === REPORT_STATUS.COMPLETED) {
            return dateFormatter(params.data.modifiedTs);
        }
        return '';
    }

    private getFileName(params: ValueFormatterParams) {
        if (params.data.status === REPORT_STATUS.COMPLETED) {
            return params.data.fileName;
        }
        return '';
    }
}
