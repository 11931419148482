import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FORMULARY_DATE_BOUNDARY } from 'src/app/enum/FormularyDateBoundary';
import { dateTruncation } from 'src/app/utility/utility';

export function validDateRange(control: AbstractControl): ValidatorFn | any {
    if ((new Date(control.value) >= new Date(dateTruncation(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE))) &&
        (new Date(control.value) <= new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE))) {
        return null;
    }
    return { matEndDateInvalid : true};
}
