<p>Expand the case(s) below to see all the products and/or GPIs associated to the case.</p>
<cvs-loader-overlay-container
        id="crmReportSpinner"
        [isLoading]="crmReportDownloadSpinner"
        [loadingMessage]="'Downloading Report...'">
<div class="export-button-container">
    <button id="exportBtn" mat-stroked-button color="primary" [disabled]="!hasRowsSelect" (click)="exportCRMReport()">
        Export Clinical Requirements Matrix
    </button>
</div>
<app-cvs-ag-grid #workflowOperationsAndImplGrid id="workflowOperationsAndImplGrid"
                 style="width: 100%"
                 class="ag-theme-balham"
                 [gridOptions] = "approvalCasesGridOptions"
                 [modules]="modules"
                 [rowData]="approvalCasesData"
                 [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                 [popupParent]="popupParent"
                 [disableCvsExport]="true"
                 [masterDetail]="true"
                 [detailCellRendererParams]="detailCellRendererParams"
                 [context]="context"
                 [style.height]="gridHeight"
                 [detailRowAutoHeight] = "true"
                 (firstDataRendered)="onFirstDataRendered($event)"
                 (rowDataUpdated)="onRowDataUpdated($event)"
                 (selectionChanged)="onSelectionChanged($event)"
>
</app-cvs-ag-grid>

</cvs-loader-overlay-container>