<div class="form-group-base floating-box">
    <div style="margin-left: 5px; margin-bottom: 10px">
        <div class="product-drug-coverage-hdr-container">
            <h1 id="productDrugCoverageHeader">Product & Drug Coverages ({{productDrugCoverages?.length}})</h1>
            <span class="right-justified">
                <button class="pds-btn-primary back-and-save-button" (click)="saveProductDrugCoverages()">Save Requirements</button>
            </span>
        </div>
    </div>
    <div id="fieldCardsContainer"  style="overflow-y:auto;height: 63vh">
        <div class="product-drug-coverage-hdr-button">
            <p id="tierStatement">All fields marked with an asterisk (*) are required</p>
            <span class="right-justified-btn">
                <button class="pds-btn-white">
                    <mat-icon svgIcon="collapse--xs"></mat-icon>
                    Collapse All
                </button>
                <button class="pds-btn-white">
                    <mat-icon svgIcon="expand--xs"></mat-icon>
                    Expand All
                </button>
            </span>
        </div>
        <ng-container>
            <mat-accordion multi>
                <mat-expansion-panel [@.disabled]="true" #panel *ngFor="let drug of productDrugCoverages"
                                     [expanded]="panelOpenState" [formGroup]="productDrugCoverageFormGroups[drug.id]">
                    <mat-expansion-panel-header class="product-drug-coverage-collapse-hdr">
                        <mat-panel-title id="productDrugCoverageTitle">
                            <div id="product-drug-coverage-{{drug.productDrugCoverageName}}-{{drug.rank}}" class="anchorTarget"></div>
                            <div class="product-drug-coverage-div-hdr"><h2>{{drug.productDrugCoverageName}}</h2></div>
                            <div class="product-drug-coverage-div-hdr-icon"><app-custom-state id="recommendationStatusAll" [status]="mappedStatus[drug.id]"></app-custom-state></div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-card>
                        <div id="tierProductDrugAttachmentGridAll" class="sub-field-font">
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-select placeholder="Attachment" formControlName="attachment" (selectionChange)="enableTextAreas($event, drug.id)">
                                        <mat-option *ngFor="let attachment of attachments" [value]="attachment.code">
                                            {{attachment.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                        <div id="tierProductDrugTextAreas" class="sub-field-font-text-areas">
                            <mat-card-content>
                                <mat-form-field id="productDrugCoverageRejectionMessage" appearance="outline" class="rejection-textarea-submission">
                                    <mat-label>Custom Claim Reject Message</mat-label>
                                    <textarea
                                            matInput
                                            maxlength="100"
                                            disablePastingSpecialCharacters="true"
                                            disableSpecialCharacters="true"
                                            formControlName="customClaimRejectMessage"
                                    ></textarea>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-content>
                                <mat-form-field id="productDrugCoverageNotes" appearance="outline" class="notes-textarea-submission">
                                    <mat-label>Notes</mat-label>
                                    <textarea
                                            matInput
                                            maxlength="100"
                                            disablePastingSpecialCharacters="true"
                                            disableSpecialCharacters="true"
                                            formControlName="pdNotes"
                                    ></textarea>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                        <div>
                            <mat-checkbox formControlName="applyDefaultCheckBox"
                                    (change)="setDefaultSelection(drug.id)">
                                Apply Default Attachment</mat-checkbox>
                        </div>
                        <div id="tierAttachmentGridAll" class="sub-field-font">
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-select placeholder="Attachment Type" formControlName="attachmentType" (selectionChange)="buildAttachmentLevels($event.value, drug.id, false)">
                                        <mat-option *ngFor="let attachmentType of attachmentTypes" [value]="attachmentType">
                                            {{attachmentType}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Type</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-select placeholder="Attachment Level" formControlName="attachmentLevel">
                                        <mat-option *ngFor="let level of attachmentLevelsKey[drug.id.toString()]" [value]="level[1]">({{(level[0].replace('_', ' '))}}) {{level[1]}}</mat-option>
                                    </mat-select>
                                    <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Level</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-content *ngIf="['GPI'].includes(productDrugCoverageFormGroups[drug.id].controls.attachmentType.value)">
                                <mat-form-field>
                                    <mat-select #attachmentMony
                                                multiple
                                                placeholder="Attachment MONY"
                                                formControlName="attachmentMonyDD">
                                        <mat-checkbox #attachmentMonyCheckbox
                                                      class="margin"
                                                      [indeterminate]="monyIndeterminates[drug.id]"
                                                      formControlName="attachmentMonyCheckbox"
                                                      click="$event.preventDefault()"
                                                      (change)="toggleAllSelection(drug.id)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let data of attachmentMonyList"
                                                    [value]="data.code"
                                                    (click)="onAttachmentMonyDDChange(drug.id)">{{data.description}}</mat-option>
                                    </mat-select>
                                    <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-content *ngIf="[undefined, null, 'NDC'].includes(productDrugCoverageFormGroups[drug.id].controls.attachmentType.value)">
                                <mat-form-field>
                                    <app-smart-input
                                            placeholder="Attachment MONY"
                                            formControlName="monyCode">
                                    </app-smart-input>
                                    <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                        <div id="tierAttachmentRxOtcAll" class="sub-field-font">
                            <mat-card-content>
                                <mat-form-field>
                                    <mat-select matInput placeholder="Attachment Rx/OTC" formControlName="coverageMiscRxOtc">
                                        <mat-option *ngFor="let data of rxOtcList"
                                                    [value]="data.code">{{data.description}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                            <mat-card-content *ngIf="blob === BUSINESS_LOB.MEDICARE">
                                <mat-form-field>
                                    <mat-select  matInput placeholder="Attachment Med D" formControlName="coverageMiscMedD">
                                        <mat-option *ngFor="let data of medDList"
                                                    [value]="data.code">{{data.description}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card-content>
                        </div>
                    </mat-card>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </div>
</div>
