<app-cvs-ag-grid
    #workflowMedispanGrid id="workflowMedispanGrid"
    style="width: 100%"
    class="ag-theme-balham"
    [gridOptions] = "workflowMedispanGridOptions"
    [modules]="modules"
    [rowData]="workflowMedispanData"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [paginationPageSize]="20"
    [paginationEnabled]="true"
    (firstDataRendered)="onFirstDataRendered()"
    [popupParent]="popupParent"
    [disableCvsExport]="true"
    [style.height]="gridHeight"
    (cellKeyDown)="onCellKeyDown($event)">
</app-cvs-ag-grid>

