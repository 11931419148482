import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {CvsAgGridComponent} from '../common/cvs-grid/cvs-ag-grid/cvs-ag-grid.component';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {ColDef, FirstDataRenderedEvent, GridOptions, RowDataUpdatedEvent} from '@ag-grid-community/core';
import {MasterDrugResponse} from '../model/MasterDrugResponse';
import {CustomNavigationComponent} from '../common/custom-navigation/custom-navigation.component';
import {PRODUCT_OVERVIEW_SOURCE} from '../enum/ProductOverviewSource';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import { Location } from '@angular/common';

@Component({
    selector: 'app-drug-universe-list',
    templateUrl: './drug-universe-list.component.html',
    styleUrls: ['./drug-universe-list.component.scss']
})
export class DrugUniverseListComponent implements OnInit, OnChanges {
    @ViewChild('drugUniverseListGrid', {static: true}) drugUniverseList: CvsAgGridComponent;
    @Input() universalDrugData: MasterDrugResponse[];
    popupParent: HTMLElement | null = document.querySelector('body');

    modules = [SetFilterModule, ColumnsToolPanelModule, MenuModule, RichSelectModule, ClientSideRowModelModule, ExcelExportModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        headerClass: 'grid-Drug-Header',
        filter: true,
        suppressSizeToFit: true,
        cellClass: 'excelString'
    };
    columnDefs: ColDef[];
    drugUniverseListGridOptions: GridOptions;
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    searchResultMessage;
    gridHeight!: string;

    constructor(private location: Location) {
        this.columnDefs = [
            { headerName: 'GPI', field: 'gpi', filter: 'agSetColumnFilter', pinned: 'left', width: 100 },
            { headerName: 'GPI Name', field: 'gpiName', filter: 'agSetColumnFilter', pinned: 'left', width: 100 },
            {
                headerName: 'NDC', field: 'ndc', filter: 'agSetColumnFilter', pinned: 'left', width: 120,
                cellRenderer: CustomNavigationComponent,
                cellRendererParams: {
                    source: PRODUCT_OVERVIEW_SOURCE.DRUG_SEARCH,
                    navigatedFromLink: this.location.path()
                }
            },
            { headerName: 'NDC Name', field: 'ndcName', filter: 'agSetColumnFilter', pinned: 'left', width: 100 },
            { headerName: 'RXCUI', field: 'rxcui', filter: 'agSetColumnFilter', width: 84 },
            { headerName: 'Obsolete Date', field: 'obsoleteDate', filter: 'agSetColumnFilter', width: 126 },
            { headerName: 'Multisource Code', field: 'multiSourceCode', filter: 'agSetColumnFilter', width: 146 },
            { headerName: 'Active/Inactive', field: '', filter: 'agSetColumnFilter', width: 129 },
            { headerName: 'RxOTC', field: 'rxOtc', filter: 'agSetColumnFilter', width: 85 },
            { headerName: 'Third Party Exception', field: 'thirdPartyException', filter: 'agSetColumnFilter', width: 168 },
            { headerName: 'Repackaging', field: 'repackaged', filter: 'agSetColumnFilter', width: 119 },
            { headerName: 'Med D', field: 'medD', filter: 'agSetColumnFilter', width: 82 },
            { headerName: 'Maintenance', field: 'maintenance', filter: 'agSetColumnFilter', width: 119 },
            { headerName: 'ROA', field: 'routeOfAdmin', filter: 'agSetColumnFilter', width: 69 },
            { headerName: 'DEA', field: 'deaCode', filter: 'agSetColumnFilter', width: 67 },
            { headerName: 'FDA App Type', field: 'fdaAppType', filter: 'agSetColumnFilter', width: 127 },
            { headerName: 'Pkg UOM', field: '', filter: 'agSetColumnFilter', width: 100 },
            { headerName: 'Pkg Size', field: '', filter: 'agSetColumnFilter', width: 100 },
            { headerName: 'Pkg Qty', field: 'packagedQuantity', filter: 'agSetColumnFilter', width: 99 },
            { headerName: 'Total Pkg Qty', field: 'totalPackagedQuantity', filter: 'agSetColumnFilter', width: 123 },
            { headerName: 'B/G/T', field: 'brandNameCode', filter: 'agSetColumnFilter', width: 79 },
            { headerName: 'DosageForm', field: 'dosageForm', filter: 'agSetColumnFilter', width: 119 },
            { headerName: 'Strength', field: '', filter: 'agSetColumnFilter', width: 100 },
            { headerName: 'Medispan Therapeutic Category', field: 'therapeuticCategory', filter: 'agSetColumnFilter', width: 235 },
            { headerName: 'Medispan Therapeutic Class', field: 'therapeuticClass', filter: 'agSetColumnFilter', width: 209 },
            { headerName: 'Part Labeler', field: '', filter: 'agSetColumnFilter', width: 119 },
            { headerName: 'NDSE', field: 'ndse', filter: 'agSetColumnFilter', width: 75 },
            { headerName: 'AWP Pricing', field: 'awpPricing', filter: 'agSetColumnFilter', width: 115 },
            { headerName: 'Protected Class', field: 'protectedClass', filter: 'agSetColumnFilter', width: 139 },
            { headerName: 'DDID', field: 'ddid', filter: 'agSetColumnFilter', width: 72 },
            { headerName: 'FDA Approved Indication', field: 'fdaApprovedIndication', filter: 'agSetColumnFilter', width: 187 },
            { headerName: 'Dosage Administration', field: 'dosageAdministration', filter: 'agSetColumnFilter', width: 174 },
            { headerName: 'How Supplied', field: 'howSupplied', filter: 'agSetColumnFilter', width: 124 },
            { headerName: 'Effective Date', field: 'effectiveDate', filter: 'agSetColumnFilter', width: 125 },
        ];

        this.drugUniverseListGridOptions = {
            defaultColDef: this.defaultColDef,
            columnDefs: this.columnDefs,
            pagination: false,
            embedFullWidthRows: true,
            suppressLoadingOverlay: true,
            excelStyles: [{
                id: 'excelString',
                dataType: 'String'
            }],
            defaultExcelExportParams: {
                sheetName: 'Drug Search Results'
            },
            onGridReady: (e) => this.createSearchResultMessage()
        };
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.createSearchResultMessage();
    }

    onFirstDataRendered($event: FirstDataRenderedEvent) {
        const columnIds: string[] = ['gpi', 'gpiName', 'ndc', 'ndcName'];
        this.drugUniverseListGridOptions.columnApi.autoSizeColumns(columnIds, true);
        this.setGridRowHeight($event.api.getDisplayedRowCount());
    }

    onRowDataUpdated(event: RowDataUpdatedEvent) {
        this.setGridRowHeight(event.api.getDisplayedRowCount());
    }

    setGridRowHeight(displayedRowCount: number) {
        if (displayedRowCount >= 11) {
            this.gridHeight = '65vh';
        } else {
            this.gridHeight = '';
            this.drugUniverseListGridOptions.domLayout = 'autoHeight';
        }
    }

    createSearchResultMessage() {
        this.searchResultMessage = `${this.universalDrugData ? this.universalDrugData.length : 'Loading'} Result(s)`;
    }
}
