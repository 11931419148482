<div class="notes">
    <label for="notes" class="notes-label"> Notes </label>
    <textarea
        [(ngModel)]="note"
        (blur)="saveNote()"
        type="text"
        id="notes"
        class="notes-textarea"
        placeholder="Copy paste or enter notes here"
        [disabled]="isDisabled">
    </textarea>
</div>
